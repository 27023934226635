import styled from 'styled-components';

export const Wrapper = styled.div`
  .custom-collapse {
    border-radius: 12px;
    overflow: hidden;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    background-color: ${({ theme }) => theme.colors.offWhite};
    display: flex;
    align-items: center;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: ${({ theme }) => theme.colors.darkGray};
    height: 100%;
    transform: translateY(-100%);
    right: 8px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: calc(var(--font-size-adjustment) * 16px);
    font-weight: 600;
    padding: ${({ theme }) => theme.spacings[4]};
    padding-left: ${({ theme }) => theme.spacings[6]};
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    padding: ${({ theme }) => theme.spacings[4]};
    padding-left: ${({ theme }) => theme.spacings[6]};
  }

  .ant-collapse-item {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    max-width: 740px;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    margin-right: ${({ theme }) => theme.spacings[7]};
  }

  .ant-collapse-header {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Title = styled.div`
  width: 90%;
  font-weight: 600;
  font-size: calc(var(--font-size-adjustment) * 16px);
  line-height: 160%;
  color: ${({ theme }) => theme.colors.darkGray};
`;
