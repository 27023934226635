const getUsedFilters = rawFilters => {
  const _filters = {};
  Object.entries(rawFilters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value && value.length > 0) {
        _filters[key] = value;
      }
    } else {
      if (value || typeof value === 'boolean') {
        _filters[key] = value;
      }
    }
  });
  return _filters;
};

export default getUsedFilters;
