import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { surveyTypesNew, userRoles } from '../../../constants';
import {
  PARTICIPANT_SESSION_DETAILS,
  SURVEY_QUESTIONS_URL
} from '../../../constants/navigationRoutes';
import { checkIfCutoffDateHasPassed } from '../../../helpers/sessions';
import useSearchParams from '../../../hooks/useSearchParams';

const useEvent = ({
  session,
  confirmRegistration,
  resetConfirmRegistration,
  participantResponses,
  getPublicSessionById,
  fetchParticipantSessions,
  userId,
  role,
  fetchParticipantResponses,
  participantSessions,
  participantResponsesLoading,
  fetchSessionLoading,
  joinWaitingList,
  fetchParticipantSessionsLoading,
  selectRole
}) => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const { id } = useParams();

  const [registrationLoading, setRegistrationLoading] = useState();

  const allPreCourseSurveysCompleted = useMemo(() => {
    const relativeProgrammes =
      session?.programmes?.map(
        programme => programme._id?.toString() || programme.toString()
      ) || [];

    return participantResponses
      ?.filter(res => res.surveyType === surveyTypesNew.PRE)
      ?.some(res => {
        const responseProgrammes = res.programmes?.map(String);
        return relativeProgrammes.every(programmeId =>
          responseProgrammes.includes(programmeId)
        );
      });
  }, [session?.programmes, participantResponses]);

  const isAlreadyRegistered = useMemo(() => {
    return !!participantSessions?.find(
      _session => _session._id === id && _session.status === 'confirmed'
    );
  }, [id, participantSessions]);

  const isAlreadyOnWaitingList = useMemo(
    () =>
      !!participantSessions?.find(
        _session => _session._id === id && _session.status === 'waiting'
      ),
    [id, participantSessions]
  );

  const maxCapacityReached = useMemo(() => session?.spacesLeft < 1, [
    session?.spacesLeft
  ]);

  const hasCutoffDatePassed = useMemo(
    () => checkIfCutoffDateHasPassed(session?.registrationCutoffDate),
    [session?.registrationCutoffDate]
  );

  const isRegistrationEnabled = useMemo(
    () =>
      session._id &&
      !maxCapacityReached &&
      !hasCutoffDatePassed &&
      !isAlreadyRegistered &&
      userId &&
      !fetchSessionLoading &&
      ((role === userRoles.participant &&
        !participantResponsesLoading &&
        !fetchParticipantSessionsLoading &&
        participantResponses &&
        participantSessions) ||
        role === userRoles.trainer),
    [
      session._id,
      maxCapacityReached,
      hasCutoffDatePassed,
      isAlreadyRegistered,
      userId,
      role,
      participantResponsesLoading,
      fetchSessionLoading,
      fetchParticipantSessionsLoading,
      participantResponses,
      participantSessions
    ]
  );

  const isJoinWaitingListEnabled = useMemo(
    () =>
      session._id &&
      !hasCutoffDatePassed &&
      !isAlreadyRegistered &&
      !isAlreadyOnWaitingList &&
      userId &&
      !fetchSessionLoading &&
      ((role === userRoles.participant &&
        !participantResponsesLoading &&
        !fetchParticipantSessionsLoading &&
        participantResponses &&
        participantSessions) ||
        role === userRoles.trainer),
    [
      session._id,
      hasCutoffDatePassed,
      isAlreadyRegistered,
      isAlreadyOnWaitingList,
      userId,
      role,
      participantResponsesLoading,
      fetchSessionLoading,
      fetchParticipantSessionsLoading,
      participantResponses,
      participantSessions
    ]
  );

  const handleSurveyNavigation = useCallback(() => {
    if (allPreCourseSurveysCompleted) {
      history.push(PARTICIPANT_SESSION_DETAILS.replace(':id', session._id));
    } else {
      // Redirect to pre-course survey
      history.push({
        pathname: SURVEY_QUESTIONS_URL.replace(':type', surveyTypesNew.PRE)
          .replace(':id', session.shortId)
          .replace(':step', 0),
        search: history.location.search
      });
    }
  }, [allPreCourseSurveysCompleted, history, session._id, session.shortId]);

  const handleEventRegistration = useCallback(
    async (message = '') => {
      setRegistrationLoading(true);

      if (!isRegistrationEnabled) {
        return;
      }

      try {
        const confirmRegistrationResult = await confirmRegistration({
          sessionId: session._id,
          message
        });

        if (confirmRegistrationResult.shouldSwitchRole) {
          await selectRole(userRoles.participant);
        }

        handleSurveyNavigation();
      } catch (error) {
        setRegistrationLoading(false);
      }
    },
    [
      isRegistrationEnabled,
      confirmRegistration,
      session._id,
      handleSurveyNavigation,
      selectRole
    ]
  );

  const fetchData = useCallback(async () => {
    if (userId && role === userRoles.participant) {
      await fetchParticipantResponses();

      await fetchParticipantSessions({
        includeDidNotShowUp: true
      });
    }

    getPublicSessionById(id, history);
  }, [
    userId,
    role,
    getPublicSessionById,
    id,
    history,
    fetchParticipantResponses,
    fetchParticipantSessions
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // After auth case, register the user to the event
  useEffect(() => {
    const registerAfterAuthFlow =
      searchParams.params.registerAfterAuth === 'true' &&
      (searchParams.params.initialSignup === 'true' ||
        searchParams.params.participantLogin === 'true');
    if (registerAfterAuthFlow) {
      if (isRegistrationEnabled) {
        handleEventRegistration();
      } else if (isJoinWaitingListEnabled) {
        handleJoinWaitingList();
      } else if (
        (isAlreadyOnWaitingList || isAlreadyRegistered) &&
        role === userRoles.participant
      ) {
        history.push(PARTICIPANT_SESSION_DETAILS.replace(':id', session._id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    role,
    searchParams.params.initialSignup,
    searchParams.params.registerAfterAuth,
    searchParams.params.participantLogin,
    userId,
    isRegistrationEnabled,
    isJoinWaitingListEnabled,
    isAlreadyOnWaitingList,
    isAlreadyRegistered,
    session
  ]);

  useEffect(() => {
    searchParams.set('sessionId', session?._id?.toString());
    searchParams.set('loginRole', userRoles.participant);

    if (!userId) {
      searchParams.set('registerAfterAuth', true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.shortId]);

  const handleJoinWaitingList = useCallback(async () => {
    if (!isJoinWaitingListEnabled) {
      return;
    }
    setRegistrationLoading(true);
    await joinWaitingList({
      sessionId: session._id,
      message: ''
    });
    handleSurveyNavigation();
  }, [
    joinWaitingList,
    session._id,
    handleSurveyNavigation,
    isJoinWaitingListEnabled
  ]);

  useEffect(() => {
    return () => {
      resetConfirmRegistration();
    };
  }, [resetConfirmRegistration]);

  return {
    handleEventRegistration,
    registrationLoading,
    isAlreadyRegistered,
    handleJoinWaitingList,
    isAlreadyOnWaitingList,
    searchParams,
    maxCapacityReached,
    hasCutoffDatePassed
  };
};

export default useEvent;
