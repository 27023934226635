import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import * as S from './style';

import { fetchProgrammeBySlug } from '../../../actions/programmesActions';
import {
  ALL_SESSIONS_URL,
  ANALYTICS_URL,
  MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES,
  MANAGE_PROGRAMMES_PROGRAMME_EDIT,
  MANAGE_PROGRAMMES_PROGRAMME_MODULES
} from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import Button from '../../common/Button';

const ProgrammeOverview = ({ programme, loading, fetchProgrammeBySlug }) => {
  const history = useHistory();
  const { slug } = useParams();

  useEffect(() => {
    fetchProgrammeBySlug(slug, { populate: 'categories' });
  }, [fetchProgrammeBySlug, slug]);

  if (!programme || loading) {
    return <Spin />;
  }

  const allSessionsForProgrammeUrl = `${ALL_SESSIONS_URL}?programme=${programme._id}`;

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 9, 9]}>
          <T.H2W>{programme.titleShort}</T.H2W>
        </Col>
        <Col w={[4, 3, 3]}>
          <Button
            type="tertiary"
            label="Edit programme"
            onClick={() => {
              history.push(
                MANAGE_PROGRAMMES_PROGRAMME_EDIT.replace(':slug', slug)
              );
            }}
          />
        </Col>
        <S.Divider full />
        <Col w={[4, 8, 8]}>
          <T.P>Everything you need to manage your programme right here.</T.P>
        </Col>
        <Col w={[4, 8, 8]}>
          <S.SectionCardsWrapper>
            <Row inner>
              <Col w={[4, 6, 6]}>
                <S.SectionCard
                  bg="mainPrimary"
                  color="darkGray"
                  order={1}
                  to={MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES.replace(
                    ':slug',
                    slug
                  )}
                >
                  <T.P color="inherit">Categories</T.P>
                </S.SectionCard>
              </Col>
              <Col w={[4, 6, 6]}>
                <S.SectionCard
                  bg="mainSecondary"
                  color="darkGray"
                  order={2}
                  to={MANAGE_PROGRAMMES_PROGRAMME_MODULES.replace(
                    ':slug',
                    slug
                  )}
                >
                  <T.P color="inherit">Modules</T.P>
                </S.SectionCard>
              </Col>
            </Row>
            <Row inner>
              <Col w={[4, 6, 6]}>
                <S.SectionCard
                  bg="darkGray"
                  color="white"
                  order={3}
                  to={allSessionsForProgrammeUrl}
                >
                  <T.P color="inherit">Events</T.P>
                </S.SectionCard>
              </Col>
              <Col w={[4, 6, 6]}>
                <S.SectionCard
                  bg="lightSecondary"
                  color="darkGray"
                  order={4}
                  to={`${ANALYTICS_URL}?programme=${programme.slug}`}
                >
                  <T.P color="inherit">Analytics</T.P>
                </S.SectionCard>
              </Col>
            </Row>
          </S.SectionCardsWrapper>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  programme: state.programmes.programme,
  loading: state.loading.fetchProgrammeLoading
});

const mapActionsToProps = {
  fetchProgrammeBySlug
};

export default connect(mapStateToProps, mapActionsToProps)(ProgrammeOverview);
