import React from 'react';

import Badge from '../../common/Badge';
import DifficultyLevelBars from '../../common/DifficultyLevelBars';
import * as T from '../../common/Typography';

import {
  INDIVIDUAL_PROGRAMME,
  MODULE_URL
} from '../../../constants/navigationRoutes';
import { moduleFormatTypes } from '../../../constants/sessionData';

const renderFormatBadge = isLive => {
  if (!isLive) {
    return (
      <Badge title={moduleFormatTypes.E_LEARNING.label} variant="primary" />
    );
  } else {
    return (
      <Badge title={moduleFormatTypes.LIVE_EVENT.label} variant="tertiary" />
    );
  }
};

export const TableColumns = isTablet => {
  const commonColumns = [
    {
      title: 'Module',
      dataIndex: 'title',
      key: 'title',
      render: (text, row) => (
        <T.Link to={MODULE_URL.replace(':id', row._id)}>{text}</T.Link>
      ),
      width: '30%'
    }
  ];

  const desktopColumns = [
    {
      title: 'Format',
      key: 'isLive',
      dataIndex: 'isLive',
      render: isLive => renderFormatBadge(isLive)
    },
    {
      title: 'Level',
      key: 'difficultyLevel',
      dataIndex: 'difficultyLevel',
      render: text => {
        return <DifficultyLevelBars levelText={text} />;
      }
    }
  ];

  return !isTablet ? commonColumns : [...commonColumns, ...desktopColumns];
};
