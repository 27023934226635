import { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Select } from 'antd';
import { fetchModules } from '../../../actions/modulesActions';

import {
  BasicInput,
  DatePicker,
  Dropdown,
  Radio,
  TextArea,
  TimePicker
} from '../../common/Inputs';

import Button from '../../common/Button';

import Layout from '../../Layouts';
import * as T from '../../common/Typography';

import { userRoles } from '../../../constants';
import { SESSION_CREATED_URL } from '../../../constants/navigationRoutes';
import { audienceTypes } from '../../../constants/sessionData';

import {
  clearInputData,
  createSessionAction,
  resetSessionState,
  storeInputData
} from '../../../actions/sessionAction';
import { fetchAllTrainers } from '../../../actions/trainerAction';

import validate from '../../../validation/schemas/createSession';

import { Divider, Form, InformationBox } from './CreateSession.style';

import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  fetchSessionDetails,
  sessionUpdateAction
} from '../../../actions/groupSessionsAction';
import { fetchProgrammes } from '../../../actions/programmesActions';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import Spin from '../../common/Spin';
import FaceToFaceQs from './FaceToFaceQs';
import OnlineQs from './OnlineQs';
import MDEditor from '../../common/MDEditor';

const { Option } = Select;

const buildInitialFormState = ({ name = '', id = '' }) => ({
  title: '',
  description: '',
  videoLink: null,
  date: '',
  endDate: '',
  startTime: '',
  registrationCutoffDate: null,
  endTime: '',
  sessionType: '',
  selectedProgrammes: [],
  modules: '',
  capacity: null,
  partnerTrainer1: { key: id, label: name },
  partnerTrainer2: { key: '', label: '' },
  location: null,
  address: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    postcode: ''
  },
  online: {
    platform: '',
    link: ''
  },
  extraInfo: '',
  contactEmail: '',
  audience: ''
});

const UpsertSession = ({
  id,
  role,
  name,
  programmesCanDeliver,
  trainersSet,
  loading,
  createdSession,
  fetchAllTrainers,
  createSessionAction,
  addSessionSuccess,
  resetSessionState,
  fetchModules,
  modules: modulesSet,
  fetchProgrammes,
  programmes,
  fetchSessionDetails,
  sessionDetailsLoading,
  sessionDetails,
  sessionUpdateAction,
  fetchModulesLoading,
  fetchProgrammesLoading
}) => {
  const initialFormState = useMemo(() => buildInitialFormState({ name, id }), [
    name,
    id
  ]);

  const { formState, setFormData } = useFormData({
    initialFormState
  });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const isEdit = useLocation().pathname.includes('/edit');

  const { sessionId } = useParams();

  const history = useHistory();
  const [decidedModuleOptions, setDecidedModulesOptions] = useState([]);

  const {
    title,
    date,
    endDate,
    registrationCutoffDate,
    startTime,
    endTime,
    selectedProgrammes,
    modules,
    capacity,
    partnerTrainer1,
    contactEmail,
    partnerTrainer2,
    location,
    address,
    online,
    extraInfo,
    sessionType,
    videoLink,
    audience
  } = formState;

  const onSessionTypeChange = (val, name) => {
    if (val === 'online' && sessionType === 'faceToFace') {
      // clear address
      return setFormData({
        ...formState,
        [name]: val,
        address: initialFormState.address,
        location: initialFormState.location
      });
    } else if (val === 'faceToFace' && sessionType === 'online') {
      // clear online
      return setFormData({
        ...formState,
        [name]: val,
        online: initialFormState.online
      });
    }
    return setFormData({ ...formState, [name]: val });
  };

  const onInputChange = (val, name) => {
    setFormData({ ...formState, [name]: val });
  };

  const onDropdownChange = (val, option, name) => {
    if (!val || !option) return setFormData({ ...formState, [name]: val });
    if (['partnerTrainer1', 'partnerTrainer2'].includes(name)) {
      const { props } = option;
      return setFormData({
        ...formState,
        [name]: { key: val, label: props?.name }
      });
    }

    if (name === 'selectedProgrammes') {
      return setFormData({ ...formState, [name]: val, modules: [] });
    }

    return setFormData({ ...formState, [name]: val });
  };

  const onAddressChange = (val, field) => {
    const { address } = formState;
    setFormData({ ...formState, address: { ...address, [field]: val } });
  };

  const onOnlineChange = (val, field, dropdownName) => {
    const name = dropdownName === 'platform' ? dropdownName : field;
    const { online } = formState;
    setFormData({
      ...formState,
      online: { ...online, [name]: val }
    });
  };

  const onFormSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleUpsertSession();
    }
  };

  const handleUpsertSession = () => {
    setFormData({ loading: true });

    const { current: finalFormState } = finalSubmissionData;

    const payload = {
      ...finalFormState,
      format: sessionType === 'online' ? 'Remote / Online' : 'Face to Face',
      partnerTrainer1: partnerTrainer1?.key,
      partnerTrainer2: partnerTrainer2?.key,
      address: sessionType === 'online' ? null : address,
      meetingPlatform: online?.platform,
      meetingLink: online?.link,
      audience: audience
    };

    delete payload.selectedProgrammes;
    delete payload.sessionType;
    delete payload.online;
    delete payload.loading;

    if (!isEdit) {
      createSessionAction(payload);
    } else {
      sessionUpdateAction(payload, sessionId, history);
    }
  };

  const renderTrainersList = partnerTrainer => {
    if (trainersSet) {
      const sortedTrainersSet = trainersSet.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      );

      return sortedTrainersSet
        .filter(({ _id }) =>
          partnerTrainer?.key ? _id !== partnerTrainer.key : true
        )
        .map(({ name, _id }) => (
          <Option
            key={_id}
            value={_id}
            name={name}
            style={{ textTransform: 'capitalize' }}
          >
            {`${name[0].toUpperCase()}${name.slice(1)}`}
          </Option>
        ));
    }
    return null;
  };

  const decideModuleOptions = useCallback(() => {
    return setDecidedModulesOptions(
      modulesSet
        .filter(module =>
          selectedProgrammes.includes(module.programme?._id || module.programme)
        )
        .filter(
          module =>
            !module.onlyLeadsOrAdminsCanDeliver ||
            [userRoles.admin, userRoles.programmeManager].includes(role)
        )
        .map(({ title, _id }) => ({
          value: _id,
          label: title
        }))
    );
  }, [modulesSet, selectedProgrammes, role]);

  const getProgrammeOptions = useCallback(() => {
    if (
      !programmes ||
      (role === userRoles.trainer && !programmesCanDeliver?.length)
    ) {
      return [];
    }

    const sortedProgrammes = programmes.sort((a, b) =>
      a.titleShort.localeCompare(b.titleShort)
    );

    if (role === userRoles.trainer || role === userRoles.programmeManager) {
      return sortedProgrammes
        .filter(programme =>
          programmesCanDeliver.includes(programme._id.toString())
        )
        .map(programme => ({
          value: programme._id.toString(),
          label: programme.titleShort
        }));
    }

    return sortedProgrammes.map(programme => ({
      value: programme._id.toString(),
      label: programme.titleShort
    }));
  }, [programmes, programmesCanDeliver, role]);

  useEffect(() => {
    decideModuleOptions();
  }, [decideModuleOptions]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    endDate,
    startTime,
    endTime,
    sessionType,
    selectedProgrammes,
    modules,
    capacity,
    partnerTrainer1,
    address,
    online,
    extraInfo,
    contactEmail
  ]);

  useEffect(() => {
    fetchModules({ liveOnly: true });
    fetchProgrammes();

    fetchAllTrainers();

    return () => resetSessionState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addSessionSuccess) {
      history.push(SESSION_CREATED_URL.replace(':id', createdSession.shortId));
    }
  }, [addSessionSuccess, createdSession.shortId, history]);

  useEffect(() => {
    if (isEdit) {
      fetchSessionDetails(sessionId, history);
    }
  }, [isEdit, fetchSessionDetails, sessionId, history]);

  useEffect(() => {
    if (isEdit && sessionDetails._id) {
      setFormData({
        ...sessionDetails,
        modules: sessionDetails.modules?.map(module =>
          typeof module === 'string' ? module : module._id
        ),
        sessionType:
          sessionDetails?.meetingLink && sessionDetails?.meetingPlatform
            ? 'online'
            : 'faceToFace',
        partnerTrainer1: {
          key: sessionDetails?.trainers[0]?._id,
          label: sessionDetails?.trainers[0]?.name
        },
        partnerTrainer2: {
          key: sessionDetails?.trainers[1]?._id,
          label: sessionDetails?.trainers[1]?.name
        },
        online: {
          platform: sessionDetails.meetingPlatform,
          link: sessionDetails.meetingLink
        },
        selectedProgrammes: sessionDetails.programmes?.map(programme =>
          typeof programme === 'string' ? programme : programme._id
        ),
        audience: sessionDetails.audience
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionDetails]);

  const trainerHasNoProgrammes =
    role === userRoles.trainer && programmesCanDeliver.length < 1;

  if (
    sessionDetailsLoading ||
    fetchModulesLoading ||
    fetchProgrammesLoading ||
    !trainersSet ||
    !programmes ||
    (isEdit && !sessionDetails)
  ) {
    return <Spin />;
  }

  return (
    <Layout>
      <T.H1 mb="7">{isEdit ? 'Update' : 'Add'} an event</T.H1>
      {trainerHasNoProgrammes && (
        <InformationBox>
          You can't add new sessions because you are still not authorised to
          deliver any programme.
        </InformationBox>
      )}
      <Divider />
      <Form onSubmit={onFormSubmit}>
        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="date"
          size="large"
          style={{ width: '100%' }}
          required
          label="Start Date"
          mb="6"
          value={date}
          error={validationErrors.date}
          disabled={trainerHasNoProgrammes}
        />
        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="endDate"
          size="large"
          style={{ width: '100%' }}
          required
          label="End Date"
          value={endDate}
          error={validationErrors.endDate}
          mb="6"
          disabled={trainerHasNoProgrammes}
        />
        <TimePicker
          onTimeChange={onInputChange}
          value={startTime}
          label="Start time"
          name="startTime"
          mb="6"
          error={validationErrors.startTime}
          popOver={{
            text:
              'If your session is running across multiple dates, select the general daily start and end time and then add further details in the extra information section below'
          }}
          disabled={trainerHasNoProgrammes}
        />
        <TimePicker
          onTimeChange={onInputChange}
          value={endTime}
          label="End time"
          name="endTime"
          error={validationErrors.endTime}
          mb="6"
          disabled={trainerHasNoProgrammes}
        />
        {/* PUT IN RADIO QUESTION HERE */}
        {/* ADDRESS AND ONLINE FIELDS HERE */}
        <Radio
          selected
          label="How are you delivering this session?"
          required
          name="sessionType"
          error={validationErrors.sessionType}
          handleChange={onSessionTypeChange}
          value={sessionType}
          options={[
            { label: 'Remote / Online', value: 'online' },
            { label: 'Face to Face', value: 'faceToFace' }
          ]}
          disabled={trainerHasNoProgrammes}
        />
        {sessionType === 'faceToFace' && (
          <FaceToFaceQs
            location={location}
            address={address}
            extraInfo={extraInfo}
            onAddressChange={onAddressChange}
            onInputChange={onInputChange}
            onDropdownChange={onDropdownChange}
            error={validationErrors.address}
            extraInfoError={validationErrors.extraInfo}
            disabled={trainerHasNoProgrammes}
          />
        )}
        {sessionType === 'online' && (
          <OnlineQs
            online={online}
            extraInfo={extraInfo}
            onOnlineChange={onOnlineChange}
            onInputChange={onInputChange}
            error={validationErrors.online}
            extraInfoError={validationErrors.extraInfo}
            disabled={trainerHasNoProgrammes}
          />
        )}

        <Dropdown
          selected={selectedProgrammes}
          m={{ mb: '6' }}
          label="Which programme(s) are you delivering?"
          placeholder="Select..."
          required
          options={getProgrammeOptions()}
          multi
          name="selectedProgrammes"
          handleChange={onDropdownChange}
          disabled={trainerHasNoProgrammes}
        />

        {!!selectedProgrammes?.length && (
          <Dropdown
            selected={modules}
            m={{ mb: '6' }}
            label="Which module(s) are you delivering?"
            placeholder="Select as many as you like"
            required
            multi
            options={decidedModuleOptions}
            name="modules"
            handleChange={onDropdownChange}
            error={validationErrors.modules}
            disabled={trainerHasNoProgrammes}
          />
        )}

        <BasicInput
          value={title}
          handleChange={onInputChange}
          mb="6"
          label="Event title"
          placeholder="Type here..."
          name="title"
          helper="If you do not create an event title, a default title will be created for the module(s) you are delivering."
          error={validationErrors.title}
          disabled={trainerHasNoProgrammes}
        />
        <MDEditor
          color="darkGray"
          placeholder="Summary description"
          rows="5"
          value={formState.description}
          onChange={description => onInputChange(description, 'description')}
          label="Summary description"
          m={{ mb: 5 }}
          required
          error={validationErrors.description}
        />

        <BasicInput
          value={videoLink}
          handleChange={onInputChange}
          mb="6"
          label="Event page video link"
          placeholder="Type here..."
          name="videoLink"
          error={validationErrors.videoLink}
          disabled={trainerHasNoProgrammes}
        />

        <BasicInput
          value={capacity}
          handleChange={onInputChange}
          mb="6"
          label="Maximum capacity"
          required
          name="capacity"
          type="number"
          popOver={{
            text:
              'This is the total amount of people allowed to attend the session'
          }}
          error={validationErrors.capacity}
          disabled={trainerHasNoProgrammes}
        />

        <DatePicker
          id="DatePicker"
          onDateChange={onInputChange}
          name="registrationCutoffDate"
          size="large"
          style={{ width: '100%' }}
          label="Registration cut off date"
          value={registrationCutoffDate}
          error={validationErrors.registrationCutoffDate}
          mb="6"
        />
        <Dropdown
          selected={audience}
          name="audience"
          m={{ mb: '6' }}
          label="Audience"
          placeholder="Select"
          options={audienceTypes.map(value => ({
            label: value,
            value
          }))}
          handleChange={onDropdownChange}
          allowClear
        />

        {role === userRoles.admin && (
          <Dropdown
            selected={partnerTrainer1.label}
            m={{ mb: '6' }}
            label="Trainer"
            placeholder="Select"
            required
            options={renderTrainersList(partnerTrainer2)}
            customOptions
            name="partnerTrainer1"
            handleChange={onDropdownChange}
            error={validationErrors.partnerTrainer?.key}
            disabled={trainerHasNoProgrammes}
          />
        )}

        <Dropdown
          selected={partnerTrainer2?.label}
          m={{ mb: '6' }}
          label="Second / partner trainer"
          placeholder="Select"
          options={renderTrainersList(partnerTrainer1)}
          customOptions
          name="partnerTrainer2"
          handleChange={onDropdownChange}
          allowClear
          disabled={trainerHasNoProgrammes}
        />

        <BasicInput
          value={contactEmail}
          handleChange={onInputChange}
          mb="6"
          label="Contact email"
          required
          name="contactEmail"
          popOver={{
            text:
              'This is the email address that will be shared with those who register to attend this session'
          }}
          error={validationErrors.contactEmail}
          disabled={trainerHasNoProgrammes}
        />

        {validationErrors.hasError && (
          <T.P mb="4" mt="2" color="error">
            There are errors, please check above.
          </T.P>
        )}
        <Button
          onClick={onFormSubmit}
          type="primary"
          label="Submit"
          height="40px"
          width="100%"
          loading={loading}
          disabled={loading || trainerHasNoProgrammes}
        />
      </Form>
    </Layout>
  );
};

const mapStateToProps = state => {
  const { trainers } = state.trainers;
  const inputData = state.storeSessionData;
  const trainersSet = [...trainers];
  const programmesCanDeliver = state?.auth?.programmesCanDeliver || [];

  return {
    id: state.auth.userId,
    role: state.auth.role,
    programmesCanDeliver,
    currentUser: state.auth,
    name: state.auth.name,
    trainersSet,
    loading: state.session.loading || state.session.sessionEditLoading,
    addSessionSuccess: state.session.addSessionSuccess,
    inputData,
    createdSession: state.session,
    userObj: state.auth,
    modules: state.modules.modules,
    programmes: state.programmes.programmes,
    sessionDetails: state.sessions.sessionDetails,
    sessionDetailsLoading: state.loading.sessionDetails,
    fetchModulesLoading: state.loading.fetchModulesLoading,
    fetchProgrammesLoading: state.loading.fetchProgrammesLoading
  };
};

export default connect(mapStateToProps, {
  fetchAllTrainers,
  createSessionAction,
  storeInputData,
  clearInputData,
  resetSessionState,
  fetchModules,
  fetchProgrammes,
  fetchSessionDetails,
  sessionUpdateAction
})(UpsertSession);
