import moment from 'moment';
import React, { Component } from 'react';

import { concatModules, getSurveyLink } from '../../../helpers';

import * as T from '../Typography';

// STYLE
import { EmailInfo, Link, List, SessionInfoTitle } from './EmailTemplate.style';

class EmailTemplate extends Component {
  render() {
    const {
      type,
      trainer,
      sessionDate,
      address,
      trainers,
      startTime,
      endTime,
      extraInformation,
      confirmLink,
      preSurveyLink,
      postSurveyLink,
      surveyType,
      shortId,
      scheduleTime,
      meetingPlatform,
      meetingLink,
      extraInfo,
      remote,
      modules,
      contactEmail,
      endDate
    } = this.props;

    let fullAddress = '';

    if (address) {
      const { postcode, addressLine1, addressLine2, town } = address;
      if (postcode || addressLine1 || addressLine2 || town) {
        fullAddress = [addressLine1, addressLine2, town, postcode]
          .filter(item => !!item)
          .join(', ');
      }
    }

    return (
      <EmailInfo>
        {scheduleTime && (
          <div>
            <T.P weight={700} style={{ display: 'inline' }}>
              Scheduled At:{' '}
            </T.P>{' '}
            <span>{scheduleTime}</span>
          </div>
        )}
        <T.P weight={700} mb="5">
          Preview Email
        </T.P>
        <T.P mb="4">Dear course participant,</T.P>
        {
          <FirstParagraph
            type={type}
            trainer={trainer}
            surveyType={surveyType}
            sessionDate={sessionDate}
            contactEmail={contactEmail}
            remote={remote}
            fullAddress={fullAddress}
            meetingPlatform={meetingPlatform}
            meetingLink={meetingLink}
            startTime={startTime}
            endTime={endTime}
            trainers={trainers}
            modules={modules}
            extraInfo={extraInfo}
            endDate={endDate}
          />
        }

        <SecondPargraph
          type={type}
          confirmLink={confirmLink}
          preSurveyLink={preSurveyLink}
          postSurveyLink={postSurveyLink}
          surveyType={surveyType}
          shortId={shortId}
        />

        <T.P mt="4" mb="4">
          {extraInformation}
        </T.P>

        <T.P mt="4" mb="2">
          Sincerely,
        </T.P>

        <T.P>Your team</T.P>
      </EmailInfo>
    );
  }
}

export default EmailTemplate;

const FirstParagraph = ({
  type,
  trainer,
  surveyType,
  sessionDate,
  contactEmail,
  remote,
  fullAddress,
  meetingPlatform,
  meetingLink,
  startTime,
  endTime,
  trainers,
  modules,
  extraInfo,
  endDate
}) => {
  switch (type) {
    case 'registration':
      return (
        <T.P mb="4">
          <strong style={{ textTransform: 'capitalize' }}>{trainer}</strong> has
          invited you to register for an upcoming course training event.
        </T.P>
      );
    case 'surveyLink':
      if (surveyType && surveyType.includes('follow-up')) {
        return (
          <T.P mb="4">
            We{"'"}re looking forward to receiving your{' '}
            {surveyType.includes('3') ? '3' : '6'} months follow up feedback for
            the following training event:
          </T.P>
        );
      }

      if (surveyType && surveyType.includes('post')) {
        return (
          <>
            <T.P mb="4">
              Thank you for attending the session{' '}
              {sessionDate && `on ${moment(sessionDate).format('DD/MM/YYYY')}`}.
            </T.P>
            <T.P mb="4">
              To complete the session and receive your certificate we require
              all participants to complete the post-course evaluation. This will
              help you to keep track of your own progress through the course.
            </T.P>
            <T.P mb="4">
              If you have not done this yet, please click or copy the link below
              to complete the 2-minute evaluation. If you have any issues please
              contact {trainer}
              {contactEmail && ` at ${contactEmail}`}.
            </T.P>
          </>
        );
      }

      return (
        <>
          <T.P mb="4">
            We{"'"}re looking forward to welcoming you at our upcoming training
            session.
          </T.P>
          <List>
            <li>
              <SessionInfoTitle> Session Date:</SessionInfoTitle>{' '}
              {moment(sessionDate).format('DD/MM/YYYY')}
            </li>
            {endDate && (
              <li>
                <SessionInfoTitle>Session End Date:</SessionInfoTitle>{' '}
                {moment(endDate).format('DD/MM/YYYY')}
              </li>
            )}
            {/* face to face */}
            <li>
              <SessionInfoTitle>Location:</SessionInfoTitle>{' '}
              {remote ? 'Online' : fullAddress || 'TBC'}
            </li>

            {/* remote */}
            {remote && type !== 'registration' && (
              <li style={{ textTransform: 'capitalize' }}>
                <SessionInfoTitle>Meeting Platform:</SessionInfoTitle>{' '}
                {meetingPlatform || 'TBC'}
              </li>
            )}
            {remote && type !== 'registration' && (
              <li>
                <SessionInfoTitle>Meeting Link:</SessionInfoTitle>{' '}
                <a href={meetingLink} target="_blank" rel="noreferrer">
                  {meetingLink}
                </a>
              </li>
            )}

            <li>
              <SessionInfoTitle>Time:</SessionInfoTitle>
              {startTime || 'TBC'} {endTime && `to ${endTime}`}
            </li>
            <li>
              <SessionInfoTitle>Trainer(s):</SessionInfoTitle> {trainers}
            </li>
            <li>
              <SessionInfoTitle>Module(s) to be taught:</SessionInfoTitle>{' '}
              {concatModules({ modules })}
            </li>
            {extraInfo && (
              <li>
                <SessionInfoTitle>Extra information:</SessionInfoTitle>{' '}
                {extraInfo}
              </li>
            )}
          </List>
        </>
      );

    case 'reminder':
      return (
        <T.P mb="4">
          This is a friendly reminder related to the following training event:
        </T.P>
      );
    default:
      return null;
  }
};

const SecondPargraph = ({
  type,
  confirmLink,
  preSurveyLink,
  surveyType,
  shortId
}) => {
  switch (type) {
    case 'registration':
      return (
        <>
          <T.P mb="2">
            To confirm your attendance please{' '}
            <Link href={confirmLink}>click this link</Link> or copy the link
            below
          </T.P>
          <Link href={confirmLink}>{confirmLink}</Link>
        </>
      );

    case 'reminder':
    case 'surveyLink':
      if (surveyType && surveyType.includes('follow-up')) {
        return (
          <>
            <T.P>
              To track your own progress and to ensure that our trainings are
              effective we rely on course participants to fill out surveys after
              each session. All the data is anonymised.
            </T.P>
            <T.P>
              To complete the session please click the following link to fill
              out the {surveyType.includes('3') ? '3' : '6'} month follow up
              evaluation.
            </T.P>
            <T.P>
              <a href={getSurveyLink(surveyType, shortId)}>
                Follow up evaluation
              </a>
            </T.P>
          </>
        );
      }
      if (surveyType && surveyType.includes('post')) {
        return (
          <>
            <T.P mb="4">
              <a href={getSurveyLink(surveyType, shortId)}>
                Click to complete evaluation
              </a>
            </T.P>
            <T.P>
              Thank you for completing the evaluation form, this will help us to
              continually improve the training experience and enable us to
              measure the positive impact of training.
            </T.P>
          </>
        );
      }
      return (
        <>
          <T.P mb="4">
            To be able to track your own progress and for us to measure the
            positive impact of training, please ensure you complete the pre
            course evaluation form before attending the session. All the data is
            anonymised. After completing an evaluation form you can immediately
            see your own progress and access any available certificates via the
            app.
          </T.P>
          {preSurveyLink && (
            <>
              <T.P mb="4">
                Please <a href={preSurveyLink}>click this link</a> to fill out
                the pre-evaluation form or copy and paste the url below.
              </T.P>
              <T.P mb="4">
                <a href={preSurveyLink}>{preSurveyLink}</a>
              </T.P>
            </>
          )}
          <T.P>
            Thank you for completing the evaluation form, this will help us to
            continually improve the training experience and enable us to measure
            the positive impact of training.
          </T.P>
        </>
      );

    default:
      return null;
  }
};
