import * as types from '../constants/actionTypes';

const initState = {
  users: null,
  user: null,
  error: null
};

const usersReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.data,
        totalCount: payload.totalCount[0]?.count || 0
      };
    case types.FETCH_USER_SUCCESS:
    case types.UPDATE_USER_INVITATION_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: null,
        user: payload
      };
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        error: payload
      };
    case types.SEND_USER_INVITATION_SUCCESS:
      return {
        ...state,
        userInvitation: payload
      };
    case types.FETCH_USER_INVITATION_DETAILS_SUCCESS:
      return {
        ...state,
        userInvitation: payload
      };
    default:
      return state;
  }
};

export default usersReducer;
