import React from 'react';
import * as S from './style';

// NOTE: To make sure it toggles give a prop called selected
// and change to either 'right' or 'left' depending on what's the opposite
// to what's currently been highlight on the last click

const Toggle = ({ leftText, rightText, style, onClick, outline, ...props }) => {
  return (
    <S.ToggleWrapper {...props} style={style} outline={outline}>
      <S.LeftHalf onClick={() => onClick('left')} outline={outline} {...props}>
        {leftText}
      </S.LeftHalf>
      <S.RightHalf
        onClick={() => onClick('right')}
        outline={outline}
        {...props}
      >
        {rightText}
      </S.RightHalf>
    </S.ToggleWrapper>
  );
};

export default Toggle;
