const Open = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33333 14C2.96667 14 2.65267 13.8693 2.39133 13.608C2.13 13.3467 1.99956 13.0329 2 12.6667V3.33333C2 2.96667 2.13067 2.65267 2.392 2.39133C2.65333 2.13 2.96711 1.99956 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14V12.6667C14 13.0333 13.8693 13.3473 13.608 13.6087C13.3467 13.87 13.0329 14.0004 12.6667 14H3.33333ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z"
      fill={color || '#006AB4'}
    />
  </svg>
);
export default Open;
