import { useTheme } from 'styled-components';
import { MODULE_URL } from './../../../constants/navigationRoutes';

import DifficultyLevelBars from '../../common/DifficultyLevelBars';
import Duration from '../../common/Duration';
import { Col, Row } from '../../common/Grid';
import Markdown from '../../common/Markdown';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import QuoteBox from '../../common/QuoteBox';
import * as T from '../../common/Typography';
import VideoPlayer from '../../common/VideoPlayer';

import { LearningObjectivesRow, MainContentWrapper } from './style';

const ModuleContent = ({ module }) => {
  const { colors } = useTheme();
  return (
    <MaxWidthContainer>
      <Row mt={7}>
        <Col w={[4, 12, 7]}>
          <MainContentWrapper>
            {module.description && module.description.trim().length > 0 && (
              <>
                <T.H3W mt={5} d="block">
                  Summary
                </T.H3W>
                <T.P color="neutralGray" mt={2} mb={2} d="block">
                  <Markdown text={module.description} />
                </T.P>
              </>
            )}
            {/* video */}
            {module.videoLink && (
              <VideoPlayer width="420" videoUrl={module.videoLink} />
            )}
            {module.clinicalOrProfessionalCompetencies &&
              module.clinicalOrProfessionalCompetencies.trim().length > 0 && (
                <>
                  <T.H3W mt={7} d="block">
                    What clinical or professional competencies does the module
                    support?
                  </T.H3W>
                  <T.P color="neutralGray" mt={2} d="block">
                    <Markdown
                      text={module.clinicalOrProfessionalCompetencies}
                    />
                  </T.P>
                </>
              )}

            {module.whoIsItFor && module.whoIsItFor.trim().length > 0 && (
              <>
                <T.H3W mt={7} d="block">
                  Who is the module for?
                </T.H3W>
                <T.P color="neutralGray" mt={2} d="block">
                  <Markdown text={module.whoIsItFor} />
                </T.P>
              </>
            )}

            {module.additionalInfo && module.additionalInfo.trim().length > 0 && (
              <>
                <T.H3W mt={7} d="block">
                  Additional information
                </T.H3W>
                <T.P color="neutralGray" mt={2} d="block">
                  <Markdown text={module.additionalInfo} />
                </T.P>
              </>
            )}

            {module.prerequisiteModules?.length > 0 && (
              <>
                {' '}
                <T.H3W mt={7} d="block">
                  Pre-requisite module(s)
                </T.H3W>
                <T.P color="neutralGray" mt={2} d="block">
                  To complete this module you must first complete:
                </T.P>
                {module?.prerequisiteModules?.map(mod => (
                  <T.P d={'block'} weight={700} color="gray">
                    -{' '}
                    <T.Link
                      weight={700}
                      to={MODULE_URL.replace(':id', mod._id)}
                      color="gray"
                    >
                      {mod.title}
                    </T.Link>
                  </T.P>
                ))}
              </>
            )}
          </MainContentWrapper>
        </Col>
        <Col w={[0, 0, 1]}></Col>
        <Col w={[4, 12, 4]} mtT={7}>
          <LearningObjectivesRow
            inner
            style={{
              backgroundColor: colors.mainPrimary,
              color: colors.darkGray,
              borderRadius: '50px 50px 0px 50px',
              width: '100%'
            }}
          >
            {module.learningObjectives &&
              module.learningObjectives.trim().length > 0 && (
                <>
                  <Col w={[4, 12, 12]}>
                    <T.H2 weight={700} color="inherit" d="block">
                      Learning objectives
                    </T.H2>
                  </Col>
                  <Col w={[4, 12, 12]} mt={4} style={{ width: '100%' }}>
                    <Markdown
                      text={module.learningObjectives}
                      color="inherit"
                    />
                  </Col>
                </>
              )}
            {module.developers && module.developers.trim().length > 0 && (
              <>
                <Col w={[4, 12, 12]} mt={4}>
                  <T.H3 weight={700} color="inherit" d="block">
                    Name/organisation of module developer
                  </T.H3>
                  <T.P color="inherit" mt={2} d="block">
                    {module.developers}
                  </T.P>
                </Col>
              </>
            )}
            <Col w={[4, 12, 12]} mt={4}>
              <T.H3 weight={700} color="inherit" d="block">
                Format
              </T.H3>
              <T.P color="inherit" mt={2} d="block">
                {module.isLive ? 'Live' : 'eLearning'}
              </T.P>
            </Col>
            {module.duration && module.duration.trim().length > 0 && (
              <>
                <Col w={[4, 12, 12]} mt={5}>
                  <T.H3 weight={700} color="inherit" d="block">
                    Module duration
                  </T.H3>
                </Col>
                <Col w={[4, 12, 12]} mt={2}>
                  <Duration duration={module.duration} />
                </Col>
              </>
            )}

            {module?.difficultyLevel && (
              <>
                <Col w={[4, 12, 12]} mt={3}>
                  <T.H3 weight={700} color="inherit" d="block">
                    Level
                  </T.H3>
                </Col>
                <Col w={[4, 12, 12]} mt={2}>
                  <DifficultyLevelBars
                    levelText={module.difficultyLevel}
                    color="darkGray"
                  />
                </Col>
              </>
            )}
          </LearningObjectivesRow>
          {module?.testimonial && (
            <Col w={[4, 12, 12]} mt={4}>
              <QuoteBox quoteString={module.testimonial} />
            </Col>
          )}
        </Col>
      </Row>
    </MaxWidthContainer>
  );
};

export default ModuleContent;
