const Edit = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 10.5v8.625c0 .2462-.0485.49-.1427.7175a1.87622 1.87622 0 01-.4065.6083 1.87622 1.87622 0 01-.6083.4065A1.8755 1.8755 0 0116.125 21H4.875a1.87476 1.87476 0 01-1.32583-.5492A1.87485 1.87485 0 013 19.125V7.875c0-.49728.19754-.97419.54917-1.32583A1.87503 1.87503 0 014.875 6h7.8506"
      stroke={color || 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5597 2.49611a.75252.75252 0 00-.2441-.17812.75291.75291 0 00-.5919-.01428.75337.75337 0 00-.2524.16615l-.5798.57703a.3749.3749 0 000 .53015l.5315.53063a.3757.3757 0 00.1219.08174.37479.37479 0 00.1439.02871.37479.37479 0 00.1439-.02871.3757.3757 0 00.1219-.08174l.5653-.5625c.2859-.28547.3127-.75047.0398-1.04906zm-2.8406 1.72265l-8.4619 8.44684a.42112.42112 0 00-.1082.1843l-.39144 1.1657a.18328.18328 0 00.04612.1817.18264.18264 0 00.0845.0481.18425.18425 0 00.09719-.0019l1.16483-.3915a.42079.42079 0 00.1842-.1082l8.4469-8.46285a.42167.42167 0 00.122-.29672.42168.42168 0 00-.122-.29672l-.4664-.46875a.4219.4219 0 00-.5958 0z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Edit;
