import { validate as _validate, createSchema, fields } from '..';

const {
  optionalCheckbox,
  attendeesEmails,
  shouldConfirmSharingEmailAddress
} = fields;

const groupBookingSchema = createSchema({
  attendeesEmails,
  includeUser: optionalCheckbox,
  hasPermissionToShareEmails: shouldConfirmSharingEmailAddress
});

const attendeesEmailsSchema = createSchema({
  attendeesEmails
});

const validate = (data, validateAttendeesEmailOnly) => {
  if (validateAttendeesEmailOnly) {
    return _validate(attendeesEmailsSchema, data);
  } else {
    return _validate(groupBookingSchema, data);
  }
};

export default validate;
