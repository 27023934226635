import React, { Component } from 'react';
import { BasicInput } from '../../common/Inputs';
import { connect } from 'react-redux';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import { LOGIN_URL } from '../../../constants/navigationRoutes';

import { ForgetPasswordForm } from './ForgetPassword.style';

import { requestUserPasswordReset } from '../../../actions/users';
import Layout from '../../Layouts';

class ForgetPassword extends Component {
  state = {
    email: '',
    errors: {},
    msg: null
  };

  // Check inputs validation then if not valide show error msg

  validateForm = () => {
    const { email } = this.state;
    const errors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors.emailError = '*Please enter your email';
    }

    if (typeof email !== 'undefined') {
      // regular expression for email validation
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors.emailError = '*Please enter valid email.';
      }
    }

    this.setState({
      errors
    });
    return formIsValid;
  };

  onFormSubmit = e => {
    const { email } = this.state;
    const {
      requestUserPasswordReset: requestUserPasswordResetActionCreator
    } = this.props;
    e.preventDefault();
    const isValid = this.validateForm();
    if (isValid) {
      // call action creator then give it email
      requestUserPasswordResetActionCreator(email, this.props.history);
    }
  };

  onInputChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  render() {
    const { email, errors, msg } = this.state;
    const { loading } = this.props;
    const { emailError } = errors;
    const { onInputChange, onFormSubmit } = this;
    return (
      <Layout>
        <T.H1>Forget Your Password!</T.H1>
        <Row mt={7}>
          <Col w={[4, 12, 8]}>
            <ForgetPasswordForm onSubmit={onFormSubmit}>
              <T.P color="gray">
                Please enter your email address and we{"'"}ll send you
                instructions on how to reset your password
              </T.P>
              <Row mt={4} inner>
                <Col w={[4, 12, 8]}>
                  <BasicInput
                    placeholder="Enter your email"
                    name="email"
                    type="text"
                    value={email}
                    onChange={onInputChange}
                    error={emailError}
                  />
                </Col>
              </Row>
              <Row mt={6} inner>
                <Col w={[4, 12, 8]} mb={3}>
                  <Button
                    onClick={onFormSubmit}
                    type="primary"
                    label="Reset Password"
                    height="40px"
                    loading={loading}
                  />
                </Col>
                <Col w={[4, 12, 4]} mb={3}>
                  <Button
                    onClick={() => this.props.history.push(LOGIN_URL)}
                    type="secondary"
                    label="Cancel"
                    height="40px"
                    loading={loading}
                  />
                </Col>
              </Row>

              <T.P color="error">{msg}</T.P>
            </ForgetPasswordForm>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isEmailUnique: state.auth.isEmailUnique,
    checkedUserInfo: state.auth.checkedUserInfo,
    loading: state.loading.forgetPasswordLoading
  };
};

export default connect(mapStateToProps, { requestUserPasswordReset })(
  ForgetPassword
);
