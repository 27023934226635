import {
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  CLEAR_SEARCH
} from '../constants/actionTypes';

const searchReducer = (state = {}, action) => {
  const { type, payload } = action;

  const setCategoryState = msg => ({
    [payload?.category]: { data: payload?.data, msg, error: payload?.error }
  });

  switch (type) {
    case SEARCH_SUCCESS:
      return {
        ...state,
        ...setCategoryState(
          payload?.data?.length > 0 ? 'search_success' : 'search_no_results'
        )
      };
    case SEARCH_FAIL:
      return {
        ...state,
        ...setCategoryState('search_failed')
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        ...setCategoryState('search_cleared')
      };
    default:
      return state;
  }
};

export default searchReducer;
