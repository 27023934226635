import { Table } from 'antd';
import * as T from '../Typography';
import * as S from './style';

const BoostTable = ({
  data,
  columns,
  stripedRows = false,
  pagination = false,
  loading
}) => {
  const formattedColumns = columns.map(column => ({
    ...column,
    title: () => <T.P weight={600}>{column.title}</T.P>,
    ...(!column.render && { render: text => <T.P>{text}</T.P> })
  }));

  return (
    <S.Wrapper stripedRows={stripedRows}>
      <Table
        columns={formattedColumns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
      />
    </S.Wrapper>
  );
};

export default BoostTable;
