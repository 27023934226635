import { Spin } from 'antd';
import { connect } from 'react-redux';
import Layout from '../../Layouts';

import WebsiteHeader from '../../common/WebsiteHeader';

import {
  confirmRegistration,
  resetConfirmRegistration,
  joinWaitingList
} from '../../../actions/confirmRegistration';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { fetchParticipantResponses } from '../../../actions/participantActions';
import { getPublicSessionById } from '../../../actions/sessionAction';
import { selectRole } from '../../../actions/authAction';
import EventContent from './EventContent';
import RegisterSection from './RegisterSection';
import useEvent from './useEvent';

const Event = ({
  userId,
  role,
  session,
  getPublicSessionById,
  fetchSessionLoading,
  fetchParticipantSessions,
  participantSessions,
  confirmRegistration,
  resetConfirmRegistration,
  participantResponses,
  fetchParticipantResponses,
  participantResponsesLoading,
  joinWaitingList,
  fetchParticipantSessionsLoading,
  selectRole
}) => {
  const {
    handleEventRegistration,
    isAlreadyRegistered,
    searchParams,
    maxCapacityReached,
    hasCutoffDatePassed,
    handleJoinWaitingList,
    isAlreadyOnWaitingList
  } = useEvent({
    session,
    confirmRegistration,
    resetConfirmRegistration,
    participantSessions,
    participantResponses,
    getPublicSessionById,
    fetchParticipantSessions,
    userId,
    role,
    fetchParticipantResponses,
    participantResponsesLoading,
    fetchSessionLoading,
    joinWaitingList,
    fetchParticipantSessionsLoading,
    selectRole
  });

  if (fetchSessionLoading || !session) {
    return <Spin />;
  }
  return (
    <Layout layout="website">
      <WebsiteHeader
        title={
          session.title ||
          session.modules?.map(module => module.title).join(', ') ||
          ''
        }
        type="event"
      />
      <EventContent event={session} />

      <RegisterSection
        userId={userId}
        role={role}
        onSubmit={() => handleEventRegistration()}
        searchParams={searchParams}
        isAlreadyRegistered={isAlreadyRegistered}
        hasCutoffDatePassed={hasCutoffDatePassed}
        contactEmail={session?.contactEmail}
        maxCapacityReached={maxCapacityReached}
        isAlreadyOnWaitingList={isAlreadyOnWaitingList}
        onSubmitJoinWaitingList={handleJoinWaitingList}
      />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    httpError: state.error,
    fetchSessionLoading: state.loading.fetchSession,
    session: state.session,
    userId: state.auth.userId,
    role: state.auth.role,
    participantSessions: state.sessions?.participantSessionsByEmail,
    participantResponses: state.participantResponses?.participantResponses,
    participantResponsesLoading: state.loading.participantResponsesLoading,
    fetchParticipantSessionsLoading: state.loading.fetchParticipantSessions
  };
};

export default connect(mapStateToProps, {
  getPublicSessionById,
  fetchParticipantSessions,
  confirmRegistration,
  resetConfirmRegistration,
  fetchParticipantResponses,
  joinWaitingList,
  selectRole
})(Event);
