import React, { Component } from 'react';
import { connect } from 'react-redux';

// ANTD COMPONENTS
import { Select, Tooltip, Button as AntButton } from 'antd';

// COMMON COMPONENTS
import Button from '../../../common/Button';
import * as T from '../../../common/Typography';
import { Row, Col } from '../../../common/Grid';

import { SelecetWrapper, IconsWrapper } from '../SessionDetails.Style';

// STYLE
import { Form, InputDiv } from './InviteAndPromote.style';

const { Option } = Select;

class InviteeList extends Component {
  state = {
    err: ''
  };

  componentDidMount() {
    if (window.ClipboardEvent) {
      let dT = null;
      try {
        dT = new DataTransfer();
      } catch (e) {
        // ignore the error
      }
      const evt = new ClipboardEvent('paste', { clipboardData: dT });
      if (evt.clipboardData || window.clipboardData) {
        (evt.clipboardData || window.clipboardData).setData('text/plain', '');

        document.addEventListener('paste', this.pasteEmails);

        document.dispatchEvent(evt);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.pasteEmails);
  }

  render() {
    const { err } = this.state;
    const {
      loading,
      onCopy,
      onClear,
      onSelectBlur,
      onSelectFocus,
      handleSubmitUpdateAttendees,
      handleUpdateAttendees,
      onTypingEmails,
      participantsEmails = [],
      selectRef
    } = this.props;

    return (
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <T.H1 mb="6" style={{ width: '100%' }}>
            Manage invitees
          </T.H1>
          <T.P mb="6" style={{ maxWidth: '700px' }}>
            Here you can manage a list of people you would like to invite to the
            session. This can be used to then send out emails or just to help
            you keep track of who you are speaking to for this session. You can
            either copy an existing list of emails or input each address
            manually.
          </T.P>
          <T.P style={{ maxWidth: '700px' }}>
            Please note that once they register, they will be automatically
            removed from this list and added to your registered attendees list.
          </T.P>

          <Form>
            <InputDiv>
              <SelecetWrapper>
                <IconsWrapper>
                  <Tooltip placement="top" title="Copy">
                    <AntButton
                      type="primary"
                      icon="copy"
                      ghost
                      onClick={() => onCopy('new')}
                      disabled={!participantsEmails.length}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title="Delete">
                    <AntButton
                      type="danger"
                      icon="delete"
                      ghost
                      onClick={() => onClear('new')}
                      disabled={!participantsEmails.length}
                    />
                  </Tooltip>
                </IconsWrapper>
                <Select
                  mode="tags"
                  size="large"
                  placeholder="emails"
                  onChange={values => handleUpdateAttendees(values, 'new')}
                  onSearch={value => onTypingEmails(value, 'new')}
                  ref={selectRef}
                  value={participantsEmails}
                  style={{ width: '100%', height: '100%' }}
                  onBlur={onSelectBlur}
                  onFocus={onSelectFocus}
                >
                  {participantsEmails &&
                    participantsEmails.map(email => (
                      <Option key={email} value={email}>
                        {email}
                      </Option>
                    ))}
                </Select>
              </SelecetWrapper>
            </InputDiv>
            <div>{err}</div>

            <InputDiv>
              <Button
                type="primary"
                style={{
                  width: '100%',
                  marginTop: '2rem',
                  fontSize: '19px',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  height: 'auto'
                }}
                onClick={() => handleSubmitUpdateAttendees('new')}
                disabled={loading}
                loading={loading}
                label="Update"
              >
                Update
              </Button>
            </InputDiv>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { sessionDetails } = state.sessions;

  return {
    sessionDetails,
    loading: state.session.loading
  };
};

export default connect(mapStateToProps, {})(InviteeList);
