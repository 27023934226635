const Copy = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7188 4H5.78125C4.79749 4 4 4.79749 4 5.78125v6.93755c0 .9837.79749 1.7812 1.78125 1.7812h6.93755c.9837 0 1.7812-.7975 1.7812-1.7812V5.78125C14.5 4.79749 13.7025 4 12.7188 4z"
      stroke={color || 'currentColor'}
      strokeLinejoin="round"
    />
    <path
      d="M11.9844 4L12 3.25a1.75491 1.75491 0 00-.514-1.23598A1.7551 1.7551 0 0010.25 1.5H3.5a2.00592 2.00592 0 00-2 2v6.75a1.7551 1.7551 0 00.51402 1.236c.3279.3279.77226.5127 1.23598.514H4"
      stroke={color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy;
