import {
  PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_REQUEST,
  PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS,
  PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_FAILURE,
  UPDATE_NEWSLETTER_DESIGN_TEMPLATE_REQUEST,
  UPDATE_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS,
  UPDATE_NEWSLETTER_DESIGN_TEMPLATE_FAILURE,
  SUBSCRIBE_NEWSLETTER_REQUEST,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  SUBSCRIBE_NEWSLETTER_FAILURE,
  UNSUBSCRIBE_NEWSLETTER_REQUEST,
  UNSUBSCRIBE_NEWSLETTER_SUCCESS,
  UNSUBSCRIBE_NEWSLETTER_FAILURE
} from '../constants/actionTypes';

const initialState = {
  isLoadingUpdate: false,
  isLoadingPreview: false,
  isLoadingSubscribe: false,
  isSubscribed: false,
  updateError: null,
  previewError: null,
  subscribeError: null,
  updateSuccess: false,
  previewSuccess: false,
  previewData: null
};

const newsletterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoadingPreview: true,
        previewSuccess: false,
        previewError: null
      };
    case PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoadingPreview: false,
        previewSuccess: true,
        previewData: payload
      };
    case PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoadingPreview: false,
        previewSuccess: false,
        previewError: payload
      };

    case UPDATE_NEWSLETTER_DESIGN_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoadingUpdate: true,
        updateSuccess: false,
        updateError: null
      };
    case UPDATE_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
        updateSuccess: true
      };
    case UPDATE_NEWSLETTER_DESIGN_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoadingUpdate: false,
        updateSuccess: false,
        updateError: payload
      };

    case SUBSCRIBE_NEWSLETTER_REQUEST:
      return {
        ...state,
        isLoadingSubscribe: true,
        subscribeError: null
      };
    case SUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isLoadingSubscribe: false,
        isSubscribed: true
      };
    case SUBSCRIBE_NEWSLETTER_FAILURE:
      return {
        ...state,
        isLoadingSubscribe: false,
        subscribeError: payload
      };

    case UNSUBSCRIBE_NEWSLETTER_REQUEST:
      return {
        ...state,
        isLoadingSubscribe: true,
        subscribeError: null
      };
    case UNSUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isLoadingSubscribe: false,
        isSubscribed: false
      };
    case UNSUBSCRIBE_NEWSLETTER_FAILURE:
      return {
        ...state,
        isLoadingSubscribe: false,
        subscribeError: payload
      };

    default:
      return state;
  }
};

export default newsletterReducer;
