import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Modal, Spin } from 'antd';
import moment from 'moment';
import { convertAddressObjToString } from './../../../helpers';
import { Backdrop } from './../../common/Backdrop';
import { INDIVIDUAL_EVENT } from '../../../constants/navigationRoutes';

const DesignUpdatePopup = ({
  isLoadingUpdate,
  isLoadingPreview,
  previewDesignTemplate,
  updateDesignTemplate,
  isModalVisible,
  onCancel,
  sessions
}) => {
  const contentRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState('');

  const formatDateTime = (date, startTime) => {
    const formattedDate = moment(date).format('D MMMM YYYY');
    const formattedTime = moment(startTime, 'HH:mm').format('h:mm a');
    return `${formattedDate} ${formattedTime}`;
  };

  const generateHtmlContent = () => {
    return sessions
      .map(
        session => `
      <a href='${window.location.origin}${INDIVIDUAL_EVENT.replace(
          ':id',
          session._id.toString()
        )}'>${session.title ||
          session.modules.map(module => module.title).join(', ')}</a> <br />
      ${formatDateTime(session.date, session.startTime)} - 
      ${formatDateTime(session.endDate, session.endTime)} <br />
      ${
        session.format === 'Remote / Online'
          ? 'Online'
          : convertAddressObjToString(session.address)
      } <br />
      <br />
    `
      )
      .join('');
  };

  const handleUpdateDesign = async () => {
    try {
      await updateDesignTemplate(htmlContent);
      message.success('Design template updated successfully.');
    } catch (error) {
      console.error(error);
      message.error('Failed to update the design template.');
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `<div class=\"upcoming-events\" style=\"font-family: inherit; text-align: inherit; class-name: upcoming-events\">${contentRef.current.innerHTML}</div>`
      )
      .then(() => {
        message.success('Content copied to clipboard!');
      })
      .catch(error => {
        console.error(error);
        message.error('Failed to copy content.');
      });
  };

  const handlePreviewFullDesign = async () => {
    try {
      const htmlContent = contentRef.current.innerHTML;
      const data = await previewDesignTemplate(htmlContent);
      const previewWindow = window.open('', 'Preview', 'width=800,height=600');
      previewWindow.document.open();
      previewWindow.document.write(data);
      previewWindow.document.close();
    } catch (error) {
      console.error(error);
      message.error('Failed to update the design template.');
    }
  };

  useEffect(() => {
    setHtmlContent(generateHtmlContent());
  }, [sessions]);

  return (
    <Modal
      title="Update Newsletter Design Template"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="copy" onClick={handleCopyToClipboard}>
          Copy HTML to Clipboard
        </Button>,
        <Button key="preview" onClick={handlePreviewFullDesign}>
          Preview Full Design
        </Button>,
        <Button key="update" type="primary" onClick={handleUpdateDesign}>
          Update
        </Button>
      ]}
      bodyStyle={{ maxHeight: '600px', overflowY: 'auto' }}
      width={600}
    >
      {(isLoadingPreview || isLoadingUpdate) && (
        <Backdrop theme={'light'}>
          <Spin size="large" />
        </Backdrop>
      )}
      <div
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          width: '100%',
          minHeight: '150px',
          border: '1px solid #d9d9d9',
          padding: '8px',
          overflowY: 'auto'
        }}
        placeholder="set events..."
      />
    </Modal>
  );
};

export default DesignUpdatePopup;
