import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  max-width: 455px;
  background: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacings[4]};
  border-radius: 12px;
  width: ${({ w }) => w || 'auto'};
`;

export const TextContent = styled.div`
  max-width: 95%;
  padding-left: ${({ theme }) => theme.spacings[3]};
  padding-bottom: ${({ theme }) => theme.spacings[2]};
`;
