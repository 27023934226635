import {
  SET_IS_FONT_LARGE,
  SET_LAYOUT_COLOR,
  SET_LAYOUT_FONT_FAMILY
} from '../constants/actionTypes';

const initialState = {
  isFontLarge: JSON.parse(localStorage.getItem('isFontLarge')) || false,
  layoutColor: localStorage.getItem('layoutColor') || '',
  layoutFontFamily: localStorage.getItem('layoutFontFamily') || ''
};

const accessibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FONT_LARGE:
      return { ...state, isFontLarge: action.payload };
    case SET_LAYOUT_COLOR:
      return { ...state, layoutColor: action.payload };
    case SET_LAYOUT_FONT_FAMILY:
      return { ...state, layoutFontFamily: action.payload };
    default:
      return state;
  }
};

export default accessibilityReducer;
