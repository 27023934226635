const BoostSpeechBubble = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width={width || '164'}
    height={height || '164'}
    viewBox="0 0 164 164"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }}
  >
    <path
      fill={color || '#fcc700'}
      d="M78.5 20.5c22.2-.765 42.2 5.401 60 18.5 21.232 19.09 25.898 41.59 14 67.5a1812.822 1812.822 0 0 1 8 28.5 11.456 11.456 0 0 1-1 2.5c-2.265 1.147-4.598 1.314-7 .5a476.501 476.501 0 0 0-25.5-6.5 373.841 373.841 0 0 1-29.5 8.5c-31.602 5.088-58.769-3.412-81.5-25.5C-2.24 88.761-.407 64.595 21.5 42c16.465-13.987 35.465-21.154 57-21.5Z"
    />
  </svg>
);

export default BoostSpeechBubble;
