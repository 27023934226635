/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';
import SurveyContent from './SurveyContent';
import {
  SessionSurveysWrapper,
  SessionSurveyContainer
} from './SessionSurveys.Style';
import { getAllSurveyLinks } from '../../../../helpers';

class SessionSurveys extends Component {
  state = {};

  render() {
    const { sessionDetails, handleDrawerOpen } = this.props;
    const { _id, shortId, modules, course } = sessionDetails;

    const links = getAllSurveyLinks(modules, shortId, course);

    return (
      <SessionSurveysWrapper>
        <SessionSurveyContainer>
          <Row mb="5">
            <Col w={[4, 12, 12]}>
              <T.P small>
                To share the surveys for this session please share the link with
                your attendees. You can send it directly via email clicking on
                "Email surveys to attendees" or schedule emails using the
                "Schedule emails" option.
              </T.P>
            </Col>
          </Row>
          {links.map((survey, index) => {
            return (
              <SurveyContent
                subId={index}
                preSurvey={links.length > 1 && index === 0}
                type={survey}
                surveyURL={links[index]}
                id={_id}
                key={index}
                handleDrawerOpen={handleDrawerOpen}
              />
            );
          })}
        </SessionSurveyContainer>
      </SessionSurveysWrapper>
    );
  }
}

const mapStateToProps = state => ({
  emailSuccess: state.groups.emailSuccess
});

export default connect(mapStateToProps, {})(SessionSurveys);
