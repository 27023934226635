import * as S from './style';
import * as T from '../Typography';
import Button from '../Button';

const AccountPageHeader = ({
  title,
  subtitle,
  topBtn = { text: '', to: '' },
  mb = '6'
}) => {
  return (
    <S.Container mb={mb}>
      <S.TitleWrapper mb="3">
        <T.H1 color="darkGray" mb="4">
          {title}
        </T.H1>
        {topBtn?.text && (
          <Button primary to={topBtn.to} label={topBtn.text}>
            {topBtn.text}
          </Button>
        )}
      </S.TitleWrapper>
      {subtitle && <T.P color="gray">{subtitle}</T.P>}
    </S.Container>
  );
};

export default AccountPageHeader;
