import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

const Duration = ({ duration }) => {
  return (
    <S.Wrapper>
      <Icon icon="clock" width="20" height="20" />
      <T.P color="gray" weight="bold" ml={1}>
        {duration}
      </T.P>
    </S.Wrapper>
  );
};

export default Duration;
