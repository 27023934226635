const organisations = [
  'County Durham and Darlington NHS Foundation Trust',
  'Cumberland Council',
  'Cumbria, Northumberland, Tyne and Wear NHS Foundation Trust',
  'Darlington Borough Council',
  'Durham County Council',
  'Gateshead Council',
  'Gateshead Health NHS Foundation Trust',
  'GP Practice - please specify below',
  'Hartlepool Borough Council',
  'Member of the public',
  'Middlesbrough Council',
  'Newcastle City Council',
  'North Cumbria Integrated Care NHS Foundation Trust',
  'North East Ambulance Service',
  'North East and North Cumbria ICB - please specify directorate below',
  /* 
    Options for the question above:
      'Contracting and procurement',
      'Corporate governance, communications, and involvement',
      'Digital and infrastructure',
      'Finance',
      'Local delivery',
      'Medical',
      'Nursing, AHP, and people and OD',
      'Strategy and transformation',
  */
  'North of England Commissioning Support Unit',
  'North Tees and Hartlepool NHS Foundation Trust',
  'North Tyneside Council',
  'North West Ambulance Service',
  'Northumberland County Council',
  'Northumbria Healthcare NHS Foundation Trust',
  'Redcar and Cleveland Borough Council',
  'South Tees Hospitals NHS Foundation Trust',
  'South Tyneside and Sunderland NHS Foundation Trust',
  'South Tyneside Council',
  'Stockton-on-Tees Borough Council',
  'Sunderland City Council',
  'Tees, Esk and Wear Valley NHS Foundation Trust',
  'The Newcastle Upon Tyne Hospitals NHS Foundation Trust',
  'University Hospitals of Morecambe Bay NHS Foundation Trust',
  'Voluntary/third sector organisation - please specify below',
  'Westmorland and Furness Council',
  'Other - please specify below',
  'Housing organisation - please specify below',
  'Education (Further/Higher) - please specify below ',
  'Education (Secondary) - please specify below ',
  'Education (Primary) - please specify below ',
  'Education (Early Years) - please specify below '
];

export default organisations;
