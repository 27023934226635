import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopSection = styled.div`
  margin-bottom: 4rem;
  width: 100%;
`;

export const StatsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  margin-bottom: 1rem;
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[6]}`};
  max-width: 500px;
`;

export const StatItem = styled(Link)`
  text-decoration: none;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: ${({ theme }) => theme.colors.gray};

  :hover {
    color: ${({ theme }) => theme.colors.darkGray};
  }
  @media ${({ theme }) => theme.breakpoints.mobileL} {
    padding: 2rem 0;
  }
`;

export const SpinWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
`;
