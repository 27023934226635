const BoostHome = ({ width, height, color, ...props }) => (
  <svg
    width={width || '20'}
    height={height || '28'}
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 23.25V8.25L10 0.75L20 8.25V23.25H12.5V14.5H7.5V23.25H0Z"
      fill={color || '#fec30d'}
    />
  </svg>
);

export default BoostHome;
