import { message } from 'antd';
import axios from 'axios';

import {
  FETCH_PARTICIPANT_RESPONSES_SUCCESS,
  FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
  LOADING_FALSE,
  LOADING_START,
  LOADING_TRUE
} from '../constants/actionTypes';

export const fetchParticipantSessionDetails = (
  sessionId,
  history,
  populated = false
) => async dispatch => {
  dispatch({
    type: LOADING_START
  });
  axios
    .get(`/api/participant/session-details/${sessionId}`, {
      params: {
        populated
      }
    })
    .then(({ data }) => {
      return dispatch({
        type: FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
        payload: data
      });
    })
    .catch(error => {
      if (error?.response?.status === 404) history.push('/404');
      message.error(
        error?.response?.data?.error || 'Error! something went wrong'
      );
    });
};

export const fetchParticipantResponses = () => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'participantResponsesLoading'
  });
  try {
    const { data } = await axios.get(`/api/participant/responses`);
    await dispatch({
      type: FETCH_PARTICIPANT_RESPONSES_SUCCESS,
      payload: data
    });

    dispatch({
      type: LOADING_FALSE,
      payload: 'participantResponsesLoading'
    });
  } catch (error) {
    message.error('Error! something went wrong');
  }
};
