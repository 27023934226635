import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import SearchFilter from '../../common/SearchFilter';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { MANAGE_USERS_ADD } from '../../../constants/navigationRoutes';
import { usersFilters } from '../../../constants/filtersData';

import * as S from './style';

import { TableColumns } from './TableColumns';

import { fetchUsers } from '../../../actions/users';
import { fetchProgrammes } from '../../../actions/programmesActions';

import Spin from '../../common/Spin';
import { userRoles } from '../../../constants';

const ManageUsers = ({
  fetchUsersAction,
  users: allUsers,
  usersTotalCount,
  filteredUsers,
  fetchUsersLoading,
  searchLoading,
  fetchProgrammesAction,
  fetchProgrammesLoading,
  programmes: allProgrammes,
  authUser
}) => {
  const history = useHistory();

  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const [page, setPage] = useState(1);
  const [accumulatedUsers, setAccumulatedUsers] = useState([]);

  useEffect(() => {
    fetchUsersAction(page);
  }, [fetchUsersAction, page]);

  useEffect(() => {
    fetchProgrammesAction();
  }, [fetchProgrammesAction]);

  useEffect(() => {
    if (allUsers) {
      setAccumulatedUsers(prevUsers => [...prevUsers, ...allUsers]);
    }
  }, [allUsers]);

  if (
    !Array.isArray(allUsers) ||
    !Array.isArray(allProgrammes) ||
    fetchProgrammesLoading
  ) {
    return <Spin />;
  }
  const users = filteredUsers || accumulatedUsers;

  const programmes =
    authUser.role === userRoles.programmeManager
      ? allProgrammes.filter(p => p.managers.includes(authUser.userId))
      : allProgrammes;

  return (
    <Layout>
      <Row>
        <Col w={[4, 6, 6]}>
          <T.H2W>Users</T.H2W>
        </Col>
        <Col w={[4, 6, 6]} jc="flex-end">
          <Button
            label="Add User"
            width="200px"
            height="auto"
            type="tertiary"
            onClick={() => history.push(MANAGE_USERS_ADD)}
          />
        </Col>
        <S.Divider full />
      </Row>
      <Row jc="center">
        <SearchFilter
          category="users/all"
          showSearch
          showFilters
          filters={usersFilters(programmes)}
          loading={searchLoading}
          searchBarTransparentBg
          fullWidth
        />
      </Row>

      <Row mb="4" jc="center">
        <Table
          data={users}
          columns={TableColumns({
            isTablet
          })}
          loading={fetchUsersLoading}
          stripedRows
        />
      </Row>

      {users.length && users.length < usersTotalCount && !filteredUsers && (
        <Row>
          <Col w={[4, 6, 4]} ml="auto" mr="auto">
            <Button
              label="Load more"
              type="primary"
              onClick={() => setPage(prevPage => prevPage + 1)}
              loading={fetchUsersLoading}
            />
          </Col>
        </Row>
      )}
    </Layout>
  );
};
const mapStateToProps = state => ({
  searchLoading: state.loading.searchLoading,
  users: state.users.users,
  usersTotalCount: state.users.totalCount,
  filteredUsers: state.search?.['users/all']?.data,
  fetchUsersLoading: state.loading.fetchUsersLoading,
  fetchProgrammesLoading: state.loading.fetchProgrammesLoading,
  programmes: state.programmes.programmes,
  authUser: state.auth
});

const mapActionsToProps = {
  fetchUsersAction: fetchUsers,
  fetchProgrammesAction: fetchProgrammes
};

export default connect(mapStateToProps, mapActionsToProps)(ManageUsers);
