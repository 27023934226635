import React from 'react';
import { useTheme } from 'styled-components';

import { Row } from '../Grid';

import { useMediaQuery } from 'react-responsive';
import {
  EVENTS_URL,
  PARTICIPANT_SESSION_DETAILS,
  SESSION_DETAILS_URL
} from '../../../constants/navigationRoutes';
import Table from '../BoostTable';
import Button from '../Button';
import { TableColumns } from './SessionListTableColumns';

const SessionList = ({ dataList, isParticipant, linkText }) => {
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const baseURL = isParticipant
    ? PARTICIPANT_SESSION_DETAILS
    : SESSION_DETAILS_URL;

  return (
    <Row mb="4" jc="center">
      <Table
        data={dataList}
        columns={TableColumns(isTablet, baseURL)}
        stripedRows
      />
      <Button
        label="Find more events"
        to={EVENTS_URL}
        type="primary"
        mt={6}
        mr="auto"
        width={'200px'}
      />
    </Row>
  );
};

export default SessionList;
