import axios from 'axios';

import {
  PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_REQUEST,
  PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS,
  PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_FAILURE,
  UPDATE_NEWSLETTER_DESIGN_TEMPLATE_REQUEST,
  UPDATE_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS,
  UPDATE_NEWSLETTER_DESIGN_TEMPLATE_FAILURE,
  SUBSCRIBE_NEWSLETTER_REQUEST,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  SUBSCRIBE_NEWSLETTER_FAILURE,
  UNSUBSCRIBE_NEWSLETTER_REQUEST,
  UNSUBSCRIBE_NEWSLETTER_SUCCESS,
  UNSUBSCRIBE_NEWSLETTER_FAILURE
} from '../constants/actionTypes';

export const previewDesignTemplate = htmlContent => async dispatch => {
  dispatch({ type: PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_REQUEST });

  try {
    const { data } = await axios.post(
      '/api/newsletter/preview-design-template',
      {
        htmlContent
      }
    );

    dispatch({
      type: PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS,
      payload: data
    });

    return data;
  } catch (error) {
    dispatch({
      type: PREVIEW_NEWSLETTER_DESIGN_TEMPLATE_FAILURE,
      payload: error.response?.data?.message || error.message
    });

    throw error;
  }
};

export const updateDesignTemplate = htmlContent => async dispatch => {
  dispatch({ type: UPDATE_NEWSLETTER_DESIGN_TEMPLATE_REQUEST });

  try {
    const { data } = await axios.post(
      '/api/newsletter/update-design-template',
      {
        htmlContent
      }
    );

    dispatch({
      type: UPDATE_NEWSLETTER_DESIGN_TEMPLATE_SUCCESS,
      payload: data
    });

    return;
  } catch (error) {
    dispatch({
      type: UPDATE_NEWSLETTER_DESIGN_TEMPLATE_FAILURE,
      payload: error.response?.data?.message || error.message
    });

    throw error;
  }
};

export const subscribe = email => async dispatch => {
  dispatch({ type: SUBSCRIBE_NEWSLETTER_REQUEST });

  try {
    const { data } = await axios.post('/api/newsletter/subscribe', {
      email
    });

    dispatch({
      type: SUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: data
    });

    return data;
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_NEWSLETTER_FAILURE,
      payload: error.response?.data?.message || error.message
    });

    throw error;
  }
};

export const unsubscribe = email => async dispatch => {
  dispatch({ type: UNSUBSCRIBE_NEWSLETTER_REQUEST });

  try {
    const { data } = await axios.delete('/api/newsletter/unsubscribe', {
      email
    });

    dispatch({
      type: UNSUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: data
    });

    return data;
  } catch (error) {
    dispatch({
      type: UNSUBSCRIBE_NEWSLETTER_FAILURE,
      payload: error.response?.data?.message || error.message
    });

    throw error;
  }
};
