import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../common/Button';
import LoginForm from '../../common/LoginForm';
import ParticipantSignUpForm from '../../common/ParticipantSignUpForm';

import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';

import { userRoles } from '../../../constants';
import { Col } from '../../common/Grid';
import Icon from '../../common/Icon';

import { RegisterRow, LinkWrapper, DividerLine } from './style';

const RegisterSection = ({
  userId,
  role,
  loading,
  onSubmit,
  searchParams,
  isAlreadyRegistered,
  isAlreadyOnWaitingList,
  contactEmail,
  hasCutoffDatePassed,
  maxCapacityReached,
  onSubmitJoinWaitingList
}) => {
  const history = useHistory();
  const location = useLocation();

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const handleShowLogin = event => {
    event.preventDefault();
    setShowLoginForm(true);
    setShowSignUpForm(false);
  };

  const handleShowSignUp = event => {
    event.preventDefault();
    setShowSignUpForm(true);
    setShowLoginForm(false);
  };

  const decideHeadline = () => {
    if (userId || maxCapacityReached) {
      return 'Register now';
    } else if (showLoginForm) {
      return 'Log in to your account';
    } else {
      return 'Create an account';
    }
  };

  const handleGroupBookingLinkClick = () => {
    history.push(`${location.pathname}/group-booking`);
  };

  const shouldShowCutoffDateNote =
    role === userRoles.participant &&
    hasCutoffDatePassed &&
    !isAlreadyRegistered &&
    !isAlreadyOnWaitingList;

  const isRoleAllowed = [userRoles.participant, userRoles.trainer].includes(
    role
  );
  const canRegister =
    isRoleAllowed &&
    !isAlreadyRegistered &&
    !isAlreadyOnWaitingList &&
    !shouldShowCutoffDateNote;

  return (
    <RegisterRow mt={7}>
      <MaxWidthContainer bgColor="transparent">
        <Col w={[4, 12, 12]}>
          <T.H1 weight={700} mb={4}>
            {decideHeadline()}
            <span
              style={{
                fontSize: 'inherit',
                color: 'white'
              }}
            >
              ...
            </span>
          </T.H1>
        </Col>
        {maxCapacityReached && !isAlreadyRegistered && (
          <Col w={[4, 10, 7]} mt={2}>
            <T.P>
              This event has reached capacity, but you can join the waiting list
              in case spaces become available.
            </T.P>
          </Col>
        )}
        {userId ? (
          <>
            {shouldShowCutoffDateNote && (
              <Col w={[4, 12, 12]} mt={2}>
                <T.P>
                  {`Sorry you are no longer able to register for this event. If
                    you would like to attend, please reach out to us at `}
                  {contactEmail ? (
                    <T.Link external href={`mailto:${contactEmail}`}>
                      {contactEmail}
                    </T.Link>
                  ) : (
                    'N/A'
                  )}
                </T.P>
              </Col>
            )}
            <Col w={[4, 12, 12]} mt={5}>
              {maxCapacityReached && !isAlreadyRegistered ? (
                <Button
                  label="Join waiting list"
                  width="200px"
                  height="100%"
                  type="secondary"
                  disabled={!canRegister}
                  onClick={onSubmitJoinWaitingList}
                  loading={loading}
                />
              ) : (
                <Button
                  label="Register"
                  width="200px"
                  height="100%"
                  type="secondary"
                  disabled={!canRegister}
                  onClick={onSubmit}
                  loading={loading}
                />
              )}
            </Col>
            {!isRoleAllowed && !maxCapacityReached && (
              <Col w={[4, 12, 12]} mt={2}>
                <T.P>
                  You must log in with participant or trainer account to
                  register
                </T.P>
              </Col>
            )}
            {isAlreadyRegistered ? (
              <Col w={[4, 12, 12]} mt={2}>
                <T.P>You have already registered for this event!</T.P>
              </Col>
            ) : (
              isAlreadyOnWaitingList && (
                <Col w={[4, 12, 12]} mt={2}>
                  <T.P>
                    You have already been added to the waiting list of this
                    event for this event!
                  </T.P>
                </Col>
              )
            )}
            {canRegister && (
              <>
                <Col w={[4, 12, 12]} mt={2}>
                  <DividerLine />
                </Col>
                <Col w={[4, 12, 12]} mt={2}>
                  <LinkWrapper
                    onClick={handleGroupBookingLinkClick}
                    aria-label="Go to group booking page"
                    role="link"
                    disabled={maxCapacityReached}
                  >
                    I want to make a group booking
                    <Icon
                      icon="chevron"
                      direction="right"
                      color="black"
                      width="24"
                      height="24"
                    />
                  </LinkWrapper>
                </Col>
              </>
            )}
          </>
        ) : (
          <>
            {!showLoginForm && !showSignUpForm && (
              <>
                <Col w={[4, 7, 7]} mt={3}>
                  <T.P>
                    To register you must create a free Learning Academy training
                    account. This account will let you attend courses, receive
                    your certificates and track your progress.
                  </T.P>
                </Col>
                <Col w={[4, 12, 12]} mt={5}>
                  <Button
                    label="Sign up"
                    width="200px"
                    type="secondary"
                    onClick={handleShowSignUp}
                    disabled={hasCutoffDatePassed}
                  />
                </Col>
                <Col w={[4, 12, 12]} mt={3}>
                  <Button
                    label="Log in"
                    width="200px"
                    type="quartenary"
                    onClick={handleShowLogin}
                    disabled={hasCutoffDatePassed}
                  />
                </Col>
                {hasCutoffDatePassed && (
                  <Col w={[4, 12, 12]} mt={2}>
                    <T.P>
                      {`Sorry you are no longer able to register for this event. If
                    you would like to attend, please reach out to us at `}
                      {contactEmail ? (
                        <T.Link external href={`mailto:${contactEmail}`}>
                          {contactEmail}
                        </T.Link>
                      ) : (
                        'N/A'
                      )}
                    </T.P>
                  </Col>
                )}
              </>
            )}
            {showLoginForm && (
              <LoginForm
                renderInline
                handleShowSignUp={handleShowSignUp}
                searchParams={searchParams}
              />
            )}
            {showSignUpForm && (
              <ParticipantSignUpForm
                renderInline
                handleShowLogin={handleShowLogin}
                searchParams={searchParams}
              />
            )}
          </>
        )}
      </MaxWidthContainer>
    </RegisterRow>
  );
};

export default RegisterSection;
