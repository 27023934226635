const Chat = ({ width, height, color, ...props }) => (
  <svg
    width={width || '100'}
    height={height || '101'}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-outside-1_748_5814"
      maskUnits="userSpaceOnUse"
      x="9.44116"
      y="30.1727"
      width="29"
      height="29"
      fill="black"
    >
      <rect
        fill={color || 'white'}
        x="9.44116"
        y="30.1727"
        width="29"
        height="29"
      />
      <path d="M24.0857 33.4895C17.8405 33.4895 12.7584 38.5717 12.7584 44.8168C12.7584 51.062 17.8405 56.1441 24.0856 56.1441C30.3309 56.1441 35.413 51.062 35.413 44.8168C35.413 38.5717 30.3309 33.4895 24.0857 33.4895M24.0856 58.4614C16.5618 58.4614 10.4412 52.3407 10.4412 44.8168C10.4412 37.2934 16.5618 31.1727 24.0857 31.1727C31.6092 31.1727 37.7302 37.2934 37.7302 44.8168C37.7302 52.3407 31.6092 58.4614 24.0856 58.4614Z" />
    </mask>
    <path
      d="M24.0857 33.4895C17.8405 33.4895 12.7584 38.5717 12.7584 44.8168C12.7584 51.062 17.8405 56.1441 24.0856 56.1441C30.3309 56.1441 35.413 51.062 35.413 44.8168C35.413 38.5717 30.3309 33.4895 24.0857 33.4895M24.0856 58.4614C16.5618 58.4614 10.4412 52.3407 10.4412 44.8168C10.4412 37.2934 16.5618 31.1727 24.0857 31.1727C31.6092 31.1727 37.7302 37.2934 37.7302 44.8168C37.7302 52.3407 31.6092 58.4614 24.0856 58.4614Z"
      fill={color || 'white'}
    />
    <path
      d="M24.0857 33.1325C17.6433 33.1325 12.4013 38.3745 12.4013 44.8168H13.1155C13.1155 38.7689 18.0377 33.8466 24.0857 33.8466V33.1325ZM12.4013 44.8168C12.4013 51.2592 17.6433 56.5012 24.0856 56.5012V55.787C18.0377 55.787 13.1155 50.8648 13.1155 44.8168H12.4013ZM24.0856 56.5012C30.5281 56.5012 35.7701 51.2592 35.7701 44.8168H35.0559C35.0559 50.8648 30.1337 55.787 24.0856 55.787V56.5012ZM35.7701 44.8168C35.7701 38.3745 30.5281 33.1325 24.0857 33.1325V33.8466C30.1336 33.8466 35.0559 38.7689 35.0559 44.8168H35.7701ZM24.0856 58.1043C16.7591 58.1043 10.7982 52.1435 10.7982 44.8168H10.0841C10.0841 52.5379 16.3646 58.8184 24.0856 58.8184V58.1043ZM10.7982 44.8168C10.7982 37.4906 16.759 31.5298 24.0857 31.5298V30.8157C16.3647 30.8157 10.0841 37.0962 10.0841 44.8168H10.7982ZM24.0857 31.5298C31.412 31.5298 37.3732 37.4906 37.3732 44.8168H38.0873C38.0873 37.0962 31.8063 30.8157 24.0857 30.8157V31.5298ZM37.3732 44.8168C37.3732 52.1435 31.412 58.1043 24.0856 58.1043V58.8184C31.8064 58.8184 38.0873 52.5379 38.0873 44.8168H37.3732Z"
      fill={color || 'white'}
      mask="url(#path-1-outside-1_748_5814)"
    />
    <mask
      id="path-3-outside-2_748_5814"
      maskUnits="userSpaceOnUse"
      x="-0.394531"
      y="56.2378"
      width="49"
      height="25"
      fill="black"
    >
      <rect
        fill={color || 'white'}
        x="-0.394531"
        y="56.2378"
        width="49"
        height="25"
      />
      <path d="M47.7384 80.8045H45.4214C45.4214 69.0881 35.8881 59.5551 24.1717 59.5551C12.4552 59.5551 2.92227 69.0881 2.92227 80.8045H0.605469C0.605469 67.8094 11.1769 57.2378 24.1717 57.2378C37.1668 57.2378 47.7384 67.8094 47.7384 80.8045" />
    </mask>
    <path
      d="M47.7384 80.8045H45.4214C45.4214 69.0881 35.8881 59.5551 24.1717 59.5551C12.4552 59.5551 2.92227 69.0881 2.92227 80.8045H0.605469C0.605469 67.8094 11.1769 57.2378 24.1717 57.2378C37.1668 57.2378 47.7384 67.8094 47.7384 80.8045"
      fill={color || 'white'}
    />
    <path
      d="M45.4214 80.8045H45.0644V81.1616H45.4214V80.8045ZM2.92228 80.8045V81.1616H3.27934V80.8045H2.92228ZM0.605469 80.8045H0.248401V81.1616H0.605469V80.8045ZM47.7384 80.4474H45.4214V81.1616H47.7384V80.4474ZM45.7785 80.8045C45.7785 68.8909 36.0853 59.198 24.1717 59.198V59.9121C35.6909 59.9121 45.0644 69.2853 45.0644 80.8045H45.7785ZM24.1717 59.198C12.258 59.198 2.56521 68.8909 2.56521 80.8045H3.27934C3.27934 69.2853 12.6524 59.9121 24.1717 59.9121V59.198ZM2.92228 80.4474H0.605469V81.1616H2.92228V80.4474ZM0.962536 80.8045C0.962536 68.0066 11.3741 57.5949 24.1717 57.5949V56.8807C10.9797 56.8807 0.248401 67.6122 0.248401 80.8045H0.962536ZM24.1717 57.5949C36.9696 57.5949 47.3813 68.0066 47.3813 80.8045H48.0954C48.0954 67.6122 37.364 56.8807 24.1717 56.8807V57.5949Z"
      fill={color || 'white'}
      mask="url(#path-3-outside-2_748_5814)"
    />
    <mask
      id="path-5-outside-3_748_5814"
      maskUnits="userSpaceOnUse"
      x="61.0791"
      y="46.0168"
      width="29"
      height="29"
      fill="black"
    >
      <rect
        fill={color || 'white'}
        x="61.0791"
        y="46.0168"
        width="29"
        height="29"
      />
      <path d="M75.7237 49.3337C69.4785 49.3337 64.3963 54.4158 64.3963 60.6609C64.3963 66.9061 69.4785 71.9882 75.7236 71.9882C81.9688 71.9882 87.0509 66.9061 87.0509 60.6609C87.0509 54.4158 81.9688 49.3337 75.7237 49.3337M75.7236 74.3055C68.1998 74.3055 62.0791 68.1848 62.0791 60.6609C62.0791 53.1375 68.1998 47.0168 75.7237 47.0168C83.2471 47.0168 89.3682 53.1375 89.3682 60.6609C89.3682 68.1848 83.2471 74.3055 75.7236 74.3055Z" />
    </mask>
    <path
      d="M75.7237 49.3337C69.4785 49.3337 64.3963 54.4158 64.3963 60.6609C64.3963 66.9061 69.4785 71.9882 75.7236 71.9882C81.9688 71.9882 87.0509 66.9061 87.0509 60.6609C87.0509 54.4158 81.9688 49.3337 75.7237 49.3337M75.7236 74.3055C68.1998 74.3055 62.0791 68.1848 62.0791 60.6609C62.0791 53.1375 68.1998 47.0168 75.7237 47.0168C83.2471 47.0168 89.3682 53.1375 89.3682 60.6609C89.3682 68.1848 83.2471 74.3055 75.7236 74.3055Z"
      fill={color || 'white'}
    />
    <path
      d="M75.7237 48.9766C69.2813 48.9766 64.0393 54.2186 64.0393 60.6609H64.7534C64.7534 54.613 69.6757 49.6907 75.7237 49.6907V48.9766ZM64.0393 60.6609C64.0393 67.1033 69.2813 72.3453 75.7236 72.3453V71.6311C69.6757 71.6311 64.7534 66.7089 64.7534 60.6609H64.0393ZM75.7236 72.3453C82.166 72.3453 87.408 67.1033 87.408 60.6609H86.6939C86.6939 66.7089 81.7716 71.6311 75.7236 71.6311V72.3453ZM87.408 60.6609C87.408 54.2186 82.166 48.9766 75.7237 48.9766V49.6907C81.7716 49.6907 86.6939 54.613 86.6939 60.6609H87.408ZM75.7236 73.9484C68.397 73.9484 62.4362 67.9876 62.4362 60.6609H61.722C61.722 68.382 68.0026 74.6626 75.7236 74.6626V73.9484ZM62.4362 60.6609C62.4362 53.3347 68.397 47.3739 75.7237 47.3739V46.6598C68.0026 46.6598 61.722 52.9403 61.722 60.6609H62.4362ZM75.7237 47.3739C83.0499 47.3739 89.0111 53.3347 89.0111 60.6609H89.7252C89.7252 52.9403 83.4443 46.6598 75.7237 46.6598V47.3739ZM89.0111 60.6609C89.0111 67.9876 83.0499 73.9484 75.7236 73.9484V74.6626C83.4443 74.6626 89.7252 68.382 89.7252 60.6609H89.0111Z"
      fill={color || 'white'}
      mask="url(#path-5-outside-3_748_5814)"
    />
    <mask
      id="path-7-outside-4_748_5814"
      maskUnits="userSpaceOnUse"
      x="51.2434"
      y="72.0819"
      width="49"
      height="25"
      fill="black"
    >
      <rect
        fill={color || 'white'}
        x="51.2434"
        y="72.0819"
        width="49"
        height="25"
      />
      <path d="M99.3763 96.6486H97.0594C97.0594 84.9322 87.526 75.3992 75.8096 75.3992C64.0932 75.3992 54.5602 84.9322 54.5602 96.6486H52.2434C52.2434 83.6535 62.8149 73.0819 75.8096 73.0819C88.8047 73.0819 99.3763 83.6535 99.3763 96.6486" />
    </mask>
    <path
      d="M99.3763 96.6486H97.0594C97.0594 84.9322 87.526 75.3992 75.8096 75.3992C64.0932 75.3992 54.5602 84.9322 54.5602 96.6486H52.2434C52.2434 83.6535 62.8149 73.0819 75.8096 73.0819C88.8047 73.0819 99.3763 83.6535 99.3763 96.6486"
      fill={color || 'white'}
    />
    <path
      d="M97.0594 96.6486H96.7023V97.0057H97.0594V96.6486ZM54.5602 96.6486V97.0057H54.9173V96.6486H54.5602ZM52.2434 96.6486H51.8863V97.0057H52.2434V96.6486ZM99.3763 96.2915H97.0594V97.0057H99.3763V96.2915ZM97.4164 96.6486C97.4164 84.735 87.7232 75.0421 75.8096 75.0421V75.7563C87.3288 75.7563 96.7023 85.1294 96.7023 96.6486H97.4164ZM75.8096 75.0421C63.896 75.0421 54.2031 84.735 54.2031 96.6486H54.9173C54.9173 85.1294 64.2904 75.7563 75.8096 75.7563V75.0421ZM54.5602 96.2915H52.2434V97.0057H54.5602V96.2915ZM52.6005 96.6486C52.6005 83.8507 63.0121 73.439 75.8096 73.439V72.7248C62.6177 72.7248 51.8863 83.4563 51.8863 96.6486H52.6005ZM75.8096 73.439C88.6075 73.439 99.0192 83.8507 99.0192 96.6486H99.7334C99.7334 83.4563 89.0019 72.7248 75.8096 72.7248V73.439Z"
      fill={color || 'white'}
      mask="url(#path-7-outside-4_748_5814)"
    />
    <path
      d="M58.8914 33.91L58.7597 33.8371L58.6129 33.8703L53.5497 35.0147L55.852 31.8266L56.0256 31.5861L55.8433 31.3521L55.5491 30.9744C53.9396 28.9036 53.0897 26.4352 53.0897 23.8316C53.0897 17.4054 58.3183 12.1769 64.7444 12.1769C71.1717 12.1769 76.4003 17.4054 76.4003 23.8316C76.4003 30.2575 71.1717 35.486 64.7444 35.486C62.7755 35.486 60.8241 34.9818 59.104 34.0275L59.1035 34.0273L58.8914 33.91ZM49.2849 37.3906L48.6732 38.2381L49.6927 38.0076L58.4547 36.0262C60.3936 37.0275 62.5595 37.5544 64.7444 37.5544C72.312 37.5544 78.4686 31.3993 78.4686 23.8316C78.4686 16.2638 72.312 10.1088 64.7444 10.1088C57.1768 10.1088 51.0216 16.2639 51.0216 23.8316C51.0216 26.6388 51.8605 29.3142 53.4536 31.6139L49.2849 37.3906Z"
      fill={color || 'white'}
      stroke={color || 'white'}
      stroke-width="0.790771"
    />
  </svg>
);
export default Chat;
