const Calendar = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2.5H3c-.82843 0-1.5.67157-1.5 1.5v9c0 .8284.67157 1.5 1.5 1.5h10c.8284 0 1.5-.6716 1.5-1.5V4c0-.82843-.6716-1.5-1.5-1.5z"
      stroke={color || 'currentColor'}
      strokeLinejoin="round"
    />
    <path
      d="M9.25 8c.41421 0 .75-.33579.75-.75s-.33579-.75-.75-.75-.75.33579-.75.75.33579.75.75.75zM11.75 8c.4142 0 .75-.33579.75-.75s-.3358-.75-.75-.75-.75.33579-.75.75.3358.75.75.75zM9.25 10.5c.41421 0 .75-.3358.75-.75 0-.41421-.33579-.75-.75-.75s-.75.33579-.75.75c0 .4142.33579.75.75.75zM11.75 10.5c.4142 0 .75-.3358.75-.75 0-.41421-.3358-.75-.75-.75s-.75.33579-.75.75c0 .4142.3358.75.75.75zM4.25 10.5c.41421 0 .75-.3358.75-.75C5 9.33579 4.66421 9 4.25 9s-.75.33579-.75.75c0 .4142.33579.75.75.75zM6.75 10.5c.41421 0 .75-.3358.75-.75 0-.41421-.33579-.75-.75-.75S6 9.33579 6 9.75c0 .4142.33579.75.75.75zM4.25 13c.41421 0 .75-.3358.75-.75s-.33579-.75-.75-.75-.75.3358-.75.75.33579.75.75.75zM6.75 13c.41421 0 .75-.3358.75-.75s-.33579-.75-.75-.75-.75.3358-.75.75.33579.75.75.75zM9.25 13c.41421 0 .75-.3358.75-.75s-.33579-.75-.75-.75-.75.3358-.75.75.33579.75.75.75z"
      fill={color || 'currentColor'}
    />
    <path
      d="M12 1.5v1m-8-1v1-1z"
      stroke={color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 5h-13"
      stroke={color || 'currentColor'}
      strokeLinejoin="round"
    />
  </svg>
);

export default Calendar;
