import styled from 'styled-components';
import { Row } from '../../common/Grid';

export const HeadWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 918px) {
    margin-top: 12px;
  }
`;

export const Img = styled.img`
  width: ${({ w }) => w || '192px'};
  height: ${({ h }) => h || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;

export const TopRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacings[7]} !important;
  margin-bottom: ${({ theme }) => theme.spacings[8]} !important;

  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    margin-top: 0 !important;
  }

  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    margin-bottom: 0 !important;
  }
`;
