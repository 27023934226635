import {
  CONFIRM_REGISTRATION_ERROR,
  CONFIRM_REGISTRATION_SUCCESS,
  JOIN_WAITING_LIST_SUCCESS,
  CONFIRM_REGISTRATION_RESET
} from '../constants/actionTypes';

const initState = {
  confirmSuccess: null,
  confirmedEmail: null,
  error: null,
  message: null,
  alreadyRegistered: null,
  isAlreadyOnWaitingList: false
};

const confirmRegistration = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CONFIRM_REGISTRATION_SUCCESS:
    case JOIN_WAITING_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        confirmSuccess: true,
        confirmedEmail: payload.confirmedEmail,
        alreadyRegistered: payload.alreadyRegistered,
        isAlreadyOnWaitingList: payload.isAlreadyOnWaitingList
      };
    case CONFIRM_REGISTRATION_ERROR:
      return {
        error: true,
        message: payload
      };
    case CONFIRM_REGISTRATION_RESET:
      return initState;
    default:
      return state;
  }
};

export default confirmRegistration;
