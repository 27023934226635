import {
  FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS,
  FETCH_PARTICIPANT_SESSION_DETAILS_FAIL
} from '../constants/actionTypes';

const initState = {
  participantSessionDetails: {},
  loaded: false
};

const fetchParticipantSessionDetails = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PARTICIPANT_SESSION_DETAILS_SUCCESS:
      return { ...state, participantSessionDetails: payload, loaded: true };
    case FETCH_PARTICIPANT_SESSION_DETAILS_FAIL:
      return { ...state, loaded: true };
    default:
      return state;
  }
};

export default fetchParticipantSessionDetails;
