import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';
import { hotjar } from 'react-hotjar';

import ErrorBoundary from './ErrorBoundary';
import { Integrations } from '@sentry/tracing';

import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App';
import store from './store';

import 'antd/lib/icon/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/collapse/style/index.css';
import 'antd/lib/table/style/index.css';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/alert/style/index.css';
import 'antd/lib/spin/style/index.css';
import 'antd/lib/divider/style/index.css';
import './index.css';
import 'antd/dist/antd.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5
  });

  hotjar.initialize({
    id: parseInt(process.env.REACT_APP_HOTJAR_SIDE_ID),
    sv: parseInt(process.env.REACT_APP_HOTJAR_VERSION)
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
