import React, { Component } from 'react';
import { SessionTopDetailsWrapper } from './SessionDetails.Style';

import { Row, Col } from '../../common/Grid';
import * as T from '../../common/Typography';
import { concatModules } from '../../../helpers';

class SessionTopDetails extends Component {
  render() {
    const { sessionDetails } = this.props;
    const { course, modules } = sessionDetails;
    if (!sessionDetails) {
      return <div>loading</div>;
    }

    return (
      <SessionTopDetailsWrapper>
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Module:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize">{concatModules({ modules })}</T.P>
          </Col>
        </Row>
        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Course:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize">{course || 'N/A'}</T.P>
          </Col>
        </Row>

        <Row mb="4">
          <Col w={[2, 4, 3]}>
            <T.P weight={700}>Live / Non-live:</T.P>
          </Col>
          <Col w={[2, 8, 9]}>
            <T.P transform="capitalize">Non-Live</T.P>
          </Col>
        </Row>
      </SessionTopDetailsWrapper>
    );
  }
}

export default SessionTopDetails;
