import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargins}
  display: flex;
  width: 100%;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  .ant-radio-checked .ant-radio-inner {
    background-color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.white};
    border-color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.midGray};
  }
  .ant-radio-wrapper {
    margin-right: 4px;
  }
  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-rdaio:hover .ant-radio-inner,
  .ant-rdaio-input:focus + .ant-radio-inner {
    border-color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.midGray};
  }

  .ant-radio-checked::after {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.colors.gray};
    width: 16px;
    height: 16px;
    left: 2px;
    top: 2px;
  }

  .ant-radio-inner {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme, error }) =>
      error ? theme.colors.error : theme.colors.midGray};
    width: 24px;
    height: 24px;
    border-width: 2px;
  }
`;

export const radioStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  lineHeight: '30px'
};
