const USER_TYPES = {
  participant: 'participant',
  trainer: 'trainer',
  admin: 'admin',
  programmeManager: 'programmeManager'
};

export const USER_TYPE_LABELS = {
  [USER_TYPES.participant]: 'Participant',
  [USER_TYPES.trainer]: 'Trainer',
  [USER_TYPES.admin]: 'Admin',
  [USER_TYPES.programmeManager]: 'Programme Manager'
};

export default USER_TYPES;
