import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';

import * as S from './style';

import {
  fetchProgrammeCategoriesBySlug,
  updateProgrammeCategory
} from '../../../actions/programmesActions';
import { TableColumns } from './TableColumns';
import { MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES } from '../../../constants/navigationRoutes';
import Modal from '../../common/modal';

const ArchivedProgrammeCategories = ({
  programmeCategories,
  fetchProgrammeCategoriesLoading,
  fetchProgrammeCategoriesBySlug,
  updateProgrammeCategory,
  updateProgrammeCategoryLoading
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const history = useHistory();
  const { slug } = useParams();
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const confirmUnarchive = async ({ categoryId }) => {
    setSelectedCategoryId(categoryId);
    setShowModal(true);
  };

  const handleUnarchive = async () => {
    await updateProgrammeCategory(selectedCategoryId, { archived: false });
    setShowModal(false);

    fetchProgrammeCategoriesBySlug(slug, { archived: true });
  };

  useEffect(() => {
    fetchProgrammeCategoriesBySlug(slug, { archived: true });
  }, [slug, fetchProgrammeCategoriesBySlug]);

  if (!programmeCategories || fetchProgrammeCategoriesLoading) {
    return <Spin />;
  }

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H2W>Archived Categories</T.H2W>
        </Col>
        <S.Divider full />
        <Col w={[4, 8, 8]}>
          <T.P weight={500}>
            Organise your modules into certain categories (e.g. Awareness,
            Identification, Management) to help potential participants navigate
            your programme.{' '}
          </T.P>
        </Col>
      </Row>
      <Row mt="4">
        <Col w={[4, 12, 12]}>
          <Button
            label="View active categories"
            width="200px"
            height="auto"
            type="primary"
            onClick={() =>
              history.push(
                MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES.replace(':slug', slug)
              )
            }
          />
        </Col>
      </Row>
      <Row mb="4" jc="center">
        <Table
          data={programmeCategories}
          columns={TableColumns({
            isTablet: isTablet,
            archiveMode: true,
            confirmUnarchive
          })}
        />
      </Row>

      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          Are you sure?
        </T.H4W>
        <T.P color="gray" mb="10">
          Unarchiving this category will make it visible again on the programme
          catalogue page. You can always archive it again later.
        </T.P>
        <Button
          label="Confirm unarchive"
          type="primary"
          width="200px"
          loading={updateProgrammeCategoryLoading}
          onClick={handleUnarchive}
        />
      </Modal>
    </Layout>
  );
};
const mapStateToProps = state => ({
  programmeCategories: state.programmes.programmeCategories,
  fetchProgrammeCategoriesLoading:
    state.loading.fetchProgrammeCategoriesLoading,
  updateProgrammeCategoryLoading: state.loading.updateProgrammeCategoryLoading
});

const mapActionsToProps = {
  fetchProgrammeCategoriesBySlug,
  updateProgrammeCategory
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ArchivedProgrammeCategories);
