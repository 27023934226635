import {
  FETCH_PROGRAMME,
  FETCH_PROGRAMMES,
  FETCH_PROGRAMMES_FAIL,
  FETCH_PROGRAMME_FAIL,
  FETCH_PROGRAMME_CATEGORIES,
  FETCH_PROGRAMME_CATEGORIES_FAIL,
  FETCH_PROGRAMME_CATEGORY_FAIL,
  CREATE_PROGRAMME_CATEGORY_FAIL,
  UPDATE_PROGRAMME_CATEGORY_FAIL,
  FETCH_PROGRAMME_CATEGORY_SUCCESS,
  UPDATE_PROGRAMME_SUCCESS,
  UPDATE_PROGRAMME_FAIL
} from '../constants/actionTypes';

const initState = {
  modules: [],
  module: {},
  httpError: null
};

const programmes = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PROGRAMMES:
      return { ...state, programmes: payload, loaded: true };
    case FETCH_PROGRAMME:
    case UPDATE_PROGRAMME_SUCCESS:
      return { ...state, programme: payload, loaded: true };
    case FETCH_PROGRAMME_CATEGORIES:
      return { ...state, programmeCategories: payload, loaded: true };
    case FETCH_PROGRAMME_CATEGORY_SUCCESS:
      return {
        ...state,
        programmeCategory: payload,
        loaded: true
      };

    case FETCH_PROGRAMMES_FAIL:
    case FETCH_PROGRAMME_FAIL:
    case FETCH_PROGRAMME_CATEGORIES_FAIL:
    case FETCH_PROGRAMME_CATEGORY_FAIL:
    case CREATE_PROGRAMME_CATEGORY_FAIL:
    case UPDATE_PROGRAMME_CATEGORY_FAIL:
    case UPDATE_PROGRAMME_FAIL:
      return {
        ...state,
        httpError: payload
      };
    default:
      return state;
  }
};

export default programmes;
