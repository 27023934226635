import { validate as _validate, createSchema, fields } from '..';

const {
  email,
  password,
  name,
  requiredText,
  organisationOther,
  checkbox
} = fields;

const validate = data => {
  const participantSchema = createSchema({
    email,
    password,
    name,
    phone: requiredText,
    jobRole: requiredText,
    organisationOther,
    newsletterSubscription: checkbox
  });

  return _validate(participantSchema, data);
};

export default validate;
