import React from 'react';

import * as T from '../../common/Typography';

import moment from 'moment';
import {
  PARTICIPANT_SESSION_DETAILS,
  SESSION_DETAILS_URL
} from '../../../constants/navigationRoutes';
import Icon from '../../common/Icon';
import InfoPopUp from '../../common/InfoPopup';

export const TableColumns = (isDesktop, isParticipant) => {
  const commonColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_text, row) => {
        const formattedDate = moment(row.date).format('D MMMM YYYY');

        return (
          <>
            <T.P color="gray" weight={600}>
              {formattedDate}
            </T.P>
          </>
        );
      }
    },
    {
      title: 'Event',
      key: 'event',
      dataIndex: 'title',
      render: (title, row) => (
        <T.P color="gray">
          {title || row.modules.map(module => module.title).join(', ')}{' '}
          {row.defaultSessionForCustomModule && !isParticipant && (
            <InfoPopUp
              title="Default session for the module"
              text="You can manage registered participants and view their responses."
              h="16px"
              w="16px"
              color={'gray'}
            />
          )}
        </T.P>
      )
    }
  ];

  const desktopColumns = [
    {
      title: 'Programme(s)',
      key: 'programmes',
      dataIndex: 'programmes',
      render: programmes => {
        return (
          <div>
            {programmes?.map(prog => (
              <>
                <T.P color="gray" key={prog._id}>
                  {prog.titleShort}
                </T.P>
                {'  '}
              </>
            ))}
          </div>
        );
      }
    },
    {
      title: '',
      key: 'action',
      dataIndex: '_id',
      render: (_id, row) => {
        const baseURL = isParticipant
          ? PARTICIPANT_SESSION_DETAILS
          : SESSION_DETAILS_URL;

        return (
          <>
            <T.Link
              color="gray"
              weight={600}
              style={{ minWidth: 140 }}
              to={baseURL.replace(':id', _id.toString())}
              underline={false}
            >
              <Icon icon="eye" color="darkGray" width="16" height="16" mt="1" />{' '}
              View
            </T.Link>
          </>
        );
      }
    }
  ];

  return !isDesktop ? commonColumns : [...commonColumns, ...desktopColumns];
};
