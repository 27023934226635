import { validate as _validate, createSchema, fields } from '..';

const {
  requiredText,
  checkbox,
  urlOptional,
  arrayOfString,
  imageLink
} = fields;

const validate = data => {
  const editProgrammeForm = createSchema({
    title: requiredText,
    titleShort: requiredText,
    topics: arrayOfString,
    subtitle: requiredText,
    description: requiredText,
    whoIsItFor: requiredText,
    findOutMore: requiredText,
    videoLink: urlOptional,
    includePreEvaluationQuestion: checkbox,
    includePostEvaluationQuestion: checkbox,
    isVisible: checkbox,
    imageLink
  });
  return _validate(editProgrammeForm, data);
};

export default validate;
