import { CHECK_BROWSER_WIDTH } from '../constants/actionTypes';

export const checkBrowserWidth = data => dispatch => {
  try {
    dispatch({
      type: CHECK_BROWSER_WIDTH,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
