/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// COMMON COMPONENTS
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import Table from '../../common/BoostTable';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import UpdateNewsletterDesignTemplateModal from './UpdateNewsletterDesignTemplateModal';
// CONSTANTS
import {
  ADD_SESSION_URL,
  ALL_SESSIONS_URL,
  MY_SESSIONS_URL,
  TRAINER_SESSIONS_URL
} from '../../../constants/navigationRoutes';

// ACTIONS
import {
  fetchALLSessions,
  fetchTrainerSessions
} from '../../../actions/groupSessionsAction';
import {
  previewDesignTemplate,
  updateDesignTemplate
} from '../../../actions/newsletterAction';

// STYLING
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { userRoles } from '../../../constants';
import Dropdown from '../../common/Inputs/Dropdown/index';
import { TableColumns } from './TableColumns';
import { ViewSessionsWrapper } from './style';

const ViewSessions = ({
  userId,
  sessions,
  totalCount,
  fetchSessionsLoading,
  match,
  history,
  fetchTrainerSessions,
  fetchALLSessions,
  role,
  previewDesignTemplate,
  updateDesignTemplate,
  isLoadingUpdate,
  isLoadingPreview
}) => {
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const [scope, setScope] = useState('upcoming');
  const [page, setPage] = useState(1);
  const [allSessions, setAllSessions] = useState([]);
  const [
    isUpdateNewsletterDesignTemplateModal,
    setIsUpdateNewsletterDesignTemplateModalVisible
  ] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const programmeId = searchParams.get('programme');

  const fetchSessionsData = useCallback(
    (_role, id, page) => {
      const filters = { type: scope, page, programme: programmeId };

      if (_role === userRoles.admin) {
        fetchALLSessions({ history, ...filters });
      } else if (_role === userRoles.programmeManager) {
        fetchALLSessions({ history, programmeManager: id, ...filters });
      } else if (_role === userRoles.trainer) {
        fetchTrainerSessions({ id, history, ...filters });
      }
    },
    [fetchALLSessions, fetchTrainerSessions, history, scope, programmeId]
  );

  const getData = useCallback(() => {
    const { trainerId } = match.params;
    let resultsFor;
    let resultForRole;

    if (match.path === MY_SESSIONS_URL) {
      resultsFor = userId;
      resultForRole = 'trainer';
    } else if (match.path === ALL_SESSIONS_URL && role === userRoles.admin) {
      resultsFor = userId;
      resultForRole = 'admin';
    } else if (
      match.path === ALL_SESSIONS_URL &&
      role === userRoles.programmeManager
    ) {
      resultsFor = userId;
      resultForRole = 'programmeManager';
    } else if (match.path === TRAINER_SESSIONS_URL) {
      if (trainerId) {
        resultsFor = trainerId;
        resultForRole = 'trainer';
      }
    }
    fetchSessionsData(resultForRole, resultsFor, page);
  }, [fetchSessionsData, match.params, match.path, role, userId, page]);

  useEffect(() => {
    if (page !== 1) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setAllSessions([]);
    setPage(1);
  }, [role, userId, scope, programmeId]);

  useEffect(() => {
    if (sessions.length > 0) {
      setAllSessions(prevSessions => [...prevSessions, ...sessions]);
    }
  }, [sessions]);

  useEffect(() => {
    if (page === 1) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, page, programmeId]);

  useEffect(() => {
    setAllSessions([]);
  }, []);

  if (!allSessions) {
    return <Spin />;
  }

  return (
    <Layout>
      <ViewSessionsWrapper>
        <AccountPageHeader title="Events" />
        <Row mb="4">
          <Col w={[4, 6, 5]}>
            <Dropdown
              selected={scope}
              placeholder="Select..."
              required
              options={[
                {
                  label:
                    scope === 'upcoming'
                      ? `Upcoming events (${totalCount})`
                      : 'Upcoming events',
                  value: 'upcoming'
                },
                {
                  label:
                    scope === 'all'
                      ? `All events (${totalCount})`
                      : 'All events',
                  value: 'all'
                }
              ]}
              name="scope"
              handleChange={value => {
                setScope(value);
              }}
            />
          </Col>
        </Row>
        <Row mb="8">
          <Col w={[4, 6, 4]}>
            <Button
              label="Add event"
              type="primary"
              to={ADD_SESSION_URL}
              link
            />
          </Col>
          {role === userRoles.admin && (
            <Col w={[4, 6, 4]}>
              <Button
                label="Update Newsletter"
                type="primary"
                onClick={() =>
                  setIsUpdateNewsletterDesignTemplateModalVisible(true)
                }
              />
            </Col>
          )}
        </Row>
        <Row mb="4" jc="center">
          <Table
            data={allSessions}
            columns={TableColumns(isTablet)}
            stripedRows
            loading={fetchSessionsLoading}
          />
        </Row>
        {totalCount > allSessions.length && (
          <Row>
            <Col w={[4, 6, 4]} ml="auto" mr="auto">
              <Button
                label="Load more"
                type="primary"
                onClick={() => setPage(prevPage => prevPage + 1)}
                loading={fetchSessionsLoading}
              />
            </Col>
          </Row>
        )}
      </ViewSessionsWrapper>

      {role === userRoles.admin && (
        <UpdateNewsletterDesignTemplateModal
          isLoadingUpdate={isLoadingUpdate}
          isLoadingPreview={isLoadingPreview}
          sessions={allSessions}
          previewDesignTemplate={previewDesignTemplate}
          updateDesignTemplate={updateDesignTemplate}
          isModalVisible={isUpdateNewsletterDesignTemplateModal}
          onCancel={() =>
            setIsUpdateNewsletterDesignTemplateModalVisible(false)
          }
        />
      )}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    role: state.auth.role,
    sessions: state.sessions.sessions,
    totalCount: state.sessions.totalCount,
    lastType: state.sessions.lastType,
    fetchSessionsLoading: state.loading.fetchSessions,
    isLoadingPreview: state.newsletter.isLoadingPreview,
    isLoadingUpdate: state.newsletter.isLoadingUpdate
  };
};

export default connect(mapStateToProps, {
  fetchTrainerSessions,
  fetchALLSessions,
  previewDesignTemplate,
  updateDesignTemplate
})(ViewSessions);
