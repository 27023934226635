import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col as GridCol } from '../../common/Grid';
import { Drawer as AntDrawer } from 'antd';

export const SessionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SessionTopDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 2rem 0;
`;

export const Statistic = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0.55rem 0.3rem;
  margin: 0.5rem 0;
  border-radius: 7px;
  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const StatisticItems = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 0 0.5rem;
`;

export const StatisticName = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.blackSecondary};
  text-align: center;
  margin: 0.8rem 0 0 0;
  font-size: calc(var(--font-size-adjustment) * 0.9rem);
  @media (min-width: 768px) {
    font-size: calc(var(--font-size-adjustment) * 1rem);
  }
`;

export const StatisticValue = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  padding: 0.3rem;
  font-size: calc(var(--font-size-adjustment) * 0.9rem);
  font-weight: 600;
  text-transform: capitalize;
  @media (min-width: 768px) {
    font-size: calc(var(--font-size-adjustment) * 1rem);
    padding: 0.9rem 0;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TrainersName = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
  padding-left: 0.7rem;
`;

// NEW COMPONENTS

export const SubDetails = styled.div`
  padding: 0.75rem 0 0.5rem 0;
  color: ${({ theme }) => theme.colors.blackSecondary};
  display: flex;
`;

export const SubDetailsTitle = styled.p`
  width: 25%;
  margin: 0;
  padding-right: 1rem;
`;

export const SubDetailsContent = styled.p`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  text-decoration: none;
  font-size: calc(var(--font-size-adjustment) * 0.95rem)
  margin: 0;
`;

export const CopyIcon = styled.i`
  font-size: calc(var(--font-size-adjustment) * 20px);
`;

export const DrawerLink = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover p {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkGray};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  ${({ enabled }) =>
    enabled
      ? ''
      : `& * {
        pointer-events: none;
      }`}
`;

export const Edit = styled.p`
  margin-left: auto;
  margin-right: 1rem;
  font-family: 'museo-sans', sans-serif;
  font-size: calc(var(--font-size-adjustment) * 16px);
  font-weight: 700;
  color: prim
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const DrawerContentWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  background: #f7f8f9;
  text-align: center;
  padding-top: 3rem;

  .ant-checkbox-group .ant-checkbox-group-item {
    display: block;
    margin: 1rem 0;
  }
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

export const TableWrapper = styled.div`
  background: #fff;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 2rem;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  align-items: center;
`;

export const Text = styled.p`
  margin: 1rem 0;
  font-weight: 700;
`;

export const EmailWrapper = styled.div`
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 2rem 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

export const EmailText = styled.p`
  font-family: 'museo-sans', sans-serif;
  font-weight: 300;
  font-size: calc(var(--font-size-adjustment) * 16px);
  line-height: 26px;
  margin-bottom: 0rem;
  color: ${({ black }) => (black ? 'black' : 'inherit')};
`;

export const BoldSpan = styled.span`
  font-weight: 500;
  color: ${({ black }) => (black ? 'black' : 'inherit')};
`;

export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  cursor: pointer;
  margin: 2rem 20px;
  font-weight: 500;
  font-size: calc(var(--font-size-adjustment) * 18px);
  cursor: pointer;

  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    margin: 10px 0 20px 0;
  }
`;

export const FormWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelecetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 90px;
  margin-bottom: 5px;
`;

export const WhiteWrapper = styled.div`
  display: flex;
  background: #ffffff;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  flex-direction: column;
`;

export const ViewMore = styled.button`
  background: none;
  font-weight: bold;
  font-size: calc(var(--font-size-adjustment) * 16px);
  line-height: 19px;
  align-items: center;
  color: #000000;
  margin: 0 auto;
  display: block;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
`;

export const InfoHeader = styled(Link)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: italic;
  margin: 0;
  padding: 0;
  font-style: text-wrap

  :hover {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const DrawerWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacings[5]};

  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    padding-left: 0;
  }
`;

export const Drawer = styled(AntDrawer)`
  min-height: 100%;
  background: ${({ theme }) => theme.colors.white};

  .ant-drawer-close {
    display: none;
  }
  .ant-drawer-mask,
  .ant-drawer-content-wrapper,
  .ant-drawer {
    animation-duration: 0s !important;
    transition: none !important;
  }
  @media ${({ theme }) => theme.breakpoints.laptop} {
    .ant-drawer-body {
      max-width: calc(100% - 300px);
    }
  }
  animation-duration: 0s !important;
`;

export const WarningWrapper = styled.div`
  margin-bottom: 48px;
`;
export const WarningText = styled.div`
  display: flex;
  align-items: start;
`;

export const EmailList = styled.ul`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 10px;
  margin-left: 58px;
  li {
    margin-bottom: 8px;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 8px;
`;

export const SpinWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const StyledCol = styled(GridCol)`
  padding: 0;
`;

export const StyledList = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
`;

export const Divider = styled.div`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.lightSecondary};
  height: 1px;
  margin: 0px 12px;
`;

export const AccessNeedsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 12px;
`;
