import styled from 'styled-components';
import setMargin from '../../../../helpers/setMargin';

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.black};
  }
  .ant-checkbox-checked,
  .ant-checkbox {
    margin-top: 2px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.darkGray};
  }

  .ant-checkbox-inner {
    background: ${({ theme, checked }) =>
      checked ? theme.colors.mainPrimary : theme.colors.white};
    border-color: ${({ theme, error }) =>
      error ? theme.colors.error : theme.colors.midGray};
    width: 24px;
    height: 24px;
  }

  .ant-checkbox-input {
    width: 24px;
    height: 24px;
  }

  .ant-checkbox {
    font-size: calc(var(--font-size-adjustment) * 16px);
  }

  .ant-checkbox-inner::after {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 30% !important;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
  }
`;

export const TextWrapper = styled.span`
  display: flex;
`;
