const buildUniqueIdentifier = (questionText, groupOrder = 0, order = 0) =>
  `${questionText}-${groupOrder}-${order}`;

const updateAnswersHeadersArray = ({
  questionText,
  dynamicHeadersArray,
  order,
  groupOrder
}) => {
  const uniqueIdentifier = buildUniqueIdentifier(
    questionText,
    groupOrder,
    order
  );
  const questionKey = {
    text: questionText,
    uniqueIdentifier,
    order,
    groupOrder
  };

  // Add the header if it doesn't already exist
  if (
    !dynamicHeadersArray.some(
      header => header.uniqueIdentifier === uniqueIdentifier
    )
  ) {
    dynamicHeadersArray.push(questionKey);

    dynamicHeadersArray.sort((a, b) => {
      if (a.groupOrder === b.groupOrder) {
        return a.order - b.order;
      }
      return a.groupOrder - b.groupOrder;
    });
  }
};

const addAnswerToParticipant = ({
  participantObj,
  uniqueIdentifier,
  answer
}) => {
  let formattedAnswer = Array.isArray(answer)
    ? answer.join(', ')
    : typeof answer === 'object'
    ? Object.entries(answer)
        .map(([key, value]) => `${key}: ${value}，`)
        .join('')
    : answer;

  if (typeof formattedAnswer === 'string') {
    formattedAnswer = formattedAnswer.replace(/,/g, '，').replace(/"/g, '”');
  }

  participantObj[uniqueIdentifier] = formattedAnswer;
};

const transformer = ({ participant, headers }) => {
  const participantObj = { ...participant };

  // Update fixed headers
  Object.keys(participantObj)
    .filter(key => key !== 'demographics')
    .forEach(key => {
      if (!headers.fixedHeaders.includes(key)) {
        headers.fixedHeaders.push(key);
      }
    });

  // Process demographics
  if (participant.demographics && participant.demographics.length > 0) {
    participant.demographics.forEach(
      ({ question, answer, order, groupOrder }) => {
        const uniqueIdentifier = buildUniqueIdentifier(
          question,
          groupOrder,
          order
        );

        updateAnswersHeadersArray({
          questionText: question,
          dynamicHeadersArray: headers.dynamicHeadersArray,
          order,
          groupOrder
        });

        addAnswerToParticipant({
          participantObj,
          uniqueIdentifier,
          answer
        });
      }
    );
  }

  delete participantObj.demographics;
  return participantObj;
};

const getCsvDataAndHeadersForExportDemographics = data => {
  const headers = {
    fixedHeaders: [],
    dynamicHeadersArray: []
  };

  const csvDataWithDemographics = data.map(participant =>
    transformer({ participant, headers })
  );

  // Prepare dynamic headers
  const dynamicHeaders = headers.dynamicHeadersArray.map(
    ({ text, uniqueIdentifier }) => ({
      label: text, // Display name for the header
      key: uniqueIdentifier // Unique key for CSV data
    })
  );

  // Combine fixed headers with dynamic headers
  const csvHeaders = [
    ...headers.fixedHeaders.map(header => ({ label: header, key: header })),
    ...dynamicHeaders
  ];

  const csvData = csvDataWithDemographics.map(participant =>
    csvHeaders.reduce((obj, header) => {
      obj[header.key] = participant[header.key] || ''; // Add value or empty string if missing
      return obj;
    }, {})
  );

  return {
    csvData,
    csvHeaders
  };
};

export default getCsvDataAndHeadersForExportDemographics;
