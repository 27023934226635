import React from 'react';

import * as T from '../../common/Typography';

export default () => {
  return (
    <div>
      <T.P mb="4" style={{ maxWidth: '800px' }}>
        Here you can view who has registered for your session. You can also
        manually add and remove attendees by editing the list below. Please note
        that you will not be able to remove email addresses of participants who
        have already completed their post-session evaluation for this session.
      </T.P>
    </div>
  );
};
