import moment from 'moment';

export const checkIfCutoffDateHasPassed = cutoffDate => {
  if (cutoffDate) {
    const date = moment(cutoffDate).startOf('day');
    const today = moment().startOf('day');
    if (date.isBefore(today)) {
      return true;
    }
  }

  return false;
};
