import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div``;

export const Form = styled.form`
  background: ${({ theme }) => theme.colors.lightPrimary};
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacings[6]};
`;

export const CheckboxLabel = styled.div`
  color: #231f20 !important;
  font-size: 18px;
`;
