import styled from 'styled-components';

export const CreateSessionWrapper = styled.div``;

export const Warning = styled.h1`
  font-family: 'museo-sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: calc(var(--font-size-adjustment) * 0.875rem)
  color: ${({ theme }) => theme.colors.error};
  align-self: flex-end;
  position: absolute;
  top: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  @media (min-width: 678px) {
    max-width: 720px;
    padding-right: 18%;
  }
`;

export const InformationBox = styled.div`
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[5]}`};
  margin: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[0]}`};

  font-size: calc(var(--font-size-adjustment) * 18px);
  width: 80%;
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  height: 2px;
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`;
