import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  clearModule,
  createModule,
  fetchModule,
  fetchModules,
  updateModule
} from '../../../actions/modulesActions';
import * as R from '../../../constants/navigationRoutes';
import { moduleValidation as validate } from '../../../validation/schemas';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import * as I from '../../common/Inputs';
import MDEditor from '../../common/MDEditor';
import * as T from '../../common/Typography';
import Modal from '../../common/modal';

import { resetFileUploadState } from '../../../actions/fileUpload';
import {
  fetchProgrammeBySlug,
  fetchProgrammeCategoriesBySlug
} from '../../../actions/programmesActions';
import {
  moduleDifficultyLevels,
  moduleFormatOptions
} from '../../../constants';
import { topics } from '../../../constants/filtersData';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import Spin from '../../common/Spin';
import Assessment from './Assesment';
import ModuleMaterials from './ModuleMaterials';
import { yesNoOptions } from './dropdownsData';
import * as S from './style';

const initialFormState = {
  title: '',
  description: '',
  videoLink: '',
  developers: '',
  learningObjectives: '',
  clinicalOrProfessionalCompetencies: '',
  whoIsItFor: '',
  duration: '',
  additionalInfo: '',
  testimonial: '',
  difficultyLevel: '',
  isLive: null,
  onlyLeadsOrAdminsCanDeliver: false,
  topics: [],
  category: '',
  link: '',
  preCourseMaterials: [],
  postCourseMaterials: [],
  enableCertificate: false,
  showPostCourseEvaluation: false,
  showTrainerFeedbackEvaluation: false,
  send3MonthFollowUpEvaluation: false,
  send6MonthFollowUpEvaluation: false,
  assessmentNeeded: null,
  assessmentQuestions: [
    {
      question: '',
      options: [
        { text: '', isCorrect: false },
        { text: '', isCorrect: false }
      ]
    }
  ],
  passMarkPercentage: null,
  isPreRequisite: false,
  preRequisiteFor: []
};

const UpdateModule = ({
  createModule,
  updateModule,
  fetchModule,
  clearModule,
  httpError,
  loading,
  module,
  modules,
  fetchModulesAction,
  fetchModulesLoading,
  programme,
  fetchProgrammeLoading,
  programmeCategories,
  fileUploadState,
  fetchProgrammeCategoriesLoading,
  fetchProgrammeBySlug,
  fetchProgrammeCategoriesBySlug,
  resetFileUploadState
}) => {
  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const [showModal, setShowModal] = useState(false);
  const { id, programmeSlug } = useParams() || {};
  const isEditMode = !!id;
  const modulesOptions = modules
    ?.filter(e => e._id !== id)
    ?.map(e => {
      return { label: e.title, value: e._id };
    });

  const handleSubmit = async e => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      const { current: finalFormState } = finalSubmissionData;

      let res;
      if (isEditMode) {
        res = await updateModule({
          _id: module._id,
          ...finalFormState,
          preCourseMaterials: finalFormState.preCourseMaterials.filter(
            e => !!e.link
          ),
          postCourseMaterials: finalFormState.postCourseMaterials.filter(
            e => !!e.link
          )
        });
      } else {
        res = await createModule({
          ...finalFormState,
          preCourseMaterials: finalFormState.preCourseMaterials.filter(
            e => !!e.link
          ),
          postCourseMaterials: finalFormState.postCourseMaterials.filter(
            e => !!e.link
          )
        });
      }
      if (!res?.error) {
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    fetchProgrammeBySlug(programmeSlug);
    fetchProgrammeCategoriesBySlug(programmeSlug);
  }, [programmeSlug, fetchProgrammeBySlug, fetchProgrammeCategoriesBySlug]);

  useEffect(() => {
    if (programme) {
      fetchModulesAction({ programme: programme._id.toString() });
      setFormData({
        programme: programme._id.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programme, fetchModulesAction]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [validateForm]);

  useEffect(() => {
    if (id) {
      fetchModule({ id });
    }
    return () => clearModule();
  }, [fetchModule, clearModule, id]);

  useEffect(() => {
    if (module?._id) {
      setFormData({
        ...module,
        preCourseMaterials: module?.preCourseMaterials?.length
          ? module?.preCourseMaterials
          : [],
        postCourseMaterials: module?.postCourseMaterials?.length
          ? module?.postCourseMaterials
          : [],
        assessmentQuestions: module?.assessmentQuestions?.length
          ? module?.assessmentQuestions
          : [
              {
                question: '',
                options: [
                  { text: '', isCorrect: false },
                  { text: '', isCorrect: false }
                ]
              }
            ],
        preRequisiteFor:
          module?.preRequisiteFor?.length > 0
            ? module?.preRequisiteFor?.map(e => {
                return e._id;
              })
            : [],
        category: module.category?._id || module.category
      });
    } else {
      setFormData({
        assessmentQuestions: [
          {
            question: '',
            options: [
              { text: '', isCorrect: false },
              { text: '', isCorrect: false }
            ]
          }
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, module._id]);

  if (
    fetchProgrammeLoading ||
    fetchProgrammeCategoriesLoading ||
    fetchModulesLoading
  ) {
    return <Spin />;
  }

  return (
    <Layout>
      <T.H2 mb={3}>{isEditMode ? 'Edit module' : 'Add a module'}</T.H2>
      <S.Divider />
      <S.Form>
        <T.H3 mb={2}>Module Details</T.H3>
        <T.P mb={6}>
          Please provide the details you want participants to know about this
          module.
        </T.P>
        <I.BasicInput
          label="Module title"
          required
          placeholder="Module title..."
          mb="5"
          value={formState.title}
          handleChange={title => setFormData({ title })}
          error={validationErrors.title}
        />
        <MDEditor
          color="darkGray"
          placeholder="Summary description..."
          rows="20"
          value={formState.description}
          onChange={description => setFormData({ description })}
          label="Summary description"
          m={{ mb: 5 }}
          required
          error={validationErrors.description}
        />

        <I.BasicInput
          label="Video link"
          placeholder="Type here..."
          mb="5"
          value={formState.videoLink}
          handleChange={videoLink => setFormData({ videoLink })}
          error={validationErrors.videoLink}
        />

        <I.BasicInput
          label="Name/organisation of module developer"
          placeholder="Name/organisation of module developer..."
          mb="5"
          value={formState.developers}
          handleChange={developers => setFormData({ developers })}
          required
          error={validationErrors.developers}
        />

        <MDEditor
          color="darkGray"
          label="Learning objectives"
          placeholder="Learning objectives..."
          rows="20"
          value={formState.learningObjectives}
          onChange={learningObjectives => setFormData({ learningObjectives })}
          m={{ mb: 5 }}
          required
          error={validationErrors.learningObjectives}
        />

        <MDEditor
          color="darkGray"
          label="What clinical or professional competencies does the module support?"
          placeholder="What clinical or professional competencies does the module support?..."
          rows="20"
          value={formState.clinicalOrProfessionalCompetencies}
          onChange={clinicalOrProfessionalCompetencies =>
            setFormData({ clinicalOrProfessionalCompetencies })
          }
          m={{ mb: 5 }}
          required
          error={validationErrors.clinicalOrProfessionalCompetencies}
        />

        <MDEditor
          color="darkGray"
          label="Who is the module for?"
          placeholder="Who is the module for?"
          rows="20"
          value={formState.whoIsItFor}
          onChange={whoIsItFor => setFormData({ whoIsItFor })}
          m={{ mb: 5 }}
          required
          error={validationErrors.whoIsItFor}
        />
        <I.BasicInput
          label="Module duration"
          placeholder="1 hour..."
          mb="5"
          value={formState.duration}
          required
          handleChange={duration => setFormData({ duration })}
          error={validationErrors.duration}
        />

        <MDEditor
          color="darkGray"
          label="Additional information"
          placeholder="Additional information..."
          rows="20"
          value={formState.additionalInfo}
          onChange={additionalInfo => setFormData({ additionalInfo })}
          m={{ mb: 5 }}
          optional
          error={validationErrors.additionalInfo}
        />

        <I.TextArea
          color="darkGray"
          label="Testimonial / Quote"
          placeholder="Type here..."
          rows="5"
          value={formState.testimonial}
          onChange={e => setFormData({ testimonial: e.target.value })}
          mb="5"
          optional
          error={validationErrors.testimonial}
        />

        <I.Dropdown
          m={{
            mb: '5'
          }}
          label="Learning Level"
          addNew={false}
          search={false}
          options={moduleDifficultyLevels.map(level => ({
            label: level,
            value: level
          }))}
          allowClear
          selected={formState.difficultyLevel}
          handleChange={value =>
            setFormData({ difficultyLevel: value || null })
          }
          error={validationErrors.difficultyLevel}
        />

        {/* 
          disable editing this as this change is changing the these cases as we can't create or delete the default session for the module
          live means that this module can be added to the sessions and can be attended by the participants
          non-live means that we create a default session for it to collect responses, and manage attendance
          so changing this value will change will have a lot of implications
        */}
        <I.Dropdown
          m={{
            mb: '5'
          }}
          label="Module Format"
          required
          addNew={false}
          search={false}
          options={moduleFormatOptions}
          selected={formState.isLive?.toString()}
          handleChange={value => setFormData({ isLive: value === 'true' })}
          disabled={isEditMode}
          helper={
            isEditMode
              ? `You can't change a module from live to non-live and vice versa`
              : ''
          }
          error={validationErrors.isLive}
        />

        <I.Checkbox
          checked={formState.onlyLeadsOrAdminsCanDeliver}
          handleChange={value =>
            setFormData({ onlyLeadsOrAdminsCanDeliver: value })
          }
          label={
            <T.P color="gray">
              Only Programme Managers / Admin can deliver this module
            </T.P>
          }
          mb="5"
          error={validationErrors.onlyLeadsOrAdminsCanDeliver}
        />

        <I.Dropdown
          m={{
            mb: '5'
          }}
          label="Topic(s)"
          addNew={false}
          search={false}
          multi
          options={topics}
          selected={formState.topics}
          handleChange={topics => setFormData({ topics })}
          error={validationErrors.topics}
        />

        <I.Dropdown
          m={{
            mb: '5'
          }}
          label="Category"
          required
          addNew={false}
          search={false}
          options={programmeCategories?.map(category => ({
            label: category.title,
            value: category._id.toString()
          }))}
          selected={formState.category}
          handleChange={value => setFormData({ category: value })}
          error={validationErrors.category}
        />

        <T.H3 mb={2}>Materials</T.H3>
        <T.P mb={6}>
          Please remember that you may have participants with accessibility
          requirements and/or needs of reasonable adjustments. Where possible
          provide a mixture of material types (e.g. video, documents, audio),
          and make it clear if they are different versions of the same content,
          for example:
          <ul>
            <li>Introduction to Mental Health (Video Format)</li>
            <li>Introduction to Mental Health (Document Format)</li>
          </ul>
        </T.P>

        {!formState.isLive && (
          <I.BasicInput
            label="Link to complete eLearning content"
            placeholder="Link..."
            mb="5"
            value={formState.link}
            handleChange={link => setFormData({ link })}
            required={!formState.isLive}
            error={validationErrors.link}
          />
        )}

        <ModuleMaterials
          fields={formState.preCourseMaterials}
          name="preCourseMaterials"
          setState={setFormData}
          label="Course materials"
          placeholder="Course materials"
          fileUploadState={fileUploadState}
          resetFileUploadState={resetFileUploadState}
          required={false}
          errors={validationErrors}
          fieldNote="Course materials are available to participants before and during the module."
        />
        <ModuleMaterials
          fields={formState.postCourseMaterials}
          name="postCourseMaterials"
          setState={setFormData}
          label="Post-course materials"
          placeholder="Post-course material"
          fileUploadState={fileUploadState}
          resetFileUploadState={resetFileUploadState}
          required={false}
          errors={validationErrors}
          fieldNote="These materials become available after participants complete the module."
        />

        <>
          <T.P color="darkGray" weight={600} mb="1">
            Certificate
          </T.P>
          <I.Checkbox
            checked={formState.enableCertificate}
            handleChange={value => setFormData({ enableCertificate: value })}
            label={
              <T.P color="gray">
                Provide learner with certificate on completion
              </T.P>
            }
            mb="5"
            error={validationErrors.enableCertificate}
          />
        </>

        <T.H3 mb={2}>Evaluation / Feedback</T.H3>
        <T.P mb={3}>
          Here you can control whether you wish to collect evaluation data from
          participants who complete this module.
        </T.P>

        <>
          <T.P color="darkGray" weight={600} mb="1">
            Evaluation
          </T.P>
          <I.Checkbox
            checked={formState.showPostCourseEvaluation}
            handleChange={value =>
              setFormData({ showPostCourseEvaluation: value })
            }
            label={
              <T.P color="gray">Include post-module evaluation questions</T.P>
            }
            mb="2"
            error={validationErrors.showPostCourseEvaluation}
          />
          {!!formState.isLive && (
            <I.Checkbox
              checked={formState.showTrainerFeedbackEvaluation}
              handleChange={value =>
                setFormData({ showTrainerFeedbackEvaluation: value })
              }
              label={<T.P color="gray">Include trainer feedback questions</T.P>}
              mb="2"
              error={validationErrors.showTrainerFeedbackEvaluation}
            />
          )}
          <I.Checkbox
            checked={formState.send3MonthFollowUpEvaluation}
            handleChange={value =>
              setFormData({ send3MonthFollowUpEvaluation: value })
            }
            label={<T.P color="gray">Send 3-month follow up evaluation</T.P>}
            mb="2"
            error={validationErrors.send3MonthFollowUpEvaluation}
          />
          <I.Checkbox
            checked={formState.send6MonthFollowUpEvaluation}
            handleChange={value =>
              setFormData({ send6MonthFollowUpEvaluation: value })
            }
            label={<T.P color="gray">Send 6-month follow up evaluation</T.P>}
            mb="5"
            error={validationErrors.send6MonthFollowUpEvaluation}
          />
        </>

        {!formState.isLive && formState.isLive !== null && (
          <>
            <T.H3 mb={2}>Assessment</T.H3>
            <T.P mb={3}>
              Here you can create an assessment to verify participants have
              completed the module to a satisfactory level.
            </T.P>

            <I.Dropdown
              m={{
                mb: '5'
              }}
              label="Assessment needed?"
              required
              addNew={false}
              search={false}
              options={yesNoOptions}
              selected={formState.assessmentNeeded?.toString()}
              handleChange={value =>
                setFormData({ assessmentNeeded: value === 'true' })
              }
              error={validationErrors.assessmentNeeded}
            />
          </>
        )}

        {!!formState.assessmentNeeded && (
          <Assessment
            setState={setFormData}
            state={formState}
            validationErrors={validationErrors}
          />
        )}

        <T.P color="darkGray" weight={600} mb="1">
          Is this a pre-requisite module?
        </T.P>
        <I.Checkbox
          m={{
            mb: '5'
          }}
          checked={formState.isPreRequisite}
          handleChange={value => {
            if (value) {
              setFormData({ isPreRequisite: value });
            } else {
              setFormData({ isPreRequisite: value, preRequisiteFor: [] });
            }
          }}
          label={<T.P color="gray">Yes</T.P>}
          error={validationErrors.isPreRequisite}
        />
        {formState.isPreRequisite && (
          <I.Dropdown
            m={{
              mb: '5'
            }}
            label={'Select which modules this is a pre-requisite for'}
            required
            showSearch={true}
            multi={true}
            options={modulesOptions}
            selected={
              formState.preRequisiteFor?.length > 0 &&
              formState.preRequisiteFor?.map(e => {
                return e._id ? e._id : e;
              })
            }
            handleChange={value => setFormData({ preRequisiteFor: value })}
            error={validationErrors.preRequisiteFor}
          />
        )}
        {httpError && (
          <T.P mb="2" color="red">
            {httpError}
          </T.P>
        )}
        <Button
          type="primary"
          label={isEditMode ? 'Save' : 'Create module'}
          width="200px"
          onClick={handleSubmit}
          loading={loading}
        />
        {validationErrors.hasError && (
          <T.P mt="2" color="red">
            Please correct the above form errors before retrying
          </T.P>
        )}
      </S.Form>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          {isEditMode
            ? 'Module successfully edited'
            : 'Module successfully created'}
        </T.H4W>
        <T.P color="gray" mb="10">
          {isEditMode
            ? 'Thank you for editing the module.'
            : 'Thank you for creating a new module.'}
        </T.P>
        <Button
          label="Return to modules"
          type="primary"
          width="200px"
          to={R.MANAGE_PROGRAMMES_PROGRAMME_MODULES.replace(
            ':slug',
            programmeSlug
          )}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  httpError: state.modules.httpError,
  loading: state.loading.createModuleLoading,
  module: state.modules.module,
  modules: state.modules.modules,
  programme: state.programmes.programme,
  fetchProgrammeLoading: state.loading.fetchProgrammeLoading,
  fetchModulesLoading: state.loading.fetchModulesLoading,
  programmeCategories: state.programmes.programmeCategories,
  fetchProgrammeCategoriesLoading:
    state.loading.fetchProgrammeCategoriesLoading,
  fileUploadState: state.fileUpload
});

export default connect(mapStateToProps, {
  createModule,
  updateModule,
  fetchModule,
  clearModule,
  fetchModulesAction: fetchModules,
  fetchProgrammeBySlug,
  fetchProgrammeCategoriesBySlug,
  resetFileUploadState
})(UpdateModule);
