import {
  FILE_LOADING,
  FILE_DELETE_FAIL,
  FILE_DELETE_SUCCESS,
  FILE_UPLOAD_FAIL,
  FILE_UPLOAD_SUCCESS,
  RESET_FILE_UPLOAD_STATE
} from '../constants/actionTypes';

const initialState = {};

const fileUploadReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        [payload.id]: { data: payload.data, error: payload.error }
      };
    case FILE_UPLOAD_FAIL:
      return {
        ...state,
        [payload.id]: { data: payload.data, error: payload.error }
      };
    case FILE_DELETE_SUCCESS:
      return {
        ...state,
        [payload.id]: {
          data: state.data.filter(file => file.fileUrl !== payload.fileUrl),
          error: null
        }
      };
    case FILE_DELETE_FAIL:
      return {
        ...state,
        [payload.id]: { error: payload.error }
      };
    case FILE_LOADING:
      return {
        ...state,
        [payload.id]: { isLoading: payload.isLoading }
      };
    case RESET_FILE_UPLOAD_STATE:
      return initialState;

    default:
      return state;
  }
};

export default fileUploadReducer;
