import { connect } from 'react-redux';
import { archiveModule, fetchModules } from '../../../actions/modulesActions';
import Layout from '../../Layouts';
import * as T from '../../common/Typography';

import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProgrammeBySlug } from '../../../actions/programmesActions';
import * as R from '../../../constants/navigationRoutes';
import useSearchParams from '../../../hooks/useSearchParams';
import Table from '../../common/BoostTable';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import Modal from '../../common/modal';
import { TableColumns } from './TableColumns';

const Modules = ({
  modules,
  loading,
  archiveLoading,
  archiveModuleAction,
  fetchModulesAction,
  programme,
  fetchProgrammeLoading,
  fetchProgrammeBySlug
}) => {
  const { slug } = useParams();
  const searchParams = useSearchParams();
  const { archived: archivedSearchParam } = searchParams.params;

  const isArchived = archivedSearchParam === 'true';

  const [showModal, setShowModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const handleArchive = ({ item }) => {
    setSelectedModule(item);
    setShowModal(true);
  };

  const fetchModules = useCallback(() => {
    fetchModulesAction({
      programme: programme._id.toString(),
      populate: 'category',
      archived: isArchived
    });
  }, [fetchModulesAction, programme?._id, isArchived]);

  const flipArchiveStatus = async () => {
    await archiveModuleAction({
      moduleId: selectedModule._id,
      data: {
        archived: !isArchived
      }
    });
    setShowModal(false);
    fetchModules();
  };

  useEffect(() => {
    fetchProgrammeBySlug(slug);
  }, [fetchProgrammeBySlug, slug]);

  useEffect(() => {
    if (programme) {
      fetchModules();
    }
  }, [fetchModules, programme]);

  if (!modules || !programme || loading || fetchProgrammeLoading) {
    return <Spin />;
  }

  return (
    <Layout>
      <T.H1 mb={7}>{!isArchived ? 'Modules' : 'Archived Modules'}</T.H1>

      <Row mt="7">
        <Col w={[4, 12, 12]} mb="5">
          <Button
            type="primary"
            label="Add module"
            width="200px"
            to={R.MANAGE_PROGRAMMES_PROGRAMME_MODULE_ADD.replace(
              ':programmeSlug',
              slug
            )}
          />
          <Button
            ml="2"
            type="tertiary"
            label={!isArchived ? 'View archived modules' : 'View all modules'}
            width="200px"
            onClick={() => {
              // searchParams.set('archived', 'true'); // Until the sp issue is resolved...
            }}
            to={R.MANAGE_PROGRAMMES_PROGRAMME_MODULES.replace(
              ':slug',
              slug
            ).concat(isArchived ? '' : '?archived=true')}
          />
        </Col>
        <Col w={[4, 12, 12]}>
          <Table
            columns={TableColumns({
              handleArchive,
              isArchived,
              programmeSlug: slug
            })}
            data={modules}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          {!isArchived ? 'No longer need this module?' : 'Are you sure?'}
        </T.H4W>
        <T.P color="gray" mb="7">
          {!isArchived
            ? `Archiving this module will make it no longer be available to
          participants, but any collected results will still be saved in All
          Results`
            : 'Unarchiving this module will make it visible again.'}
        </T.P>
        <Button
          label="Confirm"
          type="primary"
          width="200px"
          loading={archiveLoading}
          onClick={flipArchiveStatus}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  modules: state.modules.modules,
  programme: state.programmes.programme,
  archiveLoading: state.loading.archiveModuleLoading,
  loading: state.loading.fetchModulesLoading,
  selectedCourse: state.course.selectedCourse,
  fetchProgrammeLoading: state.loading.fetchProgrammeLoading
});

export default connect(mapStateToProps, {
  archiveModuleAction: archiveModule,
  fetchModulesAction: fetchModules,
  fetchProgrammeBySlug
})(Modules);
