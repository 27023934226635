const Upload = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '70'}
    height={height || '60'}
    viewBox="0 0 70 60"
    fill="none"
    {...props}
  >
    <path
      d="M36.6793 14.6021C36.292 14.4866 36.0714 14.0794 36.1869 13.6921C37.5323 9.17107 41.7225 5.85156 46.7082 5.85156C47.1123 5.85156 47.44 6.17927 47.44 6.5833C47.44 6.98732 47.1123 7.31504 46.7082 7.31504C42.5296 7.31504 38.7799 10.1087 37.5893 14.1091C37.4749 14.4959 37.0682 14.718 36.6794 14.602L36.6793 14.6021Z"
      fill={color || '#231F20'}
    />
    <path
      d="M56.9522 42.4384H52.5618C52.1578 42.4384 51.8301 42.1107 51.8301 41.7067C51.8301 41.3027 52.1578 40.9749 52.5618 40.9749H56.9522C63.004 40.9749 67.9281 36.0509 67.9281 29.999C67.9281 23.9471 63.004 19.023 56.9522 19.023H56.8466C56.6344 19.023 56.4326 18.9311 56.2936 18.7706C56.1546 18.6101 56.0918 18.3974 56.1222 18.1873C56.1875 17.7315 56.2204 17.2737 56.2204 16.8279C56.2204 11.5829 51.9528 7.31531 46.7079 7.31531C44.6674 7.31531 42.7215 7.95296 41.0803 9.15978C40.7196 9.42478 40.2074 9.30718 39.9989 8.91047C35.3509 0.0596993 23.2107 -1.12887 16.9166 6.57053C14.2652 9.81417 13.2234 14.0336 14.0582 18.146C14.1502 18.6002 13.8026 19.0236 13.3411 19.0236H13.0479C6.996 19.0236 2.07193 23.9477 2.07193 29.9996C2.07193 36.0514 6.996 40.9755 13.0479 40.9755H17.4382C17.8422 40.9755 18.1699 41.3032 18.1699 41.7072C18.1699 42.1113 17.8422 42.439 17.4382 42.439H13.0479C6.1889 42.439 0.608398 36.8585 0.608398 29.9995C0.608398 23.3329 5.87995 17.8742 12.4735 17.5731C11.8541 13.3066 13.0385 9.00295 15.7835 5.64437C22.5222 -2.5996 35.4364 -1.67556 40.8955 7.51707C42.6371 6.42522 44.6299 5.85244 46.7076 5.85244C53.0622 5.85244 58.0976 11.261 57.657 17.58C64.1897 17.9463 69.3913 23.3763 69.3913 29.999C69.3913 36.8585 63.8108 42.4384 56.9518 42.4384L56.9522 42.4384Z"
      fill={color || '#231F20'}
    />
    <path
      d="M34.9019 59.587C24.8149 59.587 16.6084 51.3806 16.6084 41.2935C16.6084 31.2065 24.8148 23 34.9019 23C44.989 23 53.1954 31.2064 53.1954 41.2935C53.1954 51.3804 44.989 59.587 34.9019 59.587ZM34.9019 24.4638C25.622 24.4638 18.0722 32.0137 18.0722 41.2935C18.0722 50.5733 25.6221 58.1232 34.9019 58.1232C44.1816 58.1232 51.7316 50.5732 51.7316 41.2935C51.7316 32.0136 44.1816 24.4638 34.9019 24.4638Z"
      fill={color || '#231F20'}
    />
    <path
      d="M34.5512 48.6577C34.5512 49.0363 34.8583 49.3434 35.2369 49.3434C35.6154 49.3434 35.9226 49.0367 35.9226 48.6577V34.7291C35.9226 34.3504 35.6155 34.0434 35.2369 34.0434C34.8582 34.0434 34.5512 34.3504 34.5512 34.7291V48.6577Z"
      fill={color || '#231F20'}
      stroke={color || '#231F20'}
      stroke-width="0.3"
    />
    <path
      d="M35.2365 35.6999L31.4358 39.5006L35.2365 35.6999ZM35.2365 35.6999L39.0373 39.5007C39.171 39.6344 39.347 39.7015 39.5221 39.7015L35.2365 35.6999ZM30.466 39.5007C30.7338 39.7685 31.1681 39.7686 31.4357 39.5007L39.5222 39.7015C39.697 39.7015 39.8731 39.635 40.0071 39.5006C40.2749 39.2327 40.2749 38.7988 40.007 38.5309L35.7214 34.2452C35.4536 33.9775 35.0192 33.9773 34.7516 34.2452C34.7516 34.2453 34.7515 34.2453 34.7515 34.2453L30.466 38.5309C30.1981 38.7988 30.1981 39.2328 30.466 39.5007Z"
      fill={color || '#231F20'}
      stroke={color || '#231F20'}
      stroke-width="0.3"
    />
  </svg>
);

export default Upload;
