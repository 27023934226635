import React, { Component } from 'react';

import { SessionTopDetailsWrapper, Edit } from '../SessionDetails.Style';

import { Row, Col } from '../../../common/Grid';
import * as T from '../../../common/Typography';

class ManageAttendees extends Component {
  render() {
    const { handleDrawerOpen, confirmedAttendeesList } = this.props;

    return (
      <SessionTopDetailsWrapper>
        <Row mb="5" mt="4">
          <Col w={[4, 8, 10]}>
            <T.P>
              Here you can see who has registered so far & edit your registered
              attendees
            </T.P>
          </Col>
        </Row>
        <Row mb="2">
          <Col w={[2, 6, 6]}>
            <T.P weight={700}>
              Registered attendees: {confirmedAttendeesList.length}
            </T.P>
          </Col>
          <Col w={[2, 6, 6]}>
            <Edit onClick={handleDrawerOpen} data-key="viewAttendeesList">
              Edit
            </Edit>
          </Col>
        </Row>
      </SessionTopDetailsWrapper>
    );
  }
}

export default ManageAttendees;
