import Header from '../../common/Header';

import IEMessage from '../../common/IEMessage';

import * as S from './style';

const General = ({ children, login, maxWidth, layoutColor }) => {
  return (
    <>
      <S.Wrapper layoutColor={layoutColor}>
        <Header login={login} />
        <S.ContentWrapper id="main-content">
          <S.Content maxWidth={maxWidth}>
            <IEMessage />
            {children}
          </S.Content>
        </S.ContentWrapper>
      </S.Wrapper>
    </>
  );
};

export default General;
