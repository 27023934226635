const FromZeroToTen = ({
  question,
  setQuestion,
  onBlur,
  disabled,
  ...props
}) => {
  const { _id: questionId, answer } = question;

  const onChange = e => {
    const { value } = e.target;

    const number = Number(value);
    if (number < 0 || number > 10) return;
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
  };

  return (
    <input
      id={questionId}
      name={questionId}
      type="number"
      min="0"
      max="10"
      onChange={onChange}
      value={answer}
      onBlur={e => {
        onBlur(e.target.value);
      }}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          event.preventDefault();
          onBlur(event.target.value);
        }
        return null;
      }}
      disabled={disabled}
      {...props}
    />
  );
};

export default FromZeroToTen;
