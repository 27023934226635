import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin};
  background: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 10px;
  display: flex;
  width: 100%;
  padding: 27px 16px;
  align-items: center;
  justify-content: space-between;
  h5,
  p,
  div {
    word-wrap: break-word !important;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }
`;

export const CopyBtn = styled.button`
  ${setMargin};
  border: none;
  background: none;
  cursor: pointer;
  min-width: 25%;
  display: flex;
  align-items: center;
  justify-content: ${({ big }) => (big ? 'flex-start' : 'flex-end')};
`;

export const Container = styled.div`
  ${setMargin};
  display: flex;
  width: 100%;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: ${({ big }) => (big ? 'relative' : 'absolute')};
  right: 0;
  bottom: -24px;
`;

export const BigWrapper = styled.div`
  ${setMargin};
  background: ${({ theme }) => theme.colors.lightPrimary};
  border-radius: 10px;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[6]}`};
  max-width: 600px;
`;
