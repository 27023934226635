import styled from 'styled-components';

const Div = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0px 8.3vw;
  background: ${({ bgColor, theme }) =>
    theme.colors[bgColor] || theme.colors.transparent};
  @media (max-width: 918px) {
    padding: ${({ mobilePadding }) => mobilePadding || '0px 24px'};
  }
`;
const MaxWidthContainer = props => {
  return <Div {...props} />;
};

export default MaxWidthContainer;
