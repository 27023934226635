import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../common/Button';

import { resetPassword } from '../../../actions/users';
import { ForgetPasswordForm } from './ForgetPassword.style';
import Layout from '../../Layouts';
import { BasicInput } from '../../common/Inputs';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import { setPassword as validate } from '../../../validation/schemas';

class ResetPassword extends Component {
  state = {
    fields: {},
    errors: {},
    msg: null
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      this.checkError(error);
    }
  }

  checkError = error => {
    if (error.id === 'RESET_PASSWORD_FAIL') {
      this.setState({ msg: error.msg.error });
    } else {
      this.setState({ msg: null });
    }
  };

  // Check inputs validation then if not valide show error msg

  validateForm = () => {
    const { fields } = this.state;

    try {
      validate({
        password: fields.newPassword,
        passwordConfirmation: fields.reNewPassword
      });

      this.setState({
        errors: {}
      });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        this.setState({ errors: error.inner });
      }
      return false;
    }
  };

  onInputChange = e => {
    const { fields } = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  };

  onFormSubmit = e => {
    const { resetPassword: resetPasswordActionCreator, match } = this.props;
    const { token } = match.params;
    const { fields } = this.state;
    const { newPassword } = fields;
    e.preventDefault();
    const isValid = this.validateForm();
    if (isValid) {
      const resetPasswordData = {
        newPassword,
        token
      };

      // call action creator then give it email and password
      resetPasswordActionCreator(resetPasswordData, this.props.history);
    }
  };

  render() {
    const { onInputChange, onFormSubmit } = this;
    const { loading } = this.props;
    const { fields, errors, msg } = this.state;
    const { newPassword, reNewPassword } = fields;

    return (
      <Layout>
        <T.H1>Reset your password!</T.H1>
        <Row mt={7}>
          <Col w={[4, 12, 8]}>
            <ForgetPasswordForm onSubmit={onFormSubmit}>
              <Row mt={7} inner>
                <Col w={[4, 12, 10]} mb={5}>
                  <BasicInput
                    placeholder="new password"
                    name="newPassword"
                    type="password"
                    onChange={onInputChange}
                    value={newPassword}
                    error={errors.password}
                  />
                </Col>

                <Col w={[4, 12, 10]} mb={5}>
                  <BasicInput
                    placeholder="confirm new password"
                    name="reNewPassword"
                    type="password"
                    onChange={onInputChange}
                    value={reNewPassword}
                    error={errors.passwordConfirmation}
                  />
                </Col>
              </Row>
              <Row inner>
                <Col w={[4, 12, 8]} mb={3}>
                  <T.P color="error">{msg}</T.P>
                  <Button
                    onClick={onFormSubmit}
                    type="primary"
                    label="Submit"
                    height="40px"
                    loading={loading}
                  />
                </Col>
              </Row>
            </ForgetPasswordForm>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  error: state.error,
  loading: state.loading.resetPasswordLoading
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
