import React from 'react';
import { Alert } from 'antd';

const { detect } = require('detect-browser');

const browser = detect();

export default () => {
  const isIE = () => {
    const ua = navigator.userAgent;
    return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
  };

  if (browser?.name === 'ie' || isIE()) {
    return (
      <div style={{ marginBottom: '2rem' }}>
        <Alert
          message="Browser not fully supported"
          description="Because you are using Internet Explorer browser some of the app functionality like signing up or logging in may not work as expected, we recommend using Chrome, Edge, Safari or Firefox instead"
          type="warning"
          showIcon
        />
      </div>
    );
  }
  return null;
};
