import { useHistory, useParams } from 'react-router-dom';
import {
  ONBOARDING_SURVEY_QUESTIONS_URL,
  SURVEY_QUESTIONS_URL
} from '../../../constants/navigationRoutes';

import { SkipButtonsDiv } from './Survey.style';

import { surveyTypesNew } from '../../../constants';
import Button from '../../common/Button';

const NextAndBackButtons = ({
  uniqueGroups,
  step,
  onSubmit,
  allCurrentGroupAnswered,
  allQuestionsAnswered,
  loading
}) => {
  const { id, type } = useParams();
  const history = useHistory();
  const nextStep = Number(step) + 1;
  const isLastStep = nextStep === uniqueGroups.length;
  const isOnboarding = type === surveyTypesNew.ONBOARDING;

  return (
    <SkipButtonsDiv>
      {/*  <Button
        label="Back"
        width="100px"
        height="50px"
        type="primary"
        onClick={() => {
          window.scrollTo(0, 0);
          history.goBack();
        }}
        disabled={Number(step) === 0}
      /> */}

      {isLastStep ? (
        <Button
          label="Finish"
          width="200px"
          height="50px"
          type="primary"
          mr="auto"
          onClick={() => {
            onSubmit();
          }}
          disabled={loading || !allQuestionsAnswered}
          loading={loading}
        />
      ) : (
        <Button
          label="Continue"
          width="200px"
          height="50px"
          type="primary"
          mr="auto"
          onClick={() => {
            window.scrollTo(0, 0);
            const pathname = (isOnboarding
              ? ONBOARDING_SURVEY_QUESTIONS_URL
              : SURVEY_QUESTIONS_URL.replace(':id', id)
            )
              .replace(':type', type)
              .replace(':step', nextStep);

            history.push({
              pathname,
              search: history.location.search
            });
          }}
          disabled={!allCurrentGroupAnswered}
        />
      )}
    </SkipButtonsDiv>
  );
};

export default NextAndBackButtons;
