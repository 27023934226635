const Info = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66675 4c0 .36816-.29846.66663-.66663.66663-.36816 0-.66662-.29847-.66662-.66663 0-.36816.29846-.66663.66662-.66663.36817 0 .66663.29847.66663.66663zM8.00012 12.6666c-.276 0-.5-.224-.5-.5V7H6.8335c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1.16662c.276 0 .5.224.5.5v5.6666c0 .276-.224.5-.5.5z"
      fill={color || 'currentColor'}
    />
    <path
      d="M8 16c-4.41138 0-8-3.5886-8-8 0-4.41138 3.58862-8 8-8 4.4114 0 8 3.58862 8 8 0 4.4114-3.5886 8-8 8zM8 1C4.14001 1 1 4.14001 1 8c0 3.86 3.14001 7 7 7 3.86 0 7-3.14 7-7 0-3.85999-3.14-7-7-7z"
      fill={color || 'currentColor'}
    />
    <path
      d="M9.5 12.6666h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Info;
