import React from 'react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import * as T from '../../common/Typography';
import { Row } from '../../common/Grid';
import Table from '../../common/BoostTable';
import { INDIVIDUAL_EVENT } from '../../../constants/navigationRoutes';

import MaxWidthContainer from '../../common/MaxWidthContainer';

const getCapacityStatusMessage = spacesLeft => {
  if (spacesLeft > 0) {
    return `${spacesLeft} space${spacesLeft > 1 ? 's' : ''} left...`;
  } else {
    return 'Join waiting list';
  }
};

const TableColumns = isDesktop => {
  const commonColumns = [
    {
      position: 2,
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_text, row) => {
        const formattedDate = moment(row.date).format('D MMMM YYYY');
        const formattedStartTime = row.startTime
          ? moment(row.startTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()
          : '';
        const formattedEndTime = row.endTime
          ? moment(row.endTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()
          : '';

        return (
          <>
            <T.P color="gray" weight={600}>
              {formattedDate}
            </T.P>
            {formattedStartTime && (
              <T.P color="gray" weight={600} small>
                {formattedStartTime}
                {formattedEndTime && `- ${formattedEndTime}`}
              </T.P>
            )}
          </>
        );
      }
    },
    {
      position: 5,
      title: 'Actions',
      key: 'actions',
      dataIndex: '_id',
      render: id => (
        <T.Link
          color="darkGray"
          to={INDIVIDUAL_EVENT.replace(':id', id.toString())}
        >
          View & Register
        </T.Link>
      )
    }
  ];

  const desktopColumns = [
    {
      position: 1,
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      render: title => (
        <T.P color="gray" weight={600}>
          {title}
        </T.P>
      )
    },
    {
      position: 3,
      title: 'Location',
      key: 'location',
      dataIndex: 'address',
      render: address =>
        address ? (
          <>
            <T.P color="gray" small weight={600}>
              {address?.addressLine1}
            </T.P>
            <T.P color="gray" weight={600}>
              {address?.postcode}
            </T.P>
          </>
        ) : (
          <T.P color="gray" weight={600}>
            Online
          </T.P>
        )
    },
    {
      position: 4,
      title: 'Capacity',
      key: 'capacity',
      dataIndex: 'spacesLeft',
      render: spacesLeft => (
        <T.P color="gray" weight={600}>
          {getCapacityStatusMessage(spacesLeft)}
        </T.P>
      )
    }
  ];

  const orderedColumns = !isDesktop
    ? commonColumns
    : [...commonColumns, ...desktopColumns];

  const sortedColumns = orderedColumns.sort((a, b) => a.position - b.position);

  return sortedColumns;
};

const EventsTableSection = ({ upcomingSessions }) => {
  const { breakpoints, colors } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const renderHeadline = text => (
    <Row mt="7" mtT="4" mb="1" jc="center">
      {!isTablet ? (
        <>
          <T.H3W>
            {text}
            <span style={{ color: colors.mainPrimary, fontSize: '32px' }}>
              ...
            </span>
          </T.H3W>
        </>
      ) : (
        <>
          <T.H2W>
            {text}
            <span style={{ color: colors.mainPrimary, fontSize: '24px' }}>
              ...
            </span>
          </T.H2W>
        </>
      )}
    </Row>
  );

  return (
    <MaxWidthContainer>
      <Row mt="7" jc="center" mb={7}>
        {upcomingSessions?.length ? (
          <>
            {renderHeadline('Upcoming training events for this module')}
            <Row mt="4" jc="center">
              <Table
                data={upcomingSessions}
                columns={TableColumns(isTablet)}
                stripedRows
              />
            </Row>
          </>
        ) : (
          renderHeadline('No upcoming training events for this module')
        )}
      </Row>
    </MaxWidthContainer>
  );
};

export default EventsTableSection;
