import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const Container = styled.div`
  ${setMargins}
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  ${setMargins};
  align-items: center;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightSecondary}`};
`;
