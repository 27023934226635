import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainSecondary};
  height: 1px;
  margin-top: ${({ theme }) => theme.spacings[3]};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  margin-right: auto;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 170px;

  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 100px;
  }
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;
export const ActionLink = styled(Link)`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 450px;
  height: auto;

  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;
