const Time = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2C4.6875 2 2 4.6875 2 8s2.6875 6 6 6 6-2.6875 6-6-2.6875-6-6-6z"
      stroke={color}
      strokeMiterlimit="10"
    />
    <path
      d="M8 4v4.5h3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Time;
