import React from 'react';
import { Empty } from 'antd';
import { Divider, AccessNeedsWrapper } from '../SessionDetails.Style';
import * as T from '../../../common/Typography';
import { Row, Col } from '../../../common/Grid';

const SpecialRequirements = ({ accessNeeds }) => {
  return (
    <Row inner ml="2">
      <div>
        <Col inner w={[4, 12, 8]}>
          <T.H1 mb="4">View access requirements</T.H1>
        </Col>
        <Divider full />
        <Col mb={7} inner w={[4, 12, 12]}>
          <T.P mt={2}>
            Here you can view where there is any specific requirements from
            participants to access and engage with this event.
          </T.P>
        </Col>
        {accessNeeds?.length ? (
          accessNeeds.map(({ questionsAndAnswers, email, name }) => (
            <Row mb={4} key={email}>
              <Divider full />
              <Row>
                <Col inner w={[4, 12, 8]} mt={5}>
                  <T.H3 mr={4}>{name}</T.H3>
                  <T.P small color="gray" weight={700}>
                    {email}
                  </T.P>
                </Col>
              </Row>

              {!!questionsAndAnswers?.length &&
                questionsAndAnswers.map(({ question, answer, order }) => (
                  <Row>
                    <AccessNeedsWrapper>
                      {question !== 'Please provide more details' && (
                        <T.H4 mt={4} weight={700}>
                          {question}
                        </T.H4>
                      )}
                      {!!answer && (
                        <T.P mt={1}>
                          {Array.isArray(answer) ? answer.join(', ') : answer}
                        </T.P>
                      )}
                    </AccessNeedsWrapper>
                  </Row>
                ))}
            </Row>
          ))
        ) : (
          <Empty
            description="No access requirements received currently"
            style={{ marginTop: '5rem' }}
          />
        )}
      </div>
    </Row>
  );
};

export default SpecialRequirements;
