import React from 'react';

import Modal from 'antd/lib/modal';
import Demographics from '../../common/Demographics';
import EvaluationResponses from '../../common/EvaluationResponses';
import Reach from '../../common/Reach';
import TrainerFeedback from '../../common/TrainerFeedback';

const showModal = content => {
  Modal.info({
    content,
    style: { top: 20 },
    icon: false,
    centered: true
  });
};

const content = {
  cont1:
    'This section provides an overview of sessions and related surveys collected via the app. I you wish to export the results click on "Export as CSV" and it will export and do',
  cont2:
    'This section shows a break down of answers given by your course participants stemming from surveys responses (categories: about your way of teaching (train the trainer) and about your trainer(other session types). The numbers show the count of each answerrelated to a particular survey type).',
  cont3:
    'We all know that changing what we do is not as simple as knowing what to do. Just because we CAN do something doesn’t mean that we WILL do it. We will be asking you some questions about what you do in practice, about what you expect you will do when you return to work and about some of the thoughts and feelings you have that make up your capability, opportunity and motivation. This will help you and us understand about experiences of doing the behaviours promoted in training as you go about your work.'
};

export default {
  reach: {
    text: 'Reach',
    onInfoClick: e => {
      showModal(content.cont1);
    },
    render: ({
      results,
      handleFilteredData,
      filters,
      resultForRole,
      fetchUserResultsLoading
    }) => (
      <Reach
        data={results}
        role={resultForRole}
        handleFilteredData={handleFilteredData}
        filters={filters}
        fetchUserResultsLoading={fetchUserResultsLoading}
      />
    )
  },
  demographics: {
    text: 'Demographics',
    render: ({ resultsFor, resultForRole, filters, ...props }) => {
      return (
        <Demographics
          {...props}
          trainerId={resultsFor}
          role={resultForRole}
          filters={filters}
        />
      );
    }
  },
  evaluationResponses: {
    text: 'Evaluation Responses',
    render: ({
      results,
      filters,
      handleFilteredData,
      resultForRole,
      fetchUserResultsLoading
    }) => (
      <EvaluationResponses
        data={results}
        handleFilteredData={handleFilteredData}
        filters={filters}
        role={resultForRole}
        fetchUserResultsLoading={fetchUserResultsLoading}
      />
    )
  },
  trainerFeedback: {
    text: 'Trainer Feedback',
    render: ({ results, filters }) => (
      <TrainerFeedback data={results} filters={filters} />
    )
  }
};
