import { createGlobalStyle } from 'styled-components';

import VAGRoundedStdBlack from '../assets/fonts/VAG_Rounded_Std_Black.otf';
import VAGRoundedStdBold from '../assets/fonts/VAG_Rounded_Std_Bold.otf';
import VAGRoundedStdThin from '../assets/fonts/VAG_Rounded_Std_Thin.otf';

export const PovertyGlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'VAGRoundedStd';
    src: url(${VAGRoundedStdBlack}) format('opentype');
    font-weight: 900;
  }

  @font-face {
    font-family: 'VAGRoundedStd';
    src: url(${VAGRoundedStdBold}) format('opentype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'VAGRoundedStd';
    src: url(${VAGRoundedStdThin}) format('opentype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'VAGRoundedStd';
    src: url(${VAGRoundedStdThin}) format('opentype');
    font-weight: 400;
  }

  * {
    font-family: 'VAGRoundedStd', sans-serif !important;
  }
`;
