const Radio = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, options, answer } = question;

  const onChange = e => {
    const { value } = e.target;
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
    onBlur(value);
  };
  return (
    <div className="radio-options">
      {options.map(e => {
        const value = e;
        const uniqueId = e + questionId;
        return (
          <div key={`${value}parent`}>
            <div key={`${value}child`}>
              <label htmlFor={uniqueId}>
                <input
                  value={value}
                  checked={answer === value ? value : ''}
                  id={uniqueId}
                  name={questionId}
                  type="radio"
                  onChange={onChange}
                  disabled={disabled}
                  aria-describedby={`qs-text-${questionId} option-${value}-${questionId}`}
                />
                <span className="checkmark" />
                <p id={`option-${value}-${questionId}`} aria-hidden="true">
                  {value}
                </p>
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Radio;
