import axios from 'axios';
import swal from 'sweetalert2';
import { customSwal } from '../theme';

import {
  FETCH_SURVEY_DATA,
  GET_PARTICIPANT_SURVEY_RESPONSES_FAIL,
  GET_PARTICIPANT_SURVEY_RESPONSES_RESET,
  GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS,
  SURVEY_SUBMISSION_FAIL,
  SURVEY_SUBMISSION_LOADING,
  SURVEY_SUBMISSION_SUCCESS
} from '../constants/actionTypes';

import { mapSurveyTypeWithBackwardCompatibility } from '../helpers';
import { checkAuth } from './authAction';
import { returnErrors } from './errorAction';

export const fetchSurveyData = ({ id, type }) => dispatch => {
  const surveyType = mapSurveyTypeWithBackwardCompatibility(type);

  axios
    .get(`/api/survey/${id}/${surveyType}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_SURVEY_DATA,
        payload: data
      });
    })
    .catch(err => {
      dispatch(
        returnErrors(
          err.response.data,
          err.response.status,
          'fetching survey data failed'
        )
      );
    });
};

export const submitSurvey = ({
  sessionId,
  surveyType,
  questions,
  cb
}) => dispatch => {
  dispatch({ type: SURVEY_SUBMISSION_LOADING });

  axios
    .post(`/api/survey/submit/`, { sessionId, surveyType, questions })
    .then(({ data }) => {
      dispatch({
        type: SURVEY_SUBMISSION_SUCCESS,
        payload: data
      });
      dispatch(checkAuth());
      return swal
        .fire({
          title: 'Done!',
          text: 'Thanks for submitting your feedback!',
          ...customSwal
        })
        .then(cb);
    })
    .catch(err => {
      dispatch({ type: SURVEY_SUBMISSION_FAIL, payload: err?.response?.data });
      return swal.fire({
        title:
          err?.response?.data?.error ||
          'There was an error submitting your survey. Please check that you have answered all required questions and entered your correct PIN',
        ...customSwal
      });
    });
};

export const getParticipantByEmail = ({
  sessionId,
  surveyType
}) => async dispatch => {
  try {
    const { data } = await axios.get(`/api/participant`, {
      params: { sessionId, surveyType }
    });
    dispatch({ type: GET_PARTICIPANT_SURVEY_RESPONSES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_PARTICIPANT_SURVEY_RESPONSES_FAIL,
      payload: err.response?.data
    });
  }
};

export const resetGetParticipantByEmail = () => dispatch => {
  dispatch({ type: GET_PARTICIPANT_SURVEY_RESPONSES_RESET });
};
