import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const MultiInputFieldWrapper = styled.div`
  ${setMargins};
  width: 100%;
`;

export const TextWithIcon = styled.button`
  ${setMargins};
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;

export const Form = styled.form`
  max-width: 498px;
  border-radius: 12px;
`;

export const GrayLine = styled.div`
  ${setMargins}
  border-top : 1px solid #EBEEF0;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  height: 2px;
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`;

export const MaterialDivider = styled.hr`
  width: 100%;
  border: 0;
  background-color: ${({ theme }) => theme.colors.midGray};
  height: 1px;
  margin-bottom: ${({ theme }) => theme.spacings[3]};
`;
