import React from 'react';
import { ThemeProvider } from 'styled-components';

const withTheme = (Component, theme, GlobalStyles) => {
  return props => (
    <ThemeProvider theme={theme}>
      {GlobalStyles && <GlobalStyles />}
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withTheme;
