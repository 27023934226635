import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 260px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
`;

export const DoubleQuotes = styled.img`
  width: 100px;
  height: 100px;
  align-self: flex-end;
  margin-right: 40px;
`;
