import { Modal as AntModal, Input } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  fetchStatsData,
  updateUserInfo as updateUserInfoAction
} from '../../../actions/users';

import { logout } from '../../../actions/authAction';
import { deleteAccountAction } from '../../../actions/deleteAccountAction';

import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Modal from '../../common/modal';
import * as T from './../../common/Typography';
import Layout from './../../Layouts';

// STYLING
import {
  BoldSpan,
  Detail,
  DetailsContent,
  DeteteAccountBtn,
  StyledLink,
  Row as StyledRow,
  Wrapper
} from './MyProfile.style';

const { confirm } = AntModal;

class MyProfile extends Component {
  state = {
    organization: null,
    visible: false,
    activeStatus: ''
  };

  componentDidUpdate(prevProps) {
    const { organization: currentOrganization } = this.props;
    const { organization: prevOrganization } = prevProps;
    if (currentOrganization !== prevOrganization) {
      this.updateState({ organization: currentOrganization });
    }
  }

  updateState = data => {
    this.setState(data);
  };

  showModal = key => {
    const { organization } = this.props;
    this.setState({
      visible: true,
      activeStatus: key,
      organization
    });
  };

  handleOk = () => {
    const { organization: organizationState } = this.state;

    const { organization: organizationProps, updateUserInfo } = this.props;

    const data = {};

    if (organizationProps !== organizationState) {
      data.organization = organizationState;
    }

    if (Object.keys(data).length > 0) {
      updateUserInfo(data, this.updateState);
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleChangeOrg = e => {
    const { value } = e.target;
    this.setState({ organization: value });
  };

  deleteAccount = (credentialId, role) => {
    const {
      deleteAccountAction: deleteAccountActionCreator,
      deleteAccountLoading
    } = this.props;
    if (role === 'trainer') {
      confirm({
        title: 'Are you sure that you want to delete your account?',
        content:
          'By doing this you will not be able to log into any of your Connect 5 accounts. This cannot be undone.',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        okButtonProps: {
          loading: deleteAccountLoading
        },
        loading: true,
        onOk() {
          deleteAccountActionCreator(credentialId);
        },
        centered: true,
        icon: null
      });
    }
  };

  capitalizeName = userName =>
    userName && userName[0].toUpperCase() + userName.substr(1);

  render() {
    const { deleteAccount } = this;
    const {
      userName,
      role,
      region,
      email,
      organization,
      credentialId
    } = this.props;

    const {
      visible,
      activeStatus,
      updateUserLoading,
      organization: organizationState
    } = this.state;

    return (
      <Layout>
        <Wrapper>
          <T.H1 mb="8">My Profile</T.H1>
          <DetailsContent>
            <StyledRow>
              <Detail>
                <BoldSpan>Name: </BoldSpan>
                {this.capitalizeName(userName)}
              </Detail>
            </StyledRow>
            <StyledRow>
              <Detail>
                <BoldSpan>Email: </BoldSpan>
                {email}
              </Detail>
            </StyledRow>
            <StyledRow>
              <Detail>
                <BoldSpan>Role: </BoldSpan>
                {role}
              </Detail>
            </StyledRow>
            <StyledRow>
              <Detail>
                <BoldSpan>Region: </BoldSpan>
                {region || 'N/A'}
              </Detail>
            </StyledRow>

            <StyledRow>
              <Detail>
                <BoldSpan>Organisation: </BoldSpan>
                {organization || 'N/A'}
              </Detail>
              <Detail>
                <BoldSpan
                  onClick={() => this.showModal('Organisation')}
                  pointer
                >
                  Edit
                </BoldSpan>
              </Detail>
            </StyledRow>
          </DetailsContent>
          <StyledLink to="/change-password">Change My Password</StyledLink>
          <DeteteAccountBtn onClick={() => deleteAccount(credentialId, role)}>
            Delete My Account
          </DeteteAccountBtn>
          <Modal
            title={`Edit ${activeStatus}`}
            visible={visible}
            parentFunc={this.handleOk}
            loading={updateUserLoading}
            setModalOpen={this.handleCancel}
          >
            {activeStatus === 'Organisation' && (
              <>
                <Row mb={4}>
                  <Col w={[4, 12, 12]}>
                    <Input
                      placeholder="Organisation"
                      onChange={this.handleChangeOrg}
                      value={organizationState}
                      label="Edit organisation"
                    />
                  </Col>
                </Row>
                <Row mb={2}>
                  <Col w={[4, 12, 6]}>
                    <Button
                      type="primary"
                      onClick={this.handleCancel}
                      label="Back"
                      loading={updateUserLoading}
                    />
                  </Col>
                  <Col w={[4, 12, 6]}>
                    <Button
                      type="secondary"
                      onClick={() => this.handleOk()}
                      label="Confirm"
                      loading={updateUserLoading}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Modal>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: state.auth.name,
    email: state.auth.email,
    credentialId: state.auth.credentialId,
    role: state.auth.role,
    organization: state.auth.organization,
    region: state.auth.region,
    updateUserLoading: state.loading.updateUserLoading,
    deleteAccountLoading: state.loading.deleteAccountLoading
  };
};

export default connect(mapStateToProps, {
  updateUserInfo: updateUserInfoAction,
  fetchStatsData,
  logout,
  deleteAccountAction
})(MyProfile);
