import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled(RouterLink)`
  ${setMargin}
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    offset: 2px;
    border-radius: 4px;
  }
`;

export const CardHead = styled.div`
  background: ${({ bgColor, theme }) =>
    theme.colors[bgColor] || theme.colors.mainPrimary};
  width: 100%;
  height: 246px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ imageResize }) => (imageResize ? 'contain' : 'cover')};
  }
`;
