import React, { useEffect, useState } from 'react';
import { Col, Row } from '../Grid';
import * as T from '../Typography';
import Table from './../Table';

import ExportButton from '../ExportButton';
import FilterResults from '../FilterResults';

const moduleColumns = [
  {
    label: 'Module',
    value: 'key',
    align: 'left'
  },
  {
    label: 'Registered participants',
    value: 'confirmedParticipants',
    align: 'center'
  },
  {
    label: 'Completed',
    value: 'completedParticipants',
    align: 'center'
  }
];

const sessionColumns = [
  {
    label: 'Event',
    value: 'title',
    align: 'left'
  },
  {
    label: 'Format',
    value: 'format',
    align: 'center'
  },
  {
    label: 'Registered participants',
    value: 'confirmedParticipants',
    align: 'center'
  },
  {
    label: 'Non-attendees',
    value: 'didNotShowUpParticipants',
    align: 'center'
  }
];

const Reach = ({
  data,
  role,
  handleFilteredData,
  filters,
  fetchUserResultsLoading
}) => {
  const [reachFilters, setReachFilters] = useState(filters);

  useEffect(() => {
    handleFilteredData({
      ...reachFilters,
      ...filters,
      panel: 'reach'
    });
  }, [filters, handleFilteredData, reachFilters]);

  if (fetchUserResultsLoading) {
    return <T.P>Fetching data...</T.P>;
  }

  return (
    <div>
      <T.P>View the number of modules delivered and events attended.</T.P>
      <FilterResults
        role={role}
        applyFiltersCb={_filters => {
          setReachFilters(_filters);
        }}
      />
      <T.H3 mt="6" mb="4">
        Modules
      </T.H3>
      <Table columns={moduleColumns} data={data.groupedSessions} />

      <T.H3 mt="6" mb="4">
        Events
      </T.H3>
      <Table columns={sessionColumns} data={data.sessions} />
      <Row inner mt={4}>
        <Col w={[4, 6, 6]}>
          <ExportButton
            filters={{
              ...reachFilters,
              ...filters
            }}
            type="primary"
            text="Export Event Data"
            height="50px"
            exportType="sessions"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Reach;
