import React, { useState } from 'react';
import { connect } from 'react-redux';

import BoostLogo from '../../../assets/boost/Boost logo (without background) - for website.png';

import Button from '../Button';
import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

import {
  ACCESSIBILITY,
  EVENTS_URL,
  EXTERNAL_LEARNING_ACADEMY,
  HOME_URL,
  LOGIN_URL,
  MODULES_URL,
  PROGRAMMES
} from '../../../constants/navigationRoutes';

const MenuLinkItem = ({
  to,
  linkText,
  icon,
  iconHeight,
  external,
  children
}) => (
  <S.MenuLinkWrapper
    to={{
      pathname: to
    }}
    target={external ? '_blank' : ''}
  >
    {children}
    {icon && (
      <S.MenuLogoImage>
        <Icon
          icon={icon}
          color="primary"
          height={iconHeight || '24px'}
          width="24px"
        />
      </S.MenuLogoImage>
    )}
    {linkText && (
      <T.P small weight="700" underline={false} style>
        {linkText}
      </T.P>
    )}
  </S.MenuLinkWrapper>
);

const WebsiteMenu = ({ role, isAuthenticated }) => {
  const [open, setOpen] = useState(false);

  const getAuthRoute = () => {
    if (!isAuthenticated) {
      return LOGIN_URL;
    } else {
      return HOME_URL;
    }
  };

  const DesktopWebsiteMenu = () => (
    <S.DesktopWrapper>
      <S.LogosSide>
        <MenuLinkItem to={EXTERNAL_LEARNING_ACADEMY} external>
          <S.Logo src={BoostLogo} alt="BoostLogo" />
        </MenuLinkItem>
      </S.LogosSide>
      <S.LinksSide>
        <MenuLinkItem to={PROGRAMMES} linkText="Programmes" icon="boostCog" />
        <MenuLinkItem to={MODULES_URL} linkText="Modules" icon="boostStar" />
        <MenuLinkItem
          to={EVENTS_URL}
          linkText="Events"
          icon="boostSpeechBubble"
        />
        <MenuLinkItem to={ACCESSIBILITY} linkText="Accessibility" />
        <Button
          to={getAuthRoute()}
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            width: '200px',
            height: 'auto'
          }}
          padding="10px"
          type="primary"
          label={isAuthenticated ? 'My account' : 'Sign Up / Sign In'}
        />
      </S.LinksSide>
    </S.DesktopWrapper>
  );

  const MobileWebsiteMenu = () => (
    <>
      <S.MobileWrapper>
        <MenuLinkItem to={EXTERNAL_LEARNING_ACADEMY} external>
          <S.Logo src={BoostLogo} alt="BoostLogo" />
        </MenuLinkItem>
        {!open && (
          <S.IconWrapper onClick={() => setOpen(true)} aria-label="menu">
            <Icon icon="menu" color="darkGray" width="20px" height="20px" />
          </S.IconWrapper>
        )}
        {open && (
          <S.IconWrapper onClick={() => setOpen(false)} aria-label="cross">
            <Icon icon="cross" color="darkGray" width="20px" height="20px" />
          </S.IconWrapper>
        )}
      </S.MobileWrapper>
      {open && (
        <S.Mask>
          <S.MenuLinksWrapper>
            <S.MenuLinkDivider />
            <S.DesktopLink to={PROGRAMMES}>
              <T.P color="darkGray" weight="700">
                Programmes
              </T.P>
            </S.DesktopLink>
            <S.MenuLinkDivider />
            <S.DesktopLink to={MODULES_URL}>
              <T.P color="darkGray" weight="700">
                Modules
              </T.P>
            </S.DesktopLink>
            <S.MenuLinkDivider />
            <S.DesktopLink to={EVENTS_URL}>
              <T.P color="darkGray" weight="700">
                Events
              </T.P>
            </S.DesktopLink>
            <S.MenuLinkDivider />
            <S.DesktopLink
              to={{
                pathname: getAuthRoute(),
                state: {
                  preventRedirect: true
                }
              }}
            >
              <T.P color="darkGray" weight="700">
                {isAuthenticated ? 'My account' : 'Sign Up / Sign In'}
              </T.P>
            </S.DesktopLink>
          </S.MenuLinksWrapper>
        </S.Mask>
      )}
    </>
  );

  return (
    <S.Container open={open}>
      <DesktopWebsiteMenu />
      <MobileWebsiteMenu />
    </S.Container>
  );
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    isAuthenticated: state.auth.isAuthenticated
  };
};
export default connect(mapStateToProps, {})(WebsiteMenu);
