/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useSearchParams from '../../../hooks/useSearchParams';

//  COMMON COMPONENTS
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import Collapse from '../../common/Collapse';
import { Col, Row } from '../../common/Grid';

// ACTIONS
import { fetchProgrammes } from '../../../actions/programmesActions';
import { fetchUserResults as fetchUserResultsAction } from '../../../actions/users';

import { userRoles } from '../../../constants';
import { Dropdown } from '../../common/Inputs';
import Spin from '../../common/Spin';
import panels from './panels';

const Analytics = ({
  loggedInUserId,
  history,
  results,
  role,
  fetchUserResults,
  programmes,
  fetchProgrammesLoading,
  programmesCanDeliver,
  fetchProgrammes,
  fetchUserResultsLoading
}) => {
  const searchParams = useSearchParams();

  const [resultsFor, setResultsFor] = useState(null);
  const [resultForRole, setResultForRole] = useState(null);
  const [filters, setFilters] = useState({});
  const [programme, setProgramme] = useState(null);

  const handleFilteredData = useCallback(
    _filters => {
      fetchUserResults(resultsFor, _filters, history);
    },
    [fetchUserResults, history, resultsFor]
  );

  const handleProgrammeSelection = useCallback(_id => {
    setProgramme(_id);
  }, []);

  const getProgrammesDropdownOptions = useCallback(() => {
    const programmeOptions = programmes
      .filter(programme => {
        if (role === userRoles.programmeManager) {
          return programmesCanDeliver?.includes(programme._id);
        }
        return true;
      })
      .map(programme => ({
        label: programme.titleShort,
        value: programme._id
      }));

    programmeOptions.sort((a, b) => a.label.localeCompare(b.label));

    programmeOptions.unshift({ label: 'All Programmes', value: 'all' });

    return programmeOptions;
  }, [programmes, programmesCanDeliver, role]);

  useEffect(() => {
    if (!loggedInUserId) {
      history.push('/unauthorized');
    }

    const userId = searchParams.params.userId;

    const _filters = {
      ...(programme && programme !== 'all' && { programme })
    };

    if (userId) {
      setResultsFor(userId);
      setResultForRole(userRoles.trainer);
      setFilters({ trainer: [userId], ..._filters });
    } else {
      setResultsFor(loggedInUserId);
      setResultForRole(role);

      if (role === userRoles.trainer) {
        setFilters({ trainer: [loggedInUserId], ..._filters });
      } else {
        setFilters(_filters);
      }
    }
  }, [history, loggedInUserId, programme, role, searchParams.params.userId]);

  useEffect(() => {
    fetchProgrammes({ forAdmin: true });
  }, [fetchProgrammes]);

  useEffect(() => {
    if (role === userRoles.admin) {
      setProgramme('all');
    } else if (
      programmes &&
      [userRoles.trainer, userRoles.programmeManager].includes(role) &&
      programmesCanDeliver
    ) {
      if (programmesCanDeliver.length === 1) {
        setProgramme(programmesCanDeliver[0]);
      } else {
        setProgramme('all');
      }
    }
  }, [programmes, programmesCanDeliver, role]);

  useEffect(() => {
    const _programmeSlug = searchParams.params.programme;
    if (_programmeSlug && programmes) {
      const _programme = programmes.find(
        programme => programme.slug === _programmeSlug
      );
      if (_programme) {
        setProgramme(_programme._id);
      }
    }
  }, [programmes, searchParams.params.programme]);

  const panelsArr =
    programme === 'all'
      ? Object.entries(panels)
          .filter(([key]) => key !== 'evaluationResponses')
          .map(([key, value]) => value)
      : Object.values(panels);

  if (fetchProgrammesLoading || !programmes) {
    return <Spin />;
  }

  return (
    <Layout>
      <>
        <AccountPageHeader title="Analytics" />

        {(role === userRoles.admin ||
          ([userRoles.programmeManager, userRoles.trainer].includes(role) &&
            programmesCanDeliver?.length > 1)) && (
          <Row>
            <Col w={[4, 8, 8]}>
              <Dropdown
                selected={programme}
                placeholder="Select Programme..."
                required
                options={getProgrammesDropdownOptions()}
                name="programme"
                handleChange={handleProgrammeSelection}
              />
            </Col>
          </Row>
        )}

        <Collapse
          items={panelsArr.map(panel => ({
            title: panel.text,
            infoContent: panel.infoContent,
            content: panel.render({
              results,
              resultsFor,
              resultForRole,
              role,
              filters,
              handleFilteredData,
              fetchUserResultsLoading
            })
          }))}
        />
      </>
    </Layout>
  );
};

const mapStateToProps = state => ({
  results: state.results,
  loggedInUserId: state.auth.userId,
  role: state.auth.role,
  programmes: state.programmes.programmes,
  fetchProgrammesLoading: state.loading.fetchProgrammesLoading,
  programmesCanDeliver: state.auth.programmesCanDeliver,
  fetchUserResultsLoading: state.loading.fetchUserResultsLoading
});

export default connect(mapStateToProps, {
  fetchUserResults: fetchUserResultsAction,
  fetchProgrammes
})(Analytics);
