import moment from 'moment';
import React from 'react';

import * as R from '../../../constants/navigationRoutes';
import Badge from '../../common/Badge';
import Icon from '../../common/Icon';
import * as T from '../../common/Typography';
import * as S from './style';

const handleBadges = category => {
  switch (category?.title) {
    default:
      return <Badge title={category?.title} variant="primary" />;
  }
};

export const TableColumns = ({ handleArchive, isArchived, programmeSlug }) => [
  {
    title: (
      <T.P weight={600} color="darkGray">
        Module name
      </T.P>
    ),
    dataIndex: 'title',
    key: 'title',
    render: text => (
      <T.P weight={600} color="gray">
        {text}
      </T.P>
    )
  },
  {
    title: (
      <T.P weight={600} color="darkGray">
        Created
      </T.P>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: date => {
      return (
        <T.P weight={600} color="gray">
          {moment(date).format('DD.MM.YYYY')}
        </T.P>
      );
    },
    sorter: (a, b) =>
      moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
    width: '20%'
  },
  {
    title: (
      <T.P weight={600} color="darkGray">
        Category
      </T.P>
    ),
    dataIndex: 'category',
    key: 'category',
    render: category => handleBadges(category),
    sorter: (a, b) => {
      return a.category.length - b.category.length;
    }
  },

  {
    key: 'action',
    render: (_, record) => (
      <S.ActionsWrapper>
        <S.ActionLink
          to={R.MANAGE_PROGRAMMES_PROGRAMME_MODULE_EDIT.replace(
            ':programmeSlug',
            programmeSlug
          ).replace(':id', record._id)}
        >
          <Icon
            icon="edit"
            mr="1"
            weight={600}
            color="darkGray"
            width="24px"
            height="24px"
          />
          <T.P color="darkGray" weight={600}>
            Edit
          </T.P>
        </S.ActionLink>
        <S.ActionButton onClick={() => handleArchive({ item: record })}>
          <Icon
            ml="4"
            mr="1"
            icon="archive"
            color="darkGray"
            width="24px"
            height="24px"
          />{' '}
          <T.P weight={600} color="darkGray">
            {isArchived ? 'Unarchive' : 'Archive'}
          </T.P>
        </S.ActionButton>
      </S.ActionsWrapper>
    ),
    width: '30%'
  }
];
