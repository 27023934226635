import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';

const levels = {
  Introductory: 1,
  Intermediate: 2,
  Advanced: 3
};

const DifficultyLevelBars = ({ levelText, color = 'primary' }) => {
  return (
    <S.Wrapper>
      <Icon mb={3} icon="levelBars" level={levels[levelText]} color={color} />
      <T.P ml={2}>{levelText}</T.P>
    </S.Wrapper>
  );
};

export default DifficultyLevelBars;
