import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { signUpParticipant } from '../../../actions/authAction';
import {
  PARTICIPANT_LOGIN,
  TERMS_OF_USE,
  PRIVACY_POLICY
} from '../../../constants/navigationRoutes';
import useFormData from '../../../hooks/useFormData';
import useRedirectAfterAuth from '../../../hooks/useRedirectAfterAuth';
import useSearchParams from '../../../hooks/useSearchParams';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/participantSignup';
import Button from '../Button';
import { Col, Row } from '../Grid';
import { BasicInput, Checkbox, Dropdown } from '../Inputs';
import * as T from '../Typography';
import * as S from './style';
import {
  northEastAndNorthCumbriaICBOrganisations,
  organisations as organisationsOptions
} from '../../../constants';

const cleanEmail = email => email.toLowerCase().trim();

const initialFormState = {
  email: '',
  password: '',
  name: '',
  phone: '',
  jobRole: '',
  organisation: { value: '', category: '' },
  organisationOther: '',
  showOrgOther: false,
  showOrgIcb: false,
  newsletterSubscription: false,
  agreeToTerms: false
};

const SignUpForm = ({
  httpError,
  loading,
  signUpParticipant,
  renderInline = false,
  searchParams: propSearchParams,
  handleShowLogin = () => {}
}) => {
  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const searchParams = useSearchParams();
  const effectiveSearchParams = propSearchParams || searchParams;

  const redirectAfterAuth = useRedirectAfterAuth();
  const {
    name,
    email,
    password,
    phone,
    jobRole,
    organisation,
    showOrgOther,
    showOrgIcb,
    organisationOther,
    newsletterSubscription,
    agreeToTerms
  } = formState;

  const capitalize = string => {
    return string.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const handleSignup = async e => {
    setFormData({ loading: true });
    searchParams.set('initialSignup', true);
    const { current: finalFormState } = finalSubmissionData;

    const payload = {
      ...finalFormState,
      email: cleanEmail(email),
      name: capitalize(name),
      organisation: {
        ...organisation,
        value:
          showOrgOther || showOrgIcb ? organisationOther : organisation.value
      }
    };

    const data = await signUpParticipant(payload);
    if (!data) return;
    redirectAfterAuth(data.role);
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    password,
    phone,
    jobRole,
    organisation,
    organisationOther,
    newsletterSubscription,
    agreeToTerms
  ]);

  useEffect(() => {
    if (organisation.value.includes('ICB')) {
      setFormData({
        showOrgIcb: true,
        showOrgOther: false
      });
    } else if (organisation.value.includes('specify')) {
      setFormData({
        showOrgIcb: false,
        showOrgOther: true
      });
    } else {
      setFormData({
        showOrgIcb: false,
        showOrgOther: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation.value]);

  const renderDontHaveAccount = () => (
    <T.P mt="6">
      Already have an account?{' '}
      <T.Link
        to={
          !renderInline && {
            pathname: PARTICIPANT_LOGIN,
            search: effectiveSearchParams.toString()
          }
        }
        onClick={e => renderInline && handleShowLogin(e)}
      >
        Log in
      </T.Link>
    </T.P>
  );

  return (
    <>
      <S.Wrapper className="sign-up">
        <Row>
          <Col w={[4, 12, 8]}>
            <S.ContentWrapper>
              <S.Form className="signup-form" renderInline={renderInline}>
                <T.P weight={400} mb="5">
                  In order to take full advantage of the Boost learning academy,
                  please create an account. This account allows you to register
                  for training courses and events.
                </T.P>
                <Row inner>
                  <Col w={[4, 12, 10]}>
                    <BasicInput
                      value={name}
                      name="name"
                      handleChange={value => setFormData({ name: value })}
                      placeholder="Full name"
                      mb={3}
                      required
                      error={validationErrors.name}
                    />
                    <BasicInput
                      value={email}
                      name="email"
                      handleChange={value => setFormData({ email: value })}
                      placeholder="Email"
                      mb={3}
                      required
                      error={
                        validationErrors.email ||
                        (httpError?.response?.status === 409 &&
                          httpError?.response?.data?.error)
                      }
                    />
                    <BasicInput
                      value={password}
                      placeholder="Create password"
                      name="password"
                      mb={3}
                      type="password"
                      handleChange={value => setFormData({ password: value })}
                      error={validationErrors.password}
                    />
                    <BasicInput
                      value={phone}
                      handleChange={value => setFormData({ phone: value })}
                      mb={3}
                      placeholder="Phone"
                      required
                      error={validationErrors.phone}
                    />
                    <BasicInput
                      value={jobRole}
                      handleChange={value => setFormData({ jobRole: value })}
                      mb={3}
                      placeholder="Job title"
                      required
                      error={validationErrors.jobRole}
                    />
                    <Dropdown
                      selected={organisation.value}
                      m={{ mb: 0 }}
                      placeholder="Organisation"
                      required
                      options={organisationsOptions}
                      handleChange={(value, option, b) =>
                        setFormData({
                          organisation: {
                            value,
                            category: option?.key?.split('_')[0]
                          }
                        })
                      }
                    />
                    {showOrgOther && (
                      <BasicInput
                        value={organisationOther}
                        autoFocus
                        handleChange={value =>
                          setFormData({ organisationOther: value })
                        }
                        placeholder="Type organisation here..."
                        mb={3}
                        error={
                          validationErrors.organisation ||
                          validationErrors.organisationOther
                        }
                      />
                    )}
                    {showOrgIcb && (
                      <Dropdown
                        selected={organisationOther}
                        m={{ mb: 3 }}
                        placeholder="Select directorate..."
                        required
                        options={northEastAndNorthCumbriaICBOrganisations.map(
                          org => ({ value: org, label: org })
                        )}
                        handleChange={value =>
                          setFormData({ organisationOther: value })
                        }
                      />
                    )}
                    <S.StyledCheckBox
                      checked={newsletterSubscription}
                      handleChange={value =>
                        setFormData({ newsletterSubscription: value })
                      }
                      mb={3}
                      label="I wish to receive Boost monthly bulletin updates via email"
                      labelColor="darkGray"
                      color="black"
                      renderInline={renderInline}
                    />
                    <S.StyledCheckBox
                      checked={agreeToTerms}
                      handleChange={value =>
                        setFormData({ agreeToTerms: value })
                      }
                      mb={3}
                      label={
                        <S.CheckboxLabel>
                          I agree to the{' '}
                          <T.Link external href={TERMS_OF_USE}>
                            Terms and Conditions
                          </T.Link>{' '}
                          and acknowledge the{' '}
                          <T.Link external href={PRIVACY_POLICY}>
                            Privacy Policy
                          </T.Link>
                        </S.CheckboxLabel>
                      }
                      labelColor="darkGray"
                      color="black"
                      renderInline={renderInline}
                    />
                  </Col>
                </Row>
                <Row inner mb={4}>
                  <Col w={[4, 12, 10]}>
                    <T.P small>
                      By creating an account I confirm that North East and North
                      Cumbria Integrated Care Board and partners can access
                      individual profile data such as name, email address and
                      organisation as well as evaluation results collected via
                      the app.
                    </T.P>
                  </Col>
                </Row>
                <Row inner>
                  <Col w={[4, 6, 6]}>
                    <Button
                      onClick={handleSubmit}
                      type={renderInline ? 'quartenary' : 'primary'}
                      label="Create account"
                      loading={loading}
                      disabled={!agreeToTerms}
                    />
                  </Col>
                </Row>
                {renderDontHaveAccount()}
              </S.Form>
            </S.ContentWrapper>
          </Col>
        </Row>
      </S.Wrapper>
    </>
  );
};

const mapStateToProps = state => ({
  httpError: state.auth.error,
  loading: state.loading.signupLoading
});

export default connect(mapStateToProps, { signUpParticipant })(SignUpForm);
