import { validate as _validate, createSchema, fields } from '..';

const { organisationOther, requiredText, checkbox } = fields;

const validate = data => {
  const schema = createSchema({
    organisationOther,
    jobRole: requiredText,
    newsletterSubscription: checkbox
  });
  return _validate(schema, data);
};

export default validate;
