import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import SearchFilter from '../../common/SearchFilter';
import { modulesFilters } from '../../../constants/filtersData';
import ProgrammeHeader from '../../common/ProgrammeHeader';
import modulesHeaderImage from '../../../assets/boost/man_at_whiteboard.png';
import { fetchModules } from '../../../actions/modulesActions';

import { TableColumns } from './TableColumns';
import { useEffect } from 'react';
import Spin from '../../common/Spin';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import { Row } from '../../common/Grid';

import * as S from './style';

const Modules = ({
  filteredModules,
  modules: allModules,
  loading,
  fetchModulesAction
}) => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  useEffect(() => {
    fetchModulesAction({ forPublic: true });
  }, [fetchModulesAction]);

  const modules = filteredModules || allModules;

  if (loading || !allModules) {
    return <Spin />;
  }

  return (
    <Layout layout="website">
      <S.TopRow>
        <ProgrammeHeader
          title="Modules"
          description="Here you can search the full list of learning modules that you can complete in the Academy, across every programmes. Click on any module title to find out more information and enrol. "
          image={modulesHeaderImage}
          withOverlay={true}
        />
      </S.TopRow>
      <Row mb="4" jc="center">
        <SearchFilter
          category="modules"
          showSearch
          showFilters
          filters={modulesFilters}
          title="Explore our modules"
        />
      </Row>
      <MaxWidthContainer>
        <Row mb="4" jc="center">
          <Table data={modules} columns={TableColumns(isDesktop)} />
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};
const mapStateToProps = state => ({
  filteredModules: state.search.modules?.data,
  modules: state.modules?.modules,
  loading: state.loading.fetchModulesLoading
});

export default connect(mapStateToProps, {
  fetchModulesAction: fetchModules
})(Modules);
