import Layout from '../../Layouts';

import headerImage from '../../../assets/boost/dd060f8008ab8b4b1593bdb1d73ff4e8.png';
import { Col, Row } from '../../common/Grid';
import IconCard from '../../common/IconCard';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import ProgrammeHeader from '../../common/ProgrammeHeader';
import * as T from '../../common/Typography';
import * as S from './style';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchProgrammes } from '../../../actions/programmesActions';
import { programmesFilters } from '../../../constants/filtersData';
import { INDIVIDUAL_PROGRAMME } from '../../../constants/navigationRoutes';
import limitChars from '../../../helpers/charLimit';
import Button from '../../common/Button';
import SearchFilter from '../../common/SearchFilter';
import Spin from '../../common/Spin';

const LIMIT = 99;

const Programmes = ({
  programmes: allProgrammes,
  filteredProgrammes,
  fetchProgrammes,
  loading,
  searchLoading
}) => {
  const [programmesOffset, setProgrammesOffset] = useState(LIMIT);

  const handleLoadMore = () => {
    setProgrammesOffset(prevOffset => prevOffset + LIMIT);
  };

  useEffect(() => {
    fetchProgrammes();
  }, [fetchProgrammes]);

  const programmes = (filteredProgrammes || allProgrammes || [])
    .slice()
    .sort((a, b) => a.title.localeCompare(b.title));

  const boostProgrammeIndex = programmes.findIndex(
    item => item.key === 'BOOST'
  );

  if (boostProgrammeIndex !== -1) {
    const [boostProgramme] = programmes.splice(boostProgrammeIndex, 1);
    programmes.unshift(boostProgramme);
  }

  if (loading || !allProgrammes) {
    return <Spin />;
  }

  return (
    <Layout layout="website">
      <S.TopRow>
        <ProgrammeHeader
          title="Programmes"
          description="Here you can browse the range of programmes which you can enrol in. Click “learn more” on any programme to see the modules on offer and upcoming events. "
          image={headerImage}
          withOverlay={true}
        />
      </S.TopRow>
      <MaxWidthContainer>
        <Row mb="4" jc="center">
          <SearchFilter
            title="Explore our programmes"
            showFilters
            filters={programmesFilters}
            category="programmes"
            loading={searchLoading}
          />
        </Row>

        <Row jc="start" ai="stretch">
          {programmes.slice(0, programmesOffset).map(programme => (
            <Col w={[4, 12, 4]} mb={5} mt={5} key={programme._id} as="stretch">
              <IconCard
                bgColor="offWhite"
                Img={() => (
                  <S.Img
                    src={programme?.imageLink?.fileUrl}
                    alt={`${programme.title} alt`}
                  />
                )}
                imageResize
                to={INDIVIDUAL_PROGRAMME.replace(':slug', programme.slug)}
              >
                <T.P smallMedium mt="4" weight={600}>
                  {programme.titleShort}
                </T.P>
                <T.P small color="gray">
                  {limitChars(programme.subtitle, 250)}
                </T.P>
                <Button
                  type="primary"
                  to={INDIVIDUAL_PROGRAMME.replace(':slug', programme.slug)}
                  label=" Learn more"
                  width="50%"
                  mt={3}
                />
              </IconCard>
            </Col>
          ))}
        </Row>

        {programmesOffset < programmes.length && (
          <Row mt="7" jc="center">
            <T.P
              onClick={handleLoadMore}
              weight={600}
              underline
              style={{ cursor: 'pointer' }}
            >
              Load More
            </T.P>
          </Row>
        )}
      </MaxWidthContainer>
    </Layout>
  );
};

const mapStateToProps = state => ({
  searchLoading: state.loading.searchLoading,
  programmes: state.programmes.programmes,
  filteredProgrammes: state.search.programmes?.data,
  loading: state.loading.fetchProgrammesLoading
});

export default connect(mapStateToProps, {
  fetchProgrammes
})(Programmes);
