import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Container = styled.div`
  padding-right: 24px;
  min-width: 320px;

  @media (max-width: 860px) {
    padding-right: 0;
  }
`;

export const Wrapper = styled.div`
  ${setMargin}
  position: relative;
  display: inline-block;
  max-width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px 50px 50px 0px;
    background-color: ${({ theme }) => theme.colors.mainSecondary};
    top: 6px;
    left: 6px;
    z-index: 0;
    display: ${({ rounded }) => (rounded ? 'block' : 'none')};
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px 50px 50px 0px;
    background-color: ${({ theme }) => theme.colors.lightSecondary};
    top: 12px;
    left: 12px;
    z-index: 0;
    display: ${({ rounded }) => (rounded ? 'block' : 'none')};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  object-fit: cover;
  margin: 0 auto;
  margin-left: 0px;
  display: inline-block;
  border-radius: ${({ rounded }) => rounded && '50px 50px 50px 0px'};
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  background-color: #fdc800cc;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fdc800cc;
  border-radius: ${({ rounded }) => rounded && '50px 50px 50px 0px'};
  pointer-events: none;
  z-index: 99;
`;
