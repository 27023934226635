export const meetingPlatforms = [
  { label: 'Zoom', value: 'zoom' },
  { label: 'Teams', value: 'teams' },
  { label: 'Google Meet', value: 'google' },
  { label: 'Other', value: 'other' }
];

export const sessionRemoteOrFace2FaceTypes = [
  { label: 'Remote / Online', value: 'online' },
  { label: 'Face to Face', value: 'faceToFace' }
];

export const moduleFormatTypes = {
  E_LEARNING: { label: 'eLearning', value: 'eLearning' },
  LIVE_EVENT: { label: 'Live event', value: 'live' }
};

export const audienceTypes = [
  'All North East and North Cumbria Health and Care Staff',
  'NENC Integrated Care Board Staff Only'
];

export const participantStatusMapping = {
  waiting: 'Waiting list',
  confirmed: 'Registered'
};
