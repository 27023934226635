import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Modal from '../../common/modal';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import {
  MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ARCHIVED,
  MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ADD
} from '../../../constants/navigationRoutes';

import * as S from './style';

import {
  fetchProgrammeCategoriesBySlug,
  updateProgrammeCategory
} from '../../../actions/programmesActions';
import { TableColumns } from './TableColumns';

const ManageCategories = ({
  programmeCategories,
  fetchProgrammeCategoriesLoading,
  fetchProgrammeCategoriesBySlug,
  updateProgrammeCategory,
  updateProgrammeCategoryLoading
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const history = useHistory();
  const { slug } = useParams();
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const confirmArchive = async ({ categoryId }) => {
    setSelectedCategoryId(categoryId);
    setShowModal(true);
  };

  const handleArchive = async () => {
    await updateProgrammeCategory(selectedCategoryId, { archived: true });
    setShowModal(false);
    fetchProgrammeCategoriesBySlug(slug);
  };

  useEffect(() => {
    fetchProgrammeCategoriesBySlug(slug);
  }, []);

  if (!programmeCategories || fetchProgrammeCategoriesLoading) {
    return <Spin />;
  }

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 8, 8]}>
          <T.H2W>Categories</T.H2W>
        </Col>
        <S.Divider full />
        <Col w={[4, 8, 8]}>
          <T.P weight={500}>
            Organise your modules into certain categories (e.g. Awareness,
            Identification, Management) to help potential participants navigate
            your programme.{' '}
          </T.P>
        </Col>
      </Row>
      <Row mt="4">
        <S.ButtonsContainer>
          <Button
            label="Add category"
            width="200px"
            height="auto"
            type="primary"
            onClick={() =>
              history.push(
                MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ADD.replace(
                  ':slug',
                  slug
                )
              )
            }
          />

          <Button
            label="View archived"
            width="200px"
            height="auto"
            type="secondary"
            mtT="3"
            onClick={() =>
              history.push(
                MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ARCHIVED.replace(
                  ':slug',
                  slug
                )
              )
            }
          />
        </S.ButtonsContainer>
      </Row>
      <Row mb="4" jc="center">
        <Table
          data={programmeCategories}
          columns={TableColumns({
            isTablet: isTablet,
            confirmArchive,
            slug
          })}
        />
      </Row>

      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          Are you sure?
        </T.H4W>
        <T.P color="gray" mb="10">
          Archiving this category will hide it from the programme's catalogue.
          You can always unarchive it later.
        </T.P>
        <Button
          label="Confirm archive"
          type="primary"
          width="200px"
          loading={updateProgrammeCategoryLoading}
          onClick={handleArchive}
        />
      </Modal>
    </Layout>
  );
};
const mapStateToProps = state => ({
  programmeCategories: state.programmes.programmeCategories,
  fetchProgrammeCategoriesLoading:
    state.loading.fetchProgrammeCategoriesLoading,
  updateProgrammeCategoryLoading: state.loading.updateProgrammeCategoryLoading
});

const mapActionsToProps = {
  fetchProgrammeCategoriesBySlug,
  updateProgrammeCategory
};

export default connect(mapStateToProps, mapActionsToProps)(ManageCategories);
