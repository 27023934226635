import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  width: 100%;
  min-height: ${({ subtitle }) => (subtitle ? '138px' : '70px')};
  padding: 0px 0px 0px 0px;
  @media (min-width: 915px) {
    min-height: ${({ subtitle }) => (subtitle ? '276px' : 'auto')};
  }
`;

export const HideInDesktop = styled.div`
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  @media (min-width: 915px) {
    display: none;
  }
`;

export const HideInTablet = styled.div`
  display: none;
  flex-direction: column;
  @media (min-width: 915px) {
    display: flex;
  }
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  height: 2px;
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  margin-right: auto;
`;

export const ContentWrapper = styled.div`
  padding: 24px 32px 48px 32px;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  @media (min-width: 915px) {
    padding: 0;
    padding-left: ${({ theme }) => theme.spacings[6]};
    margin: 0;
  }
`;
