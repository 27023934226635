import * as S from './style';

const HeaderImage = ({
  image,
  width = 440,
  height = 340,
  withOverlay = false,
  rounded = true,
  ...props
}) => {
  return (
    <S.Container>
      <S.Wrapper rounded={rounded} {...props}>
        <S.Image src={image} width={width} height={height} rounded={rounded} />
        {withOverlay && <S.Overlay rounded={rounded} />}
      </S.Wrapper>
    </S.Container>
  );
};

export default HeaderImage;
