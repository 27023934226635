export const getDifficultyRangeText = difficultyLevels => {
  const levelsOrder = ['Introductory', 'Intermediate', 'Advanced'];

  const minLevel = levelsOrder.find(level => difficultyLevels.includes(level));
  const maxLevel = [...levelsOrder]
    .reverse()
    .find(level => difficultyLevels.includes(level));

  return minLevel === maxLevel ? minLevel : `${minLevel} to ${maxLevel}`;
};
