import styled from 'styled-components';
import Call_out from '../../../assets/Call out.png';
import Call_out_mobile from '../../../assets/Hero.png';

export const HeadWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 918px) {
    margin-top: 12px;
  }
`;

export const Img = styled.img`
  width: ${({ w }) => w || '192px'};
  height: ${({ h }) => h || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;

export const CallOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: ${({ theme }) => theme.spacings[7]};
`;

export const CallOutRow2 = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media (max-width: 918px) {
    flex-direction: column;
  }
`;

export const BlueCard = styled.div`
  background: ${({ theme }) => theme.colors.mainPrimary};
  background-image: ${`url("${Call_out}")`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  padding: 50px 36px;
  flex: 1.2;
  max-width: 642px;
  border-radius: 4px;
  min-height: 658px;
  @media (max-width: 918px) {
    padding: 24px;
    min-height: 416px;

    max-width: 100%;
    background-image: ${`url("${Call_out_mobile}")`};
  }
`;

export const SubtitleWrapper = styled.div`
  max-width: 451px;
`;

export const FindMoreSubtitleWrapper = styled.div`
  max-width: 740px;
`;

export const BlackCard = styled.div`
  background: ${({ theme }) => theme.colors.darkGray};
  padding: 48px 62px 96px 48px;
  flex: 0.8;
  border-radius: 4px;
  @media (max-width: 918px) {
    padding: 24px;
  }
`;

export const CollaborateDiv = styled.div`
  padding: 18px 28px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  display: flex;
  justify-content: ${({ jc }) => jc || 'center'};
  align-items: center;
  height: ${({ small }) => (small ? '126px' : '131px')};
  @media (max-width: 918px) {
    height: ${({ small }) => (small ? '140px' : '120px')};
    padding: 10px 16px;
  }
  flex: 1;
`;

export const CollaborateCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const CollaborateRow = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
`;

export const ImgNoHeight = styled.img`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;

export const GetInvolvedWrapper = styled.form`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.lightPrimary};
  padding: 72px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[9]};
  @media (max-width: 918px) {
    padding: 24px;
  }
`;
