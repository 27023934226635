import { colors, breakpointsMax } from '../../../theme';

export const injectCalendarButtonStyles = callback => {
  // setTimeout is to verify the shadowRoot is added to the DOM
  setTimeout(() => {
    const calendarButton = document.querySelector('add-to-calendar-button');
    if (calendarButton && calendarButton.shadowRoot) {
      const style = document.createElement('style');

      style.textContent = `
      .atcb-button,
        .atcb-button.atcb-active:not(.atcb-modal-style,.atcb-dropoverlay),
        .atcb-button.atcb-click {
        cursor: pointer;
        color: ${colors.primary};
        border: none;
        background-color: ${colors.darkGray};
        font-size: 16px;
        width: 320px !important;
        min-height: 50px !important;
        border-radius: 8px;
        padding: 0px !important;
        margin: 0px;
        font-family: 'museo-sans', sans-serif;
        transition: background-color linear 0.2s;
        box-shadow: none;
      }
      .atcb-button,
        .atcb-button.atcb-active:not(.atcb-modal-style,.atcb-dropoverlay),
        .atcb-button.atcb-click {
          @media ${breakpointsMax.mobileM} {
            width: 86vw !important;
          }
      }
      .atcb-button:hover {
        box-shadow: none !important;
        background-color: ${colors.primary} !important;
        color: ${colors.darkGray} !important;
      }
      #atcb-bgoverlay:not(dialog) {
        animation: none;
        cursor: default !important;
      }
      .atcb-icon {
        margin-right: 8px;
      }
      .atcb-dropdown {
        position: relative !important;
        top: 4px !important;
      }
      .atcb-list-item {
        font-family: 'museo-sans', sans-serif;
        background-color: ${colors.white};
        padding: 8px;
      }
      .atcb-list.atcb-generated-button:not(.atcb-modal)
        .atcb-list-item:first-child {
        padding: 8px;
      }
      .atcb-list-item:hover {
        background-color: ${colors.lightBlue} !important;
      }
      #atcb-reference {
        display: none !important;
      }
    `;
      calendarButton.shadowRoot.appendChild(style);
    }
    if (callback) callback();
  }, 100);
};
