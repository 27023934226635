import CopyLink from '../../common/CopyLink';
import styled from 'styled-components';

const Div = styled.div`
  margin: 10px;
`;

const CopyLinkExample = () => (
  <>
    <Div>
      <CopyLink link="https://www.figma.com/file/x8mceQEkUDMe89WNTDY33v" />
    </Div>
    <Div>
      <CopyLink
        big
        title="Here is the registration link for this session"
        link="https://www.figma.com/file/x8mceQEkUDMe89WNTDY33v"
      />
    </Div>
  </>
);
export default CopyLinkExample;
