import * as S from './style';
import * as I from '../Inputs';

import { Radio as AntdRadio } from 'antd';

const AnswerInput = ({
  check,
  text,
  index,
  handleCheck,
  handleInput,
  required,
  error,
  rows = 1,
  autoSize,
  ...props
}) => {
  return (
    <S.Wrapper {...props}>
      <S.RadioWrapper>
        <AntdRadio
          checked={check}
          onClick={e => handleCheck({ check, index })}
          style={S.radioStyle}
          required={required}
        />
      </S.RadioWrapper>
      <I.TextArea
        rows={rows}
        autoSize={autoSize}
        value={text}
        onChange={e => handleInput({ index, value: e.target.value })}
      />
    </S.Wrapper>
  );
};

export default AnswerInput;
