import moment from 'moment';
import React, { Component } from 'react';
import {
  SessionTopDetailsWrapper,
  StyledCol,
  StyledList
} from './SessionDetails.Style';

import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import SessionTopDetailsNonLiveSession from './SessionTopDetailsNonLiveSession';
class SessionTopDetails extends Component {
  render() {
    const { sessionDetails } = this.props;
    let {
      date,
      endDate,
      trainers,
      startTime,
      endTime,
      address,
      capacity,
      modules,
      meetingPlatform,
      meetingLink,
      extraInfo,
      defaultSessionForCustomModule,
      programmes,
      format,
      contactEmail
    } = sessionDetails;
    if (!sessionDetails) {
      return <div>loading</div>;
    }

    const isOnline = format === 'Remote / Online';

    if (defaultSessionForCustomModule) {
      return (
        <SessionTopDetailsNonLiveSession sessionDetails={sessionDetails} />
      );
    }

    let fullAddress = '';

    if (address) {
      const { postcode, addressLine1, addressLine2, town } = address;
      if (postcode || addressLine1 || addressLine2 || town) {
        fullAddress = [addressLine1, addressLine2, town, postcode]
          .filter(item => !!item)
          .join(', ');
      }
    }

    const showEndDate =
      endDate &&
      moment(date).format('DD/MM/YYYY') !==
        moment(endDate).format('DD/MM/YYYY');

    return (
      <SessionTopDetailsWrapper>
        <Row mb="4">
          <StyledCol w={[1.3, 4, 3]}>
            <T.P small weight={700}>
              Dates:
            </T.P>
          </StyledCol>
          <Col w={[2.7, 8, 9]}>
            <T.P small>
              {moment(date).format('DD/MM/YYYY')}
              {showEndDate && ` to ${moment(endDate).format('DD/MM/YYYY')}`}
            </T.P>
          </Col>
        </Row>
        <Row mb="4">
          <StyledCol w={[1.3, 4, 3]}>
            <T.P small weight={700}>
              Times:
            </T.P>
          </StyledCol>
          <Col w={[2.7, 8, 9]}>
            <T.P small>
              {startTime || 'TBC'} {!!endTime && `to ${endTime}`}
            </T.P>
          </Col>
        </Row>
        <Row mb="4">
          <StyledCol nowrap w={[1.3, 4, 3]}>
            <T.P small weight={700}>
              Programme(s):
            </T.P>
          </StyledCol>
          <Col small w={[2.7, 8, 9]}>
            {!!programmes?.length ? (
              programmes.length === 1 ? (
                <T.P small transform="capitalize">
                  {programmes[0].titleShort}
                </T.P>
              ) : (
                <StyledList>
                  {programmes.map?.(({ titleShort = '', _id = '' }) => (
                    <li key={_id}>
                      <T.P small transform="capitalize">
                        {titleShort}
                      </T.P>
                    </li>
                  ))}
                </StyledList>
              )
            ) : (
              <T.P small>N/A</T.P>
            )}
          </Col>
        </Row>
        <Row mb="4">
          <StyledCol w={[1.3, 4, 3]}>
            <T.P small weight={700}>
              Module(s):
            </T.P>
          </StyledCol>
          <Col small w={[2.7, 8, 9]}>
            {!!modules?.length ? (
              modules.length === 1 ? (
                <T.P small transform="capitalize">
                  {modules[0].title}
                </T.P>
              ) : (
                <StyledList>
                  {modules.map?.(({ title = '', _id = '' }) => (
                    <li key={_id}>
                      <T.P small transform="capitalize">
                        {title}
                      </T.P>
                    </li>
                  ))}
                </StyledList>
              )
            ) : (
              <T.P small>N/A</T.P>
            )}
          </Col>
        </Row>
        <Row mb="4">
          <StyledCol w={[1.3, 4, 3]}>
            <T.P small weight={700}>
              Location:
            </T.P>
          </StyledCol>
          <Col w={[2.7, 8, 9]}>
            <T.P small transform="capitalize">
              {isOnline ? 'Online' : fullAddress || 'TBC'}
            </T.P>
          </Col>
        </Row>
        <Row mb="4">
          <StyledCol w={[2, 4, 3]}>
            <T.P small weight={700}>
              Capacity:
            </T.P>
          </StyledCol>
          <Col w={[2.7, 8, 9]}>
            <T.P small>{capacity}</T.P>
          </Col>
        </Row>

        {!!trainers?.length && (
          <Row mb="4">
            <StyledCol w={[1.3, 4, 3]}>
              <T.P small weight={700}>
                Trainer(s):
              </T.P>
            </StyledCol>
            <Col w={[2.7, 8, 9]}>
              {trainers.length === 1 ? (
                <T.P small>{trainers[0].name}</T.P>
              ) : (
                <StyledList>
                  {trainers.map?.(({ name = '', _id = '' }) => (
                    <li key={_id}>
                      <T.P small>{name}</T.P>
                    </li>
                  ))}
                </StyledList>
              )}
            </Col>
          </Row>
        )}
        {isOnline && (
          <Row mb="4">
            <StyledCol w={[1.3, 4, 3]}>
              <T.P small weight={700}>
                Meeting platform:
              </T.P>
            </StyledCol>
            <Col w={[2.7, 8, 9]}>
              <T.P small transform="capitalize">
                {meetingPlatform || 'N/A'}
              </T.P>
            </Col>
          </Row>
        )}
        {isOnline && (
          <Row mb="4">
            <StyledCol w={[1.3, 4, 3]}>
              <T.P small weight={700}>
                Meeting link:
              </T.P>
            </StyledCol>
            <Col w={[2.7, 8, 9]}>
              <a
                href={meetingLink}
                target="_blank"
                rel="noreferrer"
                style={{
                  wordBreak: 'break-all'
                }}
              >
                <T.P small style={{ maxWidth: '100%' }}>
                  {meetingLink || 'N/A'}
                </T.P>
              </a>
            </Col>
          </Row>
        )}
        {contactEmail && (
          <Row mb="4">
            <StyledCol w={[1.3, 4, 3]}>
              <T.P small weight={700}>
                Contact:
              </T.P>
            </StyledCol>
            <Col w={[2.7, 8, 9]}>
              <T.P small>{contactEmail}</T.P>
            </Col>
          </Row>
        )}
        {extraInfo && (
          <Row mb="4">
            <StyledCol w={[1.3, 4, 3]}>
              <T.P small weight={700}>
                Extra info:
              </T.P>
            </StyledCol>
            <Col w={[2.7, 8, 9]}>
              <T.P small style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                {extraInfo}
              </T.P>
            </Col>
          </Row>
        )}
      </SessionTopDetailsWrapper>
    );
  }
}

export default SessionTopDetails;
