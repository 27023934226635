import { validate as _validate, createSchema, fields } from '..';
import { eventLocation, optionalDate, optionalLink } from '../fields';

const {
  email,
  arrayOfString,
  requiredDate,
  requiredNumber,
  sessionType,
  partnerTrainer,
  address,
  onlineDetails,
  optionalText,
  requiredText
} = fields;

const createSessionSchema = createSchema({
  title: optionalText,
  description: requiredText,
  videoLink: optionalLink,
  date: requiredDate,
  endDate: requiredDate,
  registrationCutoffDate: optionalDate,
  startTime: optionalText,
  endTime: optionalText,
  sessionType,
  modules: arrayOfString,
  capacity: requiredNumber,
  partnerTrainer1: partnerTrainer,
  location: eventLocation,
  address,
  online: onlineDetails,
  extraInfo: optionalText,
  contactEmail: email,
  audience: optionalText
});

const validate = data => {
  const x = _validate(createSessionSchema, data);
  return x;
};

export default validate;
