import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

const Tip = ({
  bgColor = 'mainPrimary',
  icon = 'bulb2',
  iconColor,
  tip,
  textColor,
  borderColor,
  ...props
}) => {
  const _borderColor = borderColor || textColor || iconColor;
  return (
    <S.Tip bgColor={bgColor} borderColor={_borderColor} {...props}>
      {icon && <Icon icon={icon} color={iconColor} mr="2" />}
      {typeof tip === 'string' ? (
        <T.P size="large" weight="bold" color={textColor} mb="7px">
          Tip! {tip}
        </T.P>
      ) : (
        <S.TipContent>{tip}</S.TipContent>
      )}
    </S.Tip>
  );
};

export default Tip;
