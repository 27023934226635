import { Progress as AntProgress } from 'antd';

import { useTheme } from 'styled-components';
import * as T from '../Typography';
import * as S from './style';

const Progress = ({
  readyForSubmission,
  completionRate,
  currentStep,
  totalSteps
}) => {
  const { colors } = useTheme();

  return (
    <S.FooterDiv colorChange={readyForSubmission}>
      <S.ProgressWrapper>
        <T.P weight={700} color="white">
          Your progress
        </T.P>
        <AntProgress
          percent={completionRate}
          strokeColor={`${colors.white}`}
          trailColor="red"
        />
      </S.ProgressWrapper>
      <S.StepProgress>
        <T.P weight={700} color="white">
          Step {Number(currentStep) + 1}/ {totalSteps}
        </T.P>
      </S.StepProgress>
    </S.FooterDiv>
  );
};

export default Progress;
