import isDeepEqual from 'fast-deep-equal/react';
import React from 'react';

const useValidateFormData = ({ formState, validateFn, submitAttempt }) => {
  const [validationErrors, setValidationErrors] = React.useState({});
  const formStateRef = React.useRef(formState);
  const finalSubmissionData = React.useRef(null);

  const validateForm = React.useCallback(
    additionalParam => {
      try {
        validateFn(formState, additionalParam);
        // set the 'clean' data for final submission
        finalSubmissionData.current = formState;
        setValidationErrors({ hasError: false });
        return true;
      } catch (error) {
        if (error.name === 'ValidationError') {
          setValidationErrors({
            ...error.inner,
            hasError: true
          });
        }
        return false;
      }
    },
    [validateFn, formState]
  );

  //   check if form state attributes have changed and run validation if so
  if (!isDeepEqual(formStateRef.current, formState)) {
    formStateRef.current = formState;
  }

  React.useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateRef.current]);

  return { validateForm, validationErrors, finalSubmissionData };
};
export default useValidateFormData;
