import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}

  cursor: ${({ disabled, clickAnywhereForUpload }) =>
    disabled ? 'not-allowed' : clickAnywhereForUpload ? 'pointer' : 'default'};

  .dropzone-container {
    min-height: 250px;
    padding:  ${({ theme }) => `${theme.spacings[5]} 0`};
    display: flex;
    border: 1px dashed ${({ theme, hasError }) =>
      hasError ? theme.colors.error : theme.colors.lightGray};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.lightPrimary};

    ${({ disabled }) =>
      disabled &&
      `
      pointer-events: none;
    `};

    ${({ isInvalid, theme }) =>
      isInvalid &&
      `
      border-color: ${theme.colors.error};
    `};

    ${({ isDragActive, theme }) =>
      isDragActive &&
      `
      background: ${theme.colors.midPrimary};
      border-color: ${theme.colors.midGray};
      .dropzone .instruction .button-container {
        display: none;
      }
    `};

    .dropzone {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-align: center;
      color: ${({ theme }) => theme.colors.darkGray};
      font-size: calc(var(--font-size-adjustment) * 16px);
      line-height: 24px;

      .instruction {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;

        .button-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: 1;
          align-items: center;
          justify-content: center;

          .button-text {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
`;

export const PreviewImage = styled.img`
  max-width: 100%;
  margin-top: ${({ theme }) => theme.spacings[3]};
  src: ${({ src }) => src};
`;
