import Layout from '../../Layouts';
import * as T from '../../common/Typography';

import ParticipantSignUpForm from '../../common/ParticipantSignUpForm';

const ParticipantSignUp = ({ sessionModules = [] }) => {
  return (
    <Layout>
      <T.H1 mb="7">Please create an account</T.H1>
      <ParticipantSignUpForm sessionModules={sessionModules} />
    </Layout>
  );
};

export default ParticipantSignUp;
