const Trash = ({ width, height, color, ...props }) => (
  <svg
    width={width || '100'}
    height={height || '100'}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 5.25L6.9375 20.25C6.98203 21.1167 7.6125 21.75 8.4375 21.75H17.0625C17.8908 21.75 18.5095 21.1167 18.5625 20.25L19.5 5.25"
      stroke="#4A4A49"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M4.5 5.25H21H4.5Z" fill={color || '#4A4A49'} />
    <path
      d="M4.5 5.25H21"
      stroke="#4A4A49"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M16.125 8.25001L15.75 18.75M9.75 5.25V3.375C9.74957 3.22715 9.77837 3.08066 9.83475 2.94397C9.89114 2.80729 9.97399 2.6831 10.0785 2.57854C10.1831 2.47399 10.3073 2.39114 10.444 2.33476C10.5807 2.27838 10.7271 2.24957 10.875 2.25H14.625C14.7729 2.24957 14.9193 2.27838 15.056 2.33476C15.1927 2.39114 15.3169 2.47399 15.4215 2.57854C15.526 2.6831 15.6089 2.80729 15.6652 2.94397C15.7216 3.08066 15.7504 3.22715 15.75 3.375V5.25H9.75ZM12.75 8.25001V18.75V8.25001ZM9.375 8.25001L9.75 18.75L9.375 8.25001Z"
      stroke="#4A4A49"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default Trash;
