import React from 'react';

import EditEmail from '../../../common/EditEmail';
import { surveyTypesNew } from '../../../../constants/index';

const SendSurveyLink = ({
  sessionDetails,
  handleAddEmailsClick,
  name,
  isSchedule,
  surveyType,
  drawerKey,
  onClose
}) => {
  const emails =
    surveyType === surveyTypesNew.POST
      ? sessionDetails.participantsEmails.filter(
          item => item.status === 'confirmed' && !item.didNotShowUp
        )
      : sessionDetails.participantsEmails;

  return (
    <EditEmail
      participantsEmails={emails}
      type="surveyLink"
      trainer={name}
      sessionDate={sessionDetails.date}
      sessionType={sessionDetails.type}
      address={sessionDetails.address}
      trainers={sessionDetails.trainers}
      startTime={sessionDetails.startTime}
      endTime={sessionDetails.endTime}
      shortId={sessionDetails.shortId}
      sessionId={sessionDetails._id}
      endDate={sessionDetails.endDate}
      course={sessionDetails.course}
      handleAddEmailsClick={handleAddEmailsClick}
      isSchedule={isSchedule}
      surveyType={surveyType}
      drawerKey={drawerKey}
      backCallback={onClose}
      modules={sessionDetails.modules}
      defaultSessionForCustomModule={
        sessionDetails.defaultSessionForCustomModule
      }
      title={sessionDetails.title}
    />
  );
};

export default SendSurveyLink;
