import React from 'react';
import moment from 'moment';

import * as T from '../../common/Typography';
import * as R from '../../../constants/navigationRoutes';
import * as S from './style';
import { USER_TYPE_LABELS } from '../../../constants/userTypes';

export const TableColumns = ({ isTablet }) => {
  const commonColumns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (name, row) => <T.P color="darkGray">{name || 'N/A'}</T.P>,
      position: 1
    },

    {
      key: 'action',
      render: (_, record) => (
        <S.ActionLink to={R.MANAGE_USERS_EDIT.replace(':credId', record._id)}>
          <T.P color="darkGray" weight={600} mr="4">
            View / Edit
          </T.P>
        </S.ActionLink>
      ),
      position: 5
    }
  ];

  const nonMobileColumns = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: (email, row) => <T.P color="darkGray">{email || 'N/A'}</T.P>,
      position: 2
    },
    {
      title: 'Role(s)',
      dataIndex: 'roles',
      key: 'roles',
      render: (rolesArray, row) => {
        const rolesLabelsArray = rolesArray.map(role => USER_TYPE_LABELS[role]);
        const formattedRoles = rolesLabelsArray.join(', ');
        return (
          <>
            <T.P style={{ width: '100px' }} color="darkGray">
              {formattedRoles}
            </T.P>
          </>
        );
      },
      position: 3
    },
    {
      title: 'Last active',
      key: 'lastActive',
      dataIndex: 'lastActive',
      render: (lastActive, row) => {
        if (!lastActive && row.invitationDetails) {
          return <T.P color="darkGray">Awaiting sign up</T.P>;
        } else if (!lastActive) {
          return <T.P color="darkGray">N/A</T.P>;
        } else {
          const formattedDate = moment(lastActive).format('DD/MM/YYYY');
          return (
            <T.P style={{ width: '100px' }} color="darkGray">
              {formattedDate || 'Awaiting sign up'}
            </T.P>
          );
        }
      },
      position: 4
    }
  ];
  const columns = !isTablet
    ? commonColumns
    : [...commonColumns, ...nonMobileColumns];

  const sortedColumns = columns.sort((a, b) => a.position - b.position);

  return sortedColumns;
};
