import * as S from './style';
import * as T from '../Typography';

const defaultColors = {
  primary: {
    bgColor: 'mainPrimary',
    textColor: 'darkGray'
  },
  secondary: {
    bgColor: 'mainSecondary',
    textColor: 'darkGray'
  },
  tertiary: {
    bgColor: 'darkGray',
    textColor: 'white'
  }
};

const decideColors = ({ variant }) => {
  return defaultColors[variant] || defaultColors.primary;
};

const Badge = ({ title, variant, bgColor, textColor }) => {
  const colors = decideColors({ variant });
  return (
    <S.Wrapper bgColor={bgColor || colors.bgColor}>
      <T.P
        ellipsis={{ rows: 1 }}
        color={textColor || colors.textColor}
        style={{
          maxWidth: '200px'
        }}
      >
        {title}
      </T.P>
    </S.Wrapper>
  );
};

export default Badge;
