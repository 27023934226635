import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import Layout from '../../Layouts';

import useSearchParams from './../../../hooks/useSearchParams';

// STYLING
import { Wrapper } from './Dashboard.style';

//  COMMON COMPONENTS
import AccountPageHeader from '../../common/AccountPageHeader';
import CardLink from '../../common/CardLink';
import { Row } from '../../common/Grid';

import USER_TYPES from '../../../constants/userTypes';

// ROUTES
import {
  ALL_SESSIONS_URL,
  ANALYTICS_URL,
  MANAGE_PROGRAMMES_PROGRAMME_SELECTION,
  MANAGE_USERS,
  MY_SESSIONS_URL
} from '../../../constants/navigationRoutes';

const Dashboard = ({ role, userName }) => {
  const searchParams = useSearchParams();

  const captalizesName =
    userName && userName[0].toUpperCase() + userName.substr(1);

  useEffect(() => {
    switch (role) {
      case 'trainer':
        searchParams.append({
          target: 'me'
        });

        break;

      case 'admin':
      case 'programmeManager':
        searchParams.append({
          target: 'all'
        });
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const sessionURL = useMemo(() => {
    switch (role) {
      case 'trainer':
        return MY_SESSIONS_URL;
      case 'admin':
      case 'programmeManager':
        return ALL_SESSIONS_URL;
      default:
        return '';
    }
  }, [role]);

  return (
    <Layout>
      <Wrapper>
        <AccountPageHeader
          title={`Hi, ${captalizesName}`}
          subtitle="What would you like to do today?"
        />
        {[USER_TYPES.admin, USER_TYPES.programmeManager].includes(role) && (
          <Row>
            <CardLink
              title="Programmes"
              to={MANAGE_PROGRAMMES_PROGRAMME_SELECTION}
            />
            <CardLink
              title="Users"
              to={MANAGE_USERS}
              background="mainSecondary"
              borderRadius="12px 12px 0 12px"
            />
          </Row>
        )}
        <Row>
          <CardLink
            title="Events"
            to={sessionURL}
            color="white"
            background="darkGray"
            borderRadius="0 12px 12px 12px"
          />
          <CardLink
            title="Analytics"
            to={{
              pathname: ANALYTICS_URL
            }}
            background="lightSecondary"
            borderRadius="12px 0 12px 12px"
          />
        </Row>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    userName: state.auth.name,
    userId: state.auth.userId,
    role: state.auth.role
  };
};

export default connect(mapStateToProps, {})(Dashboard);
