import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Layout from './../../Layouts';
import * as T from './../../common/Typography';
import { Row, Col } from '../../common/Grid';
import Spin from '../../common/Spin';
import { getSessionDetails } from '../../../actions/sessionAction';
import { INDIVIDUAL_EVENT } from '../../../constants/navigationRoutes';

const Register = ({ sessionId, getSessionDetails }) => {
  const { shortId } = useParams();
  const history = useHistory();

  useEffect(() => {
    getSessionDetails(shortId, history);
  }, [getSessionDetails, history, shortId]);

  useEffect(() => {
    if (sessionId) {
      history.push(INDIVIDUAL_EVENT.replace(':id', sessionId));
    }
  }, [sessionId, history]);

  if (!sessionId) {
    return <Spin />;
  }

  return <div>Redirecting...</div>;
};

const mapStateToProps = state => ({
  sessionId: state.session._id
});

export default connect(mapStateToProps, {
  getSessionDetails
})(Register);
