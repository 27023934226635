import { connect } from 'react-redux';
import {
  setIsFontLarge,
  setLayoutColor,
  setLayoutFontFamily
} from '../../../actions/accessibilityActions';
import {
  ANDROID_ACCESSIBILITY,
  APPLE_ACCESSIBILITY,
  CHROME_GOOGLE_SPEAK,
  VOICE_ALOUD_READER_ANDROID,
  VOICE_ALOUD_READER_APPLE
} from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import * as I from '../../common/Inputs';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import Tip from '../../common/Tip';
import * as T from '../../common/Typography';
import OverlayColor from './OverlayColor';
import * as S from './style';

const fontsOptions = [
  { value: 'Comic Neue', label: 'Comic Neue' },
  { value: 'Roboto', label: 'Roboto' },
  { value: '', label: 'Default' }
];

const Accessibility = ({
  isFontLarge,
  layoutFontFamily,
  setIsFontLarge,
  setLayoutFontFamily
}) => {
  const handleSelect = value => {
    setLayoutFontFamily(value);
  };

  return (
    <Layout layout="website">
      <MaxWidthContainer>
        <Row mb="5">
          <Col w={[4, 12, 8]}>
            <T.H1 mtM="2">Accessibility</T.H1>
          </Col>
        </Row>
        <Row jc="space-between">
          <Col w={[4, 12, 8]} dir="column" ai="flex-start">
            <T.P>
              Accessibility on this website is guided by government standards
              and the Web Content Accessibility Guidelines WCAG are widely
              accepted as the international standard for accessibility on the
              web.
            </T.P>
            <T.P mt="5" mtM="0">
              Whilst we aim to make this website accessible to all users and
              achieve a conformance level 'AAA' we continually work with
              stakeholders to ensure that conformance level 'A' is adhered to as
              a minimum.
            </T.P>

            <S.Wrapper>
              <Tip
                mt="5"
                mtT="4"
                style={{ maxWidth: '300px', width: '100%' }}
                tip={
                  <T.P>
                    If you experience any accessibility issue on this site or
                    have any comment, please{' '}
                    <T.Link to="https://boost.org.uk/contact/" external>
                      contact us
                    </T.Link>
                    .
                  </T.P>
                }
                mb="0"
                bgColor="mainSecondary"
                borderColor="darkGray"
                textColor="darkGray"
              />
              <Tip
                mt="5"
                mtT="0"
                style={{ maxWidth: '300px', width: '100%' }}
                bgColor="mainPrimary"
                borderColor="darkGray"
                textColor="darkGray"
                tip={
                  <T.P weight="bold" color="darkGray">
                    There are many accessibility features on devices, which can
                    be found on links such as{' '}
                    <T.Link
                      to={`mailto:${APPLE_ACCESSIBILITY}`}
                      color="darkGray"
                      external
                      underline
                      weight="semi"
                    >
                      Apple accessability features{' '}
                    </T.Link>{' '}
                    and{' '}
                    <T.Link
                      to={`mailto:${ANDROID_ACCESSIBILITY}`}
                      color="darkGray"
                      external
                      underline
                      weight="semi"
                    >
                      Android accessibility.{' '}
                    </T.Link>{' '}
                  </T.P>
                }
                mb="0"
              />
            </S.Wrapper>
          </Col>

          <S.HideInDesktop>
            <Col w={[4, 12, 12]}>
              <S.Divider />
            </Col>
          </S.HideInDesktop>

          <Col w={[4, 8, 4]}>
            <OverlayColor />
          </Col>

          <S.HideInDesktop>
            <Col w={[4, 12, 12]}>
              <S.Divider />
            </Col>
          </S.HideInDesktop>
        </Row>
        <Row jc="space-between">
          <Col w={[4, 8, 7]}>
            <T.H3W mt="8" mtM="6">
              Adjust Text Size
            </T.H3W>
            <S.Wrapper>
              <Button
                onClick={() => {
                  if (!isFontLarge) {
                    document.getElementsByTagName('html')[0].style.fontSize =
                      '1.25rem';
                    setIsFontLarge(true);
                  } else {
                    document.getElementsByTagName('html')[0].style.fontSize =
                      '1rem';
                    setIsFontLarge(false);
                  }
                }}
                type="tertiary"
                disabled={false}
                label={
                  isFontLarge ? 'Decrease text size' : 'Increase text size'
                }
                mt={4}
              />
              <Tip
                mt="5"
                mtT="4"
                style={{ maxWidth: '300px', width: '100%' }}
                bgColor="darkGray"
                borderColor="white"
                textColor="white"
                iconColor="white"
                tip="Click increase text size by 25% (e.g. 16px to 20px)."
                mb="0"
              />
            </S.Wrapper>
          </Col>
          <Col w={[4, 4, 4]} mt="8">
            <S.ShowInDesktop>
              <S.FontInputWrapper>
                <I.Dropdown
                  label={'Change font'}
                  options={fontsOptions}
                  selected={layoutFontFamily}
                  handleChange={handleSelect}
                  allowClear={false}
                  customFont
                />
              </S.FontInputWrapper>
            </S.ShowInDesktop>
          </Col>
        </Row>
        <Row mt="4">
          <Col w={[4, 12, 8]}>
            <T.P weight="bold" color="darkGray">
              Internet Explorer
            </T.P>
          </Col>
          <Col w={[4, 12, 8]} mt="2" mb="4">
            <T.P color="darkGray">
              Go to “View” on the menu bar - Select text size / zoom
            </T.P>
          </Col>

          <Col w={[4, 12, 8]}>
            <T.P weight="bold" color="darkGray">
              Firefox
            </T.P>
          </Col>
          <Col w={[4, 12, 8]} mt="2">
            <T.P color="darkGray">
              Go to “View” on the menu bar - Select text size / zoom.
              Alternatively hold down the “Ctrl” button on your keyboard and
              press the plus (+) key to increase text size. To reduce the latter
              hold down the “Ctrl” button and press the minus (-) key. Please
              note that the above settings may differ depending on the browser
              version.
            </T.P>
          </Col>
          <S.HideInDesktop>
            <Col w={[4, 12, 12]}>
              <S.Divider />
            </Col>
            <Col w={[4, 8, 12]}>
              <S.FontInputWrapper>
                <I.Dropdown
                  label={'Change font'}
                  options={fontsOptions}
                  selected={layoutFontFamily}
                  handleChange={selectValue => setLayoutFontFamily(selectValue)}
                  allowClear={false}
                  customFont
                />
              </S.FontInputWrapper>
            </Col>
            <Col w={[4, 12, 12]}>
              <S.Divider />
            </Col>
          </S.HideInDesktop>
          <Col w={[4, 12, 8]} mt="8" mtT="0">
            <T.H3 color="black" weight="bold">
              Text To Speech
            </T.H3>
          </Col>

          <Col w={[4, 12, 8]} mt="3">
            <T.P color="darkGray">
              Many computers and mobile devices today have built in
              text-to-speech software. Here are guides for each of the major
              browsers and devices:
            </T.P>
          </Col>

          <Col w={[4, 12, 8]} mt="4">
            <T.P weight="bold" color="darkGray">
              Chrome
            </T.P>
          </Col>
          <Col w={[4, 12, 8]} mt="2">
            <T.P color="darkGray">
              <T.Link
                external
                underline
                color="darkGray"
                to={CHROME_GOOGLE_SPEAK}
              >
                Click here to download Google Speak
              </T.Link>{' '}
              and select the Add to Chrome button
            </T.P>
          </Col>

          <Col w={[4, 12, 8]} mt="4">
            <T.P weight="bold" color="darkGray">
              Windows Edge
            </T.P>
          </Col>
          <Col w={[4, 12, 8]} mt="2">
            <T.P color="darkGray">
              Open the Edge browser and then click on Read Aloud Option or on
              your keyboard press Ctrl + Shift + U
            </T.P>
          </Col>

          <Col w={[4, 12, 8]} mt="4">
            <T.P weight="bold" color="darkGray">
              Android Apps
            </T.P>
          </Col>
          <Col w={[4, 12, 8]} mt="2">
            <T.P color="darkGray">
              <T.Link
                external
                underline
                color="darkGray"
                to={VOICE_ALOUD_READER_ANDROID}
              >
                Click here to download Read Aloud
              </T.Link>{' '}
              for Android phones or tablets
            </T.P>
          </Col>

          <Col w={[4, 12, 8]} mt="4">
            <T.P weight="bold" color="darkGray">
              Apple
            </T.P>
          </Col>
          <Col w={[4, 12, 8]} mt="2">
            <T.P color="darkGray">
              <T.Link
                external
                underline
                color="darkGray"
                to={VOICE_ALOUD_READER_APPLE}
              >
                Click here to download Voice Aloud Reader for Apple devices.
              </T.Link>{' '}
              Choose your device when you download. Or go to your Apple App
              store and search Voice Aloud Reader
            </T.P>
          </Col>
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isFontLarge: state.accessibility.isFontLarge,
  layoutColor: state.accessibility.layoutColor,
  layoutFontFamily: state.accessibility.layoutFontFamily
});

export default connect(mapStateToProps, {
  setIsFontLarge,
  setLayoutColor,
  setLayoutFontFamily
})(Accessibility);
