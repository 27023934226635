import { useLocation, useHistory } from 'react-router-dom';

const appendSearchParams = sp => obj => {
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      sp.delete(key);
      value.forEach(v => sp.append(key, v));
    } else if (value === undefined) {
      sp.delete(key);
    } else {
      sp.set(key, value);
    }
  });
  return sp;
};

const paramsToObject = entries => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const toStringWithReplace = function(additionalParams) {
  const newQuery = new URLSearchParams(this.toString());
  appendSearchParams(newQuery)(additionalParams);
  return newQuery.toString();
};

const useSearchParams = () => {
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const params = paramsToObject(query.entries());

  const append = appendSearchParams(query);

  // Override the set function
  const originalSet = query.set.bind(query);

  query.set = (key, value) => {
    originalSet(key, value);
    history.replace({ search: query.toString() });
  };

  query.append = append;
  query.params = params;
  query.toStringWithReplace = toStringWithReplace.bind(query);

  return query;
};

export default useSearchParams;
