import { connect } from 'react-redux';
import General from './General';
import Website from './Website';

const Layout = ({ layout, ...props }) => {
  switch (layout) {
    case 'website':
      return <Website {...props} />;
    default:
      return <General {...props} />;
  }
};

const mapStateToProps = state => ({
  layoutColor: state.accessibility.layoutColor
});

export default connect(mapStateToProps, null)(Layout);
