// Pckages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Collapse from '../../common/Collapse';

// Functions
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

// Components
import { Wrapper } from './SessionsFiles.style';
import Layout from './../../Layouts';
import * as T from './../../common/Typography';

// Data
import materials from './materials';

const uppercaseSurvey = surveyType =>
  surveyType
    .split('-')
    .map(item => item[0].toLocaleUpperCase() + item.slice(1))
    .join(' ');

class SessionsFiles extends Component {
  componentDidMount() {
    const { PIN } = this.props;
    this.props.fetchParticipantSessions({ PIN, history: this.props.history });
  }

  render() {
    const { sessions } = this.props;

    const completedSessions = sessions;
    return (
      <Layout>
        <Wrapper>
          <T.H1 mb="8">Completed sessions files</T.H1>
          <Collapse
            isEmpty={!completedSessions.length}
            items={completedSessions.map(
              session =>
                materials[session.sessions.type].length > 0 && {
                  title: `Session: ${uppercaseSurvey(session.sessions.type)}`,
                  content: (
                    <ul
                      style={{
                        padding: '1rem',
                        width: '90%',
                        margin: '0 auto'
                      }}
                    >
                      {materials[session.sessions.type].map(resource => (
                        <li>
                          <a
                            href={resource.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {resource.displayName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )
                }
            )}
          />
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  PIN: state.auth.PIN,
  sessions: state.sessions.participantSessions
});

export default connect(mapStateToProps, { fetchParticipantSessions })(
  SessionsFiles
);
