import React, { useState } from 'react';
import { Col, Row } from '../Grid';
import * as T from '../Typography';

import ExportButton from '../ExportButton';
import FilterResults from '../FilterResults';

const TrainerFeedback = ({ role, filters }) => {
  const [feedbackFilters, setFeedbackFilters] = useState(filters);

  return (
    <div>
      <T.P mb="5">Export all feedback related to your trainers.</T.P>
      <FilterResults
        role={role}
        applyFiltersCb={_filters => {
          setFeedbackFilters(_filters);
        }}
      />
      <Row inner mt={4}>
        <Col w={[4, 6, 6]}>
          <ExportButton
            filters={{
              ...filters,
              ...feedbackFilters
            }}
            type="primary"
            text="Export Trainer feedback data"
            height="50px"
            exportType="trainerFeedback"
          />
        </Col>
      </Row>
    </div>
  );
};

export default TrainerFeedback;
