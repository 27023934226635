import React from 'react';

import Question from './Question';

import {
  ProgrammeQuestionsSection,
  QuestionGroup,
  QuestionText,
  QuestionWrapper,
  SectionCategory,
  SectionDescription,
  SubGroup,
  TextField
} from './Questions.style';
import { questionCategories } from '../../../constants';

const Questions = ({ questions, setQuestion, hideGroupHeading = false }) => {
  return questions.map((q, i) => {
    const {
      _id: questionId,
      text: questionText,
      helperText,
      group,
      // answered,
      followUpQuestion,
      answer
    } = q;

    const currentQuestionIndex = questions.findIndex(
      question => question._id.toString() === questionId.toString()
    );
    const onBlur = answer => {
      if (
        (typeof answer === 'string' &&
          (answer?.toLowerCase()?.includes('please specify') ||
            answer?.toLowerCase()?.includes('please state'))) ||
        followUpQuestion?.ifAnswerIsOneOf.includes(answer) ||
        !answer
      ) {
        return;
      }
      const nextQuestion = questions.find((question, i) => {
        return i > currentQuestionIndex && !question.answered;
      });

      if (nextQuestion) {
        const nextQuestionId = nextQuestion._id;
        const nextQuestionElement = document.getElementById(
          `question-wrapper-${nextQuestionId}`
        );
        // used the timeout because the next question was not scrolling into view if the question was date picker
        setTimeout(() => {
          nextQuestionElement?.scrollIntoView({
            behavior: 'smooth'
          });
        }, 0);
      }
    };

    const inputType = q.questionType.desc;

    const subGroup = q.subGroup && q.subGroup.text ? q.subGroup.text : null;

    const handleOther = (e, isArrayType) => {
      const _answer = isArrayType ? answer[0] : answer;
      const prefix = _answer.split(' - ')[0];
      const otherAnswer = `${prefix}: ${e.target.value}`;

      setQuestion({
        _id: questionId,
        otherAnswer: isArrayType ? [otherAnswer] : otherAnswer,
        answered: !!otherAnswer
      });
    };

    const lastRequiredQuestionIndex = questions
      .slice(0, i)
      .findLastIndex(question => question.isRequired);

    const isLastRequiredQuestionAnswered = !!questions[
      lastRequiredQuestionIndex
    ]?.answered;

    const isChildQuestion = !!q.metadata?.parentQuestion;
    const disabled =
      !isChildQuestion &&
      currentQuestionIndex !== 0 &&
      !isLastRequiredQuestionAnswered;

    const isFirstProgrammeSpecificQuestion =
      q.order === 1 &&
      q.category === questionCategories.PROGRAMME_SPECIFIC &&
      !!q.programme;

    return (
      <QuestionGroup key={questionId}>
        {!hideGroupHeading && i === 0 && (
          <>
            {group.text && <SectionCategory>{group.text}</SectionCategory>}
            {group?.description && (
              <SectionDescription>{group.description}</SectionDescription>
            )}
          </>
        )}
        {isFirstProgrammeSpecificQuestion && (
          <ProgrammeQuestionsSection>
            {q.programme.titleShort}
          </ProgrammeQuestionsSection>
        )}
        <QuestionWrapper disabled={disabled}>
          <TextField id={`question-wrapper-${questionId}`}>
            <header>
              {subGroup && <SubGroup>{subGroup}</SubGroup>}
              <QuestionText id={`qs-text-${questionId}`}>
                {q.isRequired && <span>* </span>}
                {questionText}
              </QuestionText>
              <p className="helpertext">{helperText}</p>
            </header>
            <Question
              question={q}
              inputType={inputType}
              setQuestion={setQuestion}
              onBlur={onBlur}
              disabled={disabled}
              aria-labelledby={`qs-text-${questionId}`}
              aria-label={questionText}
            />

            <div className="other-div">
              {/* Load "Other" div */}
              {followUpQuestion?.ifAnswerIsOneOf.includes(answer) && (
                <TextField>
                  <p>{followUpQuestion.text}</p>
                  <input
                    id="other"
                    name={questionId}
                    onChange={handleOther}
                    onBlur={e => onBlur(e.target.value)}
                    autoFocus
                  />
                </TextField>
              )}
              {(answer?.toLowerCase?.()?.includes('please specify') ||
                answer?.toLowerCase?.()?.includes('please state') ||
                answer?.[0]?.toLowerCase?.()?.includes('please specify') ||
                answer?.[0]?.toLowerCase?.()?.includes('please state')) && (
                <TextField>
                  <p style={{ marginTop: '1rem' }}>Please specify:</p>
                  <input
                    id="other"
                    name={questionId}
                    type="text"
                    onChange={e => handleOther(e, typeof answer !== 'string')}
                    onBlur={e => onBlur(e.target.value)}
                    autoFocus
                  />
                </TextField>
              )}
            </div>
            {/* <Warning>
              {q.isRequired && !answered && '* this question must be answered'}
            </Warning> */}
          </TextField>
        </QuestionWrapper>
      </QuestionGroup>
    );
  });
};

export default Questions;
