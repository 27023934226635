import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const PlayerWrapper = styled.div`
  width: ${({ width }) => width || '100%'};

  min-width: 0;
  aspect-ratio: 16/9;
  display: ${({ width }) => (width ? 'inline-block' : 'inherit')};
`;

export const StyledPlayer = styled(ReactPlayer)`
  position: relative;
  top: 0;
  left: 0;
`;
