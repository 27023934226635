import styled from 'styled-components';
import setMargin from '../../../../helpers/setMargin';

export const Field = styled.div`
  ${setMargin};
  width: 100% !important;
  height: 100% !important;

  .ant-time-picker {
    width: 100% !important;
    height: 100% !important;
    font-size: calc(var(--font-size-adjustment) * 1rem) !important;
    color: ${({ theme }) => theme.colors.gray} !important;

    svg {
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  .ant-time-picker-input {
    border: 1px solid;
    border-color: ${({ theme, open, color, focus, error }) => {
      if (error) return theme.colors.error;
      return open || focus
        ? theme.colors.gray
        : theme.colors[color] || theme.colors.midPrimary;
    }} !important;
    font-size: calc(var(--font-size-adjustment) * 1rem) !important;
    color: ${({ theme }) => theme.colors.gray} !important;
    padding: ${({ theme }) => theme.spacings[3]} !important;
    height: 100% !important;
  }
`;
