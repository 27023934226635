import React from 'react';

import Badge from '../../common/Badge';
import * as T from '../../common/Typography';
import DifficultyLevelBars from '../../common/DifficultyLevelBars';

import { moduleFormatTypes } from '../../../constants/sessionData';
import {
  MODULE_URL,
  INDIVIDUAL_PROGRAMME
} from '../../../constants/navigationRoutes';

const renderFormatBadge = isLive => {
  if (!isLive) {
    return (
      <Badge title={moduleFormatTypes.E_LEARNING.label} variant="primary" />
    );
  } else {
    return (
      <Badge title={moduleFormatTypes.LIVE_EVENT.label} variant="tertiary" />
    );
  }
};

export const TableColumns = isDesktop => {
  const commonColumns = [
    {
      title: 'Module',
      dataIndex: 'title',
      key: 'title',
      render: (text, row) => (
        <T.Link to={MODULE_URL.replace(':id', row._id)}>{text}</T.Link>
      ),
      width: '30%'
    }
  ];

  const desktopColumns = [
    {
      title: 'Format',
      key: 'isLive',
      dataIndex: 'isLive',
      render: isLive => renderFormatBadge(isLive)
    },
    {
      title: 'Programme',
      key: 'programme',
      dataIndex: 'programme.title',
      render: (text, row) => (
        <T.Link to={INDIVIDUAL_PROGRAMME.replace(':slug', row.programme.slug)}>
          {text}
        </T.Link>
      ),
      width: '30%'
    },
    {
      title: 'Level',
      key: 'difficultyLevel',
      dataIndex: 'difficultyLevel',
      render: text => {
        return <DifficultyLevelBars levelText={text} />;
      }
    }
  ];

  return !isDesktop ? commonColumns : [...commonColumns, ...desktopColumns];
};
