import {
  GET_INVOLVED_SUCCESS,
  GET_INVOLVED_FAIL
} from '../constants/actionTypes';

const initialState = {
  loaded: false,
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOLVED_SUCCESS:
      return {
        ...state,
        ...payload,
        loaded: true,
        error: null
      };

    case GET_INVOLVED_FAIL:
      return {
        ...initialState,
        loaded: true,
        error: payload
      };

    default:
      return state;
  }
}
