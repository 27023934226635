import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import * as S from './style';

import {
  fetchProgrammeCategoryById,
  createProgrammeCategory,
  updateProgrammeCategory
} from '../../../actions/programmesActions';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import { programmeCategoryValidation as validate } from '../../../validation/schemas';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import FileDropzone from '../../common/FileDropzone';
import * as I from '../../common/Inputs';
import Modal from '../../common/modal';
import { MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES } from '../../../constants/navigationRoutes';
import { CREATE_PROGRAMME_CATEGORY_FAIL } from '../../../constants/actionTypes';

const initialFormState = {
  title: '',
  description: '',
  imageLink: {}
};

const AddEditCategory = ({
  programmeCategory,
  loading,
  fetchProgrammeCategoryById,
  createProgrammeCategory,
  updateProgrammeCategory,
  createCategoryLoading,
  updateCategoryLoading,
  fileUploadState,
  error
}) => {
  const { categoryId, slug } = useParams();

  const location = useLocation();
  const isEdit = location.pathname.includes('/edit');

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      submitAttempt.current = true;
      const isValid = validateForm();
      if (isValid) {
        const { current: finalFormState } = finalSubmissionData;

        if (isEdit) {
          await updateProgrammeCategory(categoryId, finalFormState);
        } else {
          await createProgrammeCategory({
            ...finalFormState,
            programmeSlug: slug
          });
        }
        setShowModal(true);
      }
    },
    [
      validateForm,
      finalSubmissionData,
      isEdit,
      categoryId,
      updateProgrammeCategory,
      createProgrammeCategory,
      slug
    ]
  );

  useEffect(() => {
    if (isEdit) {
      fetchProgrammeCategoryById(categoryId);
    }
  }, []);

  useEffect(() => {
    if (isEdit && programmeCategory) {
      setFormData(programmeCategory);
    }
  }, [programmeCategory]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [validateForm]);

  useEffect(() => {
    if (
      fileUploadState.AddEditCategory &&
      fileUploadState.AddEditCategory.data &&
      fileUploadState.AddEditCategory.data?.[0]?.fileUrl
    ) {
      setFormData({ imageLink: fileUploadState.AddEditCategory.data?.[0] });
    }
  }, [fileUploadState.AddEditCategory, setFormData]);

  if ((!programmeCategory && isEdit) || loading) {
    return <Spin />;
  }

  const hasHttpError = error.id === CREATE_PROGRAMME_CATEGORY_FAIL;

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 9, 9]}>
          <T.H1>{isEdit ? 'Update Category' : 'Add Category'}</T.H1>
        </Col>
        <S.Divider full />
        <Col w={[4, 6, 6]}>
          <S.Form>
            <I.BasicInput
              label="Category Name"
              required
              placeholder="Type here..."
              mb="5"
              value={formState.title}
              handleChange={title => setFormData({ title })}
              error={validationErrors.title}
            />
            <I.TextArea
              label="Description"
              required
              placeholder="Type here..."
              mb="5"
              rows={5}
              value={formState.description}
              handleChange={description => setFormData({ description })}
              error={validationErrors.description}
            />
            <T.P weight={700} mb={2}>
              * Main Image
            </T.P>
            <FileDropzone
              id={'AddEditCategory'}
              mb={5}
              clickAnywhereForUpload={false}
              supportedFormatsText="JPEG, PNG"
              tip="Recommended dimensions: 600px x 400px"
              existingFileData={isEdit && programmeCategory?.imageLink}
              validationError={validationErrors.imageLink}
              folderPrefix="images/categories"
            />

            <Row mb={7} inner>
              <Col w={[4, 6, 6]}>
                <Button
                  label="Cancel"
                  type="tertiary"
                  to={MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES.replace(
                    ':slug',
                    slug
                  )}
                />
              </Col>
              <Col w={[4, 6, 6]}>
                <Button
                  label={isEdit ? 'Update' : 'Create'}
                  type="primary"
                  onClick={handleSubmit}
                  loading={
                    isEdit ? updateCategoryLoading : createCategoryLoading
                  }
                />
              </Col>
            </Row>
          </S.Form>
          <Modal visible={showModal} setModalOpen={setShowModal}>
            {hasHttpError ? (
              <>
                <T.H4W color="darkGray" mb="2" mt="2">
                  Error
                </T.H4W>
                <T.P color="gray" mb="10">
                  {error.msg || 'Something went wrong'}
                </T.P>
              </>
            ) : (
              <>
                <T.H4W color="darkGray" mb="2" mt="2">
                  Category {isEdit ? 'Updated' : 'Created'} Successfully
                </T.H4W>
                <T.P color="gray" mb="10">
                  Thank you for {isEdit ? 'updating' : 'adding'} the category.
                </T.P>
              </>
            )}

            <Button
              label="Return to category list"
              type="primary"
              to={`/manage/programmes/${slug}/categories`}
            />
          </Modal>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  programmeCategory: state.programmes.programmeCategory,
  loading: state.loading.fetchProgrammeCategoryLoading,
  createCategoryLoading: state.loading.createCategoryLoading,
  updateCategoryLoading: state.loading.updateCategoryLoading,
  fileUploadState: state.fileUpload,
  error: state.error
});

const mapActionsToProps = {
  fetchProgrammeCategoryById,
  createProgrammeCategory,
  updateProgrammeCategory
};

export default connect(mapStateToProps, mapActionsToProps)(AddEditCategory);
