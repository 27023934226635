import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainSecondary};
  height: 1px;
  margin-top: ${({ theme }) => theme.spacings[3]};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  margin-right: auto;
`;

export const Form = styled.form`
  width: 100%;
  border-radius: 12px;
`;
