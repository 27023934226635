import { validate as _validate, createSchema, fields } from '..';
import { userRoles } from '../../constants';

const { requiredText, arrayOfString, email, arrayOfOptionalStrings } = fields;

const validate = data => {
  const editUserSchema = createSchema({
    name: requiredText,
    email,
    roles: arrayOfString,
    managedProgrammes:
      data.roles.includes(userRoles.trainer) ||
      data.roles.includes(userRoles.programmeManager)
        ? arrayOfString
        : arrayOfOptionalStrings
  });
  return _validate(editUserSchema, data);
};

export default validate;
