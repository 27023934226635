import { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as T from '../../common/Typography';
import { Row, Col } from '../../common/Grid';
import { Modal as AntModal } from 'antd';
import { message } from 'antd';

import {
  updateMyAccount,
  checkAuth as checkAuthAction
} from '../../../actions/authAction';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import AccountPageHeader from '../../common/AccountPageHeader';

import { BasicInput, Dropdown, Checkbox } from '../../common/Inputs';
import { updateMyAccount as validate } from '../../../validation/schemas';
import { deleteAccountAction } from '../../../actions/deleteAccountAction';
import {
  userRoles,
  northEastAndNorthCumbriaICBOrganisations,
  organisations as organisationsOptions
} from '../../../constants';

const stateReducer = (state, newState) => {
  return { ...state, ...newState };
};

const isOrgExistInOptions = (org, category) => {
  if (!org) {
    return {};
  }
  let isExist = organisationsOptions.find(({ value }) => value === org);
  let selectedValue = org;

  if (!isExist) {
    selectedValue = category;
  }

  return { isExist, selectedValue };
};

const initialState = ({
  userEmail,
  userName,
  userOrganisation = {},
  userLocalAuthorities = [],
  userJobRole,
  userTeam,
  userDepartment,
  userNewsletterSubscription
}) => {
  const { isExist, selectedValue } = isOrgExistInOptions(
    userOrganisation.value,
    userOrganisation.category
  );
  return {
    email: userEmail,
    name: userName,
    localAuthorities: userLocalAuthorities,
    organisation: {
      value: selectedValue,
      category: userOrganisation.category
    },
    organisationOther: (!isExist && userOrganisation.value) || '',
    submitAttempt: false,
    validationErrors: {},
    showOrgOther: !isExist,
    showOrgIcb: false,
    updated: false,
    getLoading: true,
    jobRole: userJobRole,
    team: userTeam,
    department: userDepartment,
    newsletterSubscription: userNewsletterSubscription
  };
};

const cleanEmail = email => email.toLowerCase().trim();

const { confirm } = AntModal;
const MyAccount = ({
  userEmail,
  userName,
  loading,
  updateMyAccount,
  httpError,
  deleteAccountAction: deleteAccountActionCreator,
  deleteAccountLoading,
  credentialId,
  userOrganisation = {},
  userLocalAuthorities = [],
  role,
  checkAuthAction,
  userJobRole,
  userTeam,
  userDepartment,
  userNewsletterSubscription
}) => {
  const [state, setState] = useReducer(
    stateReducer,
    initialState({
      userEmail,
      userName,
      userOrganisation,
      userLocalAuthorities,
      userJobRole,
      userTeam,
      userDepartment,
      userNewsletterSubscription
    })
  );
  const {
    email,
    name,
    localAuthorities,
    organisation,
    organisationOther,
    submitAttempt,
    validationErrors,
    showOrgOther,
    showOrgIcb,
    getLoading,
    jobRole,
    team,
    department,
    newsletterSubscription
  } = state;

  const history = useHistory();
  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        role,
        name,
        organisation,
        localAuthorities,
        showOrgOther,
        organisationOther,
        jobRole,
        team,
        department,
        newsletterSubscription
      });

      setState({ validationErrors: {} });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrors: error.inner });
      }
      return false;
    }
  };
  useEffect(() => {
    const getInfo = async () => {
      await checkAuthAction();
      setState({ getLoading: true });
    };
    getInfo();
  }, [checkAuthAction]);
  useEffect(() => {
    setState(
      initialState({
        userEmail,
        userName,
        userOrganisation,
        userLocalAuthorities,
        userJobRole,
        userTeam,
        userDepartment,
        userNewsletterSubscription
      })
    );
  }, [
    userEmail,
    userName,
    userOrganisation,
    userLocalAuthorities,
    userJobRole,
    userTeam,
    userDepartment,
    userNewsletterSubscription
  ]);
  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleSubmit = e => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleUpdate();
    }
  };

  const handleUpdate = async e => {
    setState({ loading: true });

    await updateMyAccount({
      email: cleanEmail(email),
      name,
      organisation: {
        ...organisation,
        value:
          showOrgOther || showOrgIcb ? organisationOther : organisation.value
      },
      localAuthorities,
      jobRole,
      team,
      department,
      newsletterSubscription
    });
    message.success(
      'Done!, Your account details have been updated successfully'
    );
  };

  const deleteAccount = () => {
    confirm({
      title: 'Are you sure that you want to delete your account?',
      content:
        'By doing this you will not be able to log into any of your Connect 5 accounts. This cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      okButtonProps: {
        loading: deleteAccountLoading
      },
      loading: true,
      onOk() {
        deleteAccountActionCreator(credentialId, history);
      },
      centered: true,
      icon: null
    });
  };

  useEffect(() => {
    if (!organisation.value) return;
    if (organisation.value === userOrganisation.value) {
      return;
    }
    if (organisation.value.includes('ICB')) {
      setState({
        showOrgIcb: true,
        showOrgOther: false,
        organisationOther: northEastAndNorthCumbriaICBOrganisations.includes(
          organisationOther
        )
          ? organisationOther
          : undefined
      });
    } else if (organisation.value.includes('specify')) {
      setState({
        showOrgIcb: false,
        showOrgOther: true
      });
    } else {
      setState({
        showOrgIcb: false,
        showOrgOther: false
      });
    }
  }, [organisation.value, userOrganisation.value, organisationOther]);

  return (
    <Layout>
      <AccountPageHeader title="My Account Details" />

      <Row>
        {role !== userRoles.participant && (
          <Col w={[4, 12, 6]}>
            <BasicInput
              value={name}
              handleChange={value => setState({ name: value })}
              label="Name"
              placeholder="Type your name here"
              mb={6}
              error={validationErrors.name}
            />
          </Col>
        )}
        <Col w={[4, 12, 6]}>
          <BasicInput
            value={email}
            handleChange={value => setState({ email: value })}
            label="Email"
            id="email"
            placeholder="Type your email here"
            mb={6}
            error={
              validationErrors.email ||
              (httpError?.response?.status === 409 &&
                httpError?.response?.data?.error)
            }
          />
        </Col>
      </Row>
      {role !== userRoles.participant && (
        <Row>
          <Col w={[4, 12, 6]}>
            <Dropdown
              selected={organisation.value}
              m={{ mb: 6 }}
              placeholder="Where do you work"
              label="Where do you work"
              options={organisationsOptions}
              handleChange={(value, option, b) =>
                setState({
                  organisation: {
                    value,
                    category: option?.key?.split('_')[0]
                  }
                })
              }
            />
            {showOrgOther && (
              <BasicInput
                value={organisationOther}
                autoFocus
                handleChange={value => setState({ organisationOther: value })}
                placeholder="Type organisation here..."
                mb={6}
                error={
                  validationErrors.organisation ||
                  validationErrors.organisationOther
                }
              />
            )}
            {showOrgIcb && (
              <Dropdown
                selected={organisationOther}
                m={{ mb: 3 }}
                placeholder="Select directorate..."
                required
                options={northEastAndNorthCumbriaICBOrganisations.map(org => ({
                  value: org,
                  label: org
                }))}
                handleChange={value => setState({ organisationOther: value })}
              />
            )}
          </Col>
          <Col w={[4, 12, 6]}>
            <BasicInput
              value={jobRole}
              handleChange={value => setState({ jobRole: value })}
              mb={4}
              label="Job role"
              placeholder="Type your job role here"
              error={validationErrors.jobRole}
            />{' '}
          </Col>
          {![
            userRoles.admin,
            userRoles.programmeManager,
            userRoles.trainer
          ].includes(role) && (
            <>
              <Col w={[4, 12, 6]}>
                <BasicInput
                  value={team}
                  handleChange={value => setState({ team: value })}
                  mb={4}
                  label="team"
                  placeholder="Type your team here"
                  error={validationErrors.team}
                />{' '}
              </Col>
              <Col w={[4, 12, 6]}>
                <BasicInput
                  value={department}
                  handleChange={value => setState({ department: value })}
                  mb={4}
                  label="Business Unit / Department"
                  placeholder="Type your Business Unit / Department  here"
                  error={validationErrors.department}
                />{' '}
              </Col>
            </>
          )}
        </Row>
      )}
      <Row>
        <Checkbox
          checked={newsletterSubscription}
          label={<T.P color="gray">Subscribe to Boost monthly bulletin</T.P>}
          handleChange={value => setState({ newsletterSubscription: value })}
        />
      </Row>
      <Row>
        <Col w={[4, 6, 6]} style={{ position: 'relative' }}>
          <Button
            onClick={handleSubmit}
            type="primary"
            label="Save changes"
            loading={loading}
            mt={5}
          />
          <T.Link onClick={deleteAccount || getLoading} mt={8} color="gray">
            Delete account
          </T.Link>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  httpError: state.auth.error,
  credentialId: state.auth.credentialId,
  userOrganisation: state.auth?.organisation,
  userLocalAuthorities: state.auth.localAuthorities,
  userEmail: state.auth.email,
  userName: state.auth.name,
  role: state.auth.role,
  loading: state.loading.updateMyAccount,
  userJobRole: state.auth.jobRole,
  userTeam: state.auth.team,
  userDepartment: state.auth.department,
  userNewsletterSubscription: state.auth.newsletterSubscription
});

export default connect(mapStateToProps, {
  updateMyAccount,
  deleteAccountAction,
  checkAuthAction
})(MyAccount);
