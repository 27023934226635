import moment from 'moment';
import React from 'react';

import * as T from '../../../common/Typography';
import * as R from '../../../../constants/navigationRoutes';

export const WaitingListTableColumns = ({ isTablet, handleAddToAttendee }) => {
  const commonColumns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'userDetails.name',
      render: (name, row) => (
        <T.Link
          to={R.MANAGE_USERS_EDIT.replace(':credId', row?.userDetails?.credId)}
          color="darkGray"
        >
          {name || 'N/A'}
        </T.Link>
      ),
      position: 1
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: email => <T.P color="darkGray">{email || 'N/A'}</T.P>,
      position: 2
    }
  ];

  const nonMobileColumns = [
    {
      title: 'Sign up date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => (
        <T.P color="darkGray">
          {moment(createdAt).format('DD/MM/YYYY') || 'N/A'}
        </T.P>
      ),
      position: 2
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, row) => (
        <>
          <T.Link onClick={() => handleAddToAttendee(row.email)}>
            Add to attendee list
          </T.Link>
        </>
      )
    }
  ];

  const columns = !isTablet
    ? commonColumns
    : [...commonColumns, ...nonMobileColumns];

  const sortedColumns = columns.sort((a, b) => a.position - b.position);

  return sortedColumns;
};
