import React from 'react';
import Icon from '../Icon';
import * as T from '../Typography';
import * as S from './style';

const IconWithTextButton = ({
  label,
  onClick,
  disabled,
  icon,
  color,
  w,
  h,
  ...props
}) => {
  return (
    <S.StyledButton
      aria-label={label || icon}
      disabled={disabled}
      onClick={onClick}
      type="button"
      {...props}
    >
      <Icon icon={icon} mr="1" color={color} width={w} height={h} />
      {label && (
        <T.P weight="600" color={color}>
          {label}
        </T.P>
      )}
    </S.StyledButton>
  );
};

export default IconWithTextButton;
