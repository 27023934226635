import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginUser } from '../../../actions/authAction';
import { clearErrors } from '../../../actions/errorAction';
import { BasicInput } from '../Inputs';

import { PARTICIPANT_SIGN_UP_URL } from '../../../constants/navigationRoutes';
import useRedirectAfterAuth from '../../../hooks/useRedirectAfterAuth';
import useSearchParams from '../../../hooks/useSearchParams';
import validate from '../../../validation/schemas/login';
import Button from '../Button';
import { Col, Row } from '../Grid';
import * as T from '../Typography';
import * as S from './style';

const cleanEmail = email => email.toLowerCase().trim();

const ExtraInfo = ({ contactEmail, courseName }) => {
  return (
    <>
      <T.P weight={400} mb="5">
        Please log into the account you created when you started the{' '}
        {courseName} course. If you have yet to complete Module 1 and/or to
        complete the pre-course evaluation,{' '}
        {contactEmail && (
          <>
            please do this first. Contact your trainer on{' '}
            <T.Link external to={`mailto:${contactEmail}`}>
              {contactEmail}
            </T.Link>{' '}
            if you require help.
          </>
        )}
      </T.P>
      <T.P weight={400} mb="5">
        If you have forgotten your password click Forgot password below.
      </T.P>
    </>
  );
};

const LoginForm = ({
  loginUser: loginUserAction,
  clearErrors: clearErrorsAction,
  httpError,
  loginLoading,
  loginRole: _loginRole,
  renderInline = false,
  searchParams: propSearchParams,
  handleShowSignUp = () => {}
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(loginLoading);
  const [errors, setErrors] = useState({});
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const history = useHistory();
  const signupAsMeccTrainer = history.location.state?.signupAsMeccTrainer;
  const searchParams = useSearchParams();
  const effectiveSearchParams = propSearchParams || searchParams;
  const loginRole = effectiveSearchParams.params.loginRole || _loginRole;
  const redirectAfterAuth = useRedirectAfterAuth();

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        password
      });
      setErrors({});
      clearErrorsAction();
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors(error.inner);
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  useEffect(() => {
    setErrors({});
    clearErrorsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    searchParams.set('participantLogin', true);
    const data = await loginUserAction({
      email,
      password,
      signupAsMeccTrainer,
      loginRole
    });

    setLoading(false);

    if (!data) return;

    redirectAfterAuth(data.role);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitAttempt(true);

    const isValid = validateForm();

    if (isValid) {
      handleLogin();
    }
  };

  const renderDontHaveAccount = () => (
    <Col w={[4, 12, 12]}>
      <T.P mt="6">
        Don't have an account?{' '}
        <T.Link
          onClick={e => renderInline && handleShowSignUp(e)}
          to={
            !renderInline && {
              pathname: PARTICIPANT_SIGN_UP_URL,
              search: effectiveSearchParams.toString()
            }
          }
        >
          Sign up
        </T.Link>
      </T.P>
    </Col>
  );

  return (
    <S.Wrapper onSubmit={handleSubmit} renderInline={renderInline}>
      <Row>
        {effectiveSearchParams.params?.showExtraInfo && (
          <ExtraInfo
            contactEmail={effectiveSearchParams.params.contactEmail}
            courseName={effectiveSearchParams.params.courseName}
          />
        )}
        <Col w={[4, 9, 10]}>
          <BasicInput
            placeholder="Email"
            name="email"
            type="text"
            value={email}
            handleChange={setEmail}
            error={errors.email}
          />
        </Col>
        <Col w={[4, 9, 10]} mt={4}>
          <BasicInput
            placeholder="Password"
            name="password"
            type="password"
            value={password}
            handleChange={setPassword}
            error={errors.password}
          />
          <T.Link to="/forget-password" underline={false} color="gray" mt={1}>
            Forgot password?
          </T.Link>
        </Col>
        {httpError?.msg && typeof httpError?.msg === 'string' && (
          <Col w={[4, 12, 12]}>
            <T.P mt="4" color="error">
              {httpError?.msg}
            </T.P>
          </Col>
        )}
        <Col w={[4, 6, 8]}>
          <Button
            loading={loading}
            type={renderInline ? 'quartenary' : 'primary'}
            label="Log in"
            mt={4}
          />
        </Col>
        {renderDontHaveAccount()}
      </Row>
    </S.Wrapper>
  );
};

const mapStateToProps = state => ({
  httpError: state.error,
  loginLoading: state.loading.loginLoading
});

export default connect(mapStateToProps, { loginUser, clearErrors })(LoginForm);
