import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchAllTrainers as fetchAllTrainersAction } from '../../../actions/trainerAction';
import { localAuthoritiesWithOtherOption } from '../../../constants';
import Button from '../Button';
import { Col, Row } from '../Grid';
import { Dropdown } from '../Inputs';

const FilterResults = ({
  isOpenedByDefault = false,
  id,
  role,
  trainers,
  fetchAllTrainers,
  applyFiltersCb
}) => {
  const [showFilter, setShowFilter] = useState(isOpenedByDefault);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchAllTrainers();
  }, [id, role, fetchAllTrainers]);

  const clearAllFilter = () => {
    setFilters({
      trainer: undefined,
      localAuthority: undefined,
      sessionType: undefined
    });
  };

  const isFilterActive = () => {
    setShowFilter(!showFilter);
  };

  const trainersDropdownOptions = trainers.map(tra => ({
    label: tra.name,
    value: tra._id
  }));

  return (
    <>
      <Row mb={7} mt={4}>
        <Col w={[4, 6, 4]}>
          <Button
            onClick={isFilterActive}
            type="tertiary"
            label="Filter results"
            icon="chevron"
          />
        </Col>
        <Col w={[4, 6, 4]}>
          {showFilter && (
            <Button
              onClick={clearAllFilter}
              type="tertiary"
              label="Clear all filters"
            />
          )}
        </Col>
      </Row>

      {showFilter && (
        <>
          <Row mb={5}>
            {role !== 'trainer' && (
              <Dropdown
                multi
                onChange={values =>
                  setFilters({
                    ...filters,
                    trainer: values.length ? values : undefined
                  })
                }
                value={filters.trainer}
                label="Trainer(s):"
                m={{ mb: 3 }}
                options={trainersDropdownOptions}
              />
            )}
            <Dropdown
              multi
              m={{ mb: 3 }}
              value={filters.localAuthority}
              onChange={values =>
                setFilters({
                  ...filters,
                  localAuthority: values.length ? values : undefined
                })
              }
              label="Local authority area:"
              options={localAuthoritiesWithOtherOption.map(auth => ({
                label: auth,
                value: auth
              }))}
            />
            <Dropdown
              m={{ mb: 3 }}
              value={filters.sessionType}
              onChange={value => setFilters({ ...filters, sessionType: value })}
              label="Session delivery:"
              options={['Remote / Online', 'Face to Face'].map(v => ({
                label: v,
                value: v
              }))}
              placeholder="Select..."
            />
            <Col w={[4, 6, 4]}>
              <Button
                type="primary"
                label="Apply filters"
                onClick={() => {
                  applyFiltersCb?.(filters);
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  id: state.auth.userId,
  role: state.auth.role,
  trainers: state.trainers.trainers
});

const mapDispatchToProps = {
  fetchAllTrainers: fetchAllTrainersAction
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterResults);
