import { validate as _validate, createSchema, fields } from '..';
import { moduleMaterials, optionalTextWith200CharsLimit } from '../fields';

const {
  requiredText,
  checkbox,
  urlRequired,
  optionalLink,
  optionalText,
  assessmentQuestions,
  optionalArray,
  requiredPercent,
  arrayOfString,
  moduleTitle,
  arrayOfOptionalStrings
} = fields;

const validate = data => {
  const moduleForm = createSchema({
    title: moduleTitle,
    description: requiredText,
    videoLink: optionalLink,
    developers: requiredText,
    learningObjectives: requiredText,
    clinicalOrProfessionalCompetencies: requiredText,
    whoIsItFor: requiredText,
    duration: requiredText,
    additionalInfo: optionalText,
    testimonial: optionalTextWith200CharsLimit,
    difficultyLevel: optionalText,
    isLive: checkbox,
    onlyLeadsOrAdminsCanDeliver: checkbox,
    topics: arrayOfOptionalStrings,
    category: requiredText,
    link: !!data.isLive ? optionalLink : urlRequired,
    preCourseMaterials: moduleMaterials,
    postCourseMaterials: moduleMaterials,
    enableCertificate: checkbox,
    showPostCourseEvaluation: checkbox,
    showTrainerFeedbackEvaluation: checkbox,
    send3MonthFollowUpEvaluation: checkbox,
    send6MonthFollowUpEvaluation: checkbox,
    assessmentNeeded: !data.isLive ? checkbox : optionalText,
    assessmentQuestions: !!data.assessmentNeeded
      ? assessmentQuestions
      : optionalArray,
    passMarkPercentage: !!data.assessmentNeeded
      ? requiredPercent
      : optionalText,
    isPreRequisite: checkbox,
    preRequisiteFor: data.isPreRequisite ? arrayOfString : optionalArray
  });
  return _validate(moduleForm, data);
};

export default validate;
