import styled from 'styled-components';
import { Row, Col } from '../../common/Grid';
import setMargins from '../../../helpers/setMargin';

export const EventDetailsRow = styled(Row)`
  padding: 32px 20px;
  border-radius: 4px;
  // word-break: break-word;
`;

export const MainContentWrapper = styled.div`
  width: 100%;
`;

export const ModuleCard = styled(Col)`
  background-color: ${({ theme }) => theme.colors.lightPrimary};
  padding: ${({ theme }) => theme.spacings[5]};
`;

export const ModuleCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const RegisterRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  padding: 48px 60px;
  border-radius: 4px;
  margin-bottom: -65px !important;
`;

export const DetailsCol = styled(Col)`
  padding: 0;
`;

export const LinkWrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
  height: 29px;
  margin-right: ${({ theme }) => theme.spacings[2]};
  &:focus,
  :hover {
    text-decoration: underline;
  }
  &:disabled,
  :disabled:hover {
    opacity: 0.8;
    cursor: not-allowed;
    text-decoration: none;
  }
`;

export const DividerLine = styled.div`
  width: 230px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightSecondary};
  margin-top: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[2]};
`;

export const TextWithIcon = styled.button`
  ${setMargins};
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;
