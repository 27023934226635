import styled, { css } from 'styled-components';
import setMargins from '../../../helpers/setMargin';

const sharedStyles = css`
  ${setMargins}
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.gray};
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};
  font-weight: 600;
  font-size: calc(var(--font-size-adjustment) * 1rem);
  line-height: 160%;
  transition: all linear 0.3s;
  min-height: 50px;

  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    offset: 4px;
    border-radius: 4px;
  }
`;

const disabledStyles = css`
  cursor: not-allowed;
  opacity: 0.6;

  :hover {
    transition: all 100s ease-in-out; // To disable hover effect
  }
`;

export const squareStyles = css`
  height: ${props => props.height || '2rem'};
  width: ${props => props.width || '100%'};
`;

export const roundStyles = css`
  height: ${props => props.height || '2.5rem'};
  width: ${props => props.width || '100%'};
  border-radius: 8px;

  &::after {
    border-radius: 8px;
  }
`;

export const primaryStyles = css`
  background-color: ${({ theme }) => theme.colors.darkGray};
  border: 2px solid ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.mainPrimary};

  :hover {
    background-color: ${({ theme }) => theme.colors.mainPrimary};
    border: 2px solid ${({ theme }) => theme.colors.mainPrimary};
    color: ${({ theme }) => theme.colors.darkGray};
  }

  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.mainPrimary};
  }
`;

export const secondaryStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};

  :hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
    border: 2px solid ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.mainPrimary};
  }
`;

export const tertiaryStyles = css`
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  border: 2px solid ${({ theme }) => theme.colors.mainPrimary};

  color: ${({ theme }) => theme.colors.darkGray};

  :hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
    border: 2px solid ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.mainPrimary};
  }
`;

export const quartenaryStyles = css`
  background-color: ${({ theme }) => theme.colors.darkGray};
  border: 2px solid ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.white};

  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkGray};
  }

  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.mainPrimary};
  }
`;

export const outlineStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray};
  border-radius: 8px;
  border: ${({ theme }) => theme.borders.button};

  &::after {
    border-radius: 8px;
  }
`;

export const filterStyles = css`
  ${primaryStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor]};
  color: ${({ color, theme }) => theme.colors[color]};
  border: 1px solid ${({ color, theme }) => theme.colors[color]};
  :hover {
    background-color: ${({ bgColor, theme }) => theme.colors[bgColor]};
    color: ${({ loading, color, theme }) => !loading && theme.colors[color]};
  }
  div {
    transition: transform 0.2s ease-out;
    transform: ${({ isOpen, color }) =>
      isOpen && color === 'primary' ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;

export const smallStyles = css`
  ${primaryStyles}
  min-height: 38px;
  font-size: calc(var(--font-size-adjustment) * 14px);
`;

export const loadingStyles = css`
  background-color: ${({ theme }) => theme.colors.mainSecondary};
  border: 2px solid ${({ theme }) => theme.colors.mainSecondary};
  color: ${({ theme }) => theme.colors.darkGray};

  :hover {
    background-color: ${({ theme }) => theme.colors.mainSecondary};
    border: 2px solid ${({ theme }) => theme.colors.mainSecondary};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const StyledButton = styled.button`
  ${sharedStyles};
  ${props => props.type === 'primary' && roundStyles}
  ${props => props.type === 'primary' && primaryStyles}
  ${props => props.type === 'secondary' && roundStyles}
  ${props => props.type === 'secondary' && secondaryStyles}
  ${props => props.type === 'quartenary' && roundStyles}
  ${props => props.type === 'quartenary' && quartenaryStyles}
  ${props => props.type === 'tertiary' && roundStyles}
  ${props => props.type === 'tertiary' && tertiaryStyles}
  ${props => props.type === 'outline' && roundStyles}
  ${props => props.type === 'outline' && outlineStyles}
  ${props => props.type === 'filter' && filterStyles}
  ${props => props.type === 'filter' && roundStyles}
  ${props => props.type === 'small' && smallStyles}
  ${props => props.type === 'small' && roundStyles}
  ${props => props.disabled && disabledStyles}
  ${props => props.loading && loadingStyles}
`;
