import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import eventsHeaderImage from '../../../assets/boost/people-focus.jpeg';
import { sessionsFilters } from '../../../constants/filtersData';
import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import ProgrammeHeader from '../../common/ProgrammeHeader';
import SearchFilter from '../../common/SearchFilter';

import { useEffect } from 'react';
import { fetchPublicSessions } from '../../../actions/groupSessionsAction';
import { Row } from '../../common/Grid';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import Spin from '../../common/Spin';
import { TableColumns } from './TableColumns';
import * as S from './style';

const Events = ({
  fetchPublicSessions,
  sessions: allSessions,
  filteredSessions,
  loading
}) => {
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  useEffect(() => {
    fetchPublicSessions({ upcoming: true });
  }, [fetchPublicSessions]);

  const sessions = filteredSessions || allSessions;

  if (loading || !allSessions) {
    return <Spin />;
  }

  return (
    <Layout layout="website">
      <S.TopRow>
        <ProgrammeHeader
          title="Training & Events"
          description="Here you can see the full list of events coming up. Click on any event title to find out more information, including what learning content will be covered, and to book your place."
          image={eventsHeaderImage}
          withOverlay={true}
        />
      </S.TopRow>
      <Row mb="4" jc="center">
        <SearchFilter
          category="sessions"
          showSearch
          showFilters
          filters={sessionsFilters}
          title="Find an event"
          additionalFilters={{
            upcoming: true
          }}
        />
      </Row>
      <MaxWidthContainer>
        <Row mb="4" jc="center">
          <Table data={sessions} columns={TableColumns(isTablet)} stripedRows />
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};
const mapStateToProps = state => ({
  filteredSessions: state.search.sessions?.data,
  sessions: state.sessions.sessions,
  loading: state.loading.fetchSessions
});

export default connect(mapStateToProps, {
  fetchPublicSessions
})(Events);
