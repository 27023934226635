import { Rate } from 'antd';
import { RateDiv, ListWrapper, InnerQuestionText } from '../Questions.style';

const MatrixRateFrom0To5 = ({
  question,
  setQuestion,
  onBlur,
  disabled,
  ...props
}) => {
  const { _id: questionId, questionsList, answer } = question;

  const onChange = (value, subQuestion) => {
    const answerObj = answer || {};
    answerObj[subQuestion] = value;
    const answered =
      answerObj && Object.values(answerObj).length === questionsList.length;

    setQuestion({
      _id: questionId,
      answer: answerObj,
      answered
    });
    if (answered) onBlur(answerObj);
  };

  return questionsList?.map((subQuestion, i) => {
    return (
      <ListWrapper key={questionId + subQuestion}>
        <InnerQuestionText id={questionId + subQuestion + i}>
          {subQuestion}
        </InnerQuestionText>
        <RateDiv>
          <Rate
            id={`sliderInput-${questionId}`}
            allowClear={false}
            count={5}
            onChange={e => onChange(e, subQuestion)}
            value={answer?.[subQuestion]}
            disabled={disabled}
            {...props}
          />
        </RateDiv>
      </ListWrapper>
    );
  });
};

export default MatrixRateFrom0To5;
