// import * as S from './style';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Col, Row } from '../Grid';
import Icon from '../Icon';
import * as T from '../Typography';

const Table = ({ data, columns, chevron, view }) => {
  const history = useHistory();
  const { colors, spacings } = useTheme();

  const endCols = (chevron ? 0.3 : 0) + (view ? 0.7 : 0);

  const handleHeader = header => {
    if (!header) return <T.P mb="2"></T.P>;
    if (typeof header === 'string') {
      return (
        <T.P weight={700} color="darkGray" mb="2" transform="capitalize">
          {header}
        </T.P>
      );
    }
    return header;
  };

  const handleData = dataItem => {
    if (dataItem === 0 || dataItem?.value === 0) {
      return <T.P color="gray">0</T.P>;
    }
    if (dataItem && typeof dataItem !== 'object') {
      return dataItem;
    }

    if (!dataItem || !dataItem.value) return <T.P color="gray">N/A</T.P>;

    if (typeof dataItem?.value === 'string') {
      return <T.P color="gray">{dataItem?.value}</T.P>;
    }

    return dataItem?.value;
  };

  const decideColWidth = column => {
    if (column?.width) return column?.width;

    const availMob =
      4 -
      columns.reduce((a, c) => a + ((c.width && c.width[0]) || 0), 0) -
      endCols;
    const availTab =
      12 -
      columns.reduce((a, c) => a + ((c.width && c.width[1]) || 0), 0) -
      endCols;
    const availLap =
      12 -
      columns.reduce((a, c) => a + ((c.width && c.width[2]) || 0), 0) -
      endCols;

    const customCols = columns.filter(col => col.width).length;
    const remainingCols = columns.length - customCols;

    return [
      availMob / remainingCols,
      availTab / remainingCols,
      availLap / remainingCols
    ];
  };

  return (
    <>
      {/* TITLES */}
      <Row
        style={{
          borderBottom:
            data && data.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        {columns.map(column => (
          <Col w={decideColWidth(column)}>{handleHeader(column.label)}</Col>
        ))}
      </Row>
      {/* DATA ROWS */}
      {data?.length > 0 ? (
        data.map(row => (
          <Row
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`,
              cursor: row.link && 'pointer'
            }}
          >
            {columns.map(column => (
              <Col
                w={decideColWidth(column)}
                onClick={() => {
                  if (row[column.value]?.action) {
                    return row[column.value]?.action();
                  }
                  return row.link && history.push(row.link);
                }}
                style={{ zIndex: column.action && 2 }}
              >
                {handleData(row[column.value])}
              </Col>
            ))}
            {chevron && (
              <Col w={[0.3, 0.3, 0.3]}>
                <Icon
                  icon="chevron"
                  direction="right"
                  color="primary"
                  width="16"
                  height="16"
                  mt="1"
                />
              </Col>
            )}
            {view && (
              <Col w={[0.7, 0.7, 0.7]}>
                <T.Link to={row.link} color="secondary">
                  View
                </T.Link>
              </Col>
            )}
          </Row>
        ))
      ) : (
        <Row jc="center" mt="5" mb="5">
          <Col w={[4, 12, 12]} jc="center">
            <T.P weight="700" color="gray" ta="center">
              No data found
            </T.P>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Table;
