import styled from 'styled-components';

export const Form = styled.div`
  background: ${({ theme }) => theme.colors.lightPrimary};
  max-width: 498px;
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: 12px;
`;

export const PWrapper = styled.div`
  max-width: 498px;
`;
