import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ bgColor, theme, dark }) =>
    theme.colors[bgColor] || dark
      ? theme.colors.darkGray
      : theme.colors.lightPrimary};
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[0]}`};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  height: fit-content;
  border-radius: 4px;
  padding: 0px 8.3vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column;
  }
`;

export const LogosSide = styled.div`
  max-width: 345px;
  @media (max-width: 930px) {
    margin-bottom: ${({ theme }) => theme.spacings[5]};
  }
`;

export const LogoImage = styled.img`
  width: 180px;
`;

export const ContentSide = styled.div`
  padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[4]}`};
`;

export const ContentHading = styled.div``;
