import {
  SET_IS_FONT_LARGE,
  SET_LAYOUT_COLOR,
  SET_LAYOUT_FONT_FAMILY
} from '../constants/actionTypes';

export const setIsFontLarge = isFontLarge => dispatch => {
  localStorage.setItem('isFontLarge', JSON.stringify(isFontLarge));
  dispatch({
    type: SET_IS_FONT_LARGE,
    payload: isFontLarge
  });
};

export const setLayoutColor = color => dispatch => {
  localStorage.setItem('layoutColor', color);
  dispatch({
    type: SET_LAYOUT_COLOR,
    payload: color
  });
};

export const setLayoutFontFamily = fontFamily => dispatch => {
  localStorage.setItem('layoutFontFamily', fontFamily);
  dispatch({
    type: SET_LAYOUT_FONT_FAMILY,
    payload: fontFamily
  });
};
