import styled from 'styled-components';
import { Row } from '../../common/Grid';

export const TopRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacings[7]} !important;
  margin-bottom: ${({ theme }) => theme.spacings[8]} !important;

  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    margin-top: 0 !important;
  }
  @media ${({ theme }) => theme.breakpointsMax.tablet} {
    margin-bottom: 0 !important;
  }
`;
