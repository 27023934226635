import Button from './../../common/Button';
import styled from 'styled-components';

const Div = styled.div`
  margin: 10px;
`;

// eslint-disable-next-line no-console
const handleClick = () => console.log('clicked');

const ButtonExample = () => (
  <>
    <Div>
      <p>Primary</p>
      <Button
        onClick={handleClick}
        type="primary"
        label="Button / Primary"
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="primary"
        label="Primary / Disabled"
        disabled
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="primary"
        label="Primary / Loading"
        loading
        width="200px"
        m={4}
      />
    </Div>
    <Div>
      <p>Secondary</p>
      <Button
        onClick={handleClick}
        type="secondary"
        label="Button / Secondary"
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="secondary"
        label="Secondary / Disabled"
        disabled
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="secondary"
        label="Secondary / Loading"
        loading
        width="200px"
        m={4}
      />
    </Div>
    <Div>
      <p>Tertiary</p>
      <Button
        onClick={handleClick}
        type="tertiary"
        label="Button / Tertiary"
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="tertiary"
        label="Tertiary / Disabled"
        disabled
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="tertiary"
        label="Tertiary / Loading"
        loading
        width="200px"
        m={4}
      />
    </Div>
    <Div>
      <p>Quartenary</p>
      <Button
        onClick={handleClick}
        type="quartenary"
        label="Button / Quartenary"
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="quartenary"
        label="Quartenary / Disabled"
        disabled
        width="200px"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="quartenary"
        label="Quartenary / Load..."
        loading
        width="200px"
        m={4}
      />
    </Div>
    <Div style={{ background: '#ebebeb' }}>
      <p>Small</p>
      <Button
        onClick={handleClick}
        type="small"
        label="View the training catalogue"
        width="220px"
        m={4}
      />
    </Div>
    <Div>
      <p>filter</p>
      <Button
        onClick={handleClick}
        type="filter"
        label="Filter results"
        width="155px"
        icon="chevron"
        color="primary"
        bgColor="midPrimary"
        m={4}
      />
      <Button
        onClick={handleClick}
        type="filter"
        label="Clear filters"
        width="155px"
        icon="cross"
        color="secondary"
        bgColor="midSecondary"
        m={4}
      />
    </Div>
  </>
);
export default ButtonExample;
