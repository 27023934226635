import { compiler } from 'markdown-to-jsx';
import * as T from '../Typography';
import * as S from './style';

const Markdown = ({
  text,
  color,
  customStyles = {
    h1: {},
    h2: {},
    h3: {},
    p: {},
    link: {},
    pre: {}
  },
  customRender = {}
}) => {
  const overrides = {
    a: props => {
      return (
        <T.Link
          style={{ fontSize: 'inherit !important' }}
          external={true}
          color={customStyles.color}
          {...customStyles.link}
          {...props}
        />
      );
    },
    h3: props => (
      <T.H3
        color={customStyles.color || color}
        {...customStyles.h3}
        {...props}
      />
    ),
    div: props =>
      customRender?.p ? (
        customRender.p({ ...props })
      ) : (
        <T.P
          color={customStyles.color || color}
          {...customStyles.p}
          {...props}
        />
      ),
    p: props =>
      customRender?.p ? (
        customRender.p({ ...props })
      ) : (
        <T.P
          color={customStyles.color || color}
          {...customStyles.p}
          {...props}
        />
      ),
    li: props => (
      <li>
        <T.P
          color={customStyles.color || color}
          {...customStyles.li}
          {...props}
        />
      </li>
    ),
    strong: props => (
      <S.Strong
        color={customStyles.color || color}
        {...customStyles.li}
        {...props}
        style={{ marginInlineEnd: 4 }}
      />
    ),
    code: props =>
      customRender?.p ? (
        customRender.p({ ...props })
      ) : (
        <T.P
          color={customStyles.color || color}
          {...customStyles.p}
          {...props}
        />
      )
  };

  if (typeof text !== 'string') {
    // eslint-disable-next-line no-console
    console.error(
      `Markdown: invalid type passed as prop. Received ${typeof text} with value ${text}`
    );
    return null;
  }

  const formattedText = text
    ?.replace(/\n/g, '\n\r')
    ?.split('\n')
    ?.map(line => (line.trim() === '' ? '<br />' : line))
    ?.join('  \n');

  return (
    <S.Wrapper color={color}>
      {compiler(formattedText, {
        overrides,
        forceBlock: true
      })}
    </S.Wrapper>
  );
};

export default Markdown;
