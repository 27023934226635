import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';
import { Link } from 'react-router-dom';

export const Card = styled(Link)`
  ${setMargins};
  border-radius: ${({ borderRadius }) => borderRadius || '12px 12px 12px 0'};
  background-color: ${({ theme, background }) =>
    theme.colors[background] || theme.colors.primary};
  min-width: 240px;
  min-height: 120px;
  max-width: ${({ maxWidth }) => maxWidth || '300px'};
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    offset: 2px;
    border-radius: 4px;
  }
`;
