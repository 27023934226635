import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import Table from '../../../common/BoostTable';
import Button from '../../../common/Button';
import ExportButton from '../../../common/ExportButton';
import { Col, Row } from '../../../common/Grid';
import { BasicInput } from '../../../common/Inputs';
import * as T from '../../../common/Typography';
import UserManual from '../UserManual';
import Modal from './../../../common/modal';
import { AttendeesTableColumns } from './AttendeesTableColumns';
import { WaitingListTableColumns } from './WaitingListTableColumns';
import { RowWithBorder } from './styles';

const UpdateAttendeesList = ({
  handleSubmitUpdateAttendees,
  confirmedAttendeesList,
  handleUpdateAttendees,
  loading,
  sessionDetails,
  updateParticipantAttendanceStatus,
  waitingList,
  addWaitingToAttendeesSuccess,
  resetUpdateAttendeesSuccess
}) => {
  const [originalList, setOriginalList] = useState();
  const [confirmRemoveModalVisible, setConfirmRemoveModalVisible] = useState(
    false
  );
  const [addAttendeeModalVisible, setAddAttendeeModalVisible] = useState(false);
  const [addWaitingSuccessVisible, setAddWaitingSuccessVisible] = useState(
    false
  );
  const [capacityModalVisible, setCapacityModalVisible] = useState(false);

  const [
    confirmAttendanceStatusModalVisible,
    setConfirmAttendanceStatusModalVisible
  ] = useState(false);
  const [newAttendeeEmail, setNewAttendeeEmail] = useState('');
  const [
    attendanceStatusUpdateObject,
    setAttendanceStatusUpdateObject
  ] = useState(null);

  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const handleRemoveAttendee = email => {
    const newAttendeesList = confirmedAttendeesList
      .filter(attendee => attendee.email !== email)
      .map(({ email }) => email);

    handleUpdateAttendees(newAttendeesList, 'confirmed');
    setConfirmRemoveModalVisible(true);
  };

  const shouldIncreaseCapacity = useMemo(
    () => sessionDetails?.capacity <= confirmedAttendeesList?.length,
    [sessionDetails?.capacity, confirmedAttendeesList?.length]
  );

  const handleAddToAttendee = email => {
    if (shouldIncreaseCapacity) {
      setCapacityModalVisible(true);
    } else {
      const attendeesEmails = confirmedAttendeesList
        .filter(attendee => attendee.email !== email)
        .map(({ email }) => email);

      const newAttendeesList = [...attendeesEmails, email];
      handleUpdateAttendees(newAttendeesList, 'confirmed');
      setAddAttendeeModalVisible(true);
    }
  };

  const haveNotCreatedAccount = confirmedAttendeesList
    .filter(
      ({ email }) =>
        !sessionDetails.createdAccount.find(acc => acc?.email === email)
    )
    .map(({ email }) => email);

  const notCompletedSurvey = surveyType =>
    sessionDetails.createdAccount?.reduce(
      (acc, curr) => {
        const foundSurvey = sessionDetails.completedSessionSurveys.find(
          survey => survey.user === curr.id && survey.surveyType === surveyType
        );

        if (!foundSurvey) return [...acc, curr.email];
        return acc;
      },
      [...haveNotCreatedAccount]
    );

  const handleAddAttendeeManually = () => {
    if (shouldIncreaseCapacity) {
      setCapacityModalVisible(true);
    } else {
      newAttendeeEmail &&
        handleUpdateAttendees(
          [...originalList, newAttendeeEmail],
          'confirmed',
          null,
          () => {
            handleSubmitUpdateAttendees('confirmed', originalList);
          }
        );
      setNewAttendeeEmail('');
    }
  };

  useEffect(() => {
    setOriginalList(confirmedAttendeesList.map(({ email }) => email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAddWaitingSuccessVisible(addWaitingToAttendeesSuccess);
  }, [addWaitingToAttendeesSuccess]);

  const notCompletedSurveyPost = notCompletedSurvey('post');
  return (
    <>
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <T.H1 mb="6">View & edit registered attendees</T.H1>
        </Col>
      </Row>
      <Row inner ml="2">
        <Col inner w={[4, 12, 8]}>
          <UserManual />
        </Col>
      </Row>
      <Row>
        <Col inner w={[4, 12, 12]}>
          <Table
            data={confirmedAttendeesList}
            columns={AttendeesTableColumns({
              isTablet,
              handleRemoveAttendee,
              setConfirmAttendanceStatusModalVisible,
              setAttendanceStatusUpdateObject
            })}
            stripedRows
          />
          <ExportButton
            filters={{
              session: sessionDetails._id
            }}
            type="primary"
            text="Download attendee list"
            height="50px"
            mt="5"
            width="250px"
            exportType="sessionAttendees"
            target={`(${sessionDetails.shortId})`}
          />
        </Col>
      </Row>

      <Row mt={6}>
        <Col inner w={[4, 8, 8]}>
          <T.P mt="5" mb="2" weight={700}>
            Manually add new attendees
          </T.P>
          <BasicInput
            value={newAttendeeEmail}
            onChange={e => setNewAttendeeEmail(e.target.value)}
            placeholder="Type email address..."
          />
          <Button
            label="Add attendee"
            type="tertiary"
            mt="5"
            onClick={handleAddAttendeeManually}
            width="200px"
            loading={loading}
          />
        </Col>
      </Row>

      <RowWithBorder mt={7} mb={7}>
        <Col inner w={[4, 6, 8]}>
          <T.H3 mt="5" weight={700}>
            Waiting list
          </T.H3>
          <Row mb={4}>
            <T.P mt="2">
              Below you can see people who have signed up to the waiting list.
            </T.P>
          </Row>
        </Col>
        <Col inner w={[4, 12, 12]}>
          <Table
            data={waitingList}
            columns={WaitingListTableColumns({
              isTablet,
              handleAddToAttendee,
              setConfirmAttendanceStatusModalVisible,
              setAttendanceStatusUpdateObject
            })}
            stripedRows
          />
        </Col>
      </RowWithBorder>
      <RowWithBorder mt="6">
        <Col inner w={[4, 6, 8]}>
          <T.H3 mt="5" weight={700} d="block">
            Participant response status
          </T.H3>
          <T.P mt="2">
            Below you can see the status of each of your participants who has
            registered for this session so you know what actions to take. Please
            remember that every participant must first attend a session
            delivering module 1 to create their account.
          </T.P>
          <T.P mt={5} style={{ fontStyle: 'italic' }}>
            If you are delivering a recap session (i.e. a participant has
            already attended another session teaching these modules) it may be
            that they have completed the evaluations for those sessions instead
            as we only ask them to complete the evaluation once.
          </T.P>
        </Col>
      </RowWithBorder>
      <Row mt="4">
        <Col inner w={[4, 6, 4]}>
          <T.P mt="5" weight={600}>
            The following users have not yet created account:
          </T.P>
          {haveNotCreatedAccount.length ? (
            haveNotCreatedAccount.map(item => (
              <T.P mt="2" style={{ width: '100%' }}>
                {item}
              </T.P>
            ))
          ) : (
            <T.P mt="2" style={{ width: '100%' }}>
              --
            </T.P>
          )}
        </Col>
      </Row>
      <Row mt="2" mb="7">
        <Col inner w={[4, 6, 4]}>
          <T.P weight={600} mt="5">
            The following users have not yet completed their post-course
            evaluation:
          </T.P>
          {notCompletedSurveyPost.length ? (
            notCompletedSurveyPost.map(item => (
              <T.P mt="2" style={{ width: '100%' }}>
                {item}
              </T.P>
            ))
          ) : (
            <T.P mt="2" style={{ width: '100%' }}>
              --
            </T.P>
          )}
        </Col>
      </Row>
      <Modal
        type="confirm"
        visible={confirmRemoveModalVisible}
        setModalOpen={setConfirmRemoveModalVisible}
        onCancel={values => handleUpdateAttendees(values, 'confirmed')}
        parentFunc={() =>
          handleSubmitUpdateAttendees('confirmed', originalList)
        }
        text="This will completely remove the person from your attendee list."
      />
      <Modal
        visible={confirmAttendanceStatusModalVisible}
        setModalOpen={setConfirmAttendanceStatusModalVisible}
        type="confirm"
        text="Please click confirm to mark this person as non-attendee. This action cannot be undone."
        parentFunc={() => {
          updateParticipantAttendanceStatus(attendanceStatusUpdateObject);
          setAttendanceStatusUpdateObject(null);
        }}
        onCancel={() => {
          setAttendanceStatusUpdateObject(null);
        }}
      />
      <Modal
        type="confirm"
        visible={addAttendeeModalVisible}
        setModalOpen={setAddAttendeeModalVisible}
        onCancel={() => setAddAttendeeModalVisible(false)}
        parentFunc={() => {
          handleSubmitUpdateAttendees('confirmed', originalList, 'waiting');
        }}
        text="Please click confirm to mark this person as an attendee."
      />
      <Modal
        type="success"
        visible={addWaitingSuccessVisible}
        setModalOpen={setAddWaitingSuccessVisible}
        text="The participant will receive an email notifying them."
        handleBack={() => {
          resetUpdateAttendeesSuccess();
          setAddWaitingSuccessVisible(false);
        }}
      />
      <Modal
        type="success"
        title="Error!"
        visible={capacityModalVisible}
        setModalOpen={setCapacityModalVisible}
        handleBack={() => {
          setCapacityModalVisible(false);
        }}
        text="This event is fully booked. To add more participants please adjust the event's maximum capacity."
      />
    </>
  );
};

export default UpdateAttendeesList;
