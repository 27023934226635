import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .ant-table-thead > tr > th {
    background-color: white;
    border-color: ${({ theme }) => theme.colors.mainPrimary};
  }

  .ant-pagination-item {
    border-color: ${({ theme }) => theme.colors.midGray};

    a {
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background-color: ${({ theme }) => theme.colors.lightSecondary};
  }

  ${({ theme, stripedRows }) =>
    stripedRows &&
    `
  tr:nth-child(2n) td {
    background-color: ${theme.colors.lightSecondary};
  }
    .ant-table-tbody > tr:nth-child(2n):hover > td {
    background-color: ${theme.colors.lightSecondary} !important;
  }

  .ant-table-tbody > tr:nth-child(odd):hover > td {
    background-color: ${theme.colors.white} !important;
  }

  thead {
    background-color: #f1f1f1;
  }
  `}
`;
