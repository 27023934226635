import { Checkbox as AntdCheckbox } from 'antd';
import InfoPopUp from '../../InfoPopup';
import * as T from '../../Typography';
import * as S from './style';

const Checkbox = ({
  checked,
  label,
  error,
  handleChange,
  color,
  disabled,
  defaultChecked,
  helper,
  labelColor = 'gray',
  w,
  m,
  ...props
}) => {
  return (
    <S.Field
      w={w}
      disabled={disabled}
      color={color}
      error={error}
      checked={checked}
      {...props}
      {...m}
    >
      <AntdCheckbox
        onChange={e => handleChange(e.target.checked)}
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        aria-label={label || 'checkbox'}
      >
        {typeof label === 'string' ? (
          <S.TextWrapper>
            <T.P color={error ? 'red' : labelColor} m="0" mr={2}>
              {label}
            </T.P>
            {helper && (
              <InfoPopUp
                title={helper?.title}
                text={helper?.text}
                h="16px"
                w="16px"
              />
            )}
          </S.TextWrapper>
        ) : (
          label
        )}
      </AntdCheckbox>
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default Checkbox;
