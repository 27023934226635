import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  width: 100%;
  background: ${({ bgColor, theme }) =>
    theme.colors[bgColor] || theme.colors.lightPrimary};
  min-height: 200px;
  height: 100%;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacings[5]};

  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    offset: 2px;
    border-radius: 4px;
  }
`;
