import styled from 'styled-components';
import * as CS from '../style';

const InputCommonStyle = ({ theme, error, disabled }) => `
border: ${theme.borders.inputs};
border-color: ${error ? theme.colors.error : theme.colors.midPrimary};
border-radius: ${theme.borders.radius};

width: 100%;
line-height: 16px;
color: ${theme.colors.gray};
font-size: calc(var(--font-size-adjustment) * 16px);

cursor: ${disabled && 'not-allowed'};
background: ${theme.colors.white};

:focus {
  outline: none;
  border-color: ${theme.colors.midGray} !important;
}

`;

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacings[4]};
  ${InputCommonStyle};
  ${CS.placeholderStyle};
`;

export const InfoWrapper = styled.button`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  margin-left: ${({ theme }) => theme.spacings[2]};
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ShowPassword = styled.button`
  position: absolute;
  right: 20px;
  top: calc(50% + 4px);
  transform: translateY(-50%);
  height: 24px;
  width: 24px;

  background: none;
  border: none;
  cursor: pointer;
  display: flex;
`;
