import { useState } from 'react';

import Button from '../../common/Button';
import LoginForm from '../../common/LoginForm';
import ParticipantSignUpForm from '../../common/ParticipantSignUpForm';

import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';

import { userRoles } from '../../../constants';
import { Col } from '../../common/Grid';

import { RegisterRow } from './style';

const RegisterSection = ({
  userId,
  role,
  loading,
  onSubmit,
  searchParams,
  isAlreadyRegistered,
  nonLiveSessionWithNoSession
}) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const handleShowLogin = event => {
    event.preventDefault();
    setShowLoginForm(true);
    setShowSignUpForm(false);
  };

  const handleShowSignUp = event => {
    event.preventDefault();
    setShowSignUpForm(true);
    setShowLoginForm(false);
  };

  const decideHeadline = () => {
    if (userId) {
      return 'Register now';
    } else if (showLoginForm) {
      return 'Log in to your account';
    } else {
      return 'Create an account';
    }
  };

  return (
    <RegisterRow mt={7}>
      <MaxWidthContainer bgColor="transparent">
        <Col w={[4, 12, 12]}>
          <T.H1 weight={700} mb={4}>
            {decideHeadline()}
            <span
              style={{
                fontSize: 'inherit',
                color: 'white'
              }}
            >
              ...
            </span>
          </T.H1>
        </Col>
        {userId ? (
          <>
            <Col w={[4, 12, 12]} mt={5}>
              <Button
                label="Register"
                width="200px"
                height="100%"
                type={'secondary'}
                disabled={
                  role !== userRoles.participant ||
                  nonLiveSessionWithNoSession ||
                  isAlreadyRegistered
                }
                onClick={onSubmit}
                loading={loading}
              />
            </Col>
            {role !== userRoles.participant && !nonLiveSessionWithNoSession && (
              <Col w={[4, 12, 12]} mt={2}>
                <T.P>You must log in with participant account to register</T.P>
              </Col>
            )}
            {nonLiveSessionWithNoSession && (
              <Col w={[4, 12, 12]} mt={2}>
                <T.P mt={2}>
                  Sorry, There are no sessions available for this module!
                </T.P>
              </Col>
            )}
            {isAlreadyRegistered && (
              <Col w={[4, 12, 12]} mt={2}>
                <T.P>You have already registered for this module!</T.P>
              </Col>
            )}
          </>
        ) : (
          <>
            {!showLoginForm && !showSignUpForm && (
              <>
                <Col w={[4, 7, 7]} mt={3}>
                  <T.P>
                    To register you must create a free Learning Academy training
                    account. This account will let you attend courses, receive
                    your certificates and track your progress.
                  </T.P>
                </Col>
                <Col w={[4, 12, 12]} mt={5}>
                  <Button
                    label="Sign up"
                    width="200px"
                    type={'secondary'}
                    onClick={handleShowSignUp}
                  />
                </Col>
                <Col w={[4, 12, 12]} mt={3}>
                  <Button
                    label="Log in"
                    width="200px"
                    type={'quartenary'}
                    onClick={handleShowLogin}
                  />
                </Col>
              </>
            )}
            {showLoginForm && (
              <LoginForm
                renderInline
                handleShowSignUp={handleShowSignUp}
                searchParams={searchParams}
              />
            )}
            {showSignUpForm && (
              <ParticipantSignUpForm
                renderInline
                handleShowLogin={handleShowLogin}
                searchParams={searchParams}
              />
            )}
          </>
        )}
      </MaxWidthContainer>
    </RegisterRow>
  );
};

export default RegisterSection;
