import * as I from '../../common/Inputs';
import * as T from '../../common/Typography';
import AnswerInput from '../../common/AnswerInput';
import IconWithTextButton from '../../common/IconWithTextButton';
import * as S from './style';

const Assessment = ({ setState, state, validationErrors }) => {
  const handleQuestionChange = (index, text) => {
    const newQuestions = [...state.assessmentQuestions];
    newQuestions[index].question = text;
    setState({ assessmentQuestions: newQuestions });
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const newQuestions = [...state.assessmentQuestions];
    newQuestions[questionIndex].options[optionIndex].text = event.value;
    setState({ assessmentQuestions: newQuestions });
  };

  const handleOptionCheck = (questionIndex, optionIndex, event) => {
    const newQuestions = [...state.assessmentQuestions];
    newQuestions[questionIndex].options.forEach((a, i) => {
      newQuestions[questionIndex].options[i].isCorrect = i === optionIndex;
    });

    setState({ assessmentQuestions: newQuestions });
  };

  const addAnotherAnswer = (index, event) => {
    const newQuestions = [...state.assessmentQuestions];
    newQuestions[index].options = [
      ...state.assessmentQuestions[index].options,
      { text: '', isCorrect: false }
    ];
    setState({ assessmentQuestions: newQuestions });
  };
  const removeQuestion = index => {
    const newQuestions = [...state.assessmentQuestions];
    if (newQuestions.length > 1) {
      newQuestions.splice(index, 1);
      setState({ assessmentQuestions: newQuestions });
    }
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...state.assessmentQuestions];
    if (newQuestions[questionIndex].options.length > 2) {
      newQuestions[questionIndex].options.splice(optionIndex, 1);
      setState({ assessmentQuestions: newQuestions });
    }
  };
  const addAnotherQuestion = (index, event) => {
    const newQuestions = [
      ...state.assessmentQuestions,
      {
        question: '',
        options: [
          { text: '', isCorrect: false },
          { text: '', isCorrect: false }
        ],
        correctAnswer: ''
      }
    ];
    setState({ assessmentQuestions: newQuestions });
  };
  return (
    <>
      <T.H3 weight={'600'} mb="4">
        Assessment
      </T.H3>
      {state?.assessmentQuestions?.map((question, index) => {
        return (
          <div key={index}>
            <I.TextArea
              rows="1"
              autoSize={true}
              label="Question"
              required
              placeholder="Question..."
              mb="4"
              value={question.question}
              handleChange={e => handleQuestionChange(index, e)}
              error={
                validationErrors[`assessmentQuestions[${index}]`]?.question
              }
            />
            <T.P m="0" mr="2" mb="2" weight={'600'}>
              * Answer
            </T.P>
            {question.options.map((option, optionIndex) => {
              return (
                <>
                  <S.OptionWrapper>
                    <AnswerInput
                      rows={1}
                      autoSize={true}
                      mb={1}
                      name="radiogroup"
                      type="text"
                      required={true}
                      check={option.isCorrect}
                      text={option.text}
                      index={optionIndex}
                      key={optionIndex + option}
                      handleCheck={e =>
                        handleOptionCheck(index, optionIndex, e)
                      }
                      handleInput={e =>
                        handleOptionChange(index, optionIndex, e)
                      }
                      error={
                        validationErrors?.[`assessmentQuestions[${index}]`]?.[
                          `options[${optionIndex}]`
                        ]
                      }
                    />
                    <IconWithTextButton
                      w="20px"
                      h="20px"
                      ml="2"
                      onClick={() => removeOption(index, optionIndex)}
                      icon="bin"
                      color="error"
                    />
                  </S.OptionWrapper>
                  {validationErrors?.[`assessmentQuestions[${index}]`]?.[
                    `options[${optionIndex}]`
                  ] && (
                    <T.P color="error" m="0">
                      {
                        validationErrors?.[`assessmentQuestions[${index}]`]?.[
                          `options[${optionIndex}]`
                        ]
                      }
                    </T.P>
                  )}
                </>
              );
            })}
            {validationErrors[`assessmentQuestions[${index}]`]?.options && (
              <T.P color="error" m="0" mt="1">
                {validationErrors[`assessmentQuestions[${index}]`]?.options}
              </T.P>
            )}
            <IconWithTextButton
              label="Add additional answer"
              onClick={e => addAnotherAnswer(index, e)}
              icon="add"
              mt="4"
              color="gray"
            />
            <IconWithTextButton
              label="Remove question"
              onClick={() => removeQuestion(index)}
              icon="remove"
              mb="5"
              mt="4"
              color="error"
            />
            <S.GrayLine />
            <IconWithTextButton
              label="Add additional question"
              onClick={e => addAnotherQuestion(index, e)}
              icon="add"
              mb="5"
              mt="5"
              color="darkGray"
            />
            <S.GrayLine mb="5" />
          </div>
        );
      })}
      <I.BasicInput
        type="number"
        required
        label="Pass mark percentage"
        placeholder="%"
        mt="5"
        mb="5"
        value={state.passMarkPercentage}
        handleChange={e => {
          let newValue = e;
          if (newValue < 0) {
            newValue = 0;
          } else if (newValue > 100) {
            newValue = 100;
          }
          setState({ passMarkPercentage: newValue });
        }}
        error={validationErrors.passMarkPercentage}
      />
    </>
  );
};

export default Assessment;
