import { connect } from 'react-redux';

import * as T from '../Typography';

import * as S from './style';

import BoostLogo from '../../../assets/boost/Boost logo white.png';
import {
  NHS,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  PROGRAMMES
} from '../../../constants/navigationRoutes';

const Footer = ({ isFontLarge }) => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.LogoImage height={'100px'} src={BoostLogo} alt="BoostLogo" />

        <S.LinksSide isFontLarge={isFontLarge}>
          <T.Link
            color="white"
            to={{
              pathname: PROGRAMMES,
              state: {
                preventRedirect: true
              }
            }}
          >
            Home
          </T.Link>
          <T.Link color="white" to={NHS} external>
            NHS
          </T.Link>
          {/* Sarah will provide missing URLs */}
          <T.Link color="white">Data Policy</T.Link>
          <T.Link color="white" to={TERMS_OF_USE} external>
            Terms
          </T.Link>
          <T.Link color="white" to={PRIVACY_POLICY} external>
            Privacy
          </T.Link>
        </S.LinksSide>
      </S.Wrapper>
    </S.Container>
  );
};

const mapStateToProps = state => ({
  isFontLarge: state.accessibility.isFontLarge
});

export default connect(mapStateToProps, {})(Footer);
