import Button from '../../common/Button';
import Modal from '../../common/modal';
import * as T from '../../common/Typography';

import { MODULE_URL } from './../../../constants/navigationRoutes';

const PreRequisiteModuleModal = ({
  showModal,
  setShowModal,
  nonCompletedModules
}) => {
  return (
    <Modal visible={showModal} setModalOpen={setShowModal}>
      <>
        {' '}
        <T.H3 weight={600} mt={6} d="block" color="darkGray">
          Pre-requisite module(s)
        </T.H3>
        <T.P color="neutralGray" mt={2} d="block">
          To be eligible for this module, you must first complete:{' '}
        </T.P>
        {nonCompletedModules?.map(mod => (
          <T.P d={'block'} weight={700} color="gray">
            -{' '}
            <T.Link
              weight={700}
              onClick={() => {
                setShowModal(false);
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 0);
              }}
              to={MODULE_URL.replace(':id', mod._id)}
              color="gray"
              mt={2}
            >
              {mod.title}
            </T.Link>
          </T.P>
        ))}
      </>
      <Button
        mt={8}
        label="Close"
        type="primary"
        width="200px"
        onClick={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default PreRequisiteModuleModal;
