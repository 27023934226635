import styled, { css } from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const ToggleWrapper = styled.div`
  ${setMargin}
  min-height: 52px;
  display: flex;
  width: ${props => props.width || '100%'};
  cursor: pointer;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.midPrimary};
  font-weight: 700;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    max-width: 500px;
  }

  ${({ outline }) =>
    outline &&
    `
    background: none;
  `}
`;

const commonStyles = css`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ outline, theme }) =>
    outline &&
    `
  padding-left: ${theme.spacings[4]};
  padding-bottom: ${theme.spacings[4]};
  border-radius: 0;
  text-align: left;
  justify-content: flex-start;
  background: none;
`}
`;

export const LeftHalf = styled.div`
  border-radius: 12px 0 0 12px;
  background-color: ${({ theme, selected }) =>
    selected === 'left' && `${theme.colors.primary}`};
  color: ${({ theme, selected }) =>
    selected === 'left' && `${theme.colors.darkGray}`};

  ${({ outline, theme, selected }) =>
    outline &&
    `
    color: ${selected === 'left' && `${theme.colors.primary}`};
    border-bottom: ${
      selected === 'left'
        ? `2px solid ${theme.colors.primary}`
        : `2px solid ${theme.colors.midPrimary}`
    };
  `}

  ${commonStyles}
`;

export const RightHalf = styled.div`
  border-radius: 0 12px 12px 0;
  background-color: ${({ theme, selected }) =>
    selected === 'right' && `${theme.colors.primary}`};
  color: ${({ theme, selected }) =>
    selected === 'right' && `${theme.colors.darkGray}`};
  text-align: center;

  ${({ outline, theme, selected }) =>
    outline &&
    `
    color: ${selected === 'right' && `${theme.colors.primary}`};
    border-bottom: ${
      selected === 'right'
        ? `2px solid ${theme.colors.primary}`
        : `2px solid ${theme.colors.midPrimary}`
    };
  `}

  ${commonStyles}
`;
