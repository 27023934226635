const Arrow = ({ width, height, color, rotate, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    transform={rotate ? `rotate(${rotate})` : ''}
    {...props}
  >
    <path
      fill={color || '#231F20'}
      d="M4 13h12.17l-5.59 5.59L12 20l8-8-8-8-1.41 1.41L16.17 11H4v2Z"
    />
  </svg>
);

export default Arrow;
