import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import {
  PARTICIPANT_SESSION_DETAILS,
  PROGRAMMES
} from '../../../constants/navigationRoutes';
import { addStatusToSessions } from '../../../helpers';
import useSearchParams from '../../../hooks/useSearchParams';
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import Table from '../../common/BoostTable';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import SessionList from '../../common/List/SessionList';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import { TableColumns } from './ParticipantModulesTableColumns';

const ParticipantDashboard = ({
  sessions,
  fetchSessions,
  fetchParticipantSessionsLoading
}) => {
  const { breakpoints } = useTheme();

  const searchParams = useSearchParams();

  const isInitialSignup = searchParams.params.initialSignup === 'true';

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  useEffect(() => {
    fetchSessions({ populated: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sessionsWithStatus = useMemo(() => addStatusToSessions(sessions), [
    sessions
  ]);

  const uniqueModules = useMemo(
    () =>
      sessionsWithStatus
        ?.filter(ses => ses.showInCompleted === false)
        .flatMap(session =>
          session.modules.map(module => ({
            ...module,
            sessionId: session._id
          }))
        )
        .filter(
          (module, index, self) =>
            index === self.findIndex(m => m._id === module._id)
        ),
    [sessionsWithStatus]
  );

  if (fetchParticipantSessionsLoading || !sessions) {
    return <Spin />;
  }

  return (
    <Layout>
      <AccountPageHeader
        title={isInitialSignup ? 'Thank you!' : 'My dashboard'}
        subtitle={
          isInitialSignup
            ? 'Welcome to your Learning Academy account. Here you can see your events, programmes, track progress and access any course materials. '
            : 'Welcome back to your Learning Academy account. Here you can see your events, programmes, track progress and access any course materials.'
        }
      />

      <Row mb={7}>
        <Col w={[4, 12, 12]} dir="column" ai="flex-start">
          <T.H2 weight={700} mb={3}>
            My upcoming events
          </T.H2>
          <SessionList
            dataList={sessionsWithStatus
              ?.filter(
                ses =>
                  ses.showInCompleted === false &&
                  !ses.defaultSessionForCustomModule
              )
              .map(ses => {
                const { _id } = ses;
                return {
                  ...ses,
                  link: PARTICIPANT_SESSION_DETAILS.replace(':id', _id)
                };
              })}
            isParticipant
          />
        </Col>
        <Col w={[4, 12, 12]} dir="column" ai="flex-start">
          <T.H2 weight={700} mt={8}>
            My live modules
          </T.H2>
          <Table
            data={uniqueModules}
            columns={TableColumns(isTablet)}
            stripedRows
          />
          <Button
            label="Browse programmes"
            to={PROGRAMMES}
            type="primary"
            mt={6}
            mr="auto"
            width={'200px'}
          />
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail,
  fetchParticipantSessionsLoading: state.loading.fetchParticipantSessionsLoading
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantDashboard);
