const Question = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill={color || 'currentColor'} />
    <path
      d="M6.125 6.20311s.02812-.64453.65508-1.16133c.37226-.30683.81933-.3957 1.21992-.40117.36582-.00449.69277.05742.88828.15273.33418.16368.98672.56211.98672 1.40977 0 .89199-.56992 1.29629-1.21777 1.7418-.64786.4455-.81348.88242-.81348 1.3832"
      stroke="#fff"
      strokeWidth=".78125"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.84375 11.4375c.34518 0 .625-.2798.625-.625s-.27982-.625-.625-.625-.625.2798-.625.625.27982.625.625.625z"
      fill="#fff"
    />
  </svg>
);

export default Question;
