import styled from 'styled-components';

export const Wrapper = styled.form`
  background: ${({ theme, renderInline }) =>
    !renderInline && theme.colors.lightPrimary};
  padding: ${({ theme, renderInline }) => !renderInline && theme.spacings[6]};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  width: ${({ renderInline }) => (renderInline ? 'auto' : '100%')};
  max-width: ${({ renderInline }) => renderInline && '520px'};
`;
