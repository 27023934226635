import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 100px;
  padding: ${({ theme }) =>
    `${theme.spacings[2]} ${theme.spacings[4]} ${theme.spacings[2]} ${theme.spacings[3]}`};
  border-radius: 50px;
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};
`;
