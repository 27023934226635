const Warning = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2869 3.84359C11.0644 2.55303 12.9356 2.55303 13.7131 3.84359L20.5656 15.2179C21.3687 16.5509 20.4087 18.25 18.8525 18.25H5.14751C3.59131 18.25 2.63132 16.5509 3.43438 15.2179L10.2869 3.84359Z"
      fill="#B70D00"
    />
    <path
      d="M11.526 13.13H13.08L13.22 6.06H11.372L11.526 13.13ZM11.442 16H13.164V14.348H11.442V16Z"
      fill="white"
    />
  </svg>
);

export default Warning;
