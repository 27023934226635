import { meetingPlatforms } from '../../../constants/sessionData';

import { BasicInput, TextArea, Dropdown } from '../../common/Inputs';

const OnlineQs = ({
  online = {},
  extraInfo,
  onInputChange,
  onOnlineChange,
  error,
  extraInfoError,
  disabled
}) => {
  return (
    <>
      <Dropdown
        selected={online.platform}
        m={{ mb: '6' }}
        label="Meeting platform"
        placeholder="Select"
        required
        options={meetingPlatforms}
        name="platform"
        handleChange={onOnlineChange}
        error={error?.platform}
        disabled={disabled}
      />
      <BasicInput
        value={online.link}
        handleChange={onOnlineChange}
        mb="6"
        label="Meeting link"
        name="link"
        required
        error={error?.link}
        disabled={disabled}
      />
      <TextArea
        value={extraInfo}
        handleChange={onInputChange}
        mb={6}
        label="Any extra information"
        error={extraInfoError}
        disabled={disabled}
        name="extraInfo"
      />
    </>
  );
};

export default OnlineQs;
