import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import * as S from './style';

import {
  fetchProgrammeBySlug,
  updateProgramme
} from '../../../actions/programmesActions';
import { topics } from '../../../constants/filtersData';
import { MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW } from '../../../constants/navigationRoutes';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import { editProgrammeValidation as validate } from '../../../validation/schemas';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import FileDropzone from '../../common/FileDropzone';
import * as I from '../../common/Inputs';
import MDEditor from '../../common/MDEditor';
import Modal from '../../common/modal';
import { UPDATE_PROGRAMME_FAIL } from '../../../constants/actionTypes';

const initialFormState = {
  topics: [],
  categories: [],
  isVisible: true,
  title: '',
  titleShort: '',
  subtitle: '',
  description: '',
  whoIsItFor: '',
  findOutMore: '',
  videoLink: '',
  imageLink: {
    fileUrl: '',
    fileName: ''
  },
  includePreEvaluationQuestion: true,
  includePostEvaluationQuestion: true
};

const ProgrammeOverview = ({
  programme,
  loading,
  fetchProgrammeBySlug,
  updateProgramme,
  updateProgrammeLoading,
  fileUploadState,
  error
}) => {
  const { slug } = useParams();

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      submitAttempt.current = true;
      const isValid = validateForm();
      if (isValid) {
        const { current: finalFormState } = finalSubmissionData;

        await updateProgramme(programme._id.toString(), finalFormState);

        setShowModal(true);
      }
    },
    [validateForm, finalSubmissionData, programme?._id, updateProgramme]
  );

  useEffect(() => {
    fetchProgrammeBySlug(slug);
  }, [fetchProgrammeBySlug, slug]);

  useEffect(() => {
    setFormData(programme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programme]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [validateForm]);

  useEffect(() => {
    if (
      fileUploadState.ProgrammeOverview &&
      fileUploadState.ProgrammeOverview.data &&
      fileUploadState.ProgrammeOverview.data?.[0]?.fileUrl
    ) {
      setFormData({ imageLink: fileUploadState.ProgrammeOverview.data?.[0] });
    }
  }, [fileUploadState.ProgrammeOverview, setFormData]);

  if (!programme || loading) {
    return <Spin />;
  }

  const hasHttpError = error.id === UPDATE_PROGRAMME_FAIL;

  return (
    <Layout>
      <Row mb={7}>
        <Col w={[4, 9, 9]}>
          <T.H1>Edit Programme</T.H1>
        </Col>
        <S.Divider full />
        <Col w={[4, 6, 6]}>
          <S.Form>
            <I.Checkbox
              mb={5}
              checked={formState.isVisible}
              handleChange={value => setFormData({ isVisible: value })}
              label={<T.P color="gray">Make this programme visible</T.P>}
              error={validationErrors.isVisible}
            />

            <I.BasicInput
              label="Programme Title"
              required
              placeholder="Type here..."
              mb="5"
              value={formState.title}
              handleChange={title => setFormData({ title })}
              error={validationErrors.title}
            />
            <I.BasicInput
              label="Short title"
              required
              placeholder="Type here..."
              popOver={{
                text:
                  'This is used on the programmes page overview, breadcrumbs and dropdowns across the system'
              }}
              mb="5"
              value={formState.titleShort}
              handleChange={titleShort => setFormData({ titleShort })}
              error={validationErrors.titleShort}
            />
            <I.Dropdown
              m={{
                mb: '5'
              }}
              label="Topic(s)"
              required
              addNew={false}
              search={true}
              options={topics}
              multi
              selected={formState.topics}
              handleChange={topics => setFormData({ topics })}
              error={validationErrors.topics}
            />
            <I.TextArea
              label="Subtitle"
              required
              placeholder="Type here..."
              mb="5"
              rows={5}
              value={formState.subtitle}
              handleChange={subtitle => setFormData({ subtitle })}
              error={validationErrors.subtitle}
            />

            <MDEditor
              color="darkGray"
              label="Description"
              placeholder="Type here..."
              rows="20"
              value={formState.description}
              onChange={description => setFormData({ description })}
              m={{ mb: 5 }}
              error={validationErrors.description}
            />

            <MDEditor
              color="darkGray"
              label="Why this programme?"
              placeholder="Type here..."
              rows="20"
              value={formState.whoIsItFor}
              onChange={whoIsItFor => setFormData({ whoIsItFor })}
              m={{ mb: 5 }}
              error={validationErrors.whoIsItFor}
            />

            <MDEditor
              color="darkGray"
              label="Find out more"
              placeholder="Type here..."
              rows="20"
              value={formState.findOutMore}
              onChange={findOutMore => setFormData({ findOutMore })}
              m={{ mb: 5 }}
              error={validationErrors.findOutMore}
            />

            <I.BasicInput
              label="Video link"
              placeholder="Type here..."
              mb="5"
              value={formState.videoLink}
              handleChange={videoLink => setFormData({ videoLink })}
              error={validationErrors.videoLink}
            />
            <T.P weight={700} mb={2}>
              * Main Image
            </T.P>
            <FileDropzone
              id={'ProgrammeOverview'}
              mb={5}
              clickAnywhereForUpload={false}
              supportedFormatsText="JPEG, PNG"
              tip="Recommended dimensions: 400px x 340px"
              existingFileData={programme?.imageLink}
              validationError={validationErrors.imageLink}
              folderPrefix="images/programmes"
            />
            <>
              <T.P weight={700} mb={2}>
                Analytics
              </T.P>
              <T.P mb={2}>
                Please note that any changes here can affect data integrity
              </T.P>
              <I.Checkbox
                mb={3}
                checked={formState.includePreEvaluationQuestion}
                handleChange={value =>
                  setFormData({ includePreEvaluationQuestion: value })
                }
                label={
                  <T.P color="gray">
                    Include pre-programme evaluation questions
                  </T.P>
                }
                error={validationErrors.includePreEvaluationQuestion}
              />
              <I.Checkbox
                mb={5}
                checked={formState.includePostEvaluationQuestion}
                handleChange={value =>
                  setFormData({ includePostEvaluationQuestion: value })
                }
                label={
                  <T.P color="gray">
                    Include post-module programme evaluation questions
                  </T.P>
                }
                error={validationErrors.includePostEvaluationQuestion}
              />
            </>
            <Row mb={7} inner>
              <Col w={[4, 6, 6]}>
                <Button
                  label="Cancel"
                  type="tertiary"
                  to={MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW.replace(
                    ':slug',
                    slug
                  )}
                />
              </Col>
              <Col w={[4, 6, 6]}>
                <Button
                  label="Update"
                  type="primary"
                  onClick={handleSubmit}
                  loading={updateProgrammeLoading}
                />
              </Col>
            </Row>
          </S.Form>
          <Modal visible={showModal} setModalOpen={setShowModal}>
            {hasHttpError ? (
              <>
                <T.H4W color="darkGray" mb="2" mt="2">
                  Error
                </T.H4W>
                <T.P color="gray" mb="10">
                  {error.msg || 'Something went wrong'}
                </T.P>
              </>
            ) : (
              <>
                <T.H4W color="darkGray" mb="2" mt="2">
                  Programme Updated Successfully
                </T.H4W>
                <T.P color="gray" mb="10">
                  Thank you for editing the programme.
                </T.P>
              </>
            )}
            <Button
              label="Return to programme overview"
              type="primary"
              to={MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW.replace(':slug', slug)}
            />
          </Modal>
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  programme: state.programmes.programme,
  loading: state.loading.fetchProgrammeLoading,
  updateProgrammeLoading: state.loading.updateProgrammeLoading,
  fileUploadState: state.fileUpload,
  error: state.error
});

const mapActionsToProps = {
  fetchProgrammeBySlug,
  updateProgramme
};

export default connect(mapStateToProps, mapActionsToProps)(ProgrammeOverview);
