import React from 'react';

import * as T from '../../common/Typography';

import moment from 'moment';
import {
  INDIVIDUAL_EVENT,
  INDIVIDUAL_PROGRAMME
} from '../../../constants/navigationRoutes';

const getCapacityStatusMessage = spacesLeft => {
  if (spacesLeft > 0) {
    return `${spacesLeft} space${spacesLeft > 1 ? 's' : ''} left...`;
  } else {
    return 'Join waiting list';
  }
};

export const TableColumns = isDesktop => {
  const commonColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_text, row) => {
        const formattedDate = moment(row.date).format('D MMMM YYYY');
        const formattedStartTime = row.startTime
          ? moment(row.startTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()
          : '';
        const formattedEndTime = row.endTime
          ? moment(row.endTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()
          : '';

        return (
          <>
            <T.P color="gray" weight={600}>
              {formattedDate}
            </T.P>
            {!!formattedStartTime && (
              <T.P color="gray" weight={600} small>
                {formattedStartTime}{' '}
                {!!formattedEndTime && `- ${formattedEndTime}`}
              </T.P>
            )}
          </>
        );
      }
    },
    {
      title: 'Event',
      key: 'event',
      dataIndex: 'title',
      render: (title, row) => (
        <T.Link
          color="gray"
          to={INDIVIDUAL_EVENT.replace(':id', row._id.toString())}
        >
          {title || row.modules.map(module => module.title).join(', ')}
        </T.Link>
      )
    }
  ];

  const desktopColumns = [
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'address',
      render: address =>
        address ? (
          <div>
            <T.P color="gray" small weight={600}>
              {address?.addressLine1}
            </T.P>
            <T.P color="gray" weight={600}>
              {address?.postcode}
            </T.P>
          </div>
        ) : (
          <T.P color="gray" weight={600} style={{ minWidth: '12ch' }}>
            Online
          </T.P>
        )
    },
    {
      title: 'Programme(s)',
      key: 'programmes',
      dataIndex: 'modules',
      render: (_text, row) => {
        const uniqueProgrammes = row.modules.reduce((acc, module) => {
          const programme = module.programme;
          if (!acc.some(prog => prog._id === programme._id)) {
            acc.push(programme);
          }
          return acc;
        }, []);

        return (
          <div>
            {uniqueProgrammes.map(prog => (
              <>
                <T.Link
                  color="gray"
                  key={prog._id}
                  to={INDIVIDUAL_PROGRAMME.replace(':slug', prog.slug)}
                >
                  {prog.titleShort}
                </T.Link>
                {'  '}
              </>
            ))}
          </div>
        );
      }
    },
    {
      title: 'Capacity',
      key: 'capacity',
      dataIndex: 'spacesLeft',
      render: spacesLeft => (
        <T.P color="gray" weight={600} style={{ minWidth: 140 }}>
          {getCapacityStatusMessage(spacesLeft)}
        </T.P>
      )
    }
  ];

  return !isDesktop ? commonColumns : [...commonColumns, ...desktopColumns];
};
