import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.div`
  ${setMargin}
  padding: ${({ theme }) => `${theme.spacings[4]} 0px`};
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const SearchContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[8]}`};
  background-color: ${({ theme, searchBarTransparentBg }) =>
    searchBarTransparentBg ? 'transparent' : theme.colors.lightGray};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: ${({ theme, fullWidth }) =>
      fullWidth && `${theme.spacings[5]}  0`};
  }

  .ant-input-wrapper {
    display: flex;
    gap: ${({ theme }) => theme.spacings[4]};

    flex-direction: column;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      flex-direction: row;
    }

    .ant-input {
      display: block;
      padding: ${({ theme }) => `0 ${theme.spacings[7]} !important`};
      border-radius: 50px !important;

      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.mainPrimary};
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      display: block;
      background: transparent;

      width: 100%;

      @media ${({ theme }) => theme.breakpoints.tablet} {
        width: fit-content;
      }
    }

    .ant-input-search-button {
      padding: ${({ theme }) => `0 ${theme.spacings[8]} !important`};
      border-radius: 50px !important;
      background-color: ${({ theme }) => theme.colors.darkGray};
      color: ${({ theme }) => theme.colors.mainPrimary};
      border-color: ${({ theme }) => theme.colors.darkGray};

      width: 100%;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
  }

  gap: ${({ theme }) => theme.spacings[5]};
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[0]}`};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: ${({ theme, fullWidth }) =>
      !fullWidth
        ? `${theme.spacings[5]} ${theme.spacings[7]}`
        : `${theme.spacings[5]} 0`};
  }
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  gap: ${({ theme }) => theme.spacings[5]};
  flex-direction: column;
  align-items: center;
  justify-content: ${({ fullWidth }) => (fullWidth ? 'flex-start' : 'center')};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
  }

  & > * {
    flex: 1;
    min-width: 0;
  }

  .ant-select,
  .ant-select-selection,
  .ant-select-selection--multiple {
    border-radius: ${({ theme }) => theme.borders.radius} !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 48px;
    cursor: pointer;
    font-size: calc(var(--font-size-adjustment) * 16px);
    border-color: ${({ theme, open, color, focus, error }) => {
      if (error) return theme.colors.error;
      return open || focus
        ? theme.colors.mainPrimary
        : theme.colors[color] || theme.colors.midPrimary;
    }} !important;
  }

  .ant-select-selection-selected-value {
    margin: 0 auto !important;
    padding-top: 10px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 0;
    padding: ${({ theme }) => theme.spacings[3]} !important;
    overflow-x: hidden;
    background: ${({ theme }) => theme.colors.gray2};
    height: 100% !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: calc(var(--font-size-adjustment) * 16px);
    color: ${({ theme }) => theme.colors.darkGray};
    width: 1px;
  }

  .ant-select-selection.ant-select-selection--single {
    overflow: auto;
  }

  .ant-select-selection.ant-select-selection--single::-webkit-scrollbar {
    display: none;
  }
  .ant-select-selection__rendered {
    height: fit-content !important;
  }
  .ant-select-selection-selected-value {
    white-space: normal;
  }

  .ant-select-selection__placeholder {
    margin-top: auto;
  }

  .ant-select-arrow {
    top: 50%;
  }
`;

export const ClearFiltersButton = styled.button`
  background: transparent;
  display: block;
  border: none;
  text-decoration: underline;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
`;
