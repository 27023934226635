const BoostStar = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width={width || '164'}
    height={height || '164'}
    viewBox="0 0 164 164"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }}
  >
    <path
      fill={color || '#fec30d'}
      d="M79.5 5.5c.996-.086 1.83.248 2.5 1A3129.636 3129.636 0 0 1 105.5 54a1136.193 1136.193 0 0 0 54 8c.457.414.791.914 1 1.5a1076.278 1076.278 0 0 0-39 39 764.219 764.219 0 0 1 9.5 54 6710.47 6710.47 0 0 0-49-25 1758.135 1758.135 0 0 1-49.5 25 5074.163 5074.163 0 0 0 9-53A2241.736 2241.736 0 0 0 1.5 64a6309.174 6309.174 0 0 0 55-9.5 1616.58 1616.58 0 0 0 23-49Z"
    />
  </svg>
);

export default BoostStar;
