import React from 'react';

import * as T from '../Typography';

import moment from 'moment';

import { participantStatusMapping } from '../../../constants/sessionData';

export const TableColumns = (isTablet, baseURL) => {
  const commonColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_text, row) => {
        const formattedDate = moment(row.date).format('D MMMM YYYY');
        const formattedStartTime = row.startTime
          ? moment(row.startTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()
          : '';
        const formattedEndTime = row.endTime
          ? moment(row.endTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()
          : '';

        return (
          <>
            <T.P color="gray" weight={600}>
              {formattedDate}
            </T.P>
            {!!formattedStartTime && (
              <T.P color="gray" weight={600} small>
                {formattedStartTime}{' '}
                {!!formattedEndTime && `- ${formattedEndTime}`}
              </T.P>
            )}
          </>
        );
      }
    },
    {
      title: 'Event',
      key: 'event',
      dataIndex: 'title',
      render: (title, row) => (
        <T.Link color="gray" to={baseURL.replace(':id', row._id.toString())}>
          {title || row.modules.map(module => module.title).join(', ')}
        </T.Link>
      ),
      width: '50%'
    }
  ];

  const desktopColumns = [
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'address',
      render: address =>
        address ? (
          <div>
            <T.P color="gray" small weight={600}>
              {address?.addressLine1}
            </T.P>
            <T.P color="gray" weight={600}>
              {address?.postcode}
            </T.P>
          </div>
        ) : (
          <T.P color="gray" weight={600} style={{ minWidth: '12ch' }}>
            Online
          </T.P>
        )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: status => (
        <T.P color="gray" small weight={600}>
          {participantStatusMapping[status]}
        </T.P>
      )
    }
  ];

  return !isTablet ? commonColumns : [...commonColumns, ...desktopColumns];
};
