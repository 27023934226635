import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

export const Wrapper = styled.nav`
  ${setMargin}
`;

export const BreadcrumbList = styled.ol`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
`;

export const BreadcrumbItem = styled.li`
  &:not(:first-child)::before {
    content: '/';
    padding: 0 0.5rem;
  }

  color: ${({ theme, dark }) =>
    dark ? theme.colors.white : theme.colors.darkGray};

  a {
    font-weight: bold;
    font-size: calc(var(--font-size-adjustment) * 18px) !important;
    text-decoration: underline;

    color: inherit !important;

    ${({ active, theme, dark }) =>
      active &&
      `
      color: ${dark ? theme.colors.offWhite : theme.colors.gray}
      `};
  }
`;
