import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
  FILE_LOADING,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAIL,
  RESET_FILE_UPLOAD_STATE
} from '../constants/actionTypes';

const getSignedUrlForUpload = async ({ fileName, fileType, folderPrefix }) => {
  try {
    const res = await axios.post('/api/file-upload/get-signed-url', {
      fileName,
      fileType,
      folderPrefix
    });
    const { signedUrl } = res.data;
    return { signedUrl, fileName };
  } catch (error) {
    throw error;
  }
};

export const uploadFileToS3 = async (signedUrl, file) => {
  try {
    await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    return signedUrl.split('?')[0];
  } catch (error) {
    throw error;
  }
};

export const uploadFiles = (
  files,
  folderPrefix,
  fileUploaderId
) => async dispatch => {
  try {
    dispatch({
      type: FILE_LOADING,
      payload: { id: fileUploaderId, isLoading: true }
    });

    const uploadPromises = files.map(async file => {
      const uniqueFileName = `${uuidv4().slice(0, 8)}_${file.name}`;

      const { signedUrl, fileName } = await getSignedUrlForUpload({
        fileName: uniqueFileName,
        fileType: file.type,
        folderPrefix
      });

      const fileUrl = await uploadFileToS3(signedUrl, file);
      return { fileName, fileUrl };
    });

    const uploadedFiles = await Promise.all(uploadPromises);

    dispatch({
      type: FILE_UPLOAD_SUCCESS,
      payload: { id: fileUploaderId, data: uploadedFiles, error: null }
    });

    return uploadedFiles;
  } catch (error) {
    dispatch({
      type: FILE_UPLOAD_FAIL,
      payload: {
        id: fileUploaderId,
        data: null,
        error: error?.response?.data?.error || error.message
      }
    });
  } finally {
    dispatch({
      type: FILE_LOADING,
      payload: { id: fileUploaderId, isLoading: false }
    });
  }
};

export const removeFile = (fileUrl, fileUploaderId) => async dispatch => {
  try {
    dispatch({
      type: FILE_LOADING,
      payload: { id: fileUploaderId, isLoading: true }
    });

    dispatch({
      type: FILE_DELETE_SUCCESS,
      payload: { fileUrl, id: fileUploaderId }
    });
  } catch (error) {
    dispatch({
      type: FILE_DELETE_FAIL,
      payload: { error: error.message, id: fileUploaderId }
    });
  } finally {
    dispatch({
      type: FILE_LOADING,
      payload: { id: fileUploaderId, isLoading: false }
    });
  }
};

export const resetFileUploadState = fileUploaderId => dispatch => {
  dispatch({ type: RESET_FILE_UPLOAD_STATE, payload: { id: fileUploaderId } });
};
