import DoubleQuotesImg from '../../../assets/boost/double-quotes.png';
import * as T from '../Typography';
import { Wrapper, DoubleQuotes } from './style';

const QuoteBox = ({ quoteString }) => {
  return (
    <Wrapper>
      <T.P weight="700" smallMedium color="gray" mb={4}>
        {quoteString}
      </T.P>
      <DoubleQuotes src={DoubleQuotesImg} alt="double quotes" />
    </Wrapper>
  );
};

export default QuoteBox;
