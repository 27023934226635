const Cross = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 5.25l.9375 15c.04453.8667.675 1.5 1.5 1.5h8.625c.8283 0 1.447-.6333 1.5-1.5l.9375-15"
      stroke={color || 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3.75 5.25h16.5-16.5z" fill={color || 'currentColor'} />
    <path
      d="M3.75 5.25h16.5"
      stroke={color || 'currentColor'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.375 8.25001L15 18.75M9 5.25V3.375a1.12187 1.12187 0 01.08475-.43103A1.12163 1.12163 0 0110.125 2.25h3.75a1.12159 1.12159 0 01.431.08476c.1367.05638.2609.13923.3655.24378.1045.10456.1874.22875.2437.36543A1.1221 1.1221 0 0115 3.375V5.25H9zm3 3.00001V18.75 8.25001zm-3.375 0L9 18.75 8.625 8.25001z"
      stroke={color || 'currentColor'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Cross;
