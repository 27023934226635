import { Col, Row } from '../../common/Grid';
import LoginForm from '../../common/LoginForm';
import Layout from './../../Layouts';
import * as T from './../../common/Typography';
import { LoginGuideCard } from './style';

const Login = ({ header }) => {
  return (
    <Layout>
      <T.H1 ml={2}>{header || 'Log in to your account'}</T.H1>
      <Row mt={7}>
        <Col w={[4, 12, 7]}>
          <LoginForm />
        </Col>
        <Col w={[4, 12, 5]}>
          <LoginGuideCard>
            <T.P mb={2}>
              To access the Boost learning academy you will need to login. If
              you do not have an account please press the sign up link. Signing
              up will allow you to register for training courses and events.
            </T.P>
          </LoginGuideCard>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
