import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';

const CardLink = ({ title, to, color, background, borderRadius, maxWidth }) => {
  return (
    <S.Card
      background={background}
      borderRadius={borderRadius}
      maxWidth={maxWidth}
      mb={6}
      mr={6}
      to={to}
    >
      <T.P smallMedium to={to} color={color || 'darkGray'}>
        {title}
      </T.P>
      <Icon icon="arrow" color={color || 'darkGray'}></Icon>
    </S.Card>
  );
};

export default CardLink;
