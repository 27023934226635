import styled from 'styled-components';

export const FooterDiv = styled.div`
  background: ${({ theme, colorChange }) =>
    colorChange ? theme.colors.primary : theme.colors.darkGray};
  display: flex;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  margin-right: ${({ theme: { layoutConstants } }) =>
    `${layoutConstants.rightMarginM}`};
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { spacings } }) => `${spacings[4]} ${spacings[4]}`};
  border-radius: 12px 12px 0 0;
`;

export const StepProgress = styled.h3`
  font-weight: 300;
  font-size: calc(var(--font-size-adjustment) * 1.5rem);
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StepTitle = styled.span``;

export const ProgressWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  width: 60%;
  flex-direction: column;

  .ant-progress-inner {
    background-color: ${({ theme }) => theme.colors.gray};
  }

  .ant-progress-text {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;
