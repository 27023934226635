const Glass2 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '100'}
    height={height || '101'}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M50.8133 66.707L48.0266 63.874L63.291 48.8348L58.6843 7.17233H48.1896V3.19861H62.2393L67.4513 50.3101L50.8133 66.707Z"
      fill={color || 'white'}
    />
    <path
      d="M52.0217 97.658H48.048V66.1211L32 50.3101L37.2121 3.19861H51.261V7.17233H40.7672L36.1603 48.8348L52.0217 64.4599V97.658Z"
      fill={color || 'white'}
    />
    <path
      d="M64.227 94.7191H36.2175V98.693H64.227V94.7191Z"
      fill={color || 'white'}
    />
    <path
      d="M62.6656 23.6537H35.374V27.6276H62.6656V23.6537Z"
      fill={color || 'white'}
    />
  </svg>
);
export default Glass2;
