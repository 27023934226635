import axios from 'axios';

import {
  ADD_TRAINER_TO_GROUP_SUCCESS,
  CHECK_UNIQUE_EMAIL_ERROR,
  CHECK_UNIQUE_EMAIL_UNIQUE,
  CHECK_UNIQUE_EMAIL_UNUNIQUE,
  LOADING_FALSE,
  LOADING_TRUE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  PARTICIPANT_SIGNUP_FAIL,
  PARTICIPANT_SIGNUP_SUCCESS,
  RESET_ROLE_SUCCESS,
  SELECT_ROLE_FAIL,
  SELECT_ROLE_SUCCESS,
  SET_LAST_ACTIVE_FAIL,
  SET_LAST_ACTIVE_SUCCESS,
  SIGNUP_FAIL,
  UPDATE_MY_ACCOUNT_FAIL,
  UPDATE_MY_ACCOUNT_SUCCESS,
  USER_AUTHENTICATED,
  USER_UNAUTHENTICATED
} from '../constants/actionTypes';
import * as R from './../constants/navigationRoutes';
import { returnErrors } from './errorAction';

export const setLastActive = () => async dispatch => {
  try {
    await axios.post('/api/users/last-active');
    dispatch({
      type: SET_LAST_ACTIVE_SUCCESS
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    dispatch({
      type: SET_LAST_ACTIVE_FAIL,
      payload: error
    });
  }
};

// Login User
export const loginUser = ({
  email,
  password,
  loginRole,
  signupAsMeccTrainer
}) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'loginLoading'
  });
  try {
    const res = await axios.post('/api/login', {
      email,
      password,
      loginRole,
      signupAsMeccTrainer
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { ...res.data }
    });

    dispatch({
      type: LOADING_FALSE,
      payload: 'loginLoading'
    });
    return res?.data;
  } catch (err) {
    dispatch(
      returnErrors(
        err?.response?.data?.error || err.message,
        err?.response?.status,
        'LOGIN_FAIL'
      )
    );
    dispatch({
      type: LOADING_FALSE,
      payload: 'loginLoading'
    });
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

export const signUpParticipant = participantData => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'signupLoading'
    });
    const res = await axios.post('/api/participant', participantData);
    const { data } = res;
    dispatch({
      type: PARTICIPANT_SIGNUP_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    dispatch({
      type: PARTICIPANT_SIGNUP_FAIL,
      payload: error
    });
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'signupLoading'
    });
  }
};

// check if user is logged in or not and get the user info
export const checkAuth = () => async dispatch => {
  try {
    const res = await axios.get(`/api/users/auth`);
    const { data } = res;

    dispatch({
      type: USER_AUTHENTICATED,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: USER_UNAUTHENTICATED
    });
  }
};

export const logout = history => async dispatch => {
  try {
    await axios.post('/api/logout');
    dispatch({
      type: LOGOUT
    });
    history.push(R.LOGIN_URL);
  } catch (error) {
    // eslint-disable-next-line no-console
  }
};
export const updateMyAccount = data => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'updateMyAccount'
    });
    await axios.patch('/api/my-account', data);

    dispatch({
      type: UPDATE_MY_ACCOUNT_SUCCESS,
      payload: data
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateMyAccount'
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MY_ACCOUNT_FAIL,
      payload: error
    });
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateMyAccount'
    });
  }
};

export const selectRole = role => async dispatch => {
  try {
    const { data } = await axios.post('/api/select-role', { role });

    if (role === null) {
      return dispatch({
        type: RESET_ROLE_SUCCESS,
        payload: data
      });
    }
    dispatch({
      type: SELECT_ROLE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: SELECT_ROLE_FAIL,
      payload: error
    });
  }
};

export const signupUserViaInvitationLink = payload => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'signupViaInvitationLoading'
    });
    const res = await axios.post('/api/users/invitation', payload);
    const { data } = res;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data
    });
    return { data };
  } catch (error) {
    dispatch({
      type: SIGNUP_FAIL,
      payload: error
    });
    return { error };
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'signupViaInvitationLoading'
    });
  }
};
