import * as S from './style';
import * as T from '../Typography';

const CatHeader = ({
  title,
  subtitle,
  bgColor,
  textColor = 'white',
  ...props
}) => {
  return (
    <S.Wrapper bgColor={bgColor} mb={5} {...props}>
      {/* desktop view */}
      <S.HideInTablet>
        <S.TitleWrapper>
          <T.H2W color={textColor} mb="4">
            {title}
          </T.H2W>
        </S.TitleWrapper>
        <T.P color={textColor}>{subtitle}</T.P>
      </S.HideInTablet>

      {/* mobile view */}
      <S.HideInDesktop>
        <T.H4W color={textColor} mb="2">
          {title}
        </T.H4W>
        <T.P small color={textColor}>
          {subtitle}
        </T.P>
      </S.HideInDesktop>
    </S.Wrapper>
  );
};

export default CatHeader;
