import * as S from './style';
import * as T from '../Typography';
import MaxWidthContainer from '../MaxWidthContainer';
import Breadcrumb from '../Breadcrumb';

const typeLabels = {
  module: 'Module',
  event: 'Event'
};

const WebsiteHeader = ({
  title,
  subtitle,
  variant = 'primary',
  type,
  breadcrumbItems,
  ...props
}) => {
  const renderBreadcrumb = () => {
    if (breadcrumbItems) {
      return (
        <Breadcrumb items={breadcrumbItems} dark={variant === 'primary'} />
      );
    }
  };
  return (
    <S.Wrapper subtitle={subtitle} variant={variant} {...props}>
      {/* desktop view */}
      <MaxWidthContainer
        bgColor={variant === 'primary' ? 'darkGray' : 'mainPrimary'}
      >
        <S.HideInTablet>
          {renderBreadcrumb()}
          <T.H1W
            color={variant === 'primary' ? 'white' : 'darkGray'}
            mt={breadcrumbItems?.length ? '6' : '0'}
            mb={subtitle && '6'}
          >
            {title}
          </T.H1W>
          <T.H2W color={variant === 'primary' ? 'mainPrimary' : 'darkGray'}>
            {subtitle}
          </T.H2W>
          {type && (
            <T.H2W mt={4} color="mainPrimary">
              {typeLabels[type]}
            </T.H2W>
          )}
        </S.HideInTablet>
      </MaxWidthContainer>

      {/* mobile view */}
      <S.HideInDesktop>
        {renderBreadcrumb()}
        <T.H3W
          color={variant === 'primary' ? 'white' : 'darkGray'}
          mb={subtitle && '2'}
          mt={breadcrumbItems?.length ? '6' : '0'}
        >
          {title}
        </T.H3W>
        <T.H4W color={variant === 'primary' ? 'mainPrimary' : 'darkGray'}>
          {subtitle}
        </T.H4W>
        {type && (
          <T.H4W mt={2} color="mainPrimary">
            {typeLabels[type]}
          </T.H4W>
        )}
      </S.HideInDesktop>
    </S.Wrapper>
  );
};

export default WebsiteHeader;
