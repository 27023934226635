import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';

const InfoCard = ({ title, text, w, ...props }) => {
  return (
    <S.Wrapper w={w} {...props}>
      <Icon icon="info" width="24px" height="24px" color="darkGray" mt="2" />
      <S.TextContent>
        {title && (
          <T.H3 color="darkGray" mb="1">
            {title}
          </T.H3>
        )}
        {text && <T.P color="darkGray">{text}</T.P>}
      </S.TextContent>
    </S.Wrapper>
  );
};

export default InfoCard;
