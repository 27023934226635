const LevelBars = ({ width, height, color, ...props }) => {
  const fillBar = (level = 0, number) => {
    return level >= number ? color || 'currentColor' : 'none';
  };

  return (
    <svg
      width={width || 28}
      height={height || 25}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="13.3389"
        width="7"
        height="11"
        fill={fillBar(props.level, 1)}
        stroke="#231F20"
      />
      <rect
        x="10.5"
        y="7.33887"
        width="7"
        height="17"
        stroke="#231F20"
        fill={fillBar(props.level, 2)}
      />
      <rect
        x="20.5"
        y="1.33887"
        width="7"
        height="23"
        stroke="#231F20"
        fill={fillBar(props.level, 3)}
      />
    </svg>
  );
};

export default LevelBars;
