import { Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { confirmRegistration } from '../../../actions/confirmRegistration';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { fetchModule } from '../../../actions/modulesActions';
import Layout from '../../Layouts';

import { checkIfSessionIsFinished } from '../../../helpers';

import {
  INDIVIDUAL_PROGRAMME,
  MODULE_STEPS_URL,
  PROGRAMME_CATALOGUE,
  SURVEY_QUESTIONS_URL
} from './../../../constants/navigationRoutes';
import useSearchParams from './../../../hooks/useSearchParams';

import WebsiteHeader from '../../common/WebsiteHeader';

import { surveyTypesNew, userRoles } from '../../../constants';

import { fetchParticipantResponses } from '../../../actions/participantActions';
import EventsTableSection from './EventsTableSection';
import ModuleContent from './ModuleContent';
import PreRequisiteModuleModal from './PreRequisiteModuleModal';
import RegisterSection from './RegisterSection';

const Module = ({
  fetchModuleLoading,
  fetchModule,
  module,
  userId,
  role,
  confirmRegistration,
  loading,
  fetchParticipantSessions,
  sessions,
  participantResponses,
  fetchParticipantResponses,
  participantResponsesLoading,
  fetchParticipantSessionsLoading
}) => {
  const searchParams = useSearchParams();
  const history = useHistory();
  const { id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [nonCompletedModules, setNonCompletedModules] = useState([]);

  const prepareSearchParams = useCallback(() => {
    searchParams.set('loginRole', userRoles.participant);
    searchParams.set('moduleId', id);

    if (!userId) {
      searchParams.set('registerAfterAuth', true);
    }
  }, [id, searchParams, userId]);

  const completedPreCourseQuestions = useMemo(
    () =>
      participantResponses
        ?.filter(res => res.surveyType === surveyTypesNew.PRE)
        ?.some(res => {
          const responseProgrammes = res.programmes?.map(String);
          return responseProgrammes.includes(
            module?.programme?._id?.toString()
          );
        }),
    [participantResponses, module]
  );

  const nonLiveSessionWithNoSession = useMemo(
    () => !module.isLive && !module?.sessions?.[0]?._id,
    [module]
  );

  const isAlreadyRegistered = useMemo(
    () =>
      !!sessions?.find(_session => _session._id === module?.sessions?.[0]?._id),
    [sessions, module]
  );

  const isRegistrationDisabled = useMemo(
    () =>
      nonLiveSessionWithNoSession ||
      loading ||
      participantResponsesLoading ||
      fetchParticipantSessionsLoading ||
      !module ||
      fetchModuleLoading ||
      !userId ||
      role !== userRoles.participant ||
      isAlreadyRegistered,
    [
      nonLiveSessionWithNoSession,
      loading,
      participantResponsesLoading,
      fetchParticipantSessionsLoading,
      module,
      fetchModuleLoading,
      userId,
      role,
      isAlreadyRegistered
    ]
  );

  const onSubmit = useCallback(async () => {
    if (isRegistrationDisabled) {
      return;
    }

    let notCompletedModules = [];

    if (module.prerequisiteModules?.length > 0) {
      if (sessions?.length === 0) {
        notCompletedModules = module.prerequisiteModules;
        setNonCompletedModules(notCompletedModules);
      } else {
        // This checks for any related sessions with the prerequisiteModules
        let filteredSessions = sessions.filter(session => {
          // Filter the session's modules array to only include modules that are also in the prerequisiteModules array
          const matchingModules = session.modules.filter(sessionModule => {
            return module.prerequisiteModules.some(prerequisiteModule => {
              return prerequisiteModule._id.toString() === sessionModule;
            });
          });
          // If there are any matching modules, include the session in the filteredSessions array
          if (matchingModules.length > 0) {
            return { ...session, modules: matchingModules };
          }
          return null;
        });

        // If none of the sessions are related to the prerequisiteModules, then all of them are not completed
        if (!filteredSessions.length) {
          notCompletedModules = module.prerequisiteModules;
          setNonCompletedModules(notCompletedModules);
        } else {
          const notCompletedSessions = filteredSessions?.filter(ses => {
            const hasFinished = checkIfSessionIsFinished(ses);
            const completedPostSurvey = ses?.completedSurvey?.find(
              survey => survey.surveyType === surveyTypesNew.POST
            );
            return !(ses.completedAt || hasFinished || completedPostSurvey);
          });
          notCompletedModules = notCompletedSessions
            .reduce((acc, session) => {
              // Filter the custom modules that exist in preModules for this session
              const _matchingModules = session.modules.filter(sessionModule =>
                module.prerequisiteModules.some(
                  preModule => preModule._id === sessionModule._id
                )
              );

              // Add the matching custom modules to the accumulator array
              return acc.concat(_matchingModules);
            }, [])
            .reduce((acc, current) => {
              // remove duplicate values
              const existingObject = acc.find(obj => obj.id === current.id);
              if (!existingObject) {
                return [...acc, current];
              }
              return acc;
            }, []);

          setNonCompletedModules(notCompletedModules);
        }
      }
    }

    if (notCompletedModules?.length > 0) {
      setShowModal(true);
    } else {
      await confirmRegistration({
        userId,
        sessionId: module?.sessions?.[0]?._id
      });

      if (completedPreCourseQuestions) {
        history.push({
          pathname: MODULE_STEPS_URL.replace(':id', id),
          state: {
            justRegistered: true
          }
        });
      } else {
        history.push({
          pathname: SURVEY_QUESTIONS_URL.replace(
            ':id',
            module?.sessions?.[0]?.shortId
          )
            .replace(':type', surveyTypesNew.PRE)
            .replace(':step', 0),
          state: { fromRegistration: true },
          search: history.location.search
        });
      }
    }
  }, [
    isRegistrationDisabled,
    module,
    sessions,
    confirmRegistration,
    userId,
    completedPreCourseQuestions,
    history,
    id
  ]);

  useEffect(() => {
    fetchModule({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = useCallback(async () => {
    if (userId && role === userRoles.participant) {
      await fetchParticipantResponses();
      await fetchParticipantSessions();
    }
  }, [userId, role, fetchParticipantResponses, fetchParticipantSessions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    prepareSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const registerAfterAuthFlow =
      searchParams.params.registerAfterAuth === 'true' &&
      (searchParams.params.initialSignup === 'true' ||
        searchParams.params.participantLogin === 'true');

    if (registerAfterAuthFlow && !isRegistrationDisabled) {
      // To make sure states are persisted before registration
      setTimeout(onSubmit, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    role,
    searchParams.params.initialSignup,
    searchParams.params.registerAfterAuth,
    searchParams.params.participantLogin,
    userId,
    isRegistrationDisabled
  ]);

  module?.sessions?.forEach(session => {
    session.isCompleted = checkIfSessionIsFinished(session);
  });

  const upcomingSessions = useMemo(
    () =>
      module?.sessions?.filter(
        session =>
          !session.defaultSessionForCustomModule && !session.isCompleted
      ) || [],
    [module]
  );

  const breadcrumbItems = useMemo(
    () => [
      {
        name: module?.programme?.titleShort,
        link: INDIVIDUAL_PROGRAMME.replace(':slug', module?.programme?.slug)
      },
      {
        name: module?.category?.title,
        link: `${PROGRAMME_CATALOGUE.replace(
          ':slug',
          module?.programme?.slug
        )}?category=${module?.category?.slug}`
      }
    ],
    [module]
  );

  if (fetchModuleLoading || !module) {
    return <Spin />;
  }

  return (
    <Layout layout="website">
      <WebsiteHeader
        title={module.title}
        type="module"
        breadcrumbItems={breadcrumbItems}
      />
      <ModuleContent module={module} />

      {!module.isLive && (
        <RegisterSection
          userId={userId}
          role={role}
          loading={loading}
          onSubmit={onSubmit}
          searchParams={searchParams}
          isAlreadyRegistered={isAlreadyRegistered}
          nonLiveSessionWithNoSession={nonLiveSessionWithNoSession}
        />
      )}

      {module.isLive && (
        <EventsTableSection
          module={module}
          upcomingSessions={upcomingSessions}
        />
      )}
      <PreRequisiteModuleModal
        showModal={showModal}
        setShowModal={setShowModal}
        nonCompletedModules={nonCompletedModules}
      />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    httpError: state.error,
    loading: state.loading.confirmRegistrationLoading,
    fetchModuleLoading: state.loading.fetchModuleLoading,
    module: state.modules.module,
    userId: state.auth.userId,
    role: state.auth.role,
    sessions: state.sessions?.participantSessionsByEmail,
    participantResponses: state.participantResponses?.participantResponses,
    participantResponsesLoading: state.loading.participantResponsesLoading,
    fetchParticipantSessionsLoading: state.loading.fetchParticipantSessions
  };
};

export default connect(mapStateToProps, {
  fetchModule,
  confirmRegistration,
  fetchParticipantSessions,
  fetchParticipantResponses
})(Module);
