import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  password,
  name,
  arrayOfString,
  organisationOther,
  requiredText,
  checkbox
} = fields;

const validate = data => {
  const trainerSchema = createSchema({
    email,
    name,
    password: data.allowWeakPassword ? requiredText : password,
    localAuthorities: arrayOfString,
    organisationOther,
    jobRole: requiredText,
    phone: requiredText,
    newsletterSubscription: checkbox
  });

  const participantSchema = createSchema({
    localAuthorities: arrayOfString
  });

  if (!data.participantUpgrade) {
    return _validate(trainerSchema, data);
  } else {
    return _validate(participantSchema, data);
  }
};

export default validate;
