import { fields, createSchema, validate as _validate } from '..';

const { name, requiredText, email } = fields;

const getInvolved = createSchema({
  jobTitle: requiredText,
  name: name,
  message: requiredText,
  email
});

const validate = data => _validate(getInvolved, data);

export default validate;
