import React from 'react';
import moment from 'moment';

import * as T from '../../common/Typography';
import Icon from '../../common/Icon';
import * as R from '../../../constants/navigationRoutes';
import * as S from './style';

export const TableColumns = ({
  isTablet,
  confirmArchive,
  confirmUnarchive,
  archiveMode,
  slug
}) => {
  const commonColumns = [
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'title',
      render: (title, row) => <T.P color="darkGray">{title || 'N/A'}</T.P>,
      position: 1
    },
    {
      key: 'action',
      render: (_, record) =>
        !archiveMode ? (
          <S.ActionsWrapper>
            <S.ActionLink
              to={R.MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_EDIT.replace(
                ':slug',
                slug
              ).replace(':categoryId', record._id)}
            >
              <Icon
                icon="edit"
                mr="1"
                mb="1"
                weight={600}
                color="darkGray"
                width="24px"
                height="24px"
              />
              <T.P color="darkGray" weight={600} mr="4">
                Edit
              </T.P>
            </S.ActionLink>
            <S.ActionButton
              onClick={() => confirmArchive({ categoryId: record._id })}
            >
              <Icon
                mr="1"
                icon="archive"
                color="darkGray"
                width="24px"
                height="24px"
              />
              <T.P color="darkGray" weight={600}>
                Archive
              </T.P>
            </S.ActionButton>
          </S.ActionsWrapper>
        ) : (
          <>
            <S.ActionButton
              onClick={() => confirmUnarchive({ categoryId: record._id })}
            >
              <Icon
                icon="unarchive"
                mr="2"
                color="darkGray"
                width="24px"
                height="24px"
              />
              <T.P style={{ width: '100px' }} color="darkGray">
                Unarchive
              </T.P>
            </S.ActionButton>
          </>
        ),
      position: 4
    }
  ];

  const nonMobileColumns = [
    {
      title: 'Created',
      dataIndex: 'date',
      key: 'date',
      render: (_text, row) => {
        const formattedDate = moment(row.date).format('DD/MM/YY');
        return (
          <>
            <T.P style={{ width: '100px' }} color="darkGray">
              {formattedDate}
            </T.P>
          </>
        );
      },
      sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
      position: 2
    },
    {
      title: 'Number of modules',
      key: 'modulesCount',
      dataIndex: 'modulesCount',
      render: (count, row) => (
        <T.P style={{ width: '100px' }} color="darkGray">
          {count || '0'}
        </T.P>
      ),
      sorter: (a, b) => a.modulesCount - b.modulesCount,
      position: 3
    }
  ];
  const columns = !isTablet
    ? commonColumns
    : [...commonColumns, ...nonMobileColumns];

  const sortedColumns = columns.sort((a, b) => a.position - b.position);

  return sortedColumns;
};
