import { Link } from 'react-router-dom';
import styled from 'styled-components';
import setMargin from '../../../helpers/setMargin';

const cardBorderRadiusStyles = [
  '12px 12px 12px 0px',
  '12px 12px 0px 12px',
  '0px 12px 12px 12px',
  '12px 0px 12px 12px'
];

export const Wrapper = styled.div`
  ${setMargin}
`;

export const Divider = styled.hr`
  width: ${({ full }) => (full ? '100%' : '70%')};
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainSecondary};
  height: 1px;
  margin-top: ${({ theme }) => theme.spacings[3]};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  margin-right: auto;
`;

export const SectionCardsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[6]};
`;

export const SectionCard = styled(Link)`
  min-height: 120px;
  width: 210px;
  background-color: ${({ theme, bg }) => theme.colors[bg] || bg};
  color: ${({ theme, color }) => theme.colors[color] || color};
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[4]}`};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
  cursor: pointer;

  border-radius: ${({ order }) =>
    cardBorderRadiusStyles[((order || 1) - 1) % 4]};

  &::after {
    content: '➔';
    position: absolute;
    top: 19px;
    right: 30px;
    color: inherit;
    font-size: 20px; /* Adjust size as needed */
  }

  &:hover {
    background-color: ${({ theme, bg }) => theme.colors[bg] || bg};
    color: ${({ theme, color }) => theme.colors[color] || color};
  }
`;
