const CircleTick = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.00011C0 12.4188 3.58206 16 8.00011 16 12.4179 16 16 12.4188 16 8.00011 16 3.58206 12.4179 0 8.00011 0 3.58206 0 0 3.58206 0 8.00011zm10.4537-3.11538c.1619-.2042.4574-.23899.6616-.07725.2033.16105.2381.45651.0772.66071L7.4738 10.1742c-.17212.2203-.49875.2399-.6982.0441L4.84701 8.32651c-.18545-.18161-.18907-.48-.00655-.66522.18161-.18545.48-.18839.66545-.00655l1.55475 1.52471 3.39304-4.29472z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default CircleTick;
