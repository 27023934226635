import styled from 'styled-components';
import { Input } from 'antd';

import * as CS from '../style';

const { TextArea: AntdTextArea } = Input;

export const TextArea = styled(AntdTextArea)`
  ${CS.placeholderStyle};
  border: ${({ theme }) => theme.borders.inputs};
  border-color: ${({ theme, error }) =>
    error ? theme.colors.error : theme.colors.gray5};
  border-radius: ${({ theme }) => theme.borders.radius};
  width: 100%;
  padding: ${({ theme }) => theme.spacings[3]};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray};
  font-size: calc(var(--font-size-adjustment) * 16px);
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  background: ${({ theme }) => theme.colors.gray2};

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.darkGray};

    :hover {
      border-color: ${({ theme }) => theme.colors.darkGray};
      outline: none;
      box-shadow: none;
    }
  }

  :hover {
    outline: none;
    border-color: ${({ theme }) => theme.colors.darkGray};
    box-shadow: none;
  }
`;
