import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as I from '../../common/Inputs';
import * as T from '../../common/Typography';
import * as S from './style';
import { Row, Col } from '../../common/Grid';
import Layout from '../../Layouts';
import { INDIVIDUAL_EVENT } from '../../../constants/navigationRoutes';
import Icon from '../../common/Icon';
import AccountPageHeader from '../../common/AccountPageHeader/index';
import Button from '../../common/Button';
import Spin from '../../common/Spin';
import { groupBooking } from '../../../actions/sessionAction';
import { getPublicSessionById } from '../../../actions/sessionAction';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/groupBooking';

const cleanEmail = email => email.toLowerCase().trim();

const GroupBooking = ({
  getPublicSessionById,
  fetchSessionLoading,
  session,
  groupBooking,
  groupBookingSuccess,
  userEmail
}) => {
  const { id } = useParams();
  const history = useHistory();
  const initialFormState = {
    attendeesEmails: [{ email: '' }],
    includeUser: false,
    hasPermissionToShareEmails: false,
    validateAttendeesEmailsOnly: false
  };
  const { formState, setFormData } = useFormData({ initialFormState });
  const {
    attendeesEmails,
    includeUser,
    hasPermissionToShareEmails
  } = formState;
  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const handleOpenEventPage = () => {
    history.push(INDIVIDUAL_EVENT.replace(':id', session?._id));
  };

  const handleAddAnotherAttendee = () => {
    if (!validateForm(true)) {
      return;
    }
    setFormData({
      attendeesEmails: [...attendeesEmails, { email: '' }]
    });
  };

  const handleAttendeeEmailBlur = () => {
    const shouldIncludeUser = attendeesEmails.some(
      attendee => attendee.email === userEmail
    );

    setFormData({
      includeUser: shouldIncludeUser
    });
  };

  const handleAttendeeEmailChange = (email, index) => {
    const newAttendeesEmails = [...attendeesEmails];
    const cleanedEmail = cleanEmail(email);
    newAttendeesEmails[index].email = cleanedEmail;

    const shouldIncludeUser = attendeesEmails.some(
      attendee => attendee.email === userEmail
    );

    setFormData({
      attendeesEmails: newAttendeesEmails,
      includeUser: shouldIncludeUser
    });
  };

  const handleRemoveAttendee = attendeeIndex => {
    const newAttendeesEmails = attendeesEmails.filter(
      (item, index) => index !== attendeeIndex
    );
    const shouldIncludeUser = newAttendeesEmails.some(
      attendee => attendee.email === userEmail
    );
    setFormData({
      attendeesEmails: newAttendeesEmails,
      includeUser: shouldIncludeUser
    });
  };

  const handleIncludeMyselfInBooking = () => {
    const shouldIncludeUser = !includeUser;
    let newAttendeesEmails = [...attendeesEmails];

    if (!shouldIncludeUser) {
      for (let i = newAttendeesEmails.length - 1; i >= 0; i--) {
        if (newAttendeesEmails[i].email === userEmail) {
          if (newAttendeesEmails.length > 1) {
            newAttendeesEmails.splice(i, 1);
          } else {
            newAttendeesEmails[0].email = '';
          }
        }
      }
    } else {
      const indexOfFirstEmptyEmail = attendeesEmails.findIndex(
        obj => obj.email === ''
      );
      if (indexOfFirstEmptyEmail === -1) {
        newAttendeesEmails.push({ email: userEmail });
      } else {
        newAttendeesEmails[indexOfFirstEmptyEmail].email = userEmail;
      }
    }

    setFormData({
      includeUser: shouldIncludeUser,
      attendeesEmails: newAttendeesEmails
    });
  };

  const handlehasPermissionToShareEmails = () => {
    setFormData({
      hasPermissionToShareEmails: !hasPermissionToShareEmails
    });
  };

  const handleRegister = async () => {
    if (!validateForm()) {
      return;
    }
    const { current: finalFormState } = finalSubmissionData;
    groupBooking(
      id,
      finalFormState.attendeesEmails,
      finalFormState.includeUser
    );
  };

  useEffect(() => {
    getPublicSessionById(id);
  }, [getPublicSessionById, id]);

  if (fetchSessionLoading || !session) {
    return <Spin />;
  }

  return (
    <Layout>
      <AccountPageHeader
        title={'Group Booking'}
        subtitle={
          !groupBookingSuccess && (
            <Row ai="center">
              <Icon
                icon="openInNew"
                text={
                  <S.LinkWrapper
                    underlined={true}
                    onClick={handleOpenEventPage}
                  >
                    <T.P weight={600} small>
                      View event page
                    </T.P>
                  </S.LinkWrapper>
                }
              />
            </Row>
          )
        }
        mb="4"
      />
      {groupBookingSuccess ? (
        <>
          <Row style={{ maxWidth: '720px' }}>
            <T.P weight={400}>
              Thank you, your booking has been successful! Each attendee has
              been added to the registration list and will be notified via
              email. If you have included yourself in the booking, you will see
              the event in your dashboard.
            </T.P>
          </Row>
          <Button
            label="Return to event page"
            type="primary"
            mt={6}
            mr="auto"
            width={'200px'}
            onClick={handleOpenEventPage}
          />
        </>
      ) : (
        <>
          <Row style={{ maxWidth: '720px' }}>
            <T.P weight={400}>
              Please enter the email addresses below for each person you wish to
              include in the booking. They will be notified via email and
              invited to create an account.
            </T.P>
          </Row>
          <Row>
            <T.P weight={700} mt={2} mb={4}>
              Please note that there are
              {` ${session?.spacesLeft -
                attendeesEmails.filter(attendeeEmail => !!attendeeEmail.email)
                  .length}`}{' '}
              spaces available for this event.
            </T.P>
          </Row>
          {attendeesEmails.map((attendee, index) => (
            <Row key={index} mb={3}>
              <T.P small weight={600}>
                Attendee #{index + 1} - Email Address
                {attendee.email === userEmail ? ` (You)` : ''}
              </T.P>
              <Row ai="flex-start">
                <Col w={[11, 11, 6]} style={{ paddingLeft: 0 }}>
                  <I.BasicInput
                    handleChange={value =>
                      handleAttendeeEmailChange(value, index)
                    }
                    placeholder="Email Address"
                    value={attendee.email}
                    error={validationErrors[`attendeesEmails[${index}]`]?.email}
                  ></I.BasicInput>
                </Col>
                {attendeesEmails.length > 1 && (
                  <Icon
                    mt={3}
                    icon="trash"
                    color="gray"
                    width="20"
                    height="20"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveAttendee(index)}
                  />
                )}
              </Row>
            </Row>
          ))}
          {session?.spacesLeft > attendeesEmails.length && (
            <S.TextWithIcon type="button" onClick={handleAddAnotherAttendee}>
              <Icon icon="add" color="gray" />
              <T.P color="darkGray">Add another attendee</T.P>
            </S.TextWithIcon>
          )}
          <I.Checkbox
            checked={includeUser}
            label={<T.P color="gray">Include myself in the group booking</T.P>}
            mb="4"
            mt="4"
            disabled={
              session?.spacesLeft <= attendeesEmails.length && !includeUser
            }
            handleChange={handleIncludeMyselfInBooking}
            error={validationErrors.includeUser}
          />
          <I.Checkbox
            checked={hasPermissionToShareEmails}
            label={
              <T.P color="gray">
                I confirm that I have permission to share these email addresses
                with you
              </T.P>
            }
            handleChange={handlehasPermissionToShareEmails}
            error={validationErrors.hasPermissionToShareEmails}
          />
          <Button
            label="Register"
            type="primary"
            mt={6}
            mr="auto"
            width={'200px'}
            onClick={handleRegister}
          />
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    fetchSessionLoading: state.loading.fetchSession,
    session: state.session,
    groupBookingSuccess: state.session.groupBookingSuccess,
    userEmail: state.auth.email
  };
};

const mapActionsToProps = { getPublicSessionById, groupBooking };

export default connect(mapStateToProps, mapActionsToProps)(GroupBooking);
