import { useState } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import Icon from '../Icon';
import * as T from '../Typography';
import Button from '../Button';
import * as S from './style';

import { uploadFiles, removeFile } from '../../../actions/fileUpload';

const FileDropzone = ({
  multiple = false,
  buttonText = 'Browse files',
  title = 'Drag & Drop file here',
  invalidFileText = 'Invalid file!',
  dropText = 'Drop file here',
  removeFileText = 'Remove Image',
  supportedFormatsText,
  tip,
  icon = 'upload',
  disabled,
  clickAnywhereForUpload = true,
  uploadFilesAction,
  uploadLoading,
  removeFileAction,
  validationError,
  existingFileData,
  folderPrefix,
  ...props
}) => {
  const [files, setFiles] = useState(() => {
    if (existingFileData && Array.isArray(existingFileData)) {
      return existingFileData;
    } else if (
      existingFileData &&
      typeof existingFileData.fileUrl === 'string' &&
      typeof existingFileData.fileName === 'string'
    ) {
      return [existingFileData];
    } else {
      return [];
    }
  });

  const [error, setError] = useState(null);

  const onDrop = async acceptedFiles => {
    setError(null);

    try {
      const uploadedFiles = await uploadFilesAction(
        acceptedFiles,
        folderPrefix,
        props.id
      );

      const newFiles = uploadedFiles.map(file => ({
        fileName: file.fileName,
        fileUrl: file.fileUrl
      }));

      if (!multiple) {
        setFiles([newFiles[0]]);
      } else {
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
      }
    } catch (err) {
      setError('File upload failed!');
    }
  };

  const handleRemove = (e, fileToRemove) => {
    e.preventDefault();
    removeFileAction(fileToRemove.fileUrl, props.id);

    setFiles(prevFiles =>
      prevFiles.filter(file => file.fileName !== fileToRemove.fileName)
    );
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.tiff'],
      'application/pdf': ['.pdf'],
      'video/*': ['.mp4', '.mov', '.avi', '.wmv', '.flv', '.mkv', '.webm']
    },
    multiple,
    ...props
  });

  const isInvalid = fileRejections.length > 0;

  return (
    <S.Wrapper
      clickAnywhereForUpload={clickAnywhereForUpload}
      disabled={disabled}
      isDragActive={isDragActive}
      isInvalid={isInvalid}
      hasError={!!error || !!validationError}
      {...props}
    >
      <div
        className={`dropzone-container`}
        {...getRootProps()}
        onClick={clickAnywhereForUpload ? open : () => {}}
      >
        <div className="dropzone">
          <div className="instruction">
            {!!icon && files.length === 0 && (
              <span>
                <Icon icon={icon} color="darkGray" width={100} height={80} />
              </span>
            )}
            <T.P my={3}>{!isDragActive ? title : dropText}</T.P>
            {!isDragActive && (
              <div className="instruction">
                {!clickAnywhereForUpload && files.length === 0 && (
                  <div className="button-container">
                    <T.P>or</T.P>
                    <T.P
                      weight={600}
                      smallMedium
                      onClick={open}
                      className="button-text"
                    >
                      {buttonText}
                    </T.P>
                  </div>
                )}
                {supportedFormatsText && (
                  <T.P color="gray" small>
                    Supported formats: {supportedFormatsText}
                  </T.P>
                )}
                {tip && (
                  <T.P color="gray" small ta="center">
                    {tip}
                  </T.P>
                )}
                {isInvalid && (
                  <T.P color="error" weight={600}>
                    {invalidFileText}
                  </T.P>
                )}

                {uploadLoading?.[props.id]?.isLoading && (
                  <T.P color="darkGray" weight={600} small mt={2}>
                    Uploading...
                  </T.P>
                )}
                {(error || validationError) &&
                  !uploadLoading?.[props.id]?.isLoading && (
                    <T.P color="error" weight={600} small mt={2}>
                      {error || 'Image is required'}
                    </T.P>
                  )}
                {!isInvalid &&
                  files.map((file, index) => (
                    <div key={index}>
                      <S.PreviewImage src={file?.fileUrl} alt="Uploaded file" />
                      <T.P
                        color="darkGray"
                        weight={600}
                        small
                        mt={2}
                        key={file?.fileName}
                        ta="center"
                      >
                        {file?.fileName}
                      </T.P>
                      <Button
                        label={removeFileText}
                        type="tertiary"
                        onClick={e => handleRemove(e, file)}
                        mt={3}
                        width="200px"
                        height="auto"
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <input {...getInputProps()} />
      </div>
    </S.Wrapper>
  );
};

const mapActionsToProps = {
  uploadFilesAction: uploadFiles,
  removeFileAction: removeFile
};
const mapStateToProps = state => {
  return {
    uploadLoading: state.fileUpload
  };
};

export default connect(mapStateToProps, mapActionsToProps)(FileDropzone);
