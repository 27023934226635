import axios from 'axios';

import { returnErrors } from './errorAction';

import {
  LOADING_TRUE,
  LOADING_FALSE,
  FETCH_PROGRAMMES,
  FETCH_PROGRAMME,
  FETCH_PROGRAMMES_FAIL,
  FETCH_PROGRAMME_FAIL,
  FETCH_PROGRAMME_CATEGORIES,
  FETCH_PROGRAMME_CATEGORIES_FAIL,
  FETCH_PROGRAMME_CATEGORY_SUCCESS,
  FETCH_PROGRAMME_CATEGORY_FAIL,
  CREATE_PROGRAMME_CATEGORY_SUCCESS,
  CREATE_PROGRAMME_CATEGORY_FAIL,
  UPDATE_PROGRAMME_CATEGORY_SUCCESS,
  UPDATE_PROGRAMME_CATEGORY_FAIL,
  UPDATE_PROGRAMME_SUCCESS,
  UPDATE_PROGRAMME_FAIL
} from '../constants/actionTypes';

export const fetchProgrammes = (payload = {}) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchProgrammesLoading'
  });
  return (
    axios
      .get(`/api/programmes`, {
        params: payload
      })
      .then(res => {
        dispatch({
          type: FETCH_PROGRAMMES,
          payload: res.data
        });
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchProgrammesLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            FETCH_PROGRAMMES_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchProgrammesLoading'
        });
        dispatch({
          type: FETCH_PROGRAMMES_FAIL
        });
      })
  );
};

export const fetchProgrammeBySlug = (slug, options) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchProgrammeLoading'
  });
  return (
    axios
      .get(`/api/programmes/${slug}`, {
        params: options
      })
      .then(res => {
        dispatch({
          type: FETCH_PROGRAMME,
          payload: res.data
        });
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchProgrammeLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            FETCH_PROGRAMME_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchProgrammeLoading'
        });
        dispatch({
          type: FETCH_PROGRAMME_FAIL
        });
      })
  );
};

export const updateProgramme = (id, payload) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'updateProgrammeLoading'
  });
  return (
    axios
      .put(`/api/programmes/${id}`, payload)
      .then(res => {
        dispatch({
          type: UPDATE_PROGRAMME_SUCCESS,
          payload: res.data
        });
        dispatch({
          type: LOADING_FALSE,
          payload: 'updateProgrammeLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            UPDATE_PROGRAMME_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'updateProgrammeLoading'
        });
        dispatch({
          type: UPDATE_PROGRAMME_FAIL
        });
      })
  );
};

export const fetchProgrammeCategoriesBySlug = (
  slug,
  options
) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchProgrammeCategoriesLoading'
  });
  return (
    axios
      .get(`/api/programmes/${slug}/categories`, {
        params: options
      })
      .then(res => {
        dispatch({
          type: FETCH_PROGRAMME_CATEGORIES,
          payload: res.data
        });
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchProgrammeCategoriesLoading'
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        dispatch(
          returnErrors(
            err?.response?.data?.error || err.message,
            err?.response?.status,
            FETCH_PROGRAMME_CATEGORIES_FAIL
          )
        );
        dispatch({
          type: LOADING_FALSE,
          payload: 'fetchProgrammeCategoriesLoading'
        });
        dispatch({
          type: FETCH_PROGRAMME_CATEGORIES_FAIL
        });
      })
  );
};

export const fetchProgrammeCategoryById = id => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'fetchProgrammeCategoryLoading'
  });

  try {
    const res = await axios.get(`/api/programmes/categories/${id}`);
    dispatch({
      type: FETCH_PROGRAMME_CATEGORY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err?.response?.data?.error || err.message,
        err?.response?.status,
        FETCH_PROGRAMME_CATEGORY_FAIL
      )
    );
    dispatch({
      type: FETCH_PROGRAMME_CATEGORY_FAIL
    });
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'fetchProgrammeCategoryLoading'
    });
  }
};

export const createProgrammeCategory = payload => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'createProgrammeCategoryLoading'
  });

  try {
    const res = await axios.post(`/api/programmes/categories`, payload);
    dispatch({
      type: CREATE_PROGRAMME_CATEGORY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err?.response?.data?.error || err.message,
        err?.response?.status,
        CREATE_PROGRAMME_CATEGORY_FAIL
      )
    );
    dispatch({
      type: CREATE_PROGRAMME_CATEGORY_FAIL
    });
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'createProgrammeCategoryLoading'
    });
  }
};

export const updateProgrammeCategory = (id, payload) => async dispatch => {
  dispatch({
    type: LOADING_TRUE,
    payload: 'updateProgrammeCategoryLoading'
  });

  try {
    const res = await axios.patch(`/api/programmes/categories/${id}`, payload);
    dispatch({
      type: UPDATE_PROGRAMME_CATEGORY_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err?.response?.data?.error || err.message,
        err?.response?.status,
        UPDATE_PROGRAMME_CATEGORY_FAIL
      )
    );
    dispatch({
      type: UPDATE_PROGRAMME_CATEGORY_FAIL
    });
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'updateProgrammeCategoryLoading'
    });
  }
};
