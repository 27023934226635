import { useTheme } from 'styled-components';
import { MODULE_URL } from '../../../constants/navigationRoutes';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import DifficultyLevelBars from '../../common/DifficultyLevelBars';
import { Col, Row } from '../../common/Grid';
import Markdown from '../../common/Markdown';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import * as T from '../../common/Typography';
import VideoPlayer from '../../common/VideoPlayer';

import moment from 'moment';
import { getDifficultyRangeText } from '../../../helpers/modules';
import {
  EventDetailsRow,
  MainContentWrapper,
  ModuleCard,
  ModuleCardHeader,
  DetailsCol
} from './style';
import { resetSessionState } from '../../../actions/sessionAction';

const EventContent = ({ event, resetSessionState }) => {
  const { colors } = useTheme();

  const details = [
    { label: 'Date', value: moment(event.date).format('D MMMM YYYY') },
    event.startTime && {
      label: 'Time',
      value: `${moment(event.startTime, 'HH:mm')
        .format('h:mma')
        .toLowerCase()}${
        event.endTime
          ? ` - ${moment(event.endTime, 'HH:mm')
              .format('h:mma')
              .toLowerCase()}`
          : ''
      }`
    },
    event.registrationCutoffDate && {
      label: 'Register by',
      value: `${moment(event.registrationCutoffDate).format('D MMMM YYYY')}`
    },
    {
      label: 'Location',
      value: event.address ? (
        <>
          <T.P color="darkGray" weight={600}>
            {event.address?.addressLine1}
          </T.P>
          <T.P color="darkGray" weight={600} small>
            {event.address?.postcode}
          </T.P>
        </>
      ) : (
        <T.P color="darkGray">Online</T.P>
      )
    },
    {
      label: 'Trainers',
      value: (
        <>
          {event.trainers?.map(trainer => (
            <T.P color="darkGray">{trainer.credential?.name}</T.P>
          ))}
        </>
      )
    },
    {
      label: 'Level',
      value: (
        <>
          {getDifficultyRangeText(
            event.modules?.map(module => module.difficultyLevel) || []
          )}
        </>
      )
    },
    event?.audience && {
      label: 'Audience',
      value: <T.P color="darkGray">{event.audience}</T.P>
    },
    {
      label: 'Contact',
      value: (
        <T.Link external href={`mailto:${event.contactEmail}`}>
          {event.contactEmail}
        </T.Link>
      )
    }
  ].filter(Boolean);

  useEffect(() => {
    return () => resetSessionState();
  }, [resetSessionState]);

  return (
    <MaxWidthContainer>
      <Row mt={7} mb={5}>
        <Col w={[4, 12, 7]}>
          <MainContentWrapper>
            {event.description && event.description.trim().length > 0 && (
              <>
                <T.H3W mt={5} d="block">
                  Event Summary
                </T.H3W>
                <T.P color="gray" mt={2} mb={2} d="block">
                  <Markdown text={event.description} />
                </T.P>
              </>
            )}
            {event.videoLink && (
              <VideoPlayer width="420" videoUrl={event.videoLink} />
            )}

            {!!event.modules?.length && (
              <Row>
                <T.H3W mt={7} mb={1} d="block">
                  What modules will be covered at the event?
                </T.H3W>
                {event.modules.map(module => (
                  <ModuleCard w={[4, 12, 12]} mt={5}>
                    <ModuleCardHeader>
                      <T.H3 color="darkGray" weight={700}>
                        {module.title}
                      </T.H3>
                      <DifficultyLevelBars levelText={module.difficultyLevel} />
                    </ModuleCardHeader>
                    <T.P color="gray" mt={2}>
                      <Markdown text={module.description} />
                    </T.P>
                    <T.Link
                      color="gray"
                      mt={2}
                      to={MODULE_URL.replace(':id', module._id?.toString())}
                    >
                      View module
                    </T.Link>
                  </ModuleCard>
                ))}
              </Row>
            )}
          </MainContentWrapper>
        </Col>
        <Col w={[0, 0, 0.5]}></Col>
        <Col w={[4, 12, 4.5]} mtT={7}>
          <EventDetailsRow
            inner
            style={{
              backgroundColor: colors.mainPrimary,
              color: colors.darkGray,
              borderRadius: '50px 50px 0px 50px',
              width: '100%'
            }}
          >
            <Col w={[4, 12, 12]}>
              <T.H2 weight={700} color="inherit" d="block">
                Event Details
              </T.H2>
            </Col>
            {details.map(detail => (
              <DetailsCol w={[4, 12, 12]} mt={4} key={detail.label}>
                <Row>
                  <Col w={[4, 6, 5]}>
                    <T.H3 weight={700} color="inherit">
                      {detail.label}
                    </T.H3>
                  </Col>
                  <Col w={[4, 6, 7]}>
                    <T.P color="darkGray">{detail.value}</T.P>
                  </Col>
                </Row>
              </DetailsCol>
            ))}
          </EventDetailsRow>
        </Col>
      </Row>
    </MaxWidthContainer>
  );
};

export default connect(() => {}, {
  resetSessionState
})(EventContent);
