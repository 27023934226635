import { memo } from 'react';
import * as S from './style';

function isYouTubeUrl(url) {
  return /^https?:\/\/(www\.)?youtube\.com\/|youtu\.be\//.test(url);
}

const VideoPlayer = ({ videoUrl, width, ...props }) => {
  try {
    const validUrl = isYouTubeUrl(videoUrl);
    if (!validUrl) {
      throw new Error('Video url provided is not a valid YouTube url');
    }

    return (
      <S.PlayerWrapper width={width}>
        <S.StyledPlayer
          url={videoUrl}
          controls
          height={'100%'}
          width={width || '100%'}
          {...props}
        />
      </S.PlayerWrapper>
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export default memo(VideoPlayer);
