import styled from 'styled-components';
import { Row, Col } from '../../common/Grid';
import Button from '../../common/Button';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.lightSecondary};
  padding: ${({ theme }) => theme.spacings[4]};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.mobileL} {
    padding: ${({ theme }) => theme.spacings[6]};
  }
`;

export const Bold = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const LinkWrapper = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 500;
  cursor: pointer;
  outline: none;
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};

  :focus,
  :hover {
    text-decoration: underline;
  }
`;

export const HeaderRow = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightPrimary};
`;

export const StyledCol = styled(Col)`
  padding-left: 0;
`;

export const StyledButton = styled(Button)`
  max-width: 320px;
`;

export const WithdrawWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mainSecondary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 32px;
  margin-top: 32px;
`;
