import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import { fetchProgrammeBySlug } from '../../../actions/programmesActions';
import {
  INDIVIDUAL_PROGRAMME,
  MODULES_URL,
  PARTICIPANT_SESSION_DETAILS
} from '../../../constants/navigationRoutes';
import { addStatusToSessions } from '../../../helpers';
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import Table from '../../common/BoostTable';
import Button from '../../common/Button';
import { Col, Row } from '../../common/Grid';
import Icon from '../../common/Icon';
import SessionList from '../../common/List/SessionList';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import { TableColumns } from './ParticipantModulesTableColumns';

const ParticipantSessions = ({
  sessions,
  fetchSessions,
  fetchParticipantSessionsLoading,
  programme,
  fetchProgrammeLoading,
  fetchProgrammeBySlug
}) => {
  const { programmeSlug } = useParams();

  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  const [showPreviousSessions, setShowPreviousSessions] = useState(false);
  const [showCompletedModules, setShowCompletedModules] = useState(false);

  useEffect(() => {
    fetchSessions({ populated: true });
  }, [fetchSessions]);

  useEffect(() => {
    fetchProgrammeBySlug(programmeSlug);
  }, [fetchProgrammeBySlug, programmeSlug]);

  const programmeSessions = useMemo(() => {
    return sessions?.filter(ses =>
      ses.programmes.some(programme => programme.slug === programmeSlug)
    );
  }, [sessions, programmeSlug]);

  const sessionsWithStatus = useMemo(
    () => addStatusToSessions(programmeSessions),
    [programmeSessions]
  );

  const uniqueModules = useMemo(
    () =>
      sessionsWithStatus
        ?.filter(ses =>
          showCompletedModules
            ? ses.showInCompleted === true
            : ses.showInCompleted === false
        )
        .flatMap(session =>
          session.modules.map(module => ({
            ...module,
            sessionId: session._id
          }))
        )
        .filter(
          (module, index, self) =>
            index === self.findIndex(m => m._id === module._id)
        )
        .filter(module => module.programme?.slug === programmeSlug),
    [programmeSlug, sessionsWithStatus, showCompletedModules]
  );

  const filteredSessions = useMemo(
    () =>
      sessionsWithStatus
        ?.filter(
          ses =>
            (showPreviousSessions
              ? ses.showInCompleted === true
              : ses.showInCompleted === false) &&
            !ses.defaultSessionForCustomModule
        )
        .map(ses => {
          const { _id } = ses;
          return {
            ...ses,
            link: PARTICIPANT_SESSION_DETAILS.replace(':id', _id)
          };
        }),
    [sessionsWithStatus, showPreviousSessions]
  );

  if (
    fetchParticipantSessionsLoading ||
    !programmeSessions ||
    fetchProgrammeLoading ||
    !programme
  ) {
    return <Spin />;
  }

  return (
    <Layout>
      <AccountPageHeader title={programme.title} mb="2" />
      <Row mb="4">
        <Col w={[4, 12, 12]}>
          <T.Link
            underline
            to={INDIVIDUAL_PROGRAMME.replace(':slug', programmeSlug)}
          >
            <Icon icon="open" mr="1" width="24" />
            <T.P d="inline" small weight={600}>
              View programme details
            </T.P>
          </T.Link>
        </Col>
      </Row>
      <Row mb="4">
        <Col w={[4, 8, 8]}>
          <T.P color="gray" mb="4">
            {programme.subtitle}
          </T.P>
        </Col>
        <Col w={[4, 8, 8]}>
          <T.P color="gray" mb="4">
            Below you can access all modules you are completing or have
            completed for this programme, as well as any related events.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]} dir="column" ai="flex-start">
          <Row>
            <Col w={[4, 6, 6]} dir="column" ai="flex-start">
              <T.H2 weight={700}>
                {showPreviousSessions
                  ? 'My previous events'
                  : 'My upcoming events'}
              </T.H2>
            </Col>
            <Col w={[4, 6, 6]} dir="column" ai="flex-end">
              <Button
                label={showPreviousSessions ? 'View upcoming' : 'View previous'}
                type="tertiary"
                width="250px"
                onClick={() => {
                  setShowPreviousSessions(!showPreviousSessions);
                }}
                mb={2}
              />
            </Col>
          </Row>
          <SessionList dataList={filteredSessions} isParticipant />
        </Col>
      </Row>
      <Row mb={3} mt={8}>
        <Col w={[4, 12, 12]} dir="column" ai="flex-start">
          <Row>
            <Col w={[4, 6, 6]} dir="column" ai="flex-start">
              <T.H2 weight={700}>
                {showCompletedModules
                  ? 'My completed modules'
                  : 'My live modules'}
              </T.H2>
            </Col>
            <Col w={[4, 6, 6]} dir="column" ai="flex-end">
              <Button
                label={showCompletedModules ? 'View live' : 'View completed'}
                type="tertiary"
                width="250px"
                onClick={() => {
                  setShowCompletedModules(!showCompletedModules);
                }}
                mb={2}
              />
            </Col>
          </Row>
          <Table
            data={uniqueModules}
            columns={TableColumns(isTablet)}
            stripedRows
          />
          <Button
            label="Browse modules"
            to={MODULES_URL}
            type="primary"
            mt={6}
            mr="auto"
            width={'200px'}
          />
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail,
  fetchParticipantSessionsLoading:
    state.loading.fetchParticipantSessionsLoading,
  programme: state.programmes?.programme,
  fetchProgrammeLoading: state.loading.fetchProgrammeLoading
});

const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions,
  fetchProgrammeBySlug
};

export default connect(mapStateToProps, mapActionsToProps)(ParticipantSessions);
