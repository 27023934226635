import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { fetchModules } from '../../../actions/modulesActions';
import { fetchProgrammeBySlug } from '../../../actions/programmesActions';
import { PROGRAMME_CATALOGUE } from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import { Col, Row } from '../../common/Grid';
import IconCard from '../../common/IconCard';
import Markdown from '../../common/Markdown';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import ProgrammeHeader from '../../common/ProgrammeHeader';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import VideoPlayer from '../../common/VideoPlayer';
import CalloutSection from './CalloutSection';
import * as S from './style';

const Programme = ({
  programme,
  modules,
  fetchProgrammeBySlug,
  fetchModules,
  loading
}) => {
  const { slug } = useParams();

  const { breakpoints, colors } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  useEffect(() => {
    fetchProgrammeBySlug(slug, { populate: 'categories' });
  }, [fetchProgrammeBySlug, slug]);

  useEffect(() => {
    if (!!programme) {
      fetchModules({
        programme: programme._id,
        forPublic: true
      });
    }
  }, [programme, fetchModules]);

  if (!programme || loading) {
    return <Spin />;
  }

  return (
    <Layout layout="website">
      <Row mt="7" mtT="4" mb="8" mbT="1">
        <ProgrammeHeader
          title={programme.title}
          description={programme.subtitle || ''}
          image={programme?.imageLink?.fileUrl}
        />
      </Row>
      <MaxWidthContainer>
        <Row jc="center" ai="stretch">
          <Col w={[4, 12, 7]} mb={5} mt={5}>
            <T.H2W>
              About this programme
              <span style={{ fontSize: '32px', color: colors.mainPrimary }}>
                ...
              </span>
            </T.H2W>

            <br />
            <T.P mt={5} mb={8}>
              <Markdown text={programme.description} />
            </T.P>
            <T.H2W>Why this programme</T.H2W>
            <T.H2W color="mainPrimary">?</T.H2W>
            <T.P mt={5} mb={5}>
              <Markdown text={programme.whoIsItFor} />
            </T.P>
          </Col>
          <Col w={[4, 12, 5]} mb={5} mt={5}>
            {programme.videoLink && (
              <VideoPlayer videoUrl={programme.videoLink} />
            )}
          </Col>
        </Row>
        {!!programme.categories?.length && (
          <Row mt={8}>
            <Col w={[4, 12, 12]}>
              <T.H2W>Training catalogue</T.H2W>
              <T.H2W color="mainPrimary">...</T.H2W>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P mt={3} mb={3}>
                Explore this programme’s training catalogue by clicking on the
                categories below.{' '}
              </T.P>
            </Col>
            <Col w={[4, 12, 12]}>
              <Row>
                {programme.categories
                  ?.filter(
                    category =>
                      !category?.archived &&
                      modules.find(module => module.category === category._id)
                  )
                  ?.map((category, index) => {
                    return (
                      <Col
                        key={`${category.slug}_${index}`}
                        w={[4, 12, 4]}
                        mb={5}
                        mbT="5"
                      >
                        <IconCard
                          to={`${PROGRAMME_CATALOGUE.replace(
                            ':slug',
                            slug
                          )}?category=${category.slug}`}
                          Img={() => (
                            <S.Img
                              src={category?.imageLink?.fileUrl}
                              alt={`${category.title} alt`}
                            />
                          )}
                        >
                          <T.H4W mt="4" weight="700">
                            {category.title}
                          </T.H4W>
                          <T.P
                            mt="2"
                            mb="2"
                            color="darkGray"
                            small={!isDesktop}
                          >
                            {category.description}
                          </T.P>
                          <T.Link
                            color="darkGray"
                            to={`${PROGRAMME_CATALOGUE.replace(
                              ':slug',
                              slug
                            )}?category=${category.slug}`}
                            small={!isDesktop}
                          >
                            Learn more
                          </T.Link>
                        </IconCard>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        )}
        <CalloutSection
          findOutMoreText={programme.findOutMore}
          catalogueLink={PROGRAMME_CATALOGUE.replace(':slug', slug)}
        />
      </MaxWidthContainer>
    </Layout>
  );
};

const mapStateToProps = state => ({
  programme: state.programmes.programme,
  modules: state.modules?.modules,
  loading:
    state.loading.fetchProgrammeLoading || state.loading.fetchModulesLoading
});

export default connect(mapStateToProps, {
  fetchProgrammeBySlug,
  fetchModules
})(Programme);
