import {
  ADD_SESSION_SUCCESS,
  ADD_SESSION_FAIL,
  DELETE_SESSION_SUCCESS,
  EDIT_SESSION_SUCCESS,
  GET_SESSION_DETAILS_BY_SHORT_ID,
  UPDATE_ATTENDEES_SUCCESS,
  UPDATE_ATTENDEES_FAIL,
  GROUP_BOOKING_SUCCESS,
  GROUP_BOOKING_FAIL,
  EMAIL_SCHEDULE_SUCCESS,
  LOADING_START,
  LOADING_END,
  RESET_SESSION_STATE,
  GET_SESSION_BY_ID,
  ADD_WAITING_TO_ATTENDEES_SUCCESS,
  RESET_ADD_WAITING_TO_ATTENDEES_FLAG
} from '../constants/actionTypes';

const initState = {
  msg: '',
  trainers: [],
  date: null,
  address: {
    postcode: null,
    addressLine1: null,
    addressLine2: null
  },
  _id: null,
  type: null,
  shortId: null,
  region: null,
  startTime: null,
  endTime: null,
  participantsEmails: [],
  addAttendeesUpdatedSuccess: null,
  loading: false,
  addSessionSuccess: false,
  scheduleSuccess: false,
  registrationCutoffDate: null,
  addWaitingToAttendeesSuccess: false
};

export default function(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_SESSION_SUCCESS:
      return {
        ...state,
        ...payload,
        msg: 'success',
        addSessionSuccess: true,
        loading: false
      };
    case EDIT_SESSION_SUCCESS:
      return {
        ...state,
        ...payload,
        msg: 'success',
        addSessionSuccess: false,
        loading: false
      };
    case ADD_SESSION_FAIL:
      return { ...state, ...payload, addSessionSuccess: false, loading: false };
    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        msg: payload
      };

    case GET_SESSION_DETAILS_BY_SHORT_ID:
    case GET_SESSION_BY_ID:
      return {
        ...state,
        ...payload
      };

    case UPDATE_ATTENDEES_SUCCESS:
      return {
        ...state,
        addAttendeesUpdatedSuccess: true,
        loading: false
      };

    case ADD_WAITING_TO_ATTENDEES_SUCCESS:
      return {
        ...state,
        addWaitingToAttendeesSuccess: true,
        loading: false
      };

    case RESET_ADD_WAITING_TO_ATTENDEES_FLAG:
      return {
        ...state,
        addWaitingToAttendeesSuccess: false
      };

    case UPDATE_ATTENDEES_FAIL:
      return {
        ...state,
        addAttendeesUpdatedSuccess: false,
        loading: false
      };

    case GROUP_BOOKING_SUCCESS:
      return {
        ...state,
        groupBookingSuccess: true,
        loading: false
      };

    case GROUP_BOOKING_FAIL:
      return {
        ...state,
        groupBookingSuccess: false,
        loading: false
      };

    case EMAIL_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleSuccess: true
      };

    case LOADING_START:
      return {
        ...state,
        loading: true
      };

    case LOADING_END:
      return {
        ...state,
        loading: false
      };

    case RESET_SESSION_STATE:
      return { ...initState };

    default:
      return state;
  }
}
