const buildUniqueIdentifier = (questionText, groupOrder = 0, order = 0) =>
  `${questionText}-${groupOrder}-${order}`;

const updateAnswersHeadersArray = ({
  questionText,
  dynamicHeadersArray,
  order,
  groupOrder,
  programme
}) => {
  const uniqueIdentifier = buildUniqueIdentifier(
    questionText,
    groupOrder,
    order
  );
  const questionKey = {
    text: questionText,
    uniqueIdentifier,
    order,
    groupOrder,
    programme
  };

  // Add the header if it doesn't already exist
  if (
    !dynamicHeadersArray.some(
      header => header.uniqueIdentifier === uniqueIdentifier
    )
  ) {
    dynamicHeadersArray.push(questionKey);

    dynamicHeadersArray.sort((a, b) => {
      if (a.groupOrder === b.groupOrder) {
        return a.order - b.order;
      }
      return a.groupOrder - b.groupOrder;
    });
  }
};

const addAnswerToResponse = ({ responseObj, uniqueIdentifier, answer }) => {
  let _answer = answer;
  if (Array.isArray(_answer)) {
    _answer = _answer.join(', ');
  } else if (typeof _answer === 'object') {
    let str = '';
    Object.entries(_answer).forEach(([key, value]) => {
      str += `${key}: ${value}，`;
    });
    _answer = str;
  }

  if (typeof _answer === 'string') {
    _answer = _answer.replace(/,/g, '，').replace(/"/g, '”');
  }

  responseObj[uniqueIdentifier] = _answer;
};

const transformer = ({ response, headers }) => {
  const responseObj = { ...response };

  // Update fixed headers
  Object.keys(responseObj)
    .filter(key => key !== 'answers')
    .forEach(key => {
      if (!headers.fixedHeaders.includes(key)) {
        headers.fixedHeaders.push(key);
      }
    });

  // Process answers
  if (response.answers && response.answers.length > 0) {
    response.answers.forEach(
      ({ question, order, groupOrder, programme, answer }) => {
        const uniqueIdentifier = buildUniqueIdentifier(
          question,
          groupOrder,
          order
        );

        updateAnswersHeadersArray({
          questionText: question,
          dynamicHeadersArray: headers.dynamicHeadersArray,
          order,
          groupOrder,
          programme
        });

        addAnswerToResponse({
          responseObj,
          uniqueIdentifier,
          answer
        });
      }
    );
  }

  delete responseObj.answers;
  return responseObj;
};

const getCsvDataAndHeadersForExportResponses = data => {
  const headers = {
    fixedHeaders: [],
    dynamicHeadersArray: []
  };

  const csvDataWithAnswers = data.map(response =>
    transformer({ response, headers })
  );

  // Map general and programme headers using their uniqueIdentifier
  const generalHeaders = headers.dynamicHeadersArray
    .filter(header => !header.programme)
    .map(header => ({
      label: header.text, // Display text for CSV header
      key: header.uniqueIdentifier // Unique key to match in csvData
    }));

  const programmeHeaders = headers.dynamicHeadersArray
    .filter(header => header.programme)
    .reduce((acc, header) => {
      if (!acc[header.programme]) {
        acc[header.programme] = [];
      }
      acc[header.programme].push({
        label: header.text,
        key: header.uniqueIdentifier
      });
      return acc;
    }, {});

  // Flatten headers for the final CSV
  const dynamicHeaders = [
    ...generalHeaders,
    ...Object.values(programmeHeaders).flat()
  ];

  // Combine fixed headers with dynamic headers
  const csvHeaders = [
    ...headers.fixedHeaders.map(header => ({ label: header, key: header })),
    ...dynamicHeaders
  ];

  // Map data to match the keys in csvHeaders
  const csvData = csvDataWithAnswers.map(response =>
    csvHeaders.reduce((obj, header) => {
      obj[header.key] = response[header.key] || ''; // Add value or empty string if missing
      return obj;
    }, {})
  );

  return {
    csvData,
    csvHeaders
  };
};

export default getCsvDataAndHeadersForExportResponses;
