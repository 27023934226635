import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ bgColor, theme }) =>
    theme.colors[bgColor] || theme.colors.darkGray};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 158px;
  border-radius: 4px;
  padding: 0px 8.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 930px) {
    padding: 32px;
    height: auto;
    flex-direction: column;
  }
`;

export const LinksSide = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  max-width: ${({ isFontLarge }) => (isFontLarge ? '390px' : '330px')};
  @media (max-width: 930px) {
    margin-top: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LogoImage = styled.img`
  margin-right: 22px;
  height: 110px;

  @media (max-width: 930px) {
    margin-right: 0;
    height: 80px;
  }
`;
