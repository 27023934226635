import React from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';

import Layout from '../../Layouts';
import { v4 as uuidv4 } from 'uuid';

const ElearningTest = () => {
  const launchElearning = () => {
    const contentUrl =
      'https://portal.e-lfh.org.uk/AICC/InitialiseRelay?CONTENT_URL=/AICC_Content/OMLDA_01_001';

    const sessionId = encodeURIComponent(uuidv4());
    const scheme =
      process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
    const callbackUrl = encodeURIComponent(
      `${scheme}${window.location.host}/api/aicc/hacp`
    );

    const launchUrl = `${contentUrl}?aicc_sid=${sessionId}&aicc_url=${callbackUrl}`;
    window.open(launchUrl, '_blank');
  };

  return (
    <Layout>
      <h1>ElearningTest</h1>
      <Button
        onClick={() => launchElearning()}
        type="primary"
        label="Launch"
        mt="2"
      />
    </Layout>
  );
};

export default connect()(ElearningTest);
