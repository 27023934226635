import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import * as R from '../../../constants/navigationRoutes';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { useHistory } from 'react-router-dom';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import Spin from '../../common/Spin';

import { BasicInput, Checkbox, Dropdown } from '../../common/Inputs';

import { updateIncompleteProfile } from '../../../actions/users';
import {
  organisations as organisationsOptions,
  northEastAndNorthCumbriaICBOrganisations
} from '../../../constants';
import useFormData from '../../../hooks/useFormData';
import useSearchParams from '../../../hooks/useSearchParams';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/updateIncompleteProfile';
import IEMessage from '../../common/IEMessage';
import { ContentWrapper, Form, Wrapper } from './style';

const initialFormState = {
  organisation: { value: '', category: '' },
  organisationOther: '',
  validationErrors: {},
  showOrgOther: false,
  showOrgIcb: false,

  jobRole: '',
  newsletterSubscription: false
};

const UpdateIncompleteProfile = ({
  isAuthenticated,
  authUser,
  loading,
  httpError,
  updateIncompleteProfileLoading,
  updateIncompleteProfile
}) => {
  const { formState, setFormData } = useFormData({ initialFormState });
  const searchParams = useSearchParams();

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const history = useHistory();

  const {
    organisation,
    organisationOther,
    showOrgOther,
    showOrgIcb,
    jobRole,
    newsletterSubscription
  } = formState;

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleUpdateIncompleteProfile();
    }
  };

  const handleUpdateIncompleteProfile = async () => {
    const { current: finalFormState } = finalSubmissionData;

    const payload = {
      ...finalFormState,
      organisation: {
        ...organisation,
        value:
          showOrgOther || showOrgIcb ? organisationOther : organisation.value
      }
    };

    await updateIncompleteProfile(payload);

    return history.push(searchParams.params.redirect || R.HOME_URL);
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation, jobRole, newsletterSubscription]);

  useEffect(() => {
    if (organisation.value.includes('ICB')) {
      setFormData({
        showOrgIcb: true,
        showOrgOther: false
      });
    } else if (organisation.value.includes('specify')) {
      setFormData({
        showOrgIcb: false,
        showOrgOther: true
      });
    } else {
      setFormData({
        showOrgIcb: false,
        showOrgOther: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation.value]);

  if (!isAuthenticated) {
    return <Spin />;
  }

  return (
    <Layout>
      <T.H1 mb="7">Update your profile</T.H1>
      <Wrapper className="sign-up">
        <IEMessage />
        <Row>
          <Col w={[4, 12, 8]}>
            <ContentWrapper>
              <T.P weight={400} mb="6">
                Hi {authUser.name}, please complete your profile to access your
                dashboard.
              </T.P>
              <Form className="signup-form">
                <BasicInput
                  value={jobRole}
                  handleChange={value => setFormData({ jobRole: value })}
                  mb={4}
                  label="Job Title"
                  placeholder="Type your job title here"
                  required
                  error={validationErrors.jobRole}
                />
                <Dropdown
                  selected={organisation.value}
                  m={{ mb: 4 }}
                  label="Organisation"
                  placeholder="Select"
                  required
                  options={organisationsOptions}
                  handleChange={(value, option, b) =>
                    setFormData({
                      organisation: {
                        value,
                        category: option?.key?.split('_')[0]
                      }
                    })
                  }
                />
                {showOrgOther && (
                  <BasicInput
                    value={organisationOther}
                    autoFocus
                    handleChange={value =>
                      setFormData({ organisationOther: value })
                    }
                    placeholder="Type organisation here..."
                    mb={4}
                    error={
                      validationErrors.organisation ||
                      validationErrors.organisationOther
                    }
                  />
                )}
                {showOrgIcb && (
                  <Dropdown
                    selected={organisationOther}
                    m={{ mb: 3 }}
                    placeholder="Select directorate..."
                    required
                    options={northEastAndNorthCumbriaICBOrganisations.map(
                      org => ({ value: org, label: org })
                    )}
                    handleChange={value =>
                      setFormData({ organisationOther: value })
                    }
                  />
                )}
                <Checkbox
                  checked={newsletterSubscription}
                  handleChange={value =>
                    setFormData({ newsletterSubscription: value })
                  }
                  mb={3}
                  label="I wish to receive Boost monthly bulletin updates via email"
                />
                <Row>
                  <Col w={[4, 6, 6]} mt={4}>
                    {httpError?.response?.data?.error && (
                      <T.P color="error">
                        {' '}
                        {httpError?.response?.data?.error}
                      </T.P>
                    )}
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      label="Update profile"
                      loading={loading || updateIncompleteProfileLoading}
                    />
                  </Col>
                </Row>
              </Form>
            </ContentWrapper>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  authUser: state.auth,
  httpError: state.auth.error,
  loading: state.loading.signupLoading,
  updateIncompleteProfileLoading: state.loading.updateIncompleteProfileLoading
});

export default connect(mapStateToProps, {
  updateIncompleteProfile
})(UpdateIncompleteProfile);
