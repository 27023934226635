const Search = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 21.8389L16.65 17.4889M19 11.8389C19 16.2571 15.4183 19.8389 11 19.8389C6.58172 19.8389 3 16.2571 3 11.8389C3 7.42059 6.58172 3.83887 11 3.83887C15.4183 3.83887 19 7.42059 19 11.8389Z"
      stroke={color || '#4A4A49'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Search;
