const BoostArrow = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width={width || '164'}
    height={height || '164'}
    viewBox="0 0 164 164"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }}
  >
    <path
      fill={color || '#fcc700'}
      d="M80.5 3.5c.996-.086 1.83.248 2.5 1l65.5 99a307.162 307.162 0 0 1-35 1v56h-65v-56a307.162 307.162 0 0 1-35-1c22.36-33.351 44.692-66.684 67-100Z"
    />
  </svg>
);

export default BoostArrow;
