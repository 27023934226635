const decideDirection = dir => {
  switch (dir) {
    case 'left':
      return 'rotate(90deg)';
    case 'right':
      return 'rotate(270deg)';
    case 'up':
      return 'rotate(180deg)';
    default:
      return 'rotate(0deg)';
  }
};

const Chevron = ({ width, height, color, direction, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: direction && decideDirection(direction)
    }}
  >
    <path
      d="M3.28125 5.39062L7.5 9.60938l4.2188-4.21876"
      stroke={color || 'currentColor'}
      strokeWidth="1.40625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chevron;
