import styled from 'styled-components';

export const Wrapper = styled.form`
  background: ${({ theme }) => theme.colors.lightPrimary};
  padding: ${({ theme }) => theme.spacings[6]};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  width: 100%;
`;

export const LoginGuideCard = styled.div`
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[6]}`};
  margin: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[0]}`};
  border-radius: 12px;
  width: 100%;
`;
