import React, { useEffect, useState } from 'react';
import { AddToCalendarButton as ATCButton } from 'add-to-calendar-button-react';
import { injectCalendarButtonStyles } from './style';
import moment from 'moment';

const AddToCalendarButton = ({
  title,
  location,
  startDate,
  endDate,
  startTime,
  endTime,
  description
}) => {
  const [isStylesInjected, setIsStylesInjected] = useState(false);

  useEffect(() => {
    injectCalendarButtonStyles(() => {
      setIsStylesInjected(true);
    });
  }, []);

  return (
    <div style={{ display: isStylesInjected ? 'block' : 'none' }}>
      <ATCButton
        name={title}
        options={['iCal', 'Google']}
        customLabels='{"ical":"iCal (Recommended)", "google":"Google"}'
        location={location}
        description={description || ''}
        startDate={moment(startDate).format('YYYY-MM-DD')}
        timeZone="currentBrowser"
        trigger="click"
        {...(endDate && { endDate: moment(endDate).format('YYYY-MM-DD') })}
        {...(startTime && { startTime })}
        {...(endTime && { endTime })}
        hideIconList
        hideCheckmark
      ></ATCButton>
    </div>
  );
};

export default AddToCalendarButton;
