import { validate as _validate, createSchema, fields } from '..';

const { requiredText, imageLink } = fields;

const validate = data => {
  const programmeCategoryForm = createSchema({
    title: requiredText,
    description: requiredText,
    imageLink
  });
  return _validate(programmeCategoryForm, data);
};

export default validate;
