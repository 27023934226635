import { createGlobalStyle } from 'styled-components';

const AccessibilityGlobalStyles = createGlobalStyle`

${({ fontFamily }) =>
  fontFamily &&
  `
    * {
      font-family: ${fontFamily || "'Nunito', sans-serif"} !important;
    }
`}

${({ isFontLarge }) =>
  isFontLarge &&
  `
  :root {
    --font-size-adjustment: 1.25;
  }
`}
`;

export default AccessibilityGlobalStyles;
