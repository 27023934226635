import { useEffect, useState } from 'react';
import FileDropzone from '../../common/FileDropzone';
import Icon from '../../common/Icon';
import * as I from '../../common/Inputs';
import * as T from '../../common/Typography';
import * as S from './style';

const MaterialField = ({
  item,
  index,
  name,
  fields,
  setState,
  errors,
  placeholder,
  required,
  handleRemove
}) => {
  return (
    <>
      <I.BasicInput
        key={`title-${index}`}
        label="title"
        small
        placeholder={`${placeholder} title...`}
        required={required}
        mb="3"
        value={item.title}
        handleChange={value =>
          setState({
            [name]: fields.map((m, idx) =>
              idx === index
                ? {
                    ...m,
                    title: value
                  }
                : m
            )
          })
        }
        error={errors[`${name}[${index}]`]?.title}
      />
      <I.BasicInput
        key={`link-${index}`}
        label="link"
        small
        placeholder={`${placeholder} link...`}
        required={required}
        mb="2"
        value={item.link}
        handleChange={value =>
          setState({
            [name]: fields.map((m, idx) =>
              idx === index
                ? {
                    ...m,
                    link: value
                  }
                : m
            )
          })
        }
        error={errors[`${name}[${index}]`]?.link}
      />
      <S.TextWithIcon
        onClick={e => {
          e.preventDefault();
          handleRemove(index);
        }}
        ml="auto"
        mb={1}
      >
        <Icon icon="archive" color="error" width={20} />
        <T.P small color="error">
          Remove
        </T.P>
      </S.TextWithIcon>
    </>
  );
};

const ModuleMaterials = ({
  fields,
  name,
  label,
  placeholder,
  setState,
  fileUploadState,
  resetFileUploadState,
  errors,
  required = true,
  fieldNote = ''
}) => {
  const [addingMaterial, setAddingMaterial] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleAddMaterial = value => {
    if (value === 'link') {
      setState({
        [name]: [
          ...fields,
          {
            title: '',
            link: ''
          }
        ]
      });

      setAddingMaterial(false);
    } else if (value === 'file') {
      setUploadingFile(true);
    }
  };

  const handleRemoveMaterial = index => {
    setState({
      [name]: fields.filter((_, idx) => idx !== index)
    });
  };

  useEffect(() => {
    if (uploadingFile && fileUploadState?.[name]?.data?.[0]?.fileUrl) {
      setState({
        [name]: [
          ...fields,
          {
            title: fileUploadState?.[name]?.data?.[0]?.fileName,
            link: fileUploadState?.[name]?.data?.[0]?.fileUrl
          }
        ]
      });
      resetFileUploadState();
      setUploadingFile(false);
      setAddingMaterial(false);
    }
  }, [
    fields,
    fileUploadState,
    name,
    resetFileUploadState,
    setState,
    uploadingFile
  ]);

  return (
    <S.MultiInputFieldWrapper mt="5" mb="5">
      <T.P weight="600" mb={fieldNote ? 1 : 3}>
        {label}
      </T.P>
      {!!fieldNote && <T.P mb={3}>{fieldNote}</T.P>}
      {fields.map((item, index) => (
        <div key={index}>
          <MaterialField
            item={item}
            index={index}
            name={name}
            fields={fields}
            setState={setState}
            errors={errors}
            placeholder={placeholder}
            required={required}
            handleRemove={handleRemoveMaterial}
          />
          <S.MaterialDivider />
        </div>
      ))}
      {uploadingFile ? (
        <>
          <FileDropzone
            id={name}
            supportedFormatsText="PDF, DOC, DOCX, PPT, PPTX"
            accept={{
              'application/pdf': ['.pdf'],
              'application/msword': ['.doc', '.dot'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                '.docx'
              ],
              'application/vnd.ms-powerpoint': ['.ppt', '.pps', '.pot'],
              'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
                '.pptx'
              ]
            }}
            folderPrefix="documents/materials"
            mt={2}
          />
        </>
      ) : addingMaterial ? (
        <>
          <I.Dropdown
            m={{ mb: '5', mt: '3' }}
            label="Select material type"
            required
            addNew={false}
            search={false}
            options={[
              { value: 'link', label: 'Link' },
              { value: 'file', label: 'File' }
            ]}
            handleChange={handleAddMaterial}
          />
        </>
      ) : (
        <S.TextWithIcon type="button" onClick={() => setAddingMaterial(true)}>
          <Icon icon="add" color="gray" />
          <T.P color="darkGray">
            {' '}
            Add {fields.length === 0 ? 'a' : 'another'} material
          </T.P>
        </S.TextWithIcon>
      )}
    </S.MultiInputFieldWrapper>
  );
};

export default ModuleMaterials;
