export const DEFAULT_REQUIRED = 'This field is required';
export const INVALID_EMAIL = 'Invalid email';
export const TOO_LONG_MAX_7 = 'Too long - should be 7 characters maximum';
export const TOO_LONG_MAX_50 = 'Too long - should be 50 characters maximum';
export const TOO_LONG_MAX_55 = 'Too long - should be 55 characters maximum';
export const TOO_LONG_MAX_100 = 'Too long - should be 100 characters maximum';
export const TOO_LONG_MAX_200 = 'Too long - should be 200 characters maximum';
export const TOO_LONG_MAX_300 = 'Too long - should be 300 characters maximum';
export const TOO_LONG_MAX_500 = 'Too long - should be 500 characters maximum';
export const SHORT_PASSWORD =
  'Password must contain min. 8 characters, one capital letter, one lowercase letter, one number and one non alphabetical or numeric character';
export const SHOULD_AGREE_ON_TERMS =
  'You must agree to our terms and conditions';
export const SHOULD_CONFIRM_MECC_ATTENDANCE = 'You must confirm attendance';
export const TOO_SHORT_MIN_5 = 'Too short - should be 5 characters minimum';
export const TOO_SHORT_MIN_4 = 'Too short - should be 4 characters minimum';
export const INVALID_POSTCODE = 'Invalid Postcode';
export const NUMBER_REQUIRED = 'You must specify a number';
export const TOO_LONG_WORDS_200 = 'Too long - should be 200 words maximum';
export const INVALID_LINK = 'Invalid link';
export const INVALID_PHONE = 'Invalid phone number';
export const All_required = 'You must fill all the required fields';
export const VALID_SESSION_TYPE =
  'You must select either online or face to face';
export const EMPTY_ARRAY = 'You must select at least one of the options';
export const PASSWORDS_MUST_MATCH = 'Passwords must match';
export const OPTION_TEXT_REQUIRED = 'Option text is required';
export const TWO_OPTIONS_REQUIRED = 'At least two options are required';
export const ONE_CORRECT_ANSWER_REQUIRED =
  'Please choose one option to be the correct answer';
export const INVALID_EVENT_LOCATION =
  'You have to select a location from the available list!';
export const SHOULD_CONFIRM_SHARING_EMAIL_ADDRESSES =
  'You must confirm that you have permission to share these email addresses.';
export const DUPLICATE_EMAILS_ARE_NOT_ALLOWED =
  'Duplicate emails are not allowed';
