import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';
import * as R from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import { Col, Row } from '../../common/Grid';
import { Dropdown } from '../../common/Inputs';

const ChooseProgrammeForSessions = ({ sessions, fetchSessions }) => {
  const [programme, setProgramme] = useState();
  const history = useHistory();

  const programmes = useMemo(() => {
    if (!sessions?.length) return [];
    const programmeMap = new Map();
    sessions
      .flatMap(session => session.programmes)
      .forEach(programme => {
        programmeMap.set(programme._id, programme);
      });

    return [...programmeMap.values()].sort((a, b) =>
      a.titleShort.localeCompare(b.titleShort)
    );
  }, [sessions]);

  useEffect(() => {
    fetchSessions({ populated: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programmes?.length === 1 && programmes[0].slug) {
      history.push(
        R.PARTICIPANT_SESSIONS_LIST.replace(
          ':programmeSlug',
          programmes[0].slug
        )
      );
    }
  }, [history, programmes]);

  useEffect(() => {
    if (programme) {
      const slug = programmes.find(p => p._id === programme)?.slug;
      history.push(R.PARTICIPANT_SESSIONS_LIST.replace(':programmeSlug', slug));
    }
  }, [history, programme, programmes]);

  return (
    <Layout>
      <>
        {!programme && (
          <>
            <AccountPageHeader title="My programmes" />
            <Row mb={4}>
              <Col w={[4, 8, 6]}>
                <Dropdown
                  selected={programme}
                  label="Please select the programme you would like to view"
                  placeholder="Select..."
                  required
                  options={programmes?.map(({ _id, titleShort }) => ({
                    value: _id,
                    label: titleShort
                  }))}
                  name="programme"
                  handleChange={value => setProgramme(value)}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    </Layout>
  );
};
const mapStateToProps = state => ({
  sessions: state.sessions?.participantSessionsByEmail
});
const mapActionsToProps = {
  fetchSessions: fetchParticipantSessions
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ChooseProgrammeForSessions);
