import * as T from '../Typography';
import * as S from './style';

const Breadcrumb = ({ items, dark = false, ...props }) => {
  return (
    <S.Wrapper role="breadcrumb" aria-label="breadcrumb" {...props}>
      <S.BreadcrumbList>
        {items.map((item, index) => (
          <S.BreadcrumbItem
            key={index}
            active={index === items.length - 1 && !item.link}
            dark={dark}
          >
            {item.link ? (
              <T.Link to={item.link}>{item.name}</T.Link>
            ) : (
              item.name
            )}
          </S.BreadcrumbItem>
        ))}
      </S.BreadcrumbList>
    </S.Wrapper>
  );
};

export default Breadcrumb;
