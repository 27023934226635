import styled from 'styled-components';
import { Checkbox } from '../Inputs';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div``;

export const Form = styled.form`
  background: ${({ theme, renderInline }) =>
    !renderInline && theme.colors.lightPrimary};
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0 auto;
  padding: ${({ theme, renderInline }) => !renderInline && theme.spacings[6]};
`;

export const StyledCheckBox = styled(Checkbox)`
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background: ${({ theme, renderInline }) =>
        renderInline && theme.colors.darkGray} !important;
    }
  }
`;

export const CheckboxLabel = styled.div`
  color: #231f20 !important;
  font-size: calc(var(--font-size-adjustment) * 18px);
`;
