import styled from 'styled-components';
import { Row } from '../../common/Grid';

export const TopRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacings[7]} !important;
  margin-bottom: ${({ theme }) => theme.spacings[8]} !important;

  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    margin-top: 0 !important;
  }
`;

export const CategoryWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
