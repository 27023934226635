import { Modal, message } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Collapse from '../../common/Collapse';
import * as T from '../../common/Typography';

import { fetchSessionDetails } from '../../../actions/groupSessionsAction';
import {
  cancelScheduledEmail as cancelScheduledEmailAction,
  updateParticipantAttendanceStatus,
  updateSessionAttendeesList as updateSessionAttendeesListAction,
  resetUpdateAttendeesSuccess
} from '../../../actions/sessionAction';

import { splitEmailsList } from '../../../helpers';

// ANTD COMPONENTS
import Icon from '../../common/Icon';
import Spin from '../../common/Spin';

// COMMON COMPONENTS
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';

import * as S from './SessionDetails.Style';

// SUB COMPONENTS
import DrawerContent from './DrawerContent';
import InviteAndPromote from './InviteAndPromote';
import ManageAttendees from './ManageAttendees';
import SessionActions from './SessionActions';
import SessionSurveys from './SessionSurveys';
import SessionTopDetails from './SessionTopDetails';

// to validate emails
const emailSchema = Yup.string()
  .email()
  .required();

const Spinner = () => (
  <S.SpinWrapper>
    <Spin size="large" />
  </S.SpinWrapper>
);

class SessionDetails extends Component {
  state = {
    openSection: '1',
    visible: false,
    drawerKey: null,
    emailId: null,
    viewParticipants: false
  };

  selectRef = React.createRef();

  componentDidMount() {
    if (window.ClipboardEvent) {
      let dT = null;
      try {
        dT = new DataTransfer();
      } catch (e) {
        // ignore the error
      }
      const evt = new ClipboardEvent('paste', { clipboardData: dT });
      if (evt.clipboardData || window.clipboardData) {
        (evt.clipboardData || window.clipboardData).setData('text/plain', '');
        document.addEventListener('paste', this.pasteEmails);
        document.dispatchEvent(evt);
      }
    }

    const { id } = this.props.match.params;
    // call action and pass it the id of session to fetch it's details
    this.props.fetchSessionDetails(id, this.props.history, true);
  }

  componentDidUpdate() {
    const { lastUpdate } = this.state;
    const { sessionDetails } = this.props;
    if (lastUpdate !== sessionDetails.updatedAt) {
      this.setListIntoState();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.pasteEmails);
  }

  callback = key => {
    this.setState({ openSection: key });
  };

  setListIntoState = () => {
    const { sessionDetails = {} } = this.props;
    const { participantsEmails = [], sentEmails = [] } = sessionDetails;
    const newEmails = participantsEmails
      .filter(item => {
        return item.status === 'new';
      })
      .map(item => item.email);

    const sentEmailsList = participantsEmails
      .filter(item => item.status === 'sent')
      .map(item => item.email);

    const confirmedEmails = participantsEmails
      .filter(item => item.status === 'confirmed')
      .map(item => item.email);

    const reminderEmails = sentEmails.filter(item => item.type === 'reminder');

    this.setState({
      newEmails: [...newEmails, ...sentEmailsList],
      confirmedEmails,
      lastUpdate: sessionDetails.updatedAt,
      reminderEmails
    });
  };

  // open drawer
  handleDrawerOpen = e => {
    const { key, surveyType, emailId } = e.target.dataset;

    this.setState({
      visible: true,
      drawerKey: key,
      emailId,
      surveyType
    });
  };

  // update the attendees list status = (new || confirmed)
  handleUpdateAttendees = (values, status, blur, cb) => {
    const validEmails = [];
    let hasError = false;
    values.filter(Boolean).forEach(item => {
      try {
        const validEmail = emailSchema.validateSync(
          item.replace(/[, ;"']/g, '')
        );

        if (
          validEmail &&
          item &&
          !validEmails.includes(item.replace(/[, ;"']/g, '')) &&
          !validEmails.includes(item)
        ) {
          validEmails.push(
            item
              .replace(/[, ;"']/g, '')
              .toLowerCase()
              .trim()
          );
        }
      } catch (err) {
        if (!blur && item.split('@').length < 3) {
          Modal.error({
            title: 'Invalid!',
            content: err.errors[0],
            centered: true,
            icon: null
          });
          hasError = true;
        }
      }
    });

    this.setState({ [`${status}Emails`]: validEmails }, () => {
      if (blur) {
        const select = this.selectRef.current;
        select.blur();
        setTimeout(() => {
          select.focus();
        }, 200);
      }

      if (cb && !hasError) {
        cb();
      }
    });
  };

  onTypingEmails = (value, status) => {
    const { [`${status}Emails`]: oldEmails } = this.state;
    if (
      value &&
      (value.includes(' ') || value.includes(',') || value.includes(';'))
    ) {
      const splittedEmails = splitEmailsList(value);

      // get latest added email
      const latestEmail =
        splittedEmails && splittedEmails[splittedEmails.length - 1];

      if (latestEmail.includes('@') && latestEmail.includes('.')) {
        this.handleUpdateAttendees(
          [...splittedEmails, ...oldEmails],
          status,
          true
        );
      } else {
        this.handleUpdateAttendees(
          [
            ...splittedEmails.slice(0, splitEmailsList.length - 1),
            ...oldEmails
          ],
          status,
          true
        );
      }
    }
  };

  // submit the updated emails
  handleSubmitUpdateAttendees = (
    status,
    originalEmailList,
    oldStatus = 'confirmed'
  ) => {
    const { confirmedEmails, newEmails } = this.state;
    const emailsArray = status === 'new' ? newEmails : confirmedEmails;
    const { sessionDetails, updateSessionAttendeesList } = this.props;
    let participantsEmails = emailsArray.map(item => ({
      email: item.toLowerCase().trim(),
      status
    }));

    // create a list of deletedEmails that include any emails from originalEmailList that are not in participantsEmails
    const deletedEmails = originalEmailList?.filter(
      email => !participantsEmails.find(item => item.email === email)
    );

    const isAddWaitingToAttendees = oldStatus === 'waiting';

    if (isAddWaitingToAttendees) {
      participantsEmails = participantsEmails.map(participant =>
        originalEmailList.includes(participant.email)
          ? participant
          : { ...participant, oldStatus }
      );
    }

    updateSessionAttendeesList({
      sessionId: sessionDetails._id,
      participantsEmails,
      status,
      handleCloseDrawer: () => {},
      history: this.props.history,
      deletedEmails,
      isAddWaitingToAttendees
    });
  };

  openNextKey = () => {
    const { nextKey } = this.state;
    this.setState({
      visible: true,
      drawerKey: nextKey
    });
  };

  onCopy = status => {
    const { newEmails, confirmedEmails } = this.state;
    let dataForCopy;
    switch (status) {
      case 'new':
        dataForCopy = newEmails;
        break;

      case 'confirmed':
        dataForCopy = confirmedEmails;
        break;

      default:
        dataForCopy = [];
        break;
    }

    if (dataForCopy.length) {
      this.setState({ dataForCopy: dataForCopy.join(';') }, () => {
        if (dataForCopy.length) {
          const copyText = document.getElementById('dataForCopy');
          let range;
          let selection;
          if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(copyText);

            range.select();
          } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(copyText);
            selection.removeAllRanges();
            selection.addRange(range);
          }

          try {
            document.execCommand('copy');
            message.success('copied');
          } catch (err) {
            // console.log(err);
          }
        }
      });
    }
  };

  // to clear the emails list
  onClear = status => {
    this.setState({ [`${status}Emails`]: [] });
  };

  // blur handler for Select component
  onSelectBlur = () => {
    this.setState({ focused: false });
  };

  // focus handler for Select component
  onSelectFocus = () => {
    this.setState({ focused: true });
  };

  // when pasting list of emails in the Select component
  pasteEmails = event => {
    const { focused, drawerKey } = this.state;

    let status = '';
    switch (drawerKey) {
      case 'view-invitees':
        status = 'new';
        break;

      case 'viewAttendeesList':
        status = 'confirmed';
        break;

      default:
        break;
    }

    let emailsArray;

    if (focused) {
      event.preventDefault();
      const pastedString = event.clipboardData.getData('text/plain');

      emailsArray = splitEmailsList(pastedString);

      const { [`${status}Emails`]: oldEmails } = this.state;

      this.handleUpdateAttendees([...emailsArray, ...oldEmails], status);
    }
  };

  // close the drawer
  handleCloseDrawer = () => {
    this.setState({
      visible: false,
      drawerKey: null,
      nextKey: null
    });
    this.setListIntoState();
  };

  // to handle moving from the edit email page to add attendees page
  handleAddEmailsClick = (drawerKey, nextKey) => {
    this.setState({
      visible: true,
      drawerKey,
      nextKey
    });
  };

  handleCancelEmail = emailId => {
    const { cancelScheduledEmail, sessionDetails } = this.props;

    cancelScheduledEmail(
      {
        sessionId: sessionDetails._id,
        scheduledEmailId: emailId
      },
      this.props.history
    );
  };

  setViewParticipants = hide => {
    this.setState({
      viewParticipants: !hide
    });
  };

  render() {
    const { sessionDetails, name } = this.props;
    const {
      drawerKey,
      loading,
      visible,
      confirmedEmails,
      reminderEmails,
      newEmails,
      surveyType,
      dataForCopy,
      emailId,
      viewParticipants
    } = this.state;
    if (!sessionDetails) {
      return Spin;
    }

    const {
      participantsDidNotAttendPrevMods,
      participantsEmails
    } = sessionDetails;

    const confirmedAttendeesList =
      participantsEmails?.filter(item => item.status === 'confirmed') || [];

    const waitingList =
      participantsEmails?.filter(item => item.status === 'waiting') || [];

    const filteredAccesssNeeds = sessionDetails?.accessNeeds?.filter(
      ({ status }) => status === 'confirmed'
    );

    if (this.props.loading) return <Spinner />;
    return (
      <Layout>
        <S.SessionDetailsWrapper>
          <AccountPageHeader title="Manage Event" />
          {!!participantsDidNotAttendPrevMods?.length && (
            <S.WarningWrapper>
              <S.WarningText>
                <S.IconWrapper>
                  <Icon icon="warning" />
                </S.IconWrapper>
                <div>
                  You have {participantsDidNotAttendPrevMods.length} people
                  attending who have not yet completed the previous module.
                  Please contact them to ensure they will complete the module
                  before your session. Otherwise, remove them from the attendees
                  list.
                  <br />
                  {!viewParticipants && (
                    <T.Link onClick={() => this.setViewParticipants()}>
                      View participants
                    </T.Link>
                  )}
                </div>
              </S.WarningText>
              {viewParticipants && (
                <S.EmailList>
                  {participantsDidNotAttendPrevMods.map(p => (
                    <li>
                      <T.Link
                        external
                        href={`mailto:${p.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {p.email}
                      </T.Link>
                    </li>
                  ))}
                </S.EmailList>
              )}
            </S.WarningWrapper>
          )}
          <Collapse
            onChange={() => this.setViewParticipants(true)}
            items={[
              {
                title: <S.HeaderDiv>Event Details</S.HeaderDiv>,
                content: (
                  <>
                    <SessionTopDetails
                      sessionDetails={sessionDetails}
                      trainerView
                    />
                    <SessionActions
                      sessionDetails={sessionDetails}
                      history={this.props.history}
                    />
                  </>
                ),
                key: 1
              },
              {
                title: <S.HeaderDiv>Invite & Promote</S.HeaderDiv>,
                content: (
                  <InviteAndPromote
                    sessionDetails={sessionDetails}
                    handleDrawerOpen={this.handleDrawerOpen}
                  />
                ),
                key: 2
              },
              {
                title: <S.HeaderDiv>Manage Attendees</S.HeaderDiv>,
                content: (
                  <ManageAttendees
                    sessionDetails={sessionDetails}
                    handleDrawerOpen={this.handleDrawerOpen}
                    confirmedAttendeesList={confirmedEmails}
                    waitingList={waitingList}
                  />
                ),
                key: 3
              },
              {
                title: <S.HeaderDiv>Get Feedback</S.HeaderDiv>,
                content: (
                  <SessionSurveys
                    sessionDetails={sessionDetails}
                    handleDrawerOpen={this.handleDrawerOpen}
                  />
                )
              }
            ]}
            activeArrowDirection="up"
          />
          <div
            style={{ width: '100%', position: 'absolute', left: 0 }}
            id="parentDiv"
          >
            <S.Drawer
              placement="left"
              width="100%"
              height="100%"
              onClose={this.handleCloseDrawer}
              visible={visible}
              closable
              bodyStyle={{
                minHeight: '100%'
              }}
              getContainer="#parentDiv"
              destroyOnClose
            >
              <S.DrawerWrapper>
                <S.BackWrapper onClick={this.handleCloseDrawer}>
                  <Icon
                    icon="chevron"
                    direction="left"
                    width="16"
                    height="16"
                    mr="2"
                    color="primary"
                  />
                  <T.P color="darkGray" weight={500}>
                    Back
                  </T.P>
                </S.BackWrapper>
                <DrawerContent
                  // All
                  drawerKey={drawerKey}
                  loading={loading}
                  sessionDetails={sessionDetails}
                  onSelectBlur={this.onSelectBlur}
                  onSelectFocus={this.onSelectFocus}
                  name={name}
                  onCopy={this.onCopy}
                  onClear={this.onClear}
                  handleCloseDrawer={this.handleCloseDrawer}
                  // update
                  handleSubmitUpdateAttendees={this.handleSubmitUpdateAttendees}
                  confirmedAttendeesList={confirmedAttendeesList}
                  handleUpdateAttendees={this.handleUpdateAttendees}
                  updateParticipantAttendanceStatus={
                    this.props.updateParticipantAttendanceStatus
                  }
                  addWaitingToAttendeesSuccess={
                    this.props.addWaitingToAttendeesSuccess
                  }
                  resetUpdateAttendeesSuccess={
                    this.props.resetUpdateAttendeesSuccess
                  }
                  // sendEmails
                  handleAddEmailsClick={this.handleAddEmailsClick}
                  // email list
                  reminderEmails={reminderEmails}
                  handleDrawerOpen={this.handleDrawerOpen}
                  // special requirements
                  accessNeeds={filteredAccesssNeeds}
                  //
                  newAndSentEmailsList={newEmails}
                  // feedback
                  handleCancelEmail={this.handleCancelEmail}
                  surveyType={surveyType}
                  handleSubmitSchedule={this.handleSubmitSchedule}
                  emailId={emailId}
                  onTypingEmails={this.onTypingEmails}
                  selectRef={this.selectRef}
                  waitingList={waitingList}
                />
              </S.DrawerWrapper>
            </S.Drawer>
          </div>
          <div
            id="dataForCopy"
            style={{
              opacity: '0',
              position: 'absolute',
              width: '0',
              hieght: '0',
              // to prevent Y scroll
              left: '-100000rem'
            }}
          >
            {dataForCopy}
          </div>
          {/* <div style={{ marginLeft: '30px', marginTop: '20px' }}>
            <Button>
              <InfoHeader
                style={{ fontSize: '19px' }}
                to={{
                  pathname: `/print/${sessionDetails._id}`,
                  state: { details: printContent }
                }}
              >
                Click here to create printable PDF
              </InfoHeader>
            </Button>
          </div> */}
        </S.SessionDetailsWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    sessionDetails: state.sessions.sessionDetails,
    loading: state.loading.sessionDetails,
    name: state.auth.name,
    addWaitingToAttendeesSuccess: state.session.addWaitingToAttendeesSuccess
  };
};
export default connect(mapStateToProps, {
  fetchSessionDetails,
  updateSessionAttendeesList: updateSessionAttendeesListAction,
  cancelScheduledEmail: cancelScheduledEmailAction,
  updateParticipantAttendanceStatus,
  resetUpdateAttendeesSuccess
})(SessionDetails);
