import MDEditor from '@uiw/react-md-editor';
import * as T from '../Typography';
import * as S from './style';

const Editor = ({
  value,
  onChange,
  mode,
  error,
  label,
  helper,
  placeholder,
  m,
  rows,
  optional,
  color = 'gray',
  ...props
}) => {
  return (
    <S.Wrapper data-color-mode="light" {...m} error={error}>
      {label && (
        <S.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="600" color={color} m="0">
            {!optional && (
              <S.OptionalTag mr="1" color={color}>
                *{' '}
              </S.OptionalTag>
            )}
            {label}
          </T.P>
        </S.Label>
      )}
      {helper && (
        <T.P isSmall mb="2" ml="2">
          {helper}
        </T.P>
      )}
      <MDEditor
        value={value}
        onChange={onChange}
        preview={mode || 'edit'}
        textareaProps={{
          placeholder: placeholder,
          rows: rows
        }}
        highlightEnable={false}
        {...props}
      />
      {error && <T.P color="error">{error}</T.P>}
    </S.Wrapper>
  );
};

export default Editor;
