import { useEffect } from 'react';
import { connect } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';

import { Modal } from 'antd';

import { PARTICIPANT_DASHBOARD } from '../../../constants/navigationRoutes';

import {
  getParticipantByEmail,
  resetGetParticipantByEmail
} from '../../../actions/surveyAction';

const CheckRequirements = ({
  surveyData,
  contentMsg,
  redirectTo,
  shortId,
  answeredSameModule,
  sessionDidNotStart,
  getParticipantByEmail,
  resetGetParticipantByEmail
}) => {
  const history = useHistory();
  const { id, type } = useParams();

  if (answeredSameModule) {
    Modal.error({
      title: 'Error!',
      content: "you've already submitted this evaluation.",
      onOk: () => history.push('/participant-dashboard'),
      centered: true,
      icon: null
    });
  } else if (sessionDidNotStart) {
    Modal.error({
      title: 'Sorry! session has not started.',
      content: contentMsg,
      onOk: () => {
        if (redirectTo) {
          const _shortId = shortId || id;
          history.push(`/survey/${_shortId}/${redirectTo}`);
        } else {
          history.push(PARTICIPANT_DASHBOARD);
        }
      },
      centered: true,
      icon: null
    });
  } else if (redirectTo) {
    const _shortId = shortId || id;
    Modal.error({
      title: 'Sorry! you have to fill out another evaluation before this.',
      content:
        'Before filling out this evaluation please submit the pre-course evaluation. Clicking OK will bring you to the right evaluation.',
      onOk: () => {
        history.push(`/survey/${_shortId}/${redirectTo}`);
        window.location.reload();
      },
      centered: true,
      icon: null
    });
  }

  useEffect(() => {
    if (surveyData.sessionId) {
      getParticipantByEmail({ sessionId: surveyData.sessionId, type });
    }
    return () => {
      resetGetParticipantByEmail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getParticipantByEmail,
    resetGetParticipantByEmail,
    // surveyData.sessionId,
    type
  ]);

  return <></>;
};

const mapStateToProps = state => {
  return {
    surveyData: state.survey.surveyData || {},
    answeredSameModule: state.survey.answeredSameModule,
    sessionDidNotStart: state.survey.sessionDidNotStart,
    contentMsg: state.survey.contentMsg,
    redirectTo: state.survey.redirectTo,
    shortId: state.survey.shortId
  };
};

export default connect(mapStateToProps, {
  getParticipantByEmail,
  resetGetParticipantByEmail
})(CheckRequirements);
