import WebsiteMenu from '../../common/WebsiteMenu';
import Footer from '../../common/Footer';

import * as S from './style';

const Website = ({ children, layoutColor, hideBottom }) => {
  return (
    <S.Wrapper layoutColor={layoutColor}>
      <WebsiteMenu />
      <S.ContentWrapper hideBottom={hideBottom} id="main-content">
        {children}
      </S.ContentWrapper>
      <Footer />
    </S.Wrapper>
  );
};

export default Website;
