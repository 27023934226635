import { Icon } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTheme } from 'styled-components';
import Spin from '../Spin';

import { concatModules } from '../../../helpers';
import * as T from '../Typography';

import { StyledLink, Wrapper } from './List.style';

import { Col, Row } from '../Grid';

// NOTE: this component expects dataList to look something like this:

const SessionList = ({ dataList, linkText }) => {
  const { colors, spacings } = useTheme();

  if (!dataList || !Array.isArray(dataList)) {
    return <Spin />;
  }
  return (
    <Wrapper mw="700px">
      <Row
        style={{
          borderBottom:
            dataList?.length > 0 ? 'none' : `1px solid ${colors.midPrimary}`
        }}
      >
        <Col w={[2, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Date
          </T.P>
        </Col>
        <Col w={[0, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Modules taught
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Location
          </T.P>
        </Col>
        <Col w={[1, 3, 3]}>
          <T.P weight={700} color="darkGray" mb="2">
            Details
          </T.P>
        </Col>
      </Row>

      {dataList?.length > 0 ? (
        dataList.map(dataItem => (
          <Row
            key={dataItem.id}
            href={dataItem.link || undefined}
            to={dataItem.link.replace(':id', dataItem.shortId)}
            target={dataItem.blank ? '_blank' : '_self'}
            style={{
              borderTop: `1px solid ${colors.midPrimary}`,
              padding: `${spacings[3]} 0`
            }}
          >
            <Col w={[2, 3, 3]}>
              <T.P color="gray">
                {moment(dataItem.date).format('DD/MM/YYYY')}
              </T.P>
            </Col>
            <Col w={[0, 3, 3]}>
              <T.P color="gray">
                {concatModules({
                  modules: dataItem.modules
                })}
              </T.P>
            </Col>
            <Col w={[1, 3, 3]}>
              <T.P color="gray">
                {dataItem?.remote
                  ? 'Online'
                  : dataItem?.address?.town ||
                    dataItem?.address?.postcode ||
                    'N/A'}
              </T.P>
            </Col>
            <Col w={[1, 3, 3]}>
              <StyledLink
                as={dataItem.asLink ? 'a' : undefined}
                href={dataItem.link || undefined}
                to={dataItem.link.replace(':id', dataItem.shortId)}
                target={dataItem.blank ? '_blank' : '_self'}
              >
                {dataItem.linkText || linkText || <Icon type="right" />}
              </StyledLink>
            </Col>
          </Row>
        ))
      ) : (
        <Row jc="center" mt="5">
          <Col w={[4, 12, 12]} jc="center">
            <T.P weight="700" color="gray" ta="center">
              No data found
            </T.P>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default SessionList;
