const Clock = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6191 3.5C7.65039 3.5 3.61914 7.53125 3.61914 12.5C3.61914 17.4688 7.65039 21.5 12.6191 21.5C17.5879 21.5 21.6191 17.4688 21.6191 12.5C21.6191 7.53125 17.5879 3.5 12.6191 3.5Z"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M12.6191 6.5V13.25H17.1191"
      stroke="#231F20"
      stroke-width="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Clock;
