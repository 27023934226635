import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ bgColor, theme, dark, transparent }) =>
    transparent
      ? 'transparent'
      : theme.colors[bgColor] || dark
      ? theme.colors.darkGray
      : theme.colors.white};
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[0]}`};
  flex: ${({ shrink }) => (shrink ? 0.8 : 1)};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  height: fit-content;
  border-radius: 4px;
  padding: ${({ theme, footer }) =>
    footer ? '0px 8.3vw' : `${theme.spacings[0]} ${theme.spacings[5]}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ footer }) => (footer ? 'row' : 'column-reverse')};

  @media (max-width: 930px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;

export const LogosSide = styled.div`
  max-width: 345px;
  margin-top: ${({ theme, footer }) => (footer ? 0 : theme.spacings[5])};
  margin-right: ${({ theme, footer }) => (footer ? 0 : theme.spacings[3])};
  @media (max-width: 930px) {
    margin-top: ${({ theme }) => theme.spacings[5]};
  }
`;

export const LogoImage = styled.img`
  width: 180px;
`;

export const ContentSide = styled.div`
  padding: ${({ theme, footer }) =>
    footer ? `${theme.spacings[0]} ${theme.spacings[4]}` : 0};
`;

export const ContentHading = styled.div``;
