import React, { useState } from 'react';

import { connect } from 'react-redux';
import ExportButton from '../ExportButton';
import FilterResults from '../FilterResults';
import { Col, Row } from '../Grid';
import * as T from '../Typography';

const BehavioralTrainerResults = ({ filters, role }) => {
  const [demographicsFilters, setDemographicsFilters] = useState(filters);

  return (
    <div>
      <T.P>
        Export the demographic data of all registered participants for this
        programme.
      </T.P>
      <FilterResults
        role={role}
        applyFiltersCb={_filters => {
          setDemographicsFilters(_filters);
        }}
      />
      <Row inner mt={4}>
        <Col w={[4, 6, 6]}>
          <ExportButton
            filters={{
              ...filters,
              ...demographicsFilters
            }}
            type="primary"
            text="Export Demographics Data"
            height="50px"
            exportType="demographics"
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role
});

export default connect(mapStateToProps, {})(BehavioralTrainerResults);
