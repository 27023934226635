const Glass = ({ width, height, color, ...props }) => (
  <svg
    width={width || '100'}
    height={height || '101'}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.0478 66.3269L49.3244 62.7485L67.8758 53.8224C75.3231 50.2362 79.2506 42.0978 77.4273 34.037L71.1863 6.47787H47.6792V2.50415H74.3606L81.3002 33.16C83.5354 43.0369 78.7227 53.007 69.599 57.4009L51.0478 66.3269Z"
      fill={color || 'white'}
    />
    <path
      d="M52.6407 96.8745H48.6668V65.7873L31.2446 57.4009C22.1167 53.0078 17.3081 43.0412 19.5434 33.1601L26.4829 2.50415H53.1644V6.47811H29.6573L23.4165 34.037C21.593 42.102 25.5205 50.2362 32.9679 53.8224L52.6407 63.2881V96.8745Z"
      fill={color || 'white'}
    />
    <path
      d="M79.6498 93.9192H22.374V97.8931H79.6498V93.9192Z"
      fill={color || 'white'}
    />
    <path
      d="M79.4216 32.1986H21.4216V36.1986H79.4216V32.1986Z"
      fill={color || 'white'}
    />
  </svg>
);
export default Glass;
