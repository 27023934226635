import { StyledSelect } from '../Questions.style';

const Dropdown = ({ question, setQuestion, onBlur, disabled }) => {
  const { _id: questionId, options, multiple, answer } = question;

  const onChange = value => {
    setQuestion({
      _id: questionId,
      answer: value,
      answered: !!value
    });
    if (value) onBlur(value);
  };

  const optionsList = options?.map(option => ({
    label: option,
    value: option
  }));

  return (
    <StyledSelect
      value={
        Array.isArray(answer)
          ? answer.map(value => ({ label: value, value }))
          : answer
          ? { label: answer, value: answer }
          : null
      }
      onChange={selected =>
        onChange(
          selected
            ? Array.isArray(selected)
              ? selected.map(opt => opt.value)
              : selected.value
            : null
        )
      }
      options={optionsList}
      isMulti={multiple}
      isDisabled={disabled}
      aria-labelledby={`qs-text-${questionId}`}
      aria-describedby={
        Array.isArray(answer)
          ? answer.map(opt => `option-${opt}`).join(' ')
          : `option-${answer}`
      }
      placeholder="Select an option"
      closeMenuOnSelect={false}
    />
  );
};

export default Dropdown;
