/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';

// COMMON COMPONENTS
import * as T from '../../common/Typography';
import Layout from '../../Layouts';
import SessionList from './SessionsList';

// ACTIONS
import { fetchParticipantSessions } from '../../../actions/groupSessionsAction';

// STYLING
import { Wrapper } from './ParticipantProgress.style';

class ParticipantProgress extends Component {
  componentDidMount() {
    const { PIN } = this.props;
    this.props.fetchParticipantSessions({ PIN, history: this.props.history });
  }

  render() {
    const { sessions } = this.props;

    if (!sessions) {
      return <div>loading</div>;
    }
    return (
      <Layout>
        <Wrapper>
          <T.H1 mb="8">Completed Sessions</T.H1>
          <SessionList dataList={sessions.filter(item => item.completed)} />
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    PIN: state.auth.PIN,
    sessions: state.sessions.participantSessions
  };
};

export default connect(mapStateToProps, { fetchParticipantSessions })(
  ParticipantProgress
);
