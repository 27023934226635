import styled from 'styled-components';
import setMargins from '../../../helpers/setMargin';

export const Tip = styled.div`
  ${setMargins};
  background: ${({ theme, bgColor }) => theme.colors[bgColor] || bgColor};
  border-radius: ${({ theme: { borders } }) => borders.radiusLarge};
  display: flex;
  align-items: flex-start;
  padding: ${({ theme: { spacings } }) => `${spacings[5]} ${spacings[4]} 17px`};
  border: 2px dashed
    ${({ theme, borderColor }) => theme.colors[borderColor] || borderColor};
  box-sizing: border-box; /* Ensure the border is included in the element's total width and height */
`;

export const TipContent = styled.div`
  margin-bottom: 7px;
`;
