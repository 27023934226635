import React from 'react';

import Badge from '../../common/Badge';
import * as T from '../../common/Typography';

import {
  INDIVIDUAL_PROGRAMME,
  MODULE_STEPS_URL,
  PARTICIPANT_SESSION_DETAILS
} from '../../../constants/navigationRoutes';
import { moduleFormatTypes } from '../../../constants/sessionData';

const renderFormatBadge = isLive => {
  if (!isLive) {
    return (
      <Badge title={moduleFormatTypes.E_LEARNING.label} variant="primary" />
    );
  } else {
    return (
      <Badge title={moduleFormatTypes.LIVE_EVENT.label} variant="tertiary" />
    );
  }
};

export const TableColumns = isTablet => {
  const commonColumns = [
    {
      title: 'Module',
      dataIndex: 'title',
      key: 'title',
      render: (text, row) => (
        <T.Link
          to={
            row?.isLive
              ? PARTICIPANT_SESSION_DETAILS.replace(':id', row.sessionId)
              : MODULE_STEPS_URL.replace(':id', row._id)
          }
        >
          {text}
        </T.Link>
      )
    },
    {
      title: 'Format',
      key: 'isLive',
      dataIndex: 'isLive',
      render: isLive => renderFormatBadge(isLive)
    }
  ];

  const desktopColumns = [
    {
      title: 'Programme',
      key: 'programme',
      dataIndex: 'programme.titleShort',
      render: (text, row) => (
        <T.Link to={INDIVIDUAL_PROGRAMME.replace(':slug', row.programme?.slug)}>
          {text}
        </T.Link>
      ),
      width: '30%'
    }
  ];

  return !isTablet ? commonColumns : [...commonColumns, ...desktopColumns];
};
