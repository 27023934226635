import { connect } from 'react-redux';
import { setLayoutColor } from '../../../actions/accessibilityActions';
import * as T from '../../common/Typography';
import * as S from './style';

const colors = [
  {
    label: 'Blue',
    value: 'irlenBlue'
  },
  {
    label: 'Green',
    value: 'irlenGreen'
  },
  {
    label: 'Yellow',
    value: 'irlenYellow'
  },
  {
    label: 'Red',
    value: 'irlenRed'
  }
];

const OverlayColor = ({ layoutColor, setLayoutColor }) => {
  const handleSelect = color => {
    if (color === layoutColor) {
      localStorage.setItem('layoutColor', '');
      setLayoutColor('');
    } else {
      localStorage.setItem('layoutColor', color);
      setLayoutColor(color);
    }
  };

  return (
    <>
      <T.H3 color="neutralMain" mb="4" ml="2">
        Add colour overlay
      </T.H3>
      <S.ButtonsWrapper>
        {colors.map((item, index) => (
          <S.Button
            value={item.value}
            key={item.value + index}
            isActive={layoutColor === item.value}
            onClick={() => handleSelect(item.value)}
            aria-label={`Change color overlay to ${item.label}`}
          >
            <S.ColorWrapper bgColor={item.value} />
            <S.TextWrapper>
              <T.P
                color={
                  layoutColor === item.value ? 'neutralSurface' : 'neutralMain'
                }
                weight="normal"
              >
                {colors[index].label}
              </T.P>
            </S.TextWrapper>
          </S.Button>
        ))}
      </S.ButtonsWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  layoutColor: state.accessibility.layoutColor
});

export default connect(mapStateToProps, {
  setLayoutColor
})(OverlayColor);
