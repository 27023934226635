import { Col, Row } from '../Grid';
import HeaderImage from '../HeaderImage/index';
import MaxWidthContainer from '../MaxWidthContainer';
import * as T from '../Typography';
import * as S from './style';

const ProgrammeHeader = ({
  title,
  subtitle,
  description,
  image,
  withOverlay = false,
  ...props
}) => {
  const descriptionStrings = description?.split('\n').filter(Boolean);

  return (
    <MaxWidthContainer mobilePadding="0px">
      <S.Wrapper description={description} {...props}>
        {/* desktop view */}
        <S.HideInTablet>
          <Row inner>
            <Col w={[4, 4, 4.5]}>
              <HeaderImage image={image} withOverlay={withOverlay} />
            </Col>
            <Col w={[4, 8, 7.5]} as="center">
              <S.ContentWrapper>
                <T.H1W color="darkGray" mb={(description || subtitle) && '5'}>
                  {title}
                </T.H1W>
                <S.Divider />
                {subtitle && <T.H2W color="gray">{subtitle}</T.H2W>}
                {descriptionStrings?.map(st => (
                  <>
                    <T.P color="darkGray" smallMedium>
                      {st}
                    </T.P>
                    <br />
                  </>
                ))}
              </S.ContentWrapper>
            </Col>
          </Row>
        </S.HideInTablet>
        {/* mobile view */}
        <S.HideInDesktop>
          <HeaderImage
            image={image}
            rounded={false}
            width={1000}
            withOverlay={withOverlay}
          />
          <S.ContentWrapper>
            <T.H2W color="darkGray" mb="2" mt="16px">
              {title}
            </T.H2W>
            <S.Divider full />
            {subtitle && <T.H4W color="gray">{subtitle}</T.H4W>}
            {descriptionStrings?.map(st => (
              <>
                <T.P color="darkGray">{st}</T.P> <br />
              </>
            ))}
          </S.ContentWrapper>
        </S.HideInDesktop>
      </S.Wrapper>
    </MaxWidthContainer>
  );
};

export default ProgrammeHeader;
