import { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import * as R from '../../../constants/navigationRoutes';
import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { useHistory } from 'react-router-dom';
import { signupUserViaInvitationLink } from '../../../actions/authAction';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import Spin from '../../common/Spin';

import { BasicInput, Checkbox, Dropdown } from '../../common/Inputs';

import { fetchUserInvitationDetailsByToken } from '../../../actions/users';
import {
  northEastAndNorthCumbriaICBOrganisations,
  localAuthoritiesBasic as localAuthoritiesOptions,
  organisations as organisationsOptions
} from '../../../constants';
import useFormData from '../../../hooks/useFormData';
import useSearchParams from '../../../hooks/useSearchParams';
import useValidateFormData from '../../../hooks/useValidateFormData';
import validate from '../../../validation/schemas/signup';
import IEMessage from '../../common/IEMessage';
import { ContentWrapper, Form, Wrapper, CheckboxLabel } from './SignUp.style';

const initialFormState = {
  name: '',
  email: '',
  password: '',
  localAuthorities: '',
  agreeToTerms: '',
  organisation: { value: '', category: '' },
  organisationOther: '',
  submitAttempt: false,
  validationErrors: {},
  showOrgOther: false,
  showOrgIcb: false,
  jobRole: '',
  phone: '',
  newsletterSubscription: false,
  participantUpgrade: false
};

const SignUp = ({
  isAuthenticated,
  authUser,
  loading,
  httpError,
  userInvitation,
  fetchUserInvitationLoading,
  fetchUserInvitationDetailsByToken,
  signupUserViaInvitationLink,
  signupViaInvitationLoading
}) => {
  const searchParams = useSearchParams();

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const token = searchParams.params.token;

  const history = useHistory();

  const {
    name,
    email,
    password,
    organisation,
    localAuthorities,
    agreeToTerms,
    organisationOther,
    showOrgOther,
    showOrgIcb,
    jobRole,
    phone,
    newsletterSubscription
  } = formState;

  const participantUpgrade = useMemo(() => userInvitation?.isExistingUser, [
    userInvitation?.isExistingUser
  ]);

  const handleSubmit = e => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async () => {
    const { current: finalFormState } = finalSubmissionData;

    const payload = {
      ...finalFormState,
      invitationToken: token,
      participantUpgrade: !!participantUpgrade,
      organisation: {
        ...organisation,
        value:
          showOrgOther || showOrgIcb ? organisationOther : organisation.value
      }
    };

    await signupUserViaInvitationLink(payload);

    return history.push(R.DASHBOARD_URL);
  };

  useEffect(() => {
    if (userInvitation) {
      setFormData({ email: userInvitation.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInvitation]);

  useEffect(() => {
    setFormData({ participantUpgrade });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantUpgrade]);

  useEffect(() => {
    fetchUserInvitationDetailsByToken(token);
  }, [fetchUserInvitationDetailsByToken, token]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    password,
    organisation,
    localAuthorities,
    jobRole,
    phone,
    newsletterSubscription
  ]);

  useEffect(() => {
    if (organisation.value.includes('ICB')) {
      setFormData({
        showOrgIcb: true,
        showOrgOther: false
      });
    } else if (organisation.value.includes('specify')) {
      setFormData({
        showOrgIcb: false,
        showOrgOther: true
      });
    } else {
      setFormData({
        showOrgIcb: false,
        showOrgOther: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation.value]);

  if (!token || fetchUserInvitationLoading || signupViaInvitationLoading) {
    return <Spin />;
  }

  if (
    isAuthenticated &&
    userInvitation &&
    authUser?.email !== userInvitation?.email
  ) {
    return (
      <Layout>
        <T.P>
          {' '}
          You are already logged in with a different account. Please log out and
          try again with the correct account.
        </T.P>
      </Layout>
    );
  }

  if (!userInvitation) {
    return (
      <Layout>
        <T.P>
          The invitation link is invalid or expired. Please contact the
          administrator.
        </T.P>
      </Layout>
    );
  }

  const InitialSignupForm = (
    <Form className="signup-form">
      <BasicInput
        value={name}
        handleChange={value => setFormData({ name: value })}
        mb={4}
        label="Name"
        placeholder="Type your name here"
        required
        error={validationErrors.name}
      />
      <BasicInput
        value={email}
        handleChange={value => setFormData({ email: value })}
        label="Email"
        placeholder="Type your email here"
        mb={4}
        required
        error={
          validationErrors.email ||
          (httpError?.response?.status === 409 &&
            httpError?.response?.data?.error)
        }
        disabled
      />
      <BasicInput
        value={password}
        label="Create password"
        required
        placeholder="Password"
        mb={4}
        type="password"
        handleChange={value => setFormData({ password: value })}
        error={validationErrors.password}
      />
      <BasicInput
        value={phone}
        handleChange={value => setFormData({ phone: value })}
        mb={4}
        label="Phone"
        placeholder="Type your phone here"
        required
        error={validationErrors.phone}
      />
      <BasicInput
        value={jobRole}
        handleChange={value => setFormData({ jobRole: value })}
        mb={4}
        label="Job Title"
        placeholder="Type your job title here"
        required
        error={validationErrors.jobRole}
      />
      <Dropdown
        selected={organisation.value}
        m={{ mb: 4 }}
        label="Organisation"
        placeholder="Select"
        required
        options={organisationsOptions}
        handleChange={(value, option, b) =>
          setFormData({
            organisation: {
              value,
              category: option?.key?.split('_')[0]
            }
          })
        }
      />
      {showOrgOther && (
        <BasicInput
          value={organisationOther}
          autoFocus
          handleChange={value => setFormData({ organisationOther: value })}
          placeholder="Type organisation here..."
          mb={3}
          error={
            validationErrors.organisation || validationErrors.organisationOther
          }
        />
      )}
      {showOrgIcb && (
        <Dropdown
          selected={organisationOther}
          m={{ mb: 3 }}
          placeholder="Select directorate..."
          required
          options={northEastAndNorthCumbriaICBOrganisations.map(org => ({
            value: org,
            label: org
          }))}
          handleChange={value => setFormData({ organisationOther: value })}
        />
      )}
      <Dropdown
        m={{ mb: 4 }}
        label="In which local authorities do you expect to deliver the training?"
        required
        placeholder="Select as many as you like or add new"
        options={localAuthoritiesOptions.map(i => ({
          label: i,
          value: i
        }))}
        addNew
        multi
        selected={localAuthorities}
        handleChange={values => setFormData({ localAuthorities: values })}
        error={validationErrors.localAuthorities}
      />

      <Checkbox
        checked={newsletterSubscription}
        handleChange={value => setFormData({ newsletterSubscription: value })}
        mb={3}
        label="I wish to receive Boost monthly bulletin updates via email"
      />
      <Checkbox
        checked={agreeToTerms}
        handleChange={value => setFormData({ agreeToTerms: value })}
        mb={3}
        label={
          <CheckboxLabel>
            I agree to the{' '}
            <T.Link external href={R.TERMS_OF_USE}>
              Terms and Conditions
            </T.Link>{' '}
            and acknowledge the{' '}
            <T.Link external href={R.PRIVACY_POLICY}>
              Privacy Policy
            </T.Link>
          </CheckboxLabel>
        }
      />
      <Row>
        <Col w={[4, 6, 6]} mt={4}>
          {httpError?.response?.data?.error && (
            <T.P color="error"> {httpError?.response?.data?.error}</T.P>
          )}
          <Button
            onClick={handleSubmit}
            type="primary"
            label="Create account"
            loading={loading}
            disabled={!agreeToTerms}
          />
        </Col>
      </Row>

      <T.P mt={4}>
        Already have an account?{' '}
        <T.Link
          to={{
            pathname: '/login'
          }}
        >
          Log in
        </T.Link>
      </T.P>
    </Form>
  );

  const LoginNeeded = (
    <T.P weight={600}>
      Please{' '}
      <T.Link
        d="inline"
        to={{
          pathname: '/login',
          search: `?token=${token}&source=invitation`
        }}
      >
        login
      </T.Link>{' '}
      with your participant account to complete trainer sign up.
    </T.P>
  );

  const ParticipantSignupForm = (
    <Form className="signup-form">
      <Dropdown
        m={{ mb: 4 }}
        label="In which local authorities do you expect to deliver the training?"
        required
        placeholder="Select as many as you like or add new"
        options={localAuthoritiesOptions.map(i => ({
          label: i,
          value: i
        }))}
        addNew
        multi
        selected={localAuthorities}
        handleChange={values => setFormData({ localAuthorities: values })}
        error={validationErrors.localAuthorities}
      />
      <Row>
        <Col w={[4, 6, 6]} mt={4}>
          {httpError?.response?.data?.error && (
            <T.P color="error"> {httpError?.response?.data?.error}</T.P>
          )}
          <Button
            onClick={handleSubmit}
            type="primary"
            label="Submit"
            loading={loading}
            disabled={!localAuthorities.length}
          />
        </Col>
      </Row>
    </Form>
  );

  return (
    <Layout>
      <T.H1 mb="7">
        {!participantUpgrade ? 'Sign up' : 'Complete Trainer Sign Up'}
      </T.H1>
      <Wrapper className="sign-up">
        <IEMessage />
        <Row>
          <Col w={[4, 12, 8]}>
            <ContentWrapper>
              <T.P weight={400} mb="6">
                {!participantUpgrade ? (
                  <>
                    {userInvitation.fullName ? (
                      <>
                        Welcome{' '}
                        <T.P d="inline" weight={600}>
                          {userInvitation.fullName}.
                        </T.P>
                      </>
                    ) : (
                      'Welcome.'
                    )}{' '}
                    You have been invited to create an account on the Learning
                    Academy.
                  </>
                ) : (
                  <>
                    <T.P weight={400} mb="6">
                      Hi,{' '}
                      <T.P d="inline" weight={600}>
                        {userInvitation.fullName}.
                      </T.P>{' '}
                      To complete creating your new account as{' '}
                      {userInvitation.roles.length > 1
                        ? `(${userInvitation.roles
                            .slice(0, -1)
                            .join(', ')} and ${userInvitation.roles.at(-1)})`
                        : userInvitation.roles[0]}{' '}
                      on the platform, please login here.
                    </T.P>
                  </>
                )}
              </T.P>
              {!participantUpgrade && InitialSignupForm}
              {participantUpgrade && !isAuthenticated && LoginNeeded}
              {participantUpgrade && isAuthenticated && ParticipantSignupForm}
            </ContentWrapper>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  authUser: state.auth,
  httpError: state.auth.error,
  loading: state.loading.signupLoading,
  userInvitation: state.users.userInvitation,
  fetchUserInvitationLoading: state.loading.fetchUserInvitationLoading,
  signupViaInvitationLoading: state.loading.signupViaInvitationLoading
});

export default connect(mapStateToProps, {
  fetchUserInvitationDetailsByToken,
  signupUserViaInvitationLink
})(SignUp);
