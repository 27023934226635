const BoostCog = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width={width || '164'}
    height={height || '164'}
    viewBox="0 0 164 164"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }}
  >
    <path
      fill={color || '#fec30d'}
      d="M69.5 3.5c8.776-.44 17.442.06 26 1.5 1.393 1.171 2.227 2.671 2.5 4.5a52.514 52.514 0 0 0 3 15c2.003 2.422 4.503 3.255 7.5 2.5l14-9c3.051-.857 5.717-.19 8 2A138.26 138.26 0 0 1 144 34.5a16.242 16.242 0 0 1 0 8l-8 12c-1.041 3.136-.207 5.636 2.5 7.5a122.512 122.512 0 0 0 17 4c2.608 1.378 4.108 3.545 4.5 6.5a105.67 105.67 0 0 1-2 23 506.192 506.192 0 0 1-19.5 6.5c-1.51 1.351-2.51 3.018-3 5a103.62 103.62 0 0 0 9.5 14.5 12.494 12.494 0 0 1 0 7 192.88 192.88 0 0 1-16.5 16.5 16.242 16.242 0 0 1-8 0l-10-8c-4.509-2.607-7.675-1.441-9.5 3.5a87.212 87.212 0 0 0-3 14 29.465 29.465 0 0 1-2.5 4.5c-9 .667-18 .667-27 0-1.987-1.319-3.154-3.152-3.5-5.5a74.774 74.774 0 0 0-3-15c-3.21-3.528-6.71-3.694-10.5-.5-4.25 5.131-9.583 7.465-16 7A203.814 203.814 0 0 1 19 127.5c-.584-2.757-.25-5.423 1-8a52.187 52.187 0 0 0 7-10c1.057-4.386-.443-7.553-4.5-9.5a464.336 464.336 0 0 1-15-3c-1.833-.5-3-1.667-3.5-3.5-.94-8.77-.607-17.436 1-26 1.42-.96 2.92-1.793 4.5-2.5a78.311 78.311 0 0 0 13-2c4.538-1.713 6.038-4.88 4.5-9.5a72.825 72.825 0 0 1-8-14c-.635-2.135-.301-4.135 1-6A85.293 85.293 0 0 1 35.5 18c2-.667 4-.667 6 0l13 9c3.525.658 6.359-.509 8.5-3.5l3-17a30.143 30.143 0 0 1 3.5-3Zm7 54c20.593-.574 30.093 9.426 28.5 30-5.037 14.769-15.204 20.602-30.5 17.5C57.866 97.29 53.366 85.122 61 68.5c4.174-5.314 9.34-8.98 15.5-11Z"
    />
  </svg>
);

export default BoostCog;
