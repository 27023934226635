import { connect } from 'react-redux';
import useDownloadCertificate from '../../../hooks/useDownloadCertificate';
import { Row } from '../../common/Grid';
import * as T from '../../common/Typography';

import { StyledButton } from './style';

const Certificates = ({ session, email }) => {
  const { downloading, handleDownload } = useDownloadCertificate();

  const handleDownloadCertificate = module => {
    handleDownload({
      moduleName: module.title,
      moduleId: module._id,
      email,
      sessionId: session._id
    });
  };

  return (
    <Row>
      <T.P mb={2}>
        You can download certificate(s) for the module(s) you completed at this
        event below:
      </T.P>
      {session?.modules?.map((module, index) => (
        <Row key={module._id}>
          <StyledButton
            label={`Module ${index + 1} Certificate`}
            onClick={() => handleDownloadCertificate(module)}
            type="primary"
            mb={3}
            disabled={downloading}
            loading={downloading}
          />
        </Row>
      ))}
    </Row>
  );
};

const mapStateToProps = state => ({
  session: state.participantSession?.participantSessionDetails?.sessionDetails,
  email: state.auth.email
});

export default connect(mapStateToProps, {})(Certificates);
