import { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { fetchModules } from '../../../actions/modulesActions';
import { fetchProgrammeBySlug } from '../../../actions/programmesActions';
import useSearchParams from '../../../hooks/useSearchParams';
import Layout from '../../Layouts';
import Table from '../../common/BoostTable';
import CatHeader from '../../common/CatHeader';
import { Col, Row } from '../../common/Grid';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import ProgrammeHeader from '../../common/ProgrammeHeader';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import { TableColumns } from './ModulesTableColumns';
import * as S from './style';

const getCardColors = index => {
  if (index % 3 === 0) {
    return { bgColor: 'mainPrimary', textColor: 'darkGray' };
  } else if (index % 3 === 1) {
    return { bgColor: 'mainSecondary', textColor: 'darkGray' };
  } else {
    return { bgColor: 'darkGray', textColor: 'white' };
  }
};

const ProgrammeCatalogue = ({
  programme,
  modules,
  fetchProgrammeBySlug,
  fetchModules,
  loading
}) => {
  const { slug } = useParams();
  const searchParams = useSearchParams();
  const categorySlug = searchParams?.params?.category;

  const scrolled = useRef(false);

  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery({
    query: breakpoints.tablet
  });

  useEffect(() => {
    fetchProgrammeBySlug(slug, { populate: 'categories' });
  }, [fetchProgrammeBySlug, slug]);

  useEffect(() => {
    if (!!programme) {
      fetchModules({
        programme: programme._id,
        forPublic: true
      });
    }
  }, [programme, fetchModules]);

  const categories = useMemo(
    () =>
      programme?.categories
        ?.filter(category => !category?.archived)
        ?.map(category => ({
          ...category,
          modules: modules?.filter(module => module.category === category._id)
        }))
        .filter(category => !!category.modules?.length) || [],
    [modules, programme?.categories]
  );

  useEffect(() => {
    if (!!categories.length && categorySlug && !scrolled.current) {
      const categorySection = document.getElementById(categorySlug);
      if (categorySection) {
        categorySection.scrollIntoView({ behavior: 'smooth' });
        scrolled.current = true;
      }
    }
  }, [categories, categorySlug]);

  if (!programme || !modules || loading) {
    return <Spin />;
  }

  return (
    <Layout layout="website">
      <S.TopRow>
        <ProgrammeHeader
          title={programme.title}
          subtitle="Training Catalogue"
          image={programme?.imageLink?.fileUrl}
        />
      </S.TopRow>
      <MaxWidthContainer>
        {!categories.length && (
          <T.P>
            This programme doesn't have a training catalogue offering at the
            moment. Please, come back later.
          </T.P>
        )}
        <Row>
          {categories.map((category, index) => (
            <Col id={category.slug} w={[4, 12, 12]} mb={9} mt={5}>
              <S.CategoryWrapper>
                <CatHeader
                  title={category.title}
                  subtitle={category.description}
                  {...getCardColors(index)}
                />
                <Table
                  data={category.modules || []}
                  columns={TableColumns(isTablet)}
                />
              </S.CategoryWrapper>
            </Col>
          ))}
        </Row>
      </MaxWidthContainer>
    </Layout>
  );
};

const mapStateToProps = state => ({
  programme: state.programmes.programme,
  modules: state.modules?.modules,
  loading: state.loading.fetchProgrammeLoading
});

export default connect(mapStateToProps, {
  fetchProgrammeBySlug,
  fetchModules
})(ProgrammeCatalogue);
