import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import setMargin from '../../../helpers/setMargin';
import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const weights = {
  900: '900 !important',
  700: '700 !important',
  600: '600 !important',
  500: '500 !important',
  400: '400 !important'
};

const text = {
  caps: 'uppercase',
  capitalize: 'capitalize'
};

const commonStyle = ({ theme, color, transform, ta, d, width, ...props }) => `
  font-style: normal !important;
  letter-spacing: 0px !important;
  color: ${theme.colors[color] || color || theme.colors.darkGray} !important;
  text-transform: ${transform ? text[transform] : 'initial'} !important;
  text-align: ${ta || 'left'} !important;
  display: ${d || 'block'} !important;
  max-width: 740px;
  width: ${width || 'auto'};
  ${d === 'block' ? 'width: 100% !important;' : ''}
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
`;

const Head1 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 32px) !important;
  line-height: 120% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
`;
export const H1 = props => <Head1 {...props} level={1} />;

const Head2 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 24px) !important;
  line-height: 160% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
`;
export const H2 = props => <Head2 {...props} level={2} />;

const Head3 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 20px) !important;
  line-height: 160% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
`;
export const H3 = props => <Head3 {...props} level={3} />;

const Head4 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 18px) !important;
  line-height: 160% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
`;
export const H4 = props => <Head4 {...props} level={4} />;

const Head5 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 14px) !important;
  line-height: 150% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
`;
export const H5 = props => <Head5 {...props} level={4} />;

const Head1W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 44px) !important;
  line-height: 120% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[900]};
  letter-spacing: -1%;
  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    font-size: calc(var(--font-size-adjustment) * 40px) !important;
    line-height: 120% !important;
  }
`;
export const H1W = props => <Head1W {...props} level={1} />;

const Head2W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 32px) !important;
  line-height: 130% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
  letter-spacing: -1%;
  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    font-size: calc(var(--font-size-adjustment) * 24px) !important;
    line-height: 130% !important;
  }
`;

export const H2W = props => <Head2W {...props} level={2} />;

const Head3W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 24px) !important;
  line-height: 130% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[700]};
  letter-spacing: -1%;
  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    font-size: calc(var(--font-size-adjustment) * 20px) !important;
    line-height: 130% !important;
  }
`;
export const H3W = props => <Head3W {...props} level={3} />;

const Head4W = styled(Title)`
  ${setMargin};
  ${commonStyle};

  font-size: calc(var(--font-size-adjustment) * 20px) !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -1%;
  @media ${({ theme }) => theme.breakpointsMax.laptop} {
    font-size: calc(var(--font-size-adjustment) * 18px) !important;
    line-height: 140% !important;
  }
`;
export const H4W = props => <Head4W {...props} level={4} />;

const Head5W = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: calc(var(--font-size-adjustment) * 18px) !important;
  line-height: 140% !important;
  font-weight: ${({ weight }) => weights[weight] || weights[600]};
  letter-spacing: -1%;
`;
export const H5W = props => <Head5W {...props} level={4} />;

export const P = styled(Paragraph)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ small, large, medium, smallMedium }) => {
    if (large) {
      return 'calc(var(--font-size-adjustment) * 48px) !important';
    } else if (medium) {
      return 'calc(var(--font-size-adjustment) * 24px) !important';
    } else if (smallMedium) {
      return 'calc(var(--font-size-adjustment) * 20px) !important';
    } else if (small) {
      return 'calc(var(--font-size-adjustment) * 16px) !important';
    } else {
      return 'calc(var(--font-size-adjustment) * 18px) !important';
    }
  }};
  line-height: 160% !important;
  font-weight: ${({ weight }) => (weight ? weights[weight] : weights[500])};
  font-family: 'Frutiger', 'museo-sans', sans-serif;
  pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

const commonLinkStyle = ({
  theme,
  color,
  underline,
  weight,
  block,
  small,
  large,
  ...props
}) => `
  font-style: normal !important;
  font-size:${
    small
      ? 'calc(var(--font-size-adjustment) * 14px)'
      : large
      ? 'calc(var(--font-size-adjustment) * 20px)'
      : 'calc(var(--font-size-adjustment) * 16px)'
  } !important;
  line-height: 160% !important;
  font-weight: ${weights[weight] || weights[700]};
  text-decoration: ${underline ? 'underline' : 'none'} !important;
  padding-bottom: 0px;
  display:${block ? 'block' : 'inline-block'};
  width: fit-content;
  min-height: 24px;

  :focus {
    outline: 2px solid ${theme.colors[color] || color || theme.colors.darkGray};
    offset: 2px;
    border-radius: 4px;
  } 

`;

const StyledLink = styled(RouterLink)`
  ${setMargin};
  ${commonStyle};
  ${commonLinkStyle};
`;
const ExternalLink = styled.a`
  ${setMargin};
  ${commonStyle};
  ${commonLinkStyle};
`;

export const Link = ({
  to,
  external = false,
  underline = true,
  block = true,
  d = 'inline',
  small,
  ...props
}) => {
  return external ? (
    <ExternalLink
      target="_blank"
      href={to}
      to={to}
      underline={underline}
      block={block}
      d={d}
      small={small}
      {...props}
    />
  ) : (
    <RouterLink
      to={to}
      component={() => (
        <StyledLink
          to={to}
          underline={underline}
          block={block}
          d={d}
          small={small}
          {...props}
        />
      )}
    />
  );
};
