import { fields, createSchema, validate as _validate } from '..';

const { password, passwordConfirmation } = fields;

const schema = createSchema({
  password,
  passwordConfirmation
});

const validate = data => {
  return _validate(schema, data);
};

export default validate;
