import { SET_COURSE_CONTEXT } from '../constants/actionTypes';

const initState = {
  selectedCourse: null
};

const course = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COURSE_CONTEXT:
      return { ...state, selectedCourse: payload };
    default:
      return state;
  }
};

export default course;
