import { useMediaQuery } from 'react-responsive';

import { useTheme } from 'styled-components';
import * as T from '../../common/Typography';
import Button from './../../common/Button';
import * as S from './style';

import MakingEveryContactCountImg from '../../../assets/MakingEveryContactCount.svg';
import NorthEastNorthCumbriaImg from '../../../assets/NorthEastNorthCumbria-NHS.svg';
import Markdown from '../../common/Markdown';

const CalloutSection = ({ findOutMoreText, catalogueLink }) => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery({
    query: breakpoints.laptop
  });

  return (
    <S.CallOut>
      <S.CallOutRow2>
        <S.BlueCard>
          <T.H2W color="darkGray" mb="4" mbT="2">
            Find out more
          </T.H2W>
          <S.SubtitleWrapper>
            <T.P
              color="darkGray"
              mb="4"
              mbT="3"
              smallMedium={!!isDesktop}
              small={!isDesktop}
              weight={'400'}
            >
              <Markdown text={findOutMoreText} />
            </T.P>
          </S.SubtitleWrapper>

          <Button
            to={catalogueLink}
            type={isDesktop ? 'secondary' : 'small'}
            label="View the training catalogue"
            width={isDesktop ? '270px' : '213px'}
            height="auto"
          />
        </S.BlueCard>
        <S.BlackCard>
          <T.H2W color="white" mb="4" mbT="2">
            Sign up to the Boost Learning Academy for full access to our
            programmes, modules and events
          </T.H2W>
          <T.P
            color="white"
            mb="5"
            mbT="4"
            smallMedium={!!isDesktop}
            small={!isDesktop}
            weight={'400'}
          >
            It was created in collaboration with
          </T.P>

          <S.CollaborateCol>
            <S.CollaborateRow>
              <S.CollaborateDiv small>
                <S.Img
                  src={NorthEastNorthCumbriaImg}
                  alt="NorthEastNorthCumbriaImg"
                  maxWidth="146px"
                />
              </S.CollaborateDiv>
              <S.CollaborateDiv small>
                <S.Img
                  src={MakingEveryContactCountImg}
                  alt="MakingEveryContactCountImg"
                  maxWidth="92px"
                />
              </S.CollaborateDiv>
            </S.CollaborateRow>
          </S.CollaborateCol>
        </S.BlackCard>
      </S.CallOutRow2>
    </S.CallOut>
  );
};

export default CalloutSection;
